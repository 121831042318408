import React from "react";
import { Grid } from "@mui/material";

import Resim from "../../assets/imgs/image.jpg";
import { useNavigate } from "react-router-dom";

export const AdsVerticalMefta = ({ ads }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent={"space-around"}
      alignItems={"center"}
      sx={{ width: "100%", pt: 4 }}
    >
      {ads.map((data) => {
        if (data.order < 4 && data.condoglianze === "false")
          return (
            <Grid item lg={4} md={4} sm={4} xs={4}>
              {data.imgSrc === "" ? (
                <Grid
                  sx={{ cursor: "pointer", pr: 2 }}
                  onClick={() => {
                    navigate("/istekFormu", { state: { id: data } });
                  }}
                >
                  <img src={Resim} style={{ width: "100%", height: "100%" }} />
                </Grid>
              ) : (
                <Grid sx={{ cursor: "pointer", pr: 2 }}>
                  <a key={data._id} href={data.redirectUrl} target="_blank">
                    <img
                      src={data.imgSrc}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                </Grid>
              )}
            </Grid>
          );
      })}
    </Grid>
  );
};
