import React, { useEffect } from "react";
import "../turkeymap.css";

export const Amasya = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            console.log(event.target.parentElement.id);
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(25.000000, 42.000000)">
                <g id="goynucek" transform="translate(97.000000, 185.000000)">
                  <path
                    d="M0,96 L22,96 L35,66 L110,30 L85,0 L64,10 L38,1 L3,50 L10,68 L0,96 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Göynücek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40" y="44">
                      GYN
                    </tspan>
                  </text>
                </g>
                <g id="gumushacikoy" transform="translate(3.000000, 0.000000)">
                  <path
                    d="M68,22 L54,23 L34,3 L16,0 L13,55 L0,67 L26,83 L45,120 L58,123 L62,92 L84,84 L88,63 L68,22 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gümüşhacıköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="70">
                      GMŞ
                    </tspan>
                  </text>
                </g>
                <g id="hamamozu" transform="translate(0.000000, 67.000000)">
                  <path
                    d="M3,0 L0,9 L8,49 L23,62 L48,53 L29,16 L3,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hamamözü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13" y="41">
                      HMM
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(108.000000, 70.000000)">
                  <path
                    d="M99,145 L108,118 L138,124 L150,118 L160,131 L187,131 L223,107 L214,88 L173,52 L164,3 L131,0 L98,35 L83,61 L61,68 L37,61 L26,69 L9,69 L0,77 L15,93 L32,104 L27,116 L53,125 L74,115 L99,145 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="117" y="83">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="merzifon" transform="translate(61.000000, 18.000000)">
                  <path
                    d="M106,41 L94,24 L64,20 L65,0 L10,4 L30,45 L26,66 L4,74 L0,105 L21,113 L28,131 L47,129 L56,121 L73,121 L84,113 L84,85 L93,79 L92,63 L106,41 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merzifon"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49" y="71">
                      MRZ
                    </tspan>
                  </text>
                </g>
                <g id="suluova" transform="translate(145.000000, 59.000000)">
                  <path
                    d="M94,11 L22,0 L8,22 L9,38 L0,44 L0,72 L24,79 L46,72 L61,46 L94,11 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Suluova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30" y="41">
                      SLV
                    </tspan>
                  </text>
                </g>
                <g id="tasova" transform="translate(272.000000, 26.000000)">
                  <path
                    d="M0,47 L26,25 L31,4 L34,0 L74,7 L68,18 L70,26 L94,32 L86,40 L86,54 L94,61 L98,54 L113,58 L113,64 L97,71 L90,86 L92,114 L108,130 L104,137 L82,128 L54,125 L50,132 L9,96 L0,47 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Taşova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46" y="74">
                      TŞV
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
