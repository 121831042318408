function reducer(state, { type, payload }) {
  switch (type) {
    case "SEARCH_DECEASE": {
      const tmpState = state;
      tmpState.response = payload.response;
      tmpState.text = payload.name;
      return { ...tmpState };
    }

    default:
  }

  return state;
}

export { reducer };
