import React, { useEffect } from "react";
import "../turkeymap.css";

export const Sivas = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(20.000000, 3.000000)">
                <g id="akincilar" transform="translate(316.460177, 59.469027)">
                  <path
                    d="M7.96460177,7.96460177 L12.7433628,7.96460177 L12.7433628,11.6814159 L16.9911504,11.1504425 L18.5840708,4.77876106 L24.4247788,7.43362832 L34.5132743,4.24778761 L34.5132743,2.65486726 L36.1061947,0 L47.7876106,5.30973451 L40.3539823,32.920354 L24.9557522,42.4778761 L7.96460177,37.1681416 L2.12389381,26.0176991 L0,13.8053097 L7.96460177,7.96460177 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akıncılar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.7699115" y="26.1769912">
                      AKN
                    </tspan>
                  </text>
                </g>
                <g
                  id="altinyayla"
                  transform="translate(114.159292, 207.610619)"
                >
                  <path
                    d="M0,10.619469 L3.71681416,40.8849558 L21.2389381,56.2831858 L44.0707965,23.8938053 L24.4247788,0 L0,10.619469 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altınyayla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.3982301" y="30.9557522">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="divrigi" transform="translate(241.592920, 152.389381)">
                  <path
                    d="M97.1681416,0 L110.973451,39.2920354 L103.00885,45.6637168 L105.132743,50.4424779 L103.00885,51.5044248 L104.60177,90.2654867 L96.1061947,88.6725664 L85.4867257,98.2300885 L63.1858407,101.415929 L48.3185841,103.539823 L23.8938053,52.0353982 L16.460177,54.159292 L0,48.3185841 L5.84070796,42.4778761 L69.0265487,7.96460177 L97.1681416,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Divriği"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.9026549" y="60.6902655">
                      DİV
                    </tspan>
                  </text>
                </g>
                <g id="dogansar" transform="translate(213.451327, 41.946903)">
                  <path
                    d="M17.5221239,0 L27.079646,18.0530973 L27.079646,24.9557522 L0,24.4247788 L2.65486726,10.619469 L17.5221239,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Doğanşar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.02654867" y="19.8053097">
                      DĞN
                    </tspan>
                  </text>
                </g>
                <g id="gemerek" transform="translate(0.000000, 192.212389)">
                  <path
                    d="M31.8584071,0 L24.4247788,15.9292035 L0,33.4513274 L8.49557522,41.4159292 L12.2123894,47.2566372 L4.24778761,55.7522124 L16.460177,57.3451327 L27.6106195,66.9026549 L39.8230088,69.0265487 L72.7433628,65.840708 L76.9911504,63.7168142 L31.8584071,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gemerek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.2035398" y="47.9469027">
                      GEM
                    </tspan>
                  </text>
                </g>
                <g id="golova" transform="translate(356.814159, 64.778761)">
                  <path
                    d="M7.43362832,0 L22.300885,9.02654867 L39.8230088,9.55752212 L38.2300885,23.8938053 L19.1150442,25.4867257 L12.2123894,32.920354 L0,27.6106195 L7.43362832,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.6814159" y="19.8053097">
                      GÖL
                    </tspan>
                  </text>
                </g>
                <g id="gurun" transform="translate(122.654867, 265.486726)">
                  <path
                    d="M108.849558,11.6814159 L118.938053,46.7256637 L87.6106195,81.2389381 L51.5044248,86.5486726 L7.96460177,93.4513274 L0,66.3716814 L35.0442478,11.1504425 L64.7787611,14.3362832 L76.9911504,-1.88639664e-15 L108.849558,11.6814159 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gürün"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60" y="53.7876106">
                      GRN
                    </tspan>
                  </text>
                </g>
                <g id="hafik" transform="translate(148.141593, 54.690265)">
                  <path
                    d="M92.3893805,12.2123894 L65.3097345,11.6814159 L32.920354,9.02654867 L18.5840708,0 L0,7.43362832 L9.02654867,19.6460177 L35.5752212,60.5309735 L31.8584071,79.6460177 L75.3982301,124.778761 L79.1150442,123.185841 L102.477876,112.035398 L104.60177,102.477876 L96.1061947,97.1681416 L100.884956,88.1415929 L87.079646,80.7079646 L79.1150442,48.3185841 L92.3893805,12.2123894 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hafik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.2212389" y="58.5663717">
                      HFK
                    </tspan>
                  </text>
                </g>
                <g id="imranli" transform="translate(287.256637, 84.955752)">
                  <path
                    d="M54.159292,16.9911504 L69.5575221,32.920354 L62.1238938,47.7876106 L51.5044248,67.4336283 L23.3628319,75.3982301 L9.55752212,58.4070796 L0,20.1769912 L6.90265487,12.7433628 L16.460177,0 L23.8938053,11.6814159 L37.1681416,11.6814159 L54.159292,16.9911504 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İmranli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.3274336" y="41.5752212">
                      İMR
                    </tspan>
                  </text>
                </g>
                <g id="kangal" transform="translate(135.398230, 177.876106)">
                  <path
                    d="M91.8584071,0 C91.8584339,1.54654342e-07 93.9823009,12.7433628 93.9823009,12.7433628 L112.035398,16.9911504 L106.19469,22.8318584 L123.185841,28.6725664 L130.088496,26.5486726 L154.513274,78.0530973 L137.522124,78.5840708 L129.026549,98.7610619 L96.1061947,89.2035398 L96.1061947,99.2920354 L64.2477876,87.6106195 L52.0353982,101.946903 L22.300885,98.7610619 L0,86.0176991 L22.8318584,53.6283186 L32.920354,50.4424779 L31.3274336,35.5752212 L73.2743363,9.55752212 L88.1415929,1.59292035 C88.1415929,1.59292035 91.8583802,-1.54654342e-07 91.8584071,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kangal"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="80.1769912" y="62.2831858">
                      KNG
                    </tspan>
                  </text>
                </g>
                <g id="koyulhisar" transform="translate(230.973451, 0.000000)">
                  <path
                    d="M75.9292035,0 L53.6283186,6.90265487 L50.9734513,24.4247788 L35.5752212,29.7345133 L28.6725664,23.3628319 L23.3628319,24.4247788 L22.300885,36.1061947 L11.6814159,32.3893805 L0.530973451,20.7079646 L4.24778761,35.0442478 L0,41.4159292 L9.02654867,59.4690265 L24.4247788,58.9380531 L51.5044248,74.8672566 L64.2477876,54.6902655 L59.4690265,42.4778761 L61.5929204,40.8849558 L65.3097345,43.539823 L67.4336283,41.9469027 L64.2477876,33.9823009 L79.6460177,12.7433628 L75.9292035,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Koyulhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.1681416" y="52.1946903">
                      KOY
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(99.292035, 74.867257)">
                  <path
                    d="M58.4070796,0 L35.0442478,45.1327434 L25.4867257,79.1150442 L0,92.920354 L7.96460177,107.256637 L1.59292035,118.938053 L14.8672566,143.362832 L39.2920354,132.743363 L56.8141593,98.2300885 L79.1150442,94.5132743 L109.380531,112.566372 L124.247788,104.60177 L80.7079646,59.4690265 L84.4247788,40.3539823 L58.4070796,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.9115044" y="83.5221239">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="susehri" transform="translate(282.477876, 12.743363)">
                  <path
                    d="M28.1415929,0 L36.6371681,18.5840708 L33.9823009,37.699115 C33.9823009,37.699115 41.4159292,39.2920354 41.9469027,39.2920354 C42.4778761,39.2920354 50.9734513,45.6637168 50.9734513,45.6637168 L48.8495575,51.5044248 L38.7610619,48.8495575 L31.8584071,50.4424779 L41.9469027,54.6902655 L33.9823009,60.5309735 L36.1061947,72.7433628 L41.9469027,83.8938053 L28.6725664,83.8938053 L21.2389381,72.2123894 L11.6814159,84.9557522 L0,62.1238938 L12.7433628,41.9469027 L7.96460177,29.7345133 L10.0884956,28.1415929 L13.8053097,30.7964602 L15.9292035,29.2035398 L12.7433628,21.2389381 L28.1415929,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Suşehri"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.0530973" y="63.3451327">
                      SŞH
                    </tspan>
                  </text>
                </g>
                <g id="sarkisla" transform="translate(31.858407, 155.575221)">
                  <path
                    d="M86.0176991,92.920354 L60,105.663717 L45.1327434,100.353982 L0,36.6371681 L10.0884956,19.6460177 L5.84070796,0 L50.4424779,21.2389381 L67.4336283,12.2123894 L75.3982301,26.5486726 L69.0265487,38.2300885 L82.300885,62.6548673 L86.0176991,92.920354 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şarkışla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.1327434" y="59.6283186">
                      ŞAR
                    </tspan>
                  </text>
                </g>
                <g id="ulas" transform="translate(138.584071, 169.380531)">
                  <path
                    d="M70.0884956,18.0530973 L39.8230088,0 L17.5221239,3.71681416 L0,38.2300885 L19.6460177,62.1238938 L29.7345133,58.9380531 L27.6106195,44.0707965 L70.0884956,18.0530973 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ulaş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.4867257" y="26.7079646">
                      ULŞ
                    </tspan>
                  </text>
                </g>
                <g id="yildizeli" transform="translate(15.398230, 59.469027)">
                  <path
                    d="M132.743363,2.65486726 L115.221239,0 L102.477876,2.65486726 L98.2300885,5.30973451 L90.2654867,37.699115 L80.7079646,48.3185841 L56.2831858,45.6637168 L31.3274336,47.7876106 L4.24778761,51.5044248 L0,60.5309735 L11.6814159,68.4955752 L22.300885,96.1061947 L66.9026549,117.345133 L83.8938053,108.318584 L109.380531,94.5132743 L118.938053,60.5309735 L142.300885,15.3982301 L132.743363,2.65486726 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yıldızeli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.3097345" y="81.3982301">
                      YLD
                    </tspan>
                  </text>
                </g>
                <g id="zara" transform="translate(227.256637, 58.938053)">
                  <path
                    d="M13.2743363,1.0619469 L13.2743363,7.96460177 L0,44.0707965 L7.96460177,76.460177 L21.7699115,83.8938053 L16.9911504,92.920354 L25.4867257,98.2300885 L23.3628319,107.787611 L0,118.938053 L2.12389381,131.681416 L20.1769912,135.929204 L83.3628319,101.415929 L69.5575221,84.4247788 L60,46.1946903 L66.9026549,38.7610619 L55.2212389,15.9292035 L28.1415929,0 L13.2743363,1.0619469 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Zara"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.3893805" y="69.7168142">
                      ZRA
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
