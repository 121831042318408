import { Grid } from "@mui/material";
import React from "react";
import Resim from "../../assets/imgs/uzun.jpg";
import { useNavigate } from "react-router-dom";

export const AdsHorizontalCity = ({ ads }) => {
  const navigate = useNavigate();

  return ads.map((data) => {
    return (
      data.order >= 4 && (
        <Grid
          sx={{
            width: "85%",
            cursor: "pointer",
          }}
        >
          <Grid item sx={{ width: "100%", height: 500 }}>
            {data.imgSrc === "" ? (
              <img
                src={Resim}
                onClick={() => {
                  navigate("/iletisim", { state: { id: data } });
                }}
                style={{
                  width: "100%",
                  height: data.order === 5 ? "100%" : "95%",
                }}
              />
            ) : (
              <a key={data._id} href={data.redirectUrl} target="_blank">
                <img
                  src={data.imgSrc}
                  style={{
                    width: "100%",
                    height: data.order === 5 ? "100%" : "95%",
                  }}
                />
              </a>
            )}
          </Grid>
        </Grid>
      )
    );
  });
};
