const url = "https://api.sela.tc/api/v1/";
//const url = "http://localhost:443/api/v1/";
const get = (endpoint) => {
  return fetch(url + endpoint, {
    method: "GET",
  }).then((response) => {
    return response.json();
  });
};

const getWithPage = (endpoint, page) => {
  return fetch(url + endpoint + "?page=" + page || 1, {
    method: "GET",
    headers: {},
  }).then((response) => {
    return response.json();
  });
};

const getWithPageAndDates = (endpoint, page, start, end, adress, id) => {
  const link =
    endpoint +
    "?page=" +
    page +
    "&start=" +
    start +
    "&end=" +
    end +
    "&adress=" +
    adress +
    "&id=" +
    id;

  return fetch(url + link, {
    method: "GET",
    headers: {},
  }).then((response) => {
    return response.json();
  });
};

const getSearchWithPage = (endpoint, text, page) => {
  if (!page) {
    page = 1;
  }
  const link = endpoint + "?page=" + page + "&text=" + text;

  return fetch(url + link, {
    method: "GET",
    headers: {},
  }).then((response) => {
    return response.json();
  });
};

const postWithImg = (endpoint, data) => {
  const form = new FormData();

  form.append("imgSrc", data.imgSrc[0]);
  form.append("imgSrc", data.imgSrc[1]);

  form.append("page", data.page);
  form.append("nameSurname", data.nameSurname);
  form.append("comment", data.comment);
  form.append("iban", data.iban);

  const option = {
    method: "POST",
    body: form,
  };

  return fetch(url + endpoint, option).then((response) => response.json());
};

const Service = {
  get,
  getWithPage,
  getWithPageAndDates,
  getSearchWithPage,
  postWithImg,
};

export default Service;
