import React, { useEffect } from "react";
import "../turkeymap.css";

export const Bolu = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 63.000000)">
                <g id="dortdivan" transform="translate(287.000000, 76.000000)">
                  <path
                    d="M43.0761905,0.99047619 L15.2285714,5.9047619 L16.047619,11.6380952 L25.0571429,18.1904762 L0.485714286,64.8761905 L17.6857143,73.8857143 L34.8857143,69.7904762 L55.3619048,42.7619048 L43.0761905,19.8285714 L43.0761905,0.99047619 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dörtdivan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.4190476" y="44.0285714">
                      DDV
                    </tspan>
                  </text>
                </g>
                <g id="gerede" transform="translate(321.000000, 37.000000)">
                  <path
                    d="M55.7619048,0.676190476 L64.7714286,10.5047619 L96.7142857,17.8761905 L107.361905,28.5238095 L109,39.9904762 L104.904762,58.8285714 L68.8666667,66.2 L64.7714286,75.2095238 L72.1428571,86.6761905 L70.5047619,96.5047619 L26.2761905,124.352381 L18.0857143,126.809524 L0.885714286,108.790476 L21.3619048,81.7619048 L9.07619048,58.8285714 L9.07619048,39.1714286 L7.43809524,18.6952381 L13.9904762,12.9619048 L37.7428571,14.6 L55.7619048,0.676190476 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gerede"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.3809524" y="51.0857143">
                      GRD
                    </tspan>
                  </text>
                </g>
                <g id="goynuk" transform="translate(0.000000, 128.000000)">
                  <path
                    d="M76.9904762,0.59047619 L67.9809524,10.4190476 L24.5714286,10.4190476 L8.19047619,33.352381 L13.1047619,52.1904762 L0,63.6571429 L13.1047619,74.3047619 L15.5619048,91.5047619 L24.5714286,105.428571 L45.8666667,105.428571 L60.6095238,111.980952 L115.485714,84.1333333 L122.038095,57.9238095 L109.752381,53.0095238 L108.933333,34.9904762 L98.2857143,12.0571429 L76.9904762,0.59047619 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Göynük"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58.152381" y="55.9142857">
                      GÖY
                    </tspan>
                  </text>
                </g>
                <g id="kibriscik" transform="translate(238.000000, 123.000000)">
                  <path
                    d="M0.342857143,4.77142857 L16.7238095,71.9333333 L49.4857143,71.9333333 L74.8761905,53.9142857 L101.085714,40.8095238 L83.8857143,22.7904762 L66.6857143,26.8857143 L49.4857143,17.8761905 L29.0095238,0.676190476 L0.342857143,4.77142857 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kıbrıscık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.1142857" y="46.1714286">
                      KIB
                    </tspan>
                  </text>
                </g>
                <g id="mengen" transform="translate(257.000000, 0.000000)">
                  <path
                    d="M109.933333,4.0952381 L96.0095238,0 L82.9047619,2.45714286 L5.91428571,7.37142857 L0.180952381,32.7619048 L34.5809524,61.4285714 L46.047619,60.6095238 L72.2571429,47.5047619 L77.9904762,49.9619048 L101.742857,51.6 L119.761905,37.6761905 L114.847619,12.2857143 L109.933333,4.0952381 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mengen"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.6" y="27.4761905">
                      MNG
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(181.000000, 32.000000)">
                  <path
                    d="M122.047619,28.6095238 L110.580952,29.4285714 L76.1809524,0.761904762 L58.1619048,13.047619 L15.5714286,19.6 L4.92380952,35.9809524 L0.828571429,69.5619048 L3.28571429,80.2095238 L23.7619048,94.952381 L57.3428571,95.7714286 L86.0095238,91.6761905 L106.485714,108.87619 L131.057143,62.1904762 L122.047619,54.8190476 L121.228571,49.9047619 L122.047619,28.6095238 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57.3428571" y="51.9904762">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="mudurnu" transform="translate(76.000000, 96.000000)">
                  <path
                    d="M105.828571,5.56190476 L68.152381,12.9333333 L55.047619,2.28571429 L32.1142857,4.74285714 L12.4571429,5.56190476 L5.9047619,0.647619048 L0.99047619,32.5904762 L22.2857143,44.8761905 L32.9333333,66.9904762 L33.752381,85.0095238 L46.0380952,89.9238095 L50.1333333,80.9142857 L83.7142857,97.2952381 L114.019048,89.1047619 L128.761905,30.952381 L108.285714,16.2095238 L105.828571,5.56190476 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mudurnu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="68.9714286" y="49.4190476">
                      MUD
                    </tspan>
                  </text>
                </g>
                <g id="seben" transform="translate(190.000000, 126.000000)">
                  <path
                    d="M13.9428571,0.952380952 L0.019047619,59.1047619 L6.57142857,70.5714286 L35.2380952,78.7619048 L64.7238095,68.9333333 L48.3428571,1.77142857 L13.9428571,0.952380952 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Seben"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.7714286" y="43.9904762">
                      SEB
                    </tspan>
                  </text>
                </g>
                <g id="yenicaga" transform="translate(302.000000, 47.000000)">
                  <path
                    d="M32.9904762,2.96190476 L27.2571429,0.504761905 L1.04761905,13.6095238 L0.228571429,34.9047619 L28.0761905,29.9904762 L26.4380952,8.6952381 L32.9904762,2.96190476 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yeniçağa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.78095238" y="23.0666667">
                      YEN
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
