import React, { useEffect } from "react";
import "../turkeymap.css";

export const Giresun = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(67.000000, 10.000000)">
                <g id="alucra" transform="translate(148.000000, 146.000000)">
                  <path
                    d="M26,11 L4,0 L0,41 L0,56 L16,60 L29,97 L42,108 L31,123 L16,137 L28,154 L53,146 L90,149 L103,111 L135,97 L133,89 L114,80 L102,63 L71,57 L59,38 L43,27 L28,25 L26,11 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alucra"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51" y="79">
                      ALU
                    </tspan>
                  </text>
                </g>
                <g id="bulancak" transform="translate(0.000000, 53.000000)">
                  <path
                    d="M44,2 L60,5 L75,0 L87,10 L71,52 L77,85 L66,115 L49,126 L41,118 L21,120 L15,90 L1,81 L0,70 L47,28 L44,2 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bulancak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33" y="77">
                      BUL
                    </tspan>
                  </text>
                </g>
                <g id="camoluk" transform="translate(154.000000, 291.000000)">
                  <path
                    d="M86,3 L86,26 L104,47 L98,55 L83,55 L61,45 L27,44 L0,27 L24,8 L49,0 L86,3 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çamoluk"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49" y="28">
                      ÇAM
                    </tspan>
                  </text>
                </g>
                <g id="canakci" transform="translate(235.000000, 50.000000)">
                  <path
                    d="M53,8 L68,42 L40,57 L4,38 L0,33 L2,1 L23,0 L53,8 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çanakçı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20" y="24">
                      ÇNK
                    </tspan>
                  </text>
                </g>
                <g id="dereli" transform="translate(48.000000, 96.000000)">
                  <path
                    d="M1,83 L0,95 L34,113 L73,113 L85,86 L102,89 L106,49 L112,15 L86,13 L75,0 L29,42 L18,72 L1,83 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dereli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55" y="62">
                      DER
                    </tspan>
                  </text>
                </g>
                <g id="dogankent" transform="translate(232.000000, 88.000000)">
                  <path
                    d="M43,19 L34,20 L22,17 L20,26 L28,33 L10,49 L0,6 L7,1.11022302e-16 L43,19 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Doğankent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6" y="19">
                      DĞN
                    </tspan>
                  </text>
                </g>
                <g id="espiye" transform="translate(167.000000, 37.000000)">
                  <path
                    d="M4,8 L14,13 L27,0 L31,24 L48,67 L42,67 L30,59 L19,56 L0,24 L4,8 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Espiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13" y="30">
                      ESP
                    </tspan>
                  </text>
                </g>
                <g id="eynesil" transform="translate(266.000000, 0.000000)">
                  <path
                    d="M0,14 L24,0 L32,1 L24,22 L22,58 L0,14 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eynesil"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8" y="21">
                      EYN
                    </tspan>
                  </text>
                </g>
                <g id="gorele" transform="translate(234.000000, 13.000000)">
                  <path
                    d="M0,0 L32,1 L54,45 L24,37 L3,38 L0,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Görele"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14" y="36">
                      GRL
                    </tspan>
                  </text>
                </g>
                <g id="guce" transform="translate(176.000000, 61.000000)">
                  <path
                    d="M66,76 L33,122 L17,111 L2,109 L0,95 L13,68 L37,58 L39,43 L22,0 L42,3 L56,33 L66,76 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güce"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28" y="83">
                      GÜC
                    </tspan>
                  </text>
                </g>
                <g id="kesap" transform="translate(109.000000, 40.000000)">
                  <path
                    d="M0,23 L37,19 L48,0 L62,5 L58,21 L42,31 L40,41 L53,51 L51,71 L25,69 L14,56 L26,41 L10,36 L0,23 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kesap"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23" y="34">
                      KŞP
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(71.000000, 63.000000)">
                  <path
                    d="M16,0 L38,0 L48,13 L64,18 L52,33 L6,75 L0,42 L16,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19" y="32">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="piraziz" transform="translate(0.000000, 52.000000)">
                  <path
                    d="M29,0 L44,3 L47,29 L27,47 L0,71 L8,57 L12,35 L18,23 L29,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Piraziz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23" y="24">
                      PRZ
                    </tspan>
                  </text>
                </g>
                <g
                  id="sebinkarahisar"
                  transform="translate(46.000000, 182.000000)"
                >
                  <path
                    d="M2,9 L0,14 L7,38 L23,73 L18,109 L30,107 L39,99 L42,101 L39,110 L41,113 L51,117 L63,114 L67,117 L60,120 L52,124 L56,130 L65,134 L76,131 L83,131 L86,126 L108,136 L132,117 L120,100 L135,86 L146,71 L133,60 L120,23 L104,19 L104,3 L87,0 L75,27 L36,27 L2,9 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şebinkarahisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="73" y="74">
                      ŞKH
                    </tspan>
                  </text>
                </g>
                <g id="tirebolu" transform="translate(194.000000, 13.000000)">
                  <path
                    d="M0,24 L5,14 L40,0 L43,38 L41,70 L45,75 L38,81 L24,51 L4,48 L0,24 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tirebolu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14" y="23">
                      TİR
                    </tspan>
                  </text>
                </g>
                <g id="yaglidere" transform="translate(149.000000, 61.000000)">
                  <path
                    d="M11,50 L5,84 L27,95 L40,68 L64,58 L66,43 L60,43 L48,35 L37,32 L18,0 L2,10 L0,20 L13,30 L11,50 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yağlıdere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25" y="52">
                      YĞL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
