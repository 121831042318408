import React, { useEffect } from "react";
import "../turkeymap.css";

export const Nigde = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(52.000000, 3.000000)">
                <g id="altunhisar" transform="translate(0.000000, 74.754098)">
                  <path
                    d="M0,59.0163934 L66.8852459,0 L90.4918033,17.704918 L117.04918,57.0491803 L82.6229508,77.704918 L20.6557377,65.9016393 L0,59.0163934 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altunhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57.0491803" y="46.3278689">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="bor" transform="translate(19.672131, 131.803279)">
                  <path
                    d="M0,8.85245902 L3.93442623,16.7213115 L75.7377049,88.5245902 L72.7868852,120.983607 L159.344262,113.114754 L176.065574,95.4098361 L197.704918,95.4098361 L205.57377,70.8196721 L162.295082,62.9508197 L97.3770492,0 L62.9508197,20.6557377 L0,8.85245902 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bor"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="94.4262295" y="64.0327869">
                      BOR
                    </tspan>
                  </text>
                </g>
                <g id="camardi" transform="translate(215.409836, 101.311475)">
                  <path
                    d="M102.295082,0 L110.163934,69.8360656 L93.442623,112.131148 L77.704918,124.918033 L41.3114754,142.622951 L3.93442623,143.606557 L1.96721311,125.901639 L9.83606557,101.311475 L0,72.7868852 L30.4918033,24.5901639 L102.295082,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çamardı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53.1147541" y="76.8196721">
                      ÇAM
                    </tspan>
                  </text>
                </g>
                <g id="ciftlik" transform="translate(66.885246, 0.000000)">
                  <path
                    d="M0,74.7540984 L34.4262295,54.0983607 L20.6557377,15.7377049 L21.6393443,0 L63.9344262,31.4754098 L72.7868852,74.7540984 L55.0819672,97.3770492 L23.6065574,92.4590164 L0,74.7540984 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çiftlik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.3442623" y="78.7868852">
                      ÇFT
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(90.491803, 23.606557)">
                  <path
                    d="M0,68.852459 L26.557377,108.196721 L91.4754098,171.147541 L134.754098,179.016393 L124.918033,150.491803 L155.409836,102.295082 L227.213115,77.704918 L217.377049,66.8852459 L168.196721,54.0983607 L151.47541,1.96721311 L126.885246,0 L96.3934426,11.8032787 L40.3278689,7.86885246 L49.1803279,51.147541 L31.4754098,73.7704918 L0,68.852459 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="88.5245902" y="84.6885246">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="ulukisla" transform="translate(75.737705, 227.213115)">
                  <path
                    d="M141.639344,0 L144.590164,17.704918 L144.590164,68.852459 L109.180328,104.262295 L65.9016393,119.016393 L27.5409836,132.786885 L1.96721311,85.5737705 L0,39.3442623 L16.7213115,25.5737705 L103.278689,17.704918 L120,0 L141.639344,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ulukışla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60" y="65.0163934">
                      ULU
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
