import React, { useEffect } from "react";
import "../turkeymap.css";

export const Bilecik = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(95.000000, 38.000000)">
                <g id="bozuyuk" transform="translate(6.000000, 175.000000)">
                  <path
                    d="M84,4 L74,0 L63,18 L0,20 L2,40 L5,93 L19,99 L64,115 L81,114 L63,91 L73,65 L94,54 L121,52 L116,34 L95,24 L84,4 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozüyük"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="51">
                      BZY
                    </tspan>
                  </text>
                </g>
                <g id="golpazari" transform="translate(93.000000, 50.000000)">
                  <path
                    d="M49,6 L20,32 L0,28 L1,46 L27,77 L50,72 L71,82 L75,58 L100,48 L114,28 L97,20 L99,4 L85,0 L49,6 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölpazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52" y="39">
                      GLP
                    </tspan>
                  </text>
                </g>
                <g id="inhisar" transform="translate(146.000000, 132.000000)">
                  <path
                    d="M18,0 L2,27 L0,55 L9,75 L42,59 L41,48 L57,24 L71,7 L18,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İnhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20" y="27">
                      İNH
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(0.000000, 67.000000)">
                  <path
                    d="M35,0 L34,17 L0,62 L2,84 L32,74 L80,108 L90,112 L92,94 L105,83 L120,60 L94,29 L93,11 L35,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59" y="52">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="osmaneli" transform="translate(30.000000, 0.000000)">
                  <path
                    d="M44,5.68434189e-14 L0,45 L5,67 L63,78 L83,82 L112,56 L94,55 L76,35 L59,35 L47,21 L44,5.68434189e-14 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Osmaneli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40" y="52">
                      OSM
                    </tspan>
                  </text>
                </g>
                <g id="pazaryeri" transform="translate(2.000000, 141.000000)">
                  <path
                    d="M78,34 L30,0 L0,10 L4,54 L67,52 L78,34 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pazaryeri"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24" y="33">
                      PZR
                    </tspan>
                  </text>
                </g>
                <g id="sogut" transform="translate(90.000000, 122.000000)">
                  <path
                    d="M74,10 L53,0 L30,5 L15,28 L2,39 L0,57 L11,77 L32,87 L37,105 L44,107 L51,107 L65,85 L56,65 L58,37 L74,10 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Söğüt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25" y="50">
                      SÖĞ
                    </tspan>
                  </text>
                </g>
                <g id="yenipazar" transform="translate(164.000000, 78.000000)">
                  <path
                    d="M43,5.68434189e-14 L29,20 L4,30 L0,54 L53,61 L81,55 L70,38 L67,17 L51,4 L43,5.68434189e-14 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenipazar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35" y="37">
                      YNP
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
