import React, { useEffect } from "react";
import "../turkeymap.css";

export const Mugla = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 41.000000)">
                <g id="bodrum" transform="translate(0.000000, 77.000000)">
                  <path
                    d="M59.704579,8.74593796 L59.704579,11.2865583 L52.717873,10.0162482 L40.6499261,17.0029542 L33.028065,8.11078287 L26.676514,8.11078287 L26.676514,3.66469719 L23.5007386,1.75923191 L20.9601182,6.20531758 L17.1491876,0.488921713 L15.2437223,8.11078287 L6.35155096,4.93500739 L3.81093058,8.11078287 L10.1624815,9.38109306 L10.1624815,11.9217134 L0,19.5435746 L0,25.8951256 L3.17577548,30.3412112 L3.17577548,41.774003 L9.52732644,43.0443131 L17.1491876,36.6927622 L16.5140325,30.9763663 L20.3249631,30.3412112 L23.5007386,29.070901 L24.1358936,34.7872969 L26.676514,34.1521418 L26.676514,29.070901 L28.5819793,28.4357459 L28.5819793,30.9763663 L33.6632201,30.9763663 L41.9202363,39.2333826 L48.2717873,37.9630724 L55.8936484,42.4091581 L59.0694239,38.5982275 L63.5155096,43.0443131 L68.5967504,42.4091581 L69.8670606,39.2333826 L81.9350074,39.2333826 L85.745938,30.3412112 L81.9350074,17.6381093 L75.5834564,11.9217134 L73.6779911,2.394387 L59.704579,8.74593796 Z M31.1225997,38.5982275 L36.8389956,38.5982275 L43.1905465,43.0443131 L42.5553914,44.9497784 L38.1093058,46.2200886 L31.1225997,38.5982275 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bodrum"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48.2717873" y="29.084195">
                      BOD
                    </tspan>
                  </text>
                </g>
                <g id="dalaman" transform="translate(257.000000, 112.000000)">
                  <path
                    d="M0.237813885,82.3574594 L4.68389956,86.16839 L3.41358936,89.9793205 L9.76514032,93.7902511 L7.22451994,98.8714919 L10.4002954,105.223043 L16.1166913,105.223043 L18.0221566,96.9660266 L22.4682422,93.155096 L18.6573117,91.8847858 L15.4815362,94.4254062 L14.8463811,83.6277696 L21.8330871,82.9926145 L28.1846381,70.2895126 L36.4416544,55.6809453 L54.8611521,48.0590842 L70.7400295,44.2481536 L59.3072378,29.6395864 L60.577548,13.760709 L56.1314623,0.422451994 L47.8744461,20.7474151 L8.49483013,47.4239291 L8.49483013,61.3973412 L0.237813885,82.3574594 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dalaman"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.8197932" y="48.7075332">
                      DLM
                    </tspan>
                  </text>
                </g>
                <g id="datca" transform="translate(15.000000, 154.000000)">
                  <path
                    d="M124.098966,4.15361891 L119.65288,9.23485968 L110.760709,7.96454948 L92.9763663,4.788774 L89.1654357,6.05908419 L78.3677991,2.88330871 L71.3810931,7.32939439 L60.5834564,7.32939439 L51.6912851,0.977843427 L49.7858198,1.61299852 L45.9748892,9.23485968 L23.7444609,12.4106352 L20.5686854,9.23485968 L12.3116691,11.7754801 L12.3116691,17.4918759 L0.8788774,18.7621861 L1.5140325,25.7488922 L6.59527326,27.6543575 L7.86558346,31.465288 L12.3116691,29.5598227 L20.5686854,34.0059084 L26.9202363,28.2895126 L28.8257016,30.1949778 L35.1772526,28.2895126 L53.5967504,34.6410635 L54.8670606,26.3840473 L58.6779911,23.2082718 L58.6779911,17.4918759 L63.7592319,14.3161004 L80.2732644,14.9512555 L83.4490399,18.127031 L89.1654357,18.127031 L90.4357459,16.2215657 L92.3412112,18.7621861 L94.2466765,15.5864106 L96.7872969,16.2215657 L96.7872969,18.7621861 L100.598227,18.7621861 L114.57164,16.8567208 L117.747415,13.0457903 L119.65288,14.9512555 L130.450517,8.59970458 L124.098966,4.15361891 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Datça"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.717873" y="22.5864106">
                      DTÇ
                    </tspan>
                  </text>
                </g>
                <g id="fethiye" transform="translate(285.000000, 156.000000)">
                  <path
                    d="M42.7400295,0.248153619 L26.8611521,4.05908419 L8.44165436,11.6809453 L0.184638109,26.2895126 L3.99556869,33.2762186 L6.53618907,33.9113737 L7.80649926,37.0871492 L21.1447563,43.4387001 L26.8611521,44.0738552 L30.6720827,51.0605613 L28.7666174,55.506647 L24.3205318,51.0605613 L21.1447563,58.0472674 L15.4283604,57.4121123 L12.88774,65.0339734 L16.6986706,69.4800591 L9.71196455,71.3855244 L10.3471196,73.9261448 L17.3338257,73.9261448 L19.239291,70.1152142 L24.3205318,70.1152142 L24.9556869,72.6558346 L30.0369276,73.9261448 L30.0369276,80.2776957 L24.9556869,87.8995569 L28.1314623,93.6159527 L26.8611521,101.872969 L24.3205318,102.508124 L24.3205318,107.589365 L30.6720827,110.76514 L31.3072378,114.576071 L38.929099,118.387001 L47.8212703,105.048744 L47.1861152,80.9128508 L54.1728213,58.0472674 L56.7134417,6.59970458 L42.7400295,0.248153619 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Fethiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.0369276" y="35.8301329">
                      FTH
                    </tspan>
                  </text>
                </g>
                <g id="kavaklidere" transform="translate(171.000000, 7.000000)">
                  <path
                    d="M0.491875923,6.97341211 L10.0192024,0.621861152 L28.4387001,5.06794682 L46.8581979,20.3116691 L44.9527326,33.6499261 L32.8847858,45.0827179 L0.491875923,6.97341211 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kavaklıdere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.0871492" y="23.5007386">
                      KVK
                    </tspan>
                  </text>
                </g>
                <g id="koycegiz" transform="translate(189.000000, 90.000000)">
                  <path
                    d="M95.549483,0.82717873 L112.063516,12.8951256 L124.131462,22.422452 L115.874446,42.7474151 L76.4948301,69.4239291 L61.8862629,68.1536189 L46.6425406,62.437223 L58.7104874,56.7208272 L60.6159527,52.2747415 L64.4268833,51.6395864 L58.7104874,47.8286558 L45.3722304,52.9098966 L47.9128508,57.3559823 L44.7370753,58.6262925 L42.1964549,57.3559823 L39.0206795,59.8966027 L44.7370753,67.5184638 L41.5612999,78.9512555 L32.0339734,75.140325 L28.2230428,77.6809453 L28.2230428,81.4918759 L21.8714919,80.8567208 L23.1418021,75.7754801 L18.0605613,74.5051699 L15.5199409,69.4239291 L18.6957164,63.0723781 L16.155096,60.5317578 L10.4387001,65.6129985 L7.89807976,62.437223 L3.45199409,66.8833087 L0.911373708,60.5317578 L5.35745938,47.1935007 L11.7090103,37.6661743 L25.6824225,31.9497784 L44.1019202,5.9084195 L63.7917282,4.00295421 L95.549483,0.82717873 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Köyceğiz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="70.1432792" y="37.0443131">
                      KÖY
                    </tspan>
                  </text>
                </g>
                <g id="marmaris" transform="translate(116.000000, 118.000000)">
                  <path
                    d="M59.9379616,0.774002954 L56.7621861,2.67946824 L57.3973412,5.85524372 L52.3161004,3.94977843 L47.2348597,9.6661743 L47.8700148,15.3825702 L40.8833087,15.3825702 L40.8833087,23.0044313 L39.6129985,23.0044313 L38.9778434,20.4638109 L34.5317578,16.6528804 L20.5583456,15.3825702 L21.8286558,17.2880355 L16.11226,17.9231905 L16.11226,23.0044313 L21.1935007,29.9911374 L12.9364845,30.6262925 L12.3013294,38.2481536 L14.8419498,40.1536189 L18.6528804,37.6129985 L23.098966,40.1536189 L29.450517,44.5997046 L32.6262925,53.4918759 L27.5450517,52.8567208 L24.3692762,56.6676514 L30.0856721,59.2082718 L26.9098966,64.9246677 L21.8286558,59.8434269 L12.3013294,65.5598227 L6.58493353,65.5598227 L3.40915805,70.0059084 L7.85524372,72.5465288 L14.8419498,72.5465288 L16.11226,67.465288 L18.0177253,68.1004431 L18.0177253,71.9113737 L18.6528804,75.7223043 L24.3692762,78.2629247 L19.2880355,85.2496307 L9.12555391,85.8847858 L4.67946824,83.3441654 L0.23338257,86.5199409 L3.40915805,89.6957164 L4.67946824,95.4121123 L8.49039882,93.506647 L13.5716396,94.7769572 L19.2880355,90.3308715 L27.5450517,90.3308715 L38.3426883,73.816839 L48.5051699,71.9113737 L51.0457903,64.9246677 L59.9379616,64.9246677 L61.2082718,60.478582 L58.0324963,59.2082718 L54.2215657,54.7621861 L52.3161004,45.2348597 L56.127031,38.2481536 L60.5731167,39.5184638 L65.6543575,43.9645495 L57.3973412,47.7754801 L58.6676514,50.3161004 L63.1137371,50.3161004 L65.6543575,45.8700148 L79.6277696,52.2215657 L83.4387001,43.9645495 L80.8980798,41.4239291 L76.4519941,41.4239291 L76.4519941,38.8833087 L73.9113737,32.5317578 L78.3574594,19.1935007 L59.9379616,0.774002954 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Marmaris"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.6129985" y="39.5317578">
                      MMR
                    </tspan>
                  </text>
                </g>
                <g id="mentese" transform="translate(130.000000, 26.000000)">
                  <path
                    d="M87.8581979,1.31166913 L106.912851,0.676514032 L106.912851,5.7577548 L104.37223,7.02806499 L105.642541,7.66322009 L109.453471,2.58197932 L110.088626,10.8389956 L124.697194,26.717873 L131.048744,48.3131462 L138.035451,51.4889217 L154.549483,64.8271787 L122.156573,68.0029542 L113.899557,61.0162482 L82.1418021,68.0029542 L66.2629247,66.732644 L52.2895126,87.0576071 L17.9911374,88.3279173 L17.9911374,92.774003 L11.0044313,88.9630724 L0.206794682,87.6927622 L0.206794682,73.7193501 L10.3692762,66.732644 L12.2747415,54.6646972 L73.8847858,26.0827179 L85.9527326,14.6499261 L87.8581979,1.31166913 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Menteşe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="75.7902511" y="50.8670606">
                      MNT
                    </tspan>
                  </text>
                </g>
                <g id="milas" transform="translate(29.000000, 0.000000)">
                  <path
                    d="M9.74446086,2.54062038 L15.4608567,0 L43.4076809,1.27031019 L59.2865583,8.89217134 L71.3545052,19.0546529 L89.1388479,18.4194978 L94.8552437,19.689808 L89.1388479,35.5686854 L92.9497784,58.4342688 L105.017725,66.6912851 L113.274742,80.6646972 L111.369276,92.732644 L101.206795,99.7193501 L101.206795,113.692762 L90.4091581,115.598227 L77.7060561,113.057607 L67.5435746,118.774003 L52.9350074,116.233383 L56.745938,107.341211 L52.9350074,94.6381093 L46.5834564,88.9217134 L44.6779911,79.394387 L30.704579,85.745938 L26.2584934,84.4756278 L27.5288035,81.2998523 L23.0827179,72.4076809 L30.704579,69.8670606 L31.9748892,76.2186115 L35.7858198,73.6779911 L33.2451994,69.8670606 L33.8803545,64.7858198 L40.2319055,61.6100443 L44.042836,64.7858198 L44.6779911,60.3397341 L34.5155096,53.3530281 L28.7991137,56.5288035 L24.3530281,65.4209749 L21.1772526,64.1506647 L23.717873,57.1639586 L20.5420975,53.9881832 L15.4608567,60.3397341 L12.9202363,57.7991137 L19.2717873,45.7311669 L18.6366322,40.6499261 L15.4608567,40.6499261 L16.0960118,45.7311669 L10.379616,48.9069424 L0.852289513,42.5553914 L3.3929099,33.028065 L5.29837518,31.7577548 L5.93353028,34.2983752 L10.379616,34.9335303 L14.8257016,29.8522895 L11.6499261,24.1358936 L5.93353028,18.4194978 L8.47415066,10.1624815 L24.9881832,12.7031019 L29.4342688,10.7976366 L26.2584934,7.62186115 L24.9881832,5.08124077 L20.5420975,5.71639586 L17.366322,1.90546529 L13.5553914,2.54062038 L9.74446086,2.54062038 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Milas"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58.0162482" y="61.6233383">
                      MİL
                    </tspan>
                  </text>
                </g>
                <g id="ortaca" transform="translate(230.000000, 152.000000)">
                  <path
                    d="M35.4948301,7.4239291 L20.8862629,6.15361891 L5.64254062,0.437223043 L6.27769572,4.88330871 L3.73707533,5.51846381 L0.561299852,16.9512555 L8.183161,20.127031 L6.27769572,21.3973412 L1.19645495,20.127031 L0.561299852,25.2082718 L2.46676514,28.3840473 L0.561299852,30.2895126 L0.561299852,37.2762186 L2.46676514,39.816839 L4.37223043,37.2762186 L8.183161,37.2762186 L10.0886263,41.7223043 L18.3456425,39.816839 L27.2378139,42.3574594 L35.4948301,21.3973412 L35.4948301,7.4239291 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ortaca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.8050222" y="25.8567208">
                      ORT
                    </tspan>
                  </text>
                </g>
                <g
                  id="seydikemer"
                  transform="translate(323.000000, 131.000000)"
                >
                  <path
                    d="M18.7134417,31.5997046 L27.605613,28.4239291 L35.2274742,13.8153619 L37.1329394,0.477104874 L42.2141802,3.65288035 L43.4844904,7.46381093 L50.4711965,13.1802068 L42.2141802,27.788774 L44.1196455,32.2348597 L53.0118168,30.9645495 L76.5125554,13.8153619 L86.6750369,8.73412112 L100.648449,15.0856721 L107,23.9778434 L94.2968981,43.0324963 L85.4047267,73.5199409 L72.7016248,90.0339734 L27.605613,117.345643 L13.6322009,152.279173 L9.82127031,147.197932 L0.929098966,143.387001 L9.82127031,130.048744 L9.18611521,105.912851 L16.1728213,83.0472674 L18.7134417,31.5997046 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Seydikemer"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.3899557" y="69.0871492">
                      SYD
                    </tspan>
                  </text>
                </g>
                <g id="ula" transform="translate(175.000000, 87.000000)">
                  <path
                    d="M77.1565731,7.00295421 L68.8995569,0.0162481536 L37.1418021,7.00295421 L21.2629247,5.73264402 L7.28951256,26.0576071 L11.7355982,26.6927622 L9.83013294,30.5036928 L0.937961595,31.774003 L19.3574594,50.1935007 L25.7090103,40.6661743 L39.6824225,34.9497784 L58.1019202,8.9084195 L77.1565731,7.00295421 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ula"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.5199409" y="24.1654357">
                      ULA
                    </tspan>
                  </text>
                </g>
                <g id="yatagan" transform="translate(118.000000, 13.000000)">
                  <path
                    d="M5.85524372,6.68980798 L53.4918759,0.973412112 L85.8847858,39.0827179 L24.2747415,67.6646972 L16.0177253,53.6912851 L3.94977843,45.4342688 L0.138847858,21.9335303 L5.85524372,6.68980798 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yatağan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.0723781" y="34.014771">
                      YTĞ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
