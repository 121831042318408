import React, { useEffect } from "react";
import "../turkeymap.css";

export const Bingol = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(35.000000, 3.000000)">
                <g id="adakli" transform="translate(100.558659, 40.223464)">
                  <path
                    d="M104.581006,14.0782123 L119.664804,30.1675978 L96.5363128,96.5363128 L10.0558659,93.5195531 L20.1117318,36.2011173 L0,14.0782123 L14.0782123,0 L104.581006,14.0782123 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Adaklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.2569832" y="51.2513966">
                      ADK
                    </tspan>
                  </text>
                </g>
                <g id="genc" transform="translate(67.374302, 211.173184)">
                  <path
                    d="M33.1843575,56.3128492 L0,99.5530726 L43.2402235,148.826816 L64.3575419,141.787709 L79.4413408,92.5139665 L143.798883,96.5363128 L176.98324,98.547486 L244.357542,56.3128492 L183.01676,0 L74.4134078,62.3463687 L33.1843575,56.3128492 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Genç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="154.860335" y="57.2849162">
                      GNÇ
                    </tspan>
                  </text>
                </g>
                <g id="karliova" transform="translate(197.094972, 14.078212)">
                  <path
                    d="M14.0782123,15.0837989 L80.4469274,0 L125.698324,30.1675978 L98.547486,50.2793296 L120.670391,90.5027933 L112.625698,121.675978 L62.849162,111.620112 L35.1955307,141.787709 L26.1452514,123.687151 L0,122.681564 L23.1284916,56.3128492 L8.04469274,40.2234637 L14.0782123,15.0837989 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karlıova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.2793296" y="66.3351955">
                      KRL
                    </tspan>
                  </text>
                </g>
                <g id="kigi" transform="translate(44.245810, 48.268156)">
                  <path
                    d="M56.3128492,6.03351955 L76.424581,28.1564246 L66.3687151,85.4748603 L48.2681564,89.4972067 L30.1675978,70.3910615 L0,30.1675978 L33.1843575,0 L56.3128492,6.03351955 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kiğı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.2067039" y="45.2178771">
                      KĞI
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(87.486034, 133.743017)">
                  <path
                    d="M23.1284916,0 C25.1396631,1.49908671e-06 108.603352,3.01675978 108.603352,3.01675978 L135.75419,4.02234637 L144.804469,22.122905 L162.905028,77.4301676 L54.301676,139.776536 L13.0726257,133.743017 L28.1564246,109.608939 L0,90.5027933 L2.01117318,83.4636872 L5.02793296,4.02234637 C5.02793296,4.02234637 21.1173202,-1.49908671e-06 23.1284916,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71.396648" y="59.2960894">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="solhan" transform="translate(232.290503, 104.581006)">
                  <path
                    d="M79.4413408,162.905028 L100.558659,151.843575 L87.4860335,121.675978 L102.569832,61.3407821 L133.743017,39.2178771 L85.4748603,0 L77.4301676,31.1731844 L27.150838,21.1173184 L0,51.2849162 L18.1005587,106.592179 L79.4413408,162.905028 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Solhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48.2681564" y="72.3687151">
                      SLH
                    </tspan>
                  </text>
                </g>
                <g id="yayladere" transform="translate(0.000000, 78.435754)">
                  <path
                    d="M44.2458101,0 L11.0614525,1.00558659 L0,69.3854749 L33.1843575,75.4189944 L74.4134078,40.2234637 L44.2458101,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yayladere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.1061453" y="38.1787709">
                      YYL
                    </tspan>
                  </text>
                </g>
                <g id="yedisu" transform="translate(69.385475, 0.000000)">
                  <path
                    d="M104.581006,0 L51.2849162,1.00558659 L0,37.2067039 L8.04469274,48.2681564 L31.1731844,54.301676 L45.2513966,40.2234637 L135.75419,54.301676 L141.787709,29.1620112 L110.614525,33.1843575 L94.5251397,22.122905 L104.581006,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yedisu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.3072626" y="28.122905">
                      YDS
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
