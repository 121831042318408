import React, { useEffect } from "react";
import "../turkeymap.css";

export const Kirikkale = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(98.000000, 21.000000)">
                <g id="bahsili" transform="translate(6.000000, 175.000000)">
                  <path
                    d="M41,0 L12,1 L0,47 L42,49 L43,38 L36,27 L43,12 L41,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bahşili"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14" y="29">
                      BHŞ
                    </tspan>
                  </text>
                </g>
                <g id="baliseyh" transform="translate(62.000000, 78.000000)">
                  <path
                    d="M102,0 L31,13 L0,42 L6,52 L49,96 L87,84 L86,71 L102,56 L102,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Balışeyh"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54" y="45">
                      BAL
                    </tspan>
                  </text>
                </g>
                <g id="celebi" transform="translate(34.000000, 238.000000)">
                  <path
                    d="M68,44 L19,0 L13,5 L0,31 L6,74 L13,77 L17,86 L68,44 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çelebi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19" y="42">
                      ÇLB
                    </tspan>
                  </text>
                </g>
                <g id="delice" transform="translate(148.000000, 45.000000)">
                  <path
                    d="M41,148 L72,137 L53,107 L59,94 L91,86 L70,58 L58,19 L59,5 L50,0 L16,33 L16,89 L0,104 L1,117 L11,132 L37,136 L41,148 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Delice"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38" y="71">
                      DLC
                    </tspan>
                  </text>
                </g>
                <g id="karakecili" transform="translate(0.000000, 222.000000)">
                  <path
                    d="M6,0 L0,7 L2,17 L14,28 L15,35 L34,47 L47,21 L42,17 L51,8 L48,1 L6,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karakeçili"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18" y="19">
                      KKÇ
                    </tspan>
                  </text>
                </g>
                <g id="keskin" transform="translate(53.000000, 162.000000)">
                  <path
                    d="M49,120 L77,117 L114,74 L136,31 L132,19 L106,15 L96,1.77635684e-15 L58,12 L9,40 L2,58 L6,62 L6,69 L0,76 L49,120 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Keskin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59" y="64">
                      KES
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(42.000000, 130.000000)">
                  <path
                    d="M26,0 L69,44 L20,72 L14,72 L13,78 L6,84 L0,72 L7,57 L5,45 L26,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26" y="45">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sulakyurt" transform="translate(81.000000, 0.000000)">
                  <path
                    d="M117,45 L92,23 L56,7 L34,0 L0,58 L5,75 L2,86 L12,91 L83,78 L117,45 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sulakyurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45" y="50">
                      SUL
                    </tspan>
                  </text>
                </g>
                <g id="yahsihan" transform="translate(18.000000, 118.000000)">
                  <path
                    d="M44,2 L31,7 L16,0 L10,7 L0,58 L29,57 L50,12 L44,2 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yahşihan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15" y="34">
                      YHŞ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
