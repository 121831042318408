import React, { useEffect } from "react";
import "../turkeymap.css";

export const Bartin = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(116.000000, 95.000000)">
                <g id="amasra" transform="translate(59.000000, 12.000000)">
                  <path
                    d="M59,0 L52,3 L43,9 L34,16 L21,17 L0,32 L22,41 L50,41 L75,25 L59,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Amasra"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="28">
                      AMS
                    </tspan>
                  </text>
                </g>
                <g id="kurucasile" transform="translate(118.000000, 0.000000)">
                  <path
                    d="M56,0 L34,5 L21,4 L0,12 L16,37 L68,22 L56,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kurucaşile"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26" y="20">
                      KCŞ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(0.000000, 22.000000)">
                  <path
                    d="M186,0 L189,7 L173,23 L105,69 L102,84 L84,96 L75,116 L91,138 L58,154 L47,141 L49,121 L37,99 L39,80 L0,63 L27,48 L29,40 L59,22 L81,31 L109,31 L134,15 L186,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="61" y="71">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="ulus" transform="translate(75.000000, 29.000000)">
                  <path
                    d="M114,0 L120,25 L103,51 L109,61 L124,58 L129,61 L111,66 L99,68 L72,85 L48,117 L16,131 L0,109 L9,89 L27,77 L30,62 L99,15 L114,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ulus"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62" y="66">
                      ULS
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
