import React, { useEffect } from "react";
import "../turkeymap.css";

export const Osmaniye = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(105.000000, 51.000000)">
                <g id="bahce" transform="translate(160.000000, 141.000000)">
                  <path
                    d="M48,0 L65,30 L44,49 L31.9999994,51 L21,50 C21,50 -3.41948692e-13,45 1,45 C2,45 48,0 48,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bahçe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25" y="39">
                      BHÇ
                    </tspan>
                  </text>
                </g>
                <g id="duzici" transform="translate(99.000000, 115.000000)">
                  <path
                    d="M56,6 L60,4 L73,0 L92,3 L109,26 L62,71 L54,71 L0,65 L10,46 L12,45 L13,41 L9,37 L22,21 L25,15 L29,16 L29,27 L33,28 L37,18 L55,11 L56,6 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Düziçi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48" y="42">
                      DÜZ
                    </tspan>
                  </text>
                </g>
                <g
                  id="hasanbeyli"
                  transform="translate(153.000000, 186.000000)"
                >
                  <path
                    d="M9,0 L0,0 L0,15 L13,16 L20,27 L9,38 L18,44 L51,4 L39,6 L27,5 L9,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hasanbeyli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19" y="15">
                      HSB
                    </tspan>
                  </text>
                </g>
                <g id="kadirli" transform="">
                  {" "}
                  <path
                    d="M115,0 L65,10 L59,107 L12,146 L8,172 L8.8817842e-16,190 L9,191 L42,180 L69,161 L99,180 L109,161 L101,158 L98,150 L101,142 L106,141 L105,129 L107,126 L108,40 L91,35 L89,27 L112,15 L115,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kadirli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58" y="136">
                      KDR
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(42.000000, 161.000000)">
                  <path
                    d="M111,25 L57,19 L27,0 L0,19 L16,29 L13,78 L60,81 L80,100 L123,102 L129,69 L120,63 L131,52 L124,41 L111,40 L111,25 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="63" y="60">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sumbas" transform="translate(6.000000, 10.000000)">
                  <path
                    d="M59,0 L22,56 L14,106 L0,118 L6,136 L53,97 L59,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sumbas"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31" y="81">
                      SUM
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
