import React, { useEffect } from "react";
import "../turkeymap.css";

export const Siirt = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(0.000000, 79.000000)">
                <g id="baykan" transform="translate(48.679245, 0.000000)">
                  <path
                    d="M0,54.7641509 L25.3537736,0 L52.7358491,9.12735849 L115.613208,27.3820755 L91.2735849,69.9764151 L51.7216981,67.9481132 L0,54.7641509 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Baykan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.6084906" y="37.4386792">
                      BAY
                    </tspan>
                  </text>
                </g>
                <g id="eruh" transform="translate(118.655660, 87.216981)">
                  <path
                    d="M81.1320755,9.12735849 L156.179245,87.2169811 L129.811321,118.65566 L88.2311321,120.683962 L40.5660377,120.683962 L14.1981132,103.443396 L0,82.1462264 L7.0990566,58.8207547 L46.6509434,22.3113208 L70.990566,0 L81.1320755,9.12735849 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eruh"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69.9764151" y="69.8915094">
                      ERH
                    </tspan>
                  </text>
                </g>
                <g id="kurtalan" transform="translate(0.000000, 54.764151)">
                  <path
                    d="M100.400943,13.1839623 L48.6792453,0 L19.2688679,23.3254717 L0,41.5801887 L19.2688679,62.8773585 L91.2735849,76.0613208 L86.2028302,110.542453 L118.65566,114.599057 L125.754717,91.2735849 L100.400943,13.1839623 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kurtalan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.9339623" y="48.5943396">
                      KUR
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(100.400943, 66.933962)">
                  <path
                    d="M59.8349057,11.1556604 L39.5518868,3.04245283 L0,0 L25.3537736,79.1037736 L65.9198113,41.5801887 L59.8349057,11.1556604 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.3537736" y="33.3820755">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="pervari" transform="translate(199.787736, 59.834906)">
                  <path
                    d="M230.212264,67.9481132 L208.915094,19.2688679 L170.377358,22.3113208 L147.051887,41.5801887 L100.400943,0 L73.0188679,33.4669811 L0,36.509434 L75.0471698,114.599057 L128.79717,83.1603774 L203.84434,113.584906 L230.212264,67.9481132 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pervari"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="112.570755" y="57.7216981">
                      PRV
                    </tspan>
                  </text>
                </g>
                <g id="sirvan" transform="translate(139.952830, 4.056604)">
                  <path
                    d="M160.235849,55.7783019 L98.3726415,19.2688679 L59.8349057,0 L24.3396226,23.3254717 L0,65.9198113 L20.2830189,74.0330189 L49.6933962,83.1603774 L59.8349057,92.2877358 L132.853774,89.245283 L160.235849,55.7783019 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şirvan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="83.1603774" y="58.7358491">
                      ŞİR
                    </tspan>
                  </text>
                </g>
                <g id="tillo" transform="translate(160.235849, 78.089623)">
                  <path
                    d="M29.4103774,9.12735849 L0,0 L6.08490566,30.4245283 L29.4103774,9.12735849 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tillo"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.08490566" y="16.1415094">
                      TİL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
