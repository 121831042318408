import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";

function Iletisim() {
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  return (
    <Card sx={{ minWidth: 275 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sela.TC İletişim</title>
        <meta
          name="description"
          content="Sela.TC Vefat Haberleri, Cenaze ilanları İletişim"
        ></meta>
      </Helmet>
      <CardContent>
        <Typography
          sx={{ fontSize: 17 }}
          color="text.secondary"
          gutterBottom
        ></Typography>
        <br />
        <Typography variant="h5" component="div">
          TEL: 0553 946 74 06
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Iletisim;
