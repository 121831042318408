import React, { useEffect } from "react";
import "../turkeymap.css";

export const Tekirdag = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 11.000000)">
                <g id="cerkezkoy" transform="translate(339.000000, 74.000000)">
                  <path
                    d="M66.866261,0.997026492 L44.808312,40.7013346 L32.6764401,35.1868474 L16.1329784,39.5984372 L0.692414113,27.4665653 L18.3387733,27.4665653 L35.9851325,6.51151373 L66.866261,0.997026492 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çerkezköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.6764401" y="24.5404882">
                      ÇER
                    </tspan>
                  </text>
                </g>
                <g id="corlu" transform="translate(272.000000, 109.000000)">
                  <path
                    d="M111.808312,5.70133464 L88.6474656,58.6404122 L64.3837218,63.052002 L40.1199779,79.5954637 L37.914183,93.9331305 L9.23884935,91.7273356 L0.415669759,70.7722841 L3.7243621,22.2447964 L44.5315677,26.6563862 L61.0750294,17.8332066 L73.2069014,20.0390015 L97.4706452,10.1129244 L95.2648503,1.28974484 L99.6764401,0.186847393 L111.808312,5.70133464 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çorlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.7083881" y="52.4056428">
                      ÇOR
                    </tspan>
                  </text>
                </g>
                <g id="ergene" transform="translate(253.000000, 51.000000)">
                  <path
                    d="M21.6214647,0.836180076 L0.666413136,47.1578729 L22.7243621,80.2447964 L63.5315677,84.6563862 L80.0750294,75.8332066 L92.2069014,78.0390015 L116.470645,68.1129244 L114.26485,59.2897448 L102.132978,62.5984372 L86.6894006,50.4665653 L69.0412833,30.6144112 L37.0620289,32.8202061 L24.930157,15.1738469 L21.6214647,0.836180076 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ergene"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.3707213" y="58.5694627">
                      ERG
                    </tspan>
                  </text>
                </g>
                <g id="hayrabolu" transform="translate(50.000000, 58.000000)">
                  <path
                    d="M167.270797,50.0839499 L148.521541,40.1578729 L120.949105,15.894129 L104.405643,13.6883341 L84.5534887,14.7912316 L55.878155,0.453564766 L33.8202061,5.96805201 L18.3796418,3.76225711 L30.5115137,36.8491806 L23.894129,60.010027 L0.733282627,83.1708734 L47.0549755,112.949105 L48.1578729,136.109951 L64.7013346,128.389669 L76.8332066,129.492566 L91.1708734,106.33172 L137.492566,78.7592836 L117.640412,57.8042321 L167.270797,50.0839499 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hayrabolu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="63.5984372" y="64.8042321">
                      HAY
                    </tspan>
                  </text>
                </g>
                <g id="kapakli" transform="translate(322.000000, 49.000000)">
                  <path
                    d="M94.8952355,0.630385179 L83.866261,25.9970265 L52.9851325,31.5115137 L35.3387733,52.4665653 L17.6924141,52.4665653 L0.0460549379,32.6144112 L5.56054218,14.968052 L26.5155937,19.3796418 L34.2358758,31.5115137 L55.1909274,8.35066732 L94.8952355,0.630385179 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kapaklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.6025172" y="20.8651546">
                      KPL
                    </tspan>
                  </text>
                </g>
                <g id="malkara" transform="translate(0.000000, 141.000000)">
                  <path
                    d="M126.833207,46.4925662 L120.215822,71.8592075 L125.730309,119.283798 L74.9970265,140.238849 L20.9550515,135.82726 L4.41158979,99.4316438 L13.2347694,32.1548994 L0,23.3317198 L8.82317959,15.6114377 L50.7332826,0.170873397 L97.0549755,29.9491045 L98.1578729,53.1099509 L114.701335,45.3896688 L126.833207,46.4925662 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Malkara"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57.3506673" y="79.962105">
                      MLK
                    </tspan>
                  </text>
                </g>
                <g
                  id="marmaraereglisi"
                  transform="translate(309.000000, 167.000000)"
                >
                  <path
                    d="M51.6474656,0.640412159 L61.5735427,26.0070535 L48.3387733,44.7563101 L39.5155937,52.4765922 L19.6634396,51.3736948 L0.914183005,35.9331305 L3.1199779,21.5954637 L27.3837218,5.05200195 L51.6474656,0.640412159 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Marmaraereğlisi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.8692345" y="31.904156">
                      MRĞ
                    </tspan>
                  </text>
                </g>
                <g id="muratli" transform="translate(167.000000, 98.000000)">
                  <path
                    d="M86.6664131,0.157872909 L50.2707973,10.0839499 L0.640412159,17.8042321 L22.6983611,40.9650785 L48.0650024,56.4056428 L66.8142591,53.0969504 L81.1519259,74.052002 L105.410526,81.7722841 L108.724362,33.2447964 L86.6664131,0.157872909 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Muratlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.0939769" y="34.7303091">
                      MUR
                    </tspan>
                  </text>
                </g>
                <g id="saray" transform="translate(274.000000, 0.000000)">
                  <path
                    d="M149.51262,0 L156.130005,8.82317959 L134.072056,25.3666413 L145.10103,38.6014107 L142.895236,49.6303852 L103.190927,57.3506673 L82.2358758,80.5115137 L73.4126963,68.3796418 L53.5605422,63.968052 L48.0460549,81.6144112 L16.0620289,83.8202061 L3.930157,66.1738469 L0.621464656,50.7332826 L3.930157,43.0130005 L106.49962,5.51448724 L149.51262,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="saray"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.7953116" y="45.6014107">
                      SRY
                    </tspan>
                  </text>
                </g>
                <g
                  id="suleymanpasa"
                  transform="translate(120.000000, 136.000000)"
                >
                  <path
                    d="M161.238849,64.7273356 L152.41567,43.7722841 L128.151926,36.052002 L113.814259,15.0969504 L95.0650024,18.4056428 L69.6983611,2.9650785 L67.4925662,0.759283604 L21.1708734,28.3317198 L6.83320657,51.4925662 L0.215821878,76.8592075 L5.73030912,124.283798 L26.6853606,126.489593 L47.6404122,120.975105 L71.904156,129.798285 L88.4476178,116.563516 L110.505567,72.4476178 L161.238849,64.7273356 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Süleymanpaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.2867713" y="69.5215407">
                      SÜL
                    </tspan>
                  </text>
                </g>
                <g id="sarkoy" transform="translate(55.000000, 256.000000)">
                  <path
                    d="M136.904156,9.79828507 L117.052002,29.6504391 L101.611438,52.8112856 L55.2897448,79.2808243 L32.1288984,81.4866192 L13.3796418,90.3097988 L0.144872421,72.6634396 L19.9970265,45.0910034 L19.9970265,25.2388493 L70.7303091,4.28379783 L91.6853606,6.48959272 L112.640412,0.975105481 L136.904156,9.79828507 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şarköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.7013346" y="39.9591315">
                      ŞAR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
