import React, { useEffect, useState } from "react";
import DeceaseList from "../components/deceaseList";

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Service from "../service";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Helmet } from "react-helmet";

import { Announcements } from "../components/announcements";

import moment from "moment";
import { Adana as ADANA } from "../districts/Adana";
import { Adiyaman as ADIYAMAN } from "../districts/Adiyaman";
import { Afyonkarahisar as AFYONKARAHISAR } from "../districts/Afyonkarahisar";
import { Agri as AĞRI } from "../districts/Agri";
import { Aksaray as AKSARAY } from "../districts/Aksaray";
import { Amasya as AMASYA } from "../districts/Amasya";
import { Ankara as ANKARA } from "../districts/Ankara";
import { Antalya as ANTALYA } from "../districts/Antalya";
import { Ardahan as ARDAHAN } from "../districts/Ardahan";
import { Artvin as ARTVIN } from "../districts/Artvin";
import { Aydin as AYDIN } from "../districts/Aydin";
import { Balikesir as BALIKESIR } from "../districts/Balikesir";
import { Bartin as BARTIN } from "../districts/Bartin";
import { Batman as BATMAN } from "../districts/Batman";
import { Bayburt as BAYBURT } from "../districts/Bayburt";
import { Bilecik as BILECIK } from "../districts/Bilecik";
import { Bingol as BINGÖL } from "../districts/Bingol";
import { Bitlis as BITLIS } from "../districts/Bitlis";
import { Bolu as BOLU } from "../districts/Bolu";
import { Burdur as BURDUR } from "../districts/Burdur";
import { Bursa as BURSA } from "../districts/Bursa";
import { Canakkale as ÇANAKKALE } from "../districts/Canakkale";
import { Cankiri as ÇANKIRI } from "../districts/Cankiri";
import { Corum as ÇORUM } from "../districts/Corum";
import { Denizli as DENIZLI } from "../districts/Denizli";
import { Diyarbakir as DIYARBAKIR } from "../districts/Diyarbakir";
import { Duzce as DÜZCE } from "../districts/Duzce";
import { Edirne as EDIRNE } from "../districts/Edirne";
import { Elazig as ELAZIĞ } from "../districts/Elazig";
import { Erzincan as ERZINCAN } from "../districts/Erzincan";
import { Erzurum as ERZURUM } from "../districts/Erzurum";
import { Eskisehir as ESKIŞEHIR } from "../districts/Eskisehir";
import { Gaziantep as GAZIANTEP } from "../districts/Gaziantep";
import { Giresun as GIRESUN } from "../districts/Giresun";
import { Gumushane as GÜMÜŞHANE } from "../districts/Gumushane";
import { Hakkari as HAKKARI } from "../districts/Hakkari";
import { Hatay as HATAY } from "../districts/Hatay";
import { Igdir as IĞDIR } from "../districts/Igdir";
import { Isparta as ISPARTA } from "../districts/Isparta";
import { Istanbul as İSTANBUL } from "../districts/Istanbul";
import { Izmir as İZMIR } from "../districts/Izmir";
import { Kahramanmaras as KAHRAMANMARAŞ } from "../districts/Kahramanmaras";
import { Karabuk as KARABÜK } from "../districts/Karabuk";
import { Karaman as KARAMAN } from "../districts/Karaman";
import { Kars as KARS } from "../districts/Kars";
import { Kastamonu as KASTAMONU } from "../districts/Kastamonu";
import { Kayseri as KAYSERI } from "../districts/Kayseri";
import { Kilis as KILIS } from "../districts/Kilis";
import { Kirikkale as KIRIKKALE } from "../districts/Kirikkale";
import { Kirklareli as KIRKLARELI } from "../districts/Kirklareli";
import { Kirsehir as KIRŞEHIR } from "../districts/Kirsehir";
import { Kocaeli as KOCAELI } from "../districts/Kocaeli";
import { Konya as KONYA } from "../districts/Konya";
import { Kutahya as KÜTAHYA } from "../districts/Kutahya";
import { Malatya as MALATYA } from "../districts/Malatya";
import { Manisa as MANISA } from "../districts/Manisa";
import { Mardin as MARDIN } from "../districts/Mardin";
import { Mersin as MERSIN } from "../districts/Mersin";
import { Mugla as MUĞLA } from "../districts/Mugla";
import { Mus as MUŞ } from "../districts/Mus";
import { Nevsehir as NEVŞEHIR } from "../districts/Nevsehir";
import { Nigde as NIĞDE } from "../districts/Nigde";
import { Ordu as ORDU } from "../districts/Ordu";
import { Osmaniye as OSMANIYE } from "../districts/Osmaniye";
import { Rize as RIZE } from "../districts/Rize";
import { Sakarya as SAKARYA } from "../districts/Sakarya";
import { Samsun as SAMSUN } from "../districts/Samsun";
import { Sanliurfa as ŞANLIURFA } from "../districts/Sanliurfa";
import { Siirt as SIIRT } from "../districts/Siirt";
import { Sinop as SINOP } from "../districts/Sinop";
import { Sivas as SIVAS } from "../districts/Sivas";
import { Sirnak as ŞIRNAK } from "../districts/Sirnak";
import { Tekirdag as TEKIRDAĞ } from "../districts/Tekirdag";
import { Tokat as TOKAT } from "../districts/Tokat";
import { Trabzon as TRABZON } from "../districts/Trabzon";
import { Tunceli as TUNCELI } from "../districts/Tunceli";
import { Usak as UŞAK } from "../districts/Usak";
import { Van as VAN } from "../districts/Van";
import { Yalova as YALOVA } from "../districts/Yalova";
import { Yozgat as YOZGAT } from "../districts/Yozgat";
import { Zonguldak as ZONGULDAK } from "../districts/Zonguldak";

export const CityContent = ({ city, height }) => {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  const [districts, setDistricts] = React.useState([]);
  const [district, setDistrict] = React.useState();
  const [list, setList] = useState();
  const [start, setStart] = React.useState(date);
  const [end, setEnd] = React.useState(Date.now());

  const compList = [
    ADANA,
    ADIYAMAN,
    AFYONKARAHISAR,
    AĞRI,
    AMASYA,
    ANKARA,
    ANTALYA,
    ARTVIN,
    AYDIN,
    BALIKESIR,
    BILECIK,
    BINGÖL,
    BITLIS,
    BOLU,
    BURDUR,
    BURSA,
    ÇANAKKALE,
    ÇANKIRI,
    ÇORUM,
    DENIZLI,
    DIYARBAKIR,
    EDIRNE,
    ELAZIĞ,
    ERZINCAN,
    ERZURUM,
    ESKIŞEHIR,
    GAZIANTEP,
    GIRESUN,
    GÜMÜŞHANE,
    HAKKARI,
    HATAY,
    ISPARTA,
    MERSIN,
    İSTANBUL,
    İZMIR,
    KARS,
    KASTAMONU,
    KAYSERI,
    KIRKLARELI,
    KIRŞEHIR,
    KOCAELI,
    KONYA,
    KÜTAHYA,
    MALATYA,
    MANISA,
    KAHRAMANMARAŞ,
    MARDIN,
    MUĞLA,
    MUŞ,
    NEVŞEHIR,
    NIĞDE,
    ORDU,
    RIZE,
    SAKARYA,
    SAMSUN,
    SIIRT,
    SINOP,
    SIVAS,
    TEKIRDAĞ,
    TOKAT,
    TRABZON,
    TUNCELI,
    ŞANLIURFA,
    UŞAK,
    VAN,
    YOZGAT,
    ZONGULDAK,
    AKSARAY,
    BAYBURT,
    KARAMAN,
    KIRIKKALE,
    BATMAN,
    ŞIRNAK,
    BARTIN,
    ARDAHAN,
    IĞDIR,
    YALOVA,
    KARABÜK,
    KILIS,
    OSMANIYE,
    DÜZCE,
  ];

  const Component = compList[city.id - 1];

  useEffect(() => {
    Service.get("adress/districts?id=" + city.id).then((result) => {
      setDistricts([...result]);
    });
  }, [city]);

  const handlePageChange = (page, districtID) => {
    const st = moment(start).format("YYYY-MM-DD");
    const ed = moment(end).format("YYYY-MM-DD");

    Service.getWithPageAndDates(
      "deceaseds/adress",
      page || 1,
      st,
      ed,
      "homeDistrict",
      districtID || district
    ).then((data) => {
      setList(data);
      districtID && setDistrict(districtID);
    });
  };

  const handleClick = (e) => {
    const ilce = e.turkishToUpper();
    const id = districts.find((item) => item.ilce === ilce);
    if (id) {
      handlePageChange(1, id._id);
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  //const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  if (city) {
    return (
      <Grid container sx={{ pl: 2, pt: 1 }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sela.TC {city.il} Cenaze ilanları, Vefat haberleri</title>
          <meta
            name="description"
            content={"Sela.TC " + city.il + " Vefat Haberleri, Cenaze ilanları"}
          ></meta>
        </Helmet>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid lg={12}></Grid>
          {!isMobile && (
            <Grid
              container
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Grid item lg={3} md={3} sm={3}>
                <FormControl sx={{ m: 1, width: "95%" }}>
                  <InputLabel id="demo-simple-select-label">
                    İlçe Seçiniz
                  </InputLabel>
                  <Select
                    label={"İlçe Seçiniz"}
                    size="medium"
                    sx={{ width: "95%" }}
                    onChange={(e) => handlePageChange(1, e.target.value)}
                    value={district || ""}
                  >
                    {districts.map((item) => (
                      <MenuItem value={item._id} key={item._id}>
                        {item.ilce}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid item lg={4} md={4} sm={4}>
                  <DesktopDatePicker
                    label="Başl."
                    inputFormat="DD/MM/yyyy"
                    value={start}
                    onChange={setStart}
                    renderInput={(params) => (
                      <TextField sx={{ width: "95%" }} {...params} />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4}>
                  <DesktopDatePicker
                    label="Bitiş"
                    inputFormat="DD/MM/yyyy"
                    value={end}
                    onChange={setEnd}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </LocalizationProvider>

              <Grid item lg={1} md={1} sm={1}>
                <Button
                  sx={{ width: "100%" }}
                  variant={"outlined"}
                  onClick={() => handlePageChange()}
                >
                  Ara
                </Button>
              </Grid>
            </Grid>
          )}
          {isMobile && (
            <Grid sx={{ width: "95%", pb: 2, pt: 2 }}>
              <FormControl sx={{ width: "100%", pb: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  İlçe Seçiniz
                </InputLabel>
                <Select
                  label={"İlçe Seçiniz"}
                  size="medium"
                  onChange={(e) => handlePageChange(1, e.target.value)}
                  value={district || ""}
                >
                  {districts.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.ilce}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Başl."
                  inputFormat="DD/MM/yyyy"
                  value={start}
                  onChange={setStart}
                  renderInput={(params) => (
                    <TextField sx={{ width: "100%", pb: 1 }} {...params} />
                  )}
                />
                <DesktopDatePicker
                  label="Bitiş"
                  inputFormat="DD/MM/yyyy"
                  value={end}
                  onChange={setEnd}
                  renderInput={(params) => (
                    <TextField sx={{ width: "100%", pb: 1 }} {...params} />
                  )}
                />
                <br />
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => handlePageChange()}
                >
                  Ara
                </Button>
              </LocalizationProvider>
            </Grid>
          )}
        </Grid>
        {!isMobile && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          >
            <div style={{ height: height || 600, width: "100%" }}>
              <Component handleClick={handleClick} />
            </div>
          </Grid>
        )}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          direction={"row"}
          justifyContent={"center"}
        >
          {list && (
            <DeceaseList handlePageChange={handlePageChange} list={list} />
          )}
          {isMobile && <Announcements />}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
