import { createTheme } from "@mui/material/styles";
import { grey, orange } from "@mui/material/colors";

const theme = createTheme({
  typography: {
    h1: {
      main: grey[50],
      fontSize: "3rem",
    },
  },

  palette: {
    mode: "light",
    primary: {
      light: "#B6AFAF",
      main: grey[600],
      dark: "#008394",
      contrastText: "#fff",
    },
    secondary: {
      light: "#B6AFAF",
      main: orange[400],
      dark: "#b22a00",
      contrastText: "#fff",
    },
    contained: {
      light: "#B6AFAF",
      main: "#B6AFAF",
      dark: "#b22a00",
      contrastText: "#fff",
    },
    outlined: {
      light: "#B6AFAF",
      main: "#B6AFAF",
      dark: "#b22a00",
      contrastText: "#fff",
    },
  },
});

export default theme;
