import React, { useEffect } from "react";
import "../turkeymap.css";
export const Ankara = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            console.log(event.target.parentElement.id);
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(11.000000, 3.000000)">
                <g id="akyurt" transform="translate(292.665685, 97.555228)">
                  <path
                    d="M2.12076583,19.6170839 L3.71134021,13.7849779 L16.4359352,0 L25.44919,1.59057437 L33.4020619,1.06038292 L36.0530191,4.24153166 L30.7511046,9.01325479 L33.4020619,20.6774669 L15.9057437,27.0397644 L8.48306333,22.7982327 L0,23.3284242 L2.12076583,19.6170839 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akyurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.8453608" y="17.1340206">
                      AKY
                    </tspan>
                  </text>
                </g>
                <g id="altindag" transform="translate(262.974963, 120.353461)">
                  <path
                    d="M29.6907216,0.530191458 L38.7039764,0 L45.5964654,4.24153166 L43.4756996,8.48306333 L35.5228277,6.3622975 L16.4359352,17.4963181 L0,18.556701 L0.530191458,13.7849779 L13.7849779,5.30191458 L13.7849779,2.12076583 L29.6907216,0.530191458 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altındağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.4963181" y="11.832106">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="ayas" transform="translate(155.346097, 82.179676)">
                  <path
                    d="M50.89838,4.24153166 L77.4079529,0 L73.6966127,20.1472754 L60.4418262,31.281296 L57.7908689,45.0662739 L61.5022091,55.1399116 L51.4285714,66.8041237 L47.1870398,75.287187 L21.7378498,59.3814433 L0,63.0927835 L21.7378498,16.9661267 L39.2341679,20.1472754 L51.4285714,10.6038292 L50.89838,4.24153166 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ayaş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.0530191" y="47.3549337">
                      AYŞ
                    </tspan>
                  </text>
                </g>
                <g id="bala" transform="translate(270.927835, 155.346097)">
                  <path
                    d="M66.2739323,3.71134021 L59.9116348,28.1001473 L56.730486,31.8114875 L57.7908689,37.1134021 L64.1531664,42.9455081 L64.6833579,46.6568483 L74.7569956,53.0191458 L77.9381443,75.8173785 L76.34757,77.4079529 L77.9381443,80.5891016 L81.1192931,84.8306333 L80.0589102,89.6023564 L82.179676,95.9646539 L89.0721649,95.9646539 L91.7231222,98.6156112 L89.6023564,100.736377 L87.4815906,103.917526 L91.1929308,104.447717 L92.7835052,109.21944 L90.6627393,109.749632 L83.2400589,105.5081 L55.1399116,99.6759941 L29.1605302,107.098675 L19.6170839,101.266568 L13.2547865,104.977909 L1.59057437,91.7231222 L0,78.9985272 L7.95287187,72.6362297 L7.42268041,35.5228277 L2.12076583,23.8586156 L15.9057437,5.83210604 L21.7378498,1.59057437 L33.4020619,0 L39.7643594,2.12076583 L46.6568483,12.1944035 L53.0191458,12.1944035 L66.2739323,3.71134021 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bala"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.7643594" y="64.3210604">
                      BAL
                    </tspan>
                  </text>
                </g>
                <g id="beypazari" transform="translate(120.883652, 42.415317)">
                  <path
                    d="M0.530191458,23.3284242 L4.77172312,21.7378498 L25.9793814,21.7378498 L42.4153166,10.0736377 L59.3814433,1.59057437 L64.6833579,0 L71.5758468,17.4963181 L58.3210604,24.9189985 L56.2002946,56.730486 L34.4624448,102.857143 L25.44919,106.568483 L16.9661267,105.5081 L19.6170839,100.206186 L9.01325479,100.206186 L4.24153166,90.1325479 L0,77.9381443 L14.3151694,69.455081 L14.8453608,66.8041237 L9.54344624,68.9248895 L0.530191458,73.6966127 L0.530191458,23.3284242 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beypazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.9793814" y="55.3078056">
                      BYP
                    </tspan>
                  </text>
                </g>
                <g id="camlidere" transform="translate(185.567010, 4.771723)">
                  <path
                    d="M27.5699558,0 L24.9189985,5.83210604 L29.6907216,13.2547865 L28.6303387,19.6170839 L0,37.6435935 L6.89248895,55.1399116 L48.7776141,45.0662739 L47.7172312,26.5095729 L50.3681885,14.3151694 L27.5699558,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çamlıdere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.1001473" y="37.8114875">
                      ÇML
                    </tspan>
                  </text>
                </g>
                <g id="cankaya" transform="translate(252.371134, 137.849779)">
                  <path
                    d="M10.6038292,1.06038292 L27.0397644,0 L40.2945508,19.0868925 L34.4624448,23.3284242 L0,16.4359352 L10.6038292,1.06038292 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çankaya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.3755523" y="15.0132548">
                      ÇAN
                    </tspan>
                  </text>
                </g>
                <g id="cubuk" transform="translate(261.914580, 31.811487)">
                  <path
                    d="M56.2002946,67.3343152 L66.2739323,50.3681885 L66.2739323,35.5228277 L46.1266568,21.2076583 L41.8851252,9.01325479 L28.6303387,0 L14.3151694,10.6038292 L14.8453608,36.5832106 L0.530191458,44.005891 L0,49.3078056 L1.06038292,58.8512518 L3.71134021,72.1060383 L32.8718704,85.3608247 L34.4624448,79.5287187 L47.1870398,65.7437408 L56.2002946,67.3343152 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çubuk"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.7511046" y="44.173785">
                      ÇBK
                    </tspan>
                  </text>
                </g>
                <g id="elmadag" transform="translate(304.329897, 118.232695)">
                  <path
                    d="M0,37.1134021 L6.3622975,39.2341679 L13.2547865,49.3078056 L20.1472754,49.3078056 L32.8718704,40.8247423 L38.173785,13.7849779 L41.3549337,10.0736377 L21.7378498,0 L4.24153166,6.3622975 L2.12076583,10.6038292 L4.24153166,25.9793814 L0,37.1134021 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Elmadağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.9057437" y="29.8586156">
                      ELM
                    </tspan>
                  </text>
                </g>
                <g id="etimesgut" transform="translate(232.754050, 123.004418)">
                  <path
                    d="M4.24153166,0 L13.2547865,2.65095729 L16.4359352,10.0736377 L13.7849779,17.4963181 L7.95287187,20.6774669 L0,22.2680412 L4.24153166,15.3755523 L4.24153166,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Etimesgut"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.3622975" y="13.4226804">
                      ETİ
                    </tspan>
                  </text>
                </g>
                <g id="evren" transform="translate(384.388807, 281.531664)">
                  <path
                    d="M1.06038292,0 L3.71134021,4.24153166 L7.42268041,3.71134021 L9.54344624,10.0736377 L22.2680412,10.6038292 L23.8586156,12.1944035 L24.9189985,15.3755523 L10.0736377,24.3888071 L7.95287187,32.8718704 L11.1340206,36.0530191 L6.3622975,37.1134021 L1.59057437,31.8114875 L4.24153166,21.7378498 L0,12.724595 L1.06038292,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Evren"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7.42268041" y="20.3151694">
                      EVR
                    </tspan>
                  </text>
                </g>
                <g id="golbasi" transform="translate(234.344624, 154.285714)">
                  <path
                    d="M0,19.0868925 L25.9793814,45.5964654 L36.5832106,80.0589102 L44.5360825,73.6966127 L44.005891,36.5832106 L38.7039764,24.9189985 L52.4889543,6.89248895 L18.0265096,0 L0,19.0868925 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölbaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.3284242" y="18.1944035">
                      GÖL
                    </tspan>
                  </text>
                </g>
                <g id="gudul" transform="translate(177.083947, 59.911635)">
                  <path
                    d="M15.3755523,0 L25.44919,8.48306333 L29.1605302,27.0397644 L29.6907216,32.8718704 L17.4963181,42.4153166 L0,39.2341679 L2.12076583,7.42268041 L15.3755523,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güdül"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.54344624" y="28.2680412">
                      GDL
                    </tspan>
                  </text>
                </g>
                <g id="haymana" transform="translate(190.868925, 173.372607)">
                  <path
                    d="M43.4756996,0 L5.30191458,52.4889543 L2.65095729,69.9852725 L10.6038292,85.8910162 L0,123.53461 L15.3755523,131.487482 L51.9587629,121.413844 L55.6701031,113.991163 L64.1531664,114.521355 L75.8173785,102.326951 L78.4683358,90.1325479 L88.5419735,91.7231222 L93.3136966,86.9513991 L81.6494845,73.6966127 L80.0589102,60.9720177 L69.455081,26.5095729 L43.4756996,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Haymana"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.1266568" y="81.287187">
                      HYM
                    </tspan>
                  </text>
                </g>
                <g
                  id="kahramankazan"
                  transform="translate(229.042710, 74.226804)"
                >
                  <path
                    d="M3.71134021,7.95287187 L16.4359352,0 L24.9189985,5.83210604 L32.8718704,6.89248895 L33.9322533,15.9057437 L26.5095729,24.3888071 L24.3888071,34.9926362 L15.3755523,36.0530191 L7.95287187,48.7776141 L0,28.1001473 L3.71134021,7.95287187 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kahramankazan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.1340206" y="26.6774669">
                      KZN
                    </tspan>
                  </text>
                </g>
                <g id="kalecik" transform="translate(318.114875, 54.079529)">
                  <path
                    d="M10.0736377,13.2547865 L10.0736377,28.1001473 L0,45.0662739 L7.42268041,44.5360825 L10.6038292,47.7172312 L5.30191458,52.4889543 L7.95287187,64.1531664 L27.5699558,74.2268041 L35.5228277,77.9381443 L42.4153166,75.287187 L58.8512518,59.9116348 L53.5493373,57.2606775 L55.1399116,51.4285714 L52.4889543,42.4153166 L70.5154639,11.6642121 L44.5360825,2.65095729 L31.281296,0 L17.4963181,0.530191458 L10.0736377,13.2547865 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kalecik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.6907216" y="37.281296">
                      KLC
                    </tspan>
                  </text>
                </g>
                <g id="kecioren" transform="translate(236.995582, 109.219440)">
                  <path
                    d="M16.4359352,0 L15.3755523,5.83210604 L22.2680412,12.724595 L39.7643594,13.2547865 L39.7643594,16.4359352 L26.5095729,24.9189985 L25.9793814,29.6907216 L12.1944035,23.8586156 L9.01325479,16.4359352 L0,13.7849779 L7.42268041,1.06038292 L16.4359352,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Keçiören"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.8453608" y="21.9057437">
                      KEÇ
                    </tspan>
                  </text>
                </g>
                <g
                  id="kizilcahamam"
                  transform="translate(192.459499, 0.000000)"
                >
                  <path
                    d="M98.0854197,31.8114875 L94.904271,24.3888071 L102.326951,15.9057437 L87.4815906,12.1944035 L77.9381443,9.01325479 L64.6833579,1.06038292 L44.005891,0 L20.6774669,4.77172312 L43.4756996,19.0868925 L40.8247423,31.8114875 L41.8851252,49.8379971 L0,59.9116348 L10.0736377,68.3946981 L13.7849779,86.4212077 L40.2945508,82.179676 L53.0191458,74.2268041 L61.5022091,80.0589102 L69.455081,81.1192931 L69.9852725,75.8173785 L84.3004418,68.3946981 L83.7702504,42.4153166 L98.0854197,31.8114875 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kızılcahamam"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.3814433" y="51.5964654">
                      KIZ
                    </tspan>
                  </text>
                </g>
                <g id="mamak" transform="translate(279.410898, 126.715758)">
                  <path
                    d="M0,11.1340206 L19.0868925,0 L27.0397644,2.12076583 L29.1605302,17.4963181 L24.9189985,28.6303387 L13.2547865,30.2209131 L0,11.1340206 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mamak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="5" y="16">
                      MMK
                    </tspan>
                  </text>
                </g>
                <g id="nallihan" transform="translate(0.000000, 52.488954)">
                  <path
                    d="M121.413844,13.2547865 L121.413844,63.622975 L117.172312,65.2135493 L117.702504,60.9720177 L117.172312,55.1399116 L112.930781,55.1399116 L113.460972,60.4418262 L96.4948454,59.9116348 L83.7702504,60.9720177 L82.7098675,57.7908689 L77.4079529,56.2002946 L81.1192931,53.0191458 L76.8777614,52.4889543 L68.9248895,53.0191458 L68.9248895,49.8379971 L64.6833579,50.3681885 L49.8379971,55.6701031 L41.3549337,51.9587629 L40.2945508,49.3078056 L26.5095729,55.6701031 L27.0397644,58.8512518 L7.42268041,56.730486 L3.18114875,47.1870398 L0,40.8247423 L35.5228277,22.7982327 L39.7643594,5.83210604 L42.4153166,7.10542736e-15 L64.1531664,10.0736377 L83.7702504,5.30191458 L88.011782,12.724595 L106.568483,18.0265096 L121.413844,13.2547865 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nallıhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="72.6362297" y="36.7511046">
                      NLH
                    </tspan>
                  </text>
                </g>
                <g id="polatli" transform="translate(129.366716, 141.561119)">
                  <path
                    d="M16.9661267,7.42268041 L4.24153166,24.3888071 L15.9057437,37.1134021 L26.5095729,66.2739323 L31.8114875,73.1664212 L25.44919,93.8438881 L36.5832106,106.038292 L28.6303387,117.172312 L20.6774669,116.642121 L0,132.547865 L10.0736377,138.910162 L21.7378498,159.587629 L38.173785,160.11782 L61.5022091,155.346097 L72.1060383,117.702504 L64.1531664,101.79676 L66.8041237,84.3004418 L104.977909,31.8114875 L89.0721649,12.724595 L77.4079529,7.42268041 L73.1664212,15.9057437 L47.7172312,0 L25.9793814,3.71134021 L16.9661267,7.42268041 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Polatlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.3078056" y="56.89838">
                      POL
                    </tspan>
                  </text>
                </g>
                <g id="pursaklar" transform="translate(252.371134, 90.132548)">
                  <path
                    d="M10.6038292,0 L13.2547865,13.7849779 L42.4153166,27.0397644 L40.2945508,30.7511046 L24.3888071,32.3416789 L6.89248895,31.8114875 L0,24.9189985 L1.06038292,19.0868925 L3.18114875,8.48306333 L10.6038292,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pursaklar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.724595" y="28.2680412">
                      PUR
                    </tspan>
                  </text>
                </g>
                <g id="sincan" transform="translate(206.774669, 102.326951)">
                  <path
                    d="M30.2209131,20.6774669 L30.2209131,36.0530191 L25.9793814,42.9455081 L11.6642121,51.9587629 L0,46.6568483 L10.0736377,34.9926362 L6.3622975,24.9189985 L9.01325479,11.1340206 L22.2680412,0 L30.2209131,20.6774669 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sincan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10" y="30">
                      SNC
                    </tspan>
                  </text>
                </g>
                <g
                  id="sereflikochisar"
                  transform="translate(300.088365, 255.022091)"
                >
                  <path
                    d="M0,7.42268041 L34.9926362,32.8718704 L33.4020619,28.6303387 L30.7511046,25.44919 L34.4624448,22.2680412 L49.8379971,37.6435935 L54.6097202,51.9587629 L50.89838,50.89838 L48.7776141,51.4285714 L49.8379971,66.2739323 L47.1870398,72.6362297 L49.8379971,76.34757 L66.2739323,91.1929308 L67.8645066,90.1325479 L65.7437408,81.6494845 L68.9248895,72.6362297 L73.1664212,78.4683358 L78.9985272,86.9513991 L78.4683358,95.4344624 L84.8306333,104.977909 L92.2533137,97.0250368 L101.266568,67.8645066 L95.4344624,62.562592 L90.6627393,63.622975 L85.8910162,58.3210604 L88.5419735,48.2474227 L84.3004418,39.2341679 L85.3608247,26.5095729 L76.34757,19.0868925 L70.5154639,18.556701 L69.455081,15.3755523 L65.7437408,16.9661267 L60.4418262,20.6774669 L58.3210604,17.4963181 L51.4285714,19.0868925 L56.2002946,13.2547865 L53.5493373,11.1340206 L50.3681885,13.2547865 L49.3078056,11.1340206 L49.3078056,7.95287187 L53.5493373,7.95287187 L57.7908689,7.95287187 L54.0795287,5.83210604 L25.9793814,0 L0,7.42268041 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şereflikoçhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="63.622975" y="45.7643594">
                      ŞRF
                    </tspan>
                  </text>
                </g>
                <g
                  id="yenimahalle"
                  transform="translate(218.438881, 133.078056)"
                >
                  <path
                    d="M0,21.2076583 L15.9057437,40.2945508 L33.4020619,21.7378498 L44.5360825,5.83210604 L30.7511046,0 L28.1001473,7.42268041 L22.2680412,10.6038292 L14.3151694,12.1944035 L0,21.2076583 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenimahalle"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8" y="26">
                      YMH
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
