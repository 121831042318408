import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Service from "../service";
import { Google } from "../components/google";
import { Apple } from "../components/apple";
import React from "react";

import { useNavigate } from "react-router-dom";

export const RegionBar = ({ city }) => {
  const [cities, setCities] = React.useState([]);
  const navigation = useNavigate();
  const [ct, setCt] = React.useState(city);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const clickHandler = (e) => {
    setCt(e.target.value);
    navigation("/il/" + e.target.value, {
      state: e.target.value,
    });
  };

  React.useEffect(() => {
    Service.get("adress/cities").then((data) => setCities(data));
  }, []);

  return (
    <Grid
      container
      sx={{
        p: 2,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Grid>
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.cenazenvarapp&gl=TR"
          } target={"_blank"}
        >
          <Google />
        </a>
        <Apple />
      </Grid>
      <Grid sx={{ width: isMobile ? "100%" : 150 }}>
        <FormControl sx={{ m: 1, width: isMobile ? "100%" : 150 }}>
          <InputLabel id="demo-simple-select-label">İl Seçiniz</InputLabel>

          <Select
            sx={{ width: isMobile ? "100%" : 150 }}
            label="İl Seçiniz"
            size="small"
            onChange={clickHandler}
            value={ct || ""}
          >
            {cities.map((item) => (
              <MenuItem value={item.il} key={item._id}>
                {item.il}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
