import React from "react";
import SELAIMG from "../assets/imgs/sela.png";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const Logo = () => {
  return (
    <Button component={Link} to="/">
      <img src={SELAIMG} style={{ width: 160, height: 70 }} alt={"sela.tc"} />
    </Button>
  );
};

export default Logo;
