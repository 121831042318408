import React from "react";
import applestore from "../assets/imgs/apple.png";

export const Apple = () => {
  return (
    <img
      src={applestore}
      style={{ width: 150, height: 40 }}
      alt={"Apple Store'dan indirmek için tıklayın. "}
    />
  );
};
