import { Grid } from "@mui/material";
import React from "react";

import Resim from "../../assets/imgs/uzun.jpg";
import { useNavigate } from "react-router-dom";

export const AdsHorizontalMefta2 = ({ ads }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ height: "100%", pr: 2 }}
    >
      {ads.map((data) => {
        if (data.order >= 3 && data.condoglianze === "true") {
          return data.imgSrc === "" ? (
            <Grid
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/istekFormu", { state: { id: data } });
              }}
            >
              <img src={Resim} style={{ width: 230, height: 330 }} />
            </Grid>
          ) : (
            <a key={data._id} href={data.redirectUrl} target="_blank">
              <img src={data.imgSrc} style={{ width: 250, height: 330 }} />
            </a>
          );
        }
      })}
    </Grid>
  );
};
