import React from "react";
import NavBar from "../components/nav";
import { Container } from "@mui/material";

import { Outlet } from "react-router-dom";
//import { Footer } from "../components/footer";

export const Main = () => {
  return (
    <>
      <NavBar />
      <Container maxWidth={"xl"}>
        <Outlet />
      </Container>
    </>
  );
};
