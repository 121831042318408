import React from "react";
import { Main } from "./layouts/main";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import About from "./pages/about";
import Iletisim from "./pages/iletisim";
import Decease from "./pages/decease";
import City from "./pages/city";
import Announcements from "./pages/announcements";
import Notfound from "./pages/404";
import { Search } from "./pages/search";
import { RequestForm } from "./pages/requestForm";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="vefatara" element={<Search />} />
          <Route path="duyurular" element={<Announcements />} />
          <Route path="il/:id" element={<City />} />
          <Route path="merhum/:id" element={<Decease />} />
          <Route path="hakkimizda" element={<About />} />
          <Route path="iletisim" element={<Iletisim />} />
          <Route path="istekFormu" element={<RequestForm />} />
          <Route path="*" element={<Notfound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
