import React, { useEffect } from "react";
import "../turkeymap.css";

export const Mardin = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 67.000000)">
                <g id="artuklu" transform="translate(147.001828, 73.107861)">
                  <path
                    d="M63.6745887,3.14442413 L69.1773309,14.1499086 L51.8829982,23.583181 L52.6691042,32.2303473 L75.4661792,28.2998172 L99.0493601,50.3107861 L113.985375,86.4716636 L45.5941499,94.3327239 L29.0859232,41.6636197 L7.0749543,36.1608775 L0,17.2943327 L22.0109689,0 L63.6745887,3.14442413 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Artuklu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57.3857404" y="56.3107861">
                      ART
                    </tspan>
                  </text>
                </g>
                <g id="dargecit" transform="translate(345.886654, 0.000000)">
                  <path
                    d="M0,46.3802559 L29.0859232,18.0804388 L66.0329068,0 L75.4661792,24.369287 L68.3912249,51.8829982 L25.9414991,66.8190128 L0,46.3802559 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dargeçit"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.2303473" y="41.3747715">
                      DRG
                    </tspan>
                  </text>
                </g>
                <g id="derik" transform="translate(0.000000, 64.460695)">
                  <path
                    d="M0,3.14442413 L75.4661792,0 L80.1828154,25.9414991 L125.776965,53.4552102 L114.771481,87.2577697 L62.1023766,106.91042 L6.28884826,12.5776965 L0,3.14442413 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Derik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58.1718464" y="53.166362">
                      DER
                    </tspan>
                  </text>
                </g>
                <g id="kiziltepe" transform="translate(62.102377, 109.268739)">
                  <path
                    d="M0,62.1023766 L0.786106033,92.7605119 L27.5137112,121.846435 L69.9634369,84.8994516 L130.493601,58.1718464 L113.985375,5.50274223 L91.9744059,0 L63.6745887,8.64716636 L52.6691042,42.4497258 L0,62.1023766 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kızıltepe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="85.6855576" y="47.6636197">
                      KZT
                    </tspan>
                  </text>
                </g>
                <g id="mazidagi" transform="translate(75.466179, 36.160878)">
                  <path
                    d="M-9.9475983e-14,28.2998172 L73.8939671,-9.9475983e-14 L93.5466179,36.9469835 L71.535649,54.2413163 L78.6106033,73.1078611 L50.3107861,81.7550274 L4.7166362,54.2413163 L-9.9475983e-14,28.2998172 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mazidağı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.0914077" y="41.3747715">
                      MZD
                    </tspan>
                  </text>
                </g>
                <g id="midyat" transform="translate(253.912249, 46.380256)">
                  <path
                    d="M14.1499086,9.43327239 C14.1499086,9.43327239 91.9744059,0 91.9744059,0 C91.9744059,0 117.915905,20.4387569 117.915905,20.4387569 L111.627057,60.5301645 L44.8080439,75.4661792 L29.8720293,61.3162706 L0.786106033,19.6526508 L14.1499086,9.43327239 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Midyat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.1023766" y="39.0164534">
                      MDY
                    </tspan>
                  </text>
                </g>
                <g id="nusaybin" transform="translate(246.051188, 105.338208)">
                  <path
                    d="M37.7330896,2.3583181 L52.6691042,16.5082267 L119.488117,1.57221207 L119.488117,16.5082267 L139.926874,14.9360146 L137.568556,47.952468 L58.1718464,64.4606947 L14.9360146,54.2413163 L0,18.0804388 L4.7166362,0 L37.7330896,2.3583181 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nusaybin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.8884826" y="38.2303473">
                      NSY
                    </tspan>
                  </text>
                </g>
                <g id="omerli" transform="translate(210.676417, 66.032907)">
                  <path
                    d="M35.3747715,57.3857404 L40.0914077,39.3053016 L73.1078611,41.6636197 L44.0219378,0 L0,10.2193784 L5.50274223,21.2248629 L11.7915905,35.3747715 L35.3747715,57.3857404 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ömerli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.7276051" y="26.4387569">
                      ÖMR
                    </tspan>
                  </text>
                </g>
                <g id="savur" transform="translate(149.360146, 2.358318)">
                  <path
                    d="M0,33.8025594 L15.7221207,29.0859232 L14.1499086,5.50274223 L92.7605119,0 L118.702011,53.4552102 L105.338208,63.6745887 L61.3162706,73.8939671 L19.6526508,70.749543 L0,33.8025594 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Savur"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.6691042" y="39.8025594">
                      SVR
                    </tspan>
                  </text>
                </g>
                <g id="yesilli" transform="translate(198.884826, 87.257770)">
                  <path
                    d="M17.2943327,0 L0,9.04021938 L0.786106033,18.0804388 L23.583181,14.1499086 L17.2943327,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yeşilli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.28884826" y="13.0749543">
                      YŞL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
