import React, { useEffect } from "react";
import "../turkeymap.css";

export const Artvin = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(31.000000, 3.000000)">
                <g id="ardanuc" transform="translate(132.688822, 54.380665)">
                  <path
                    d="M82.6586103,0 L52.2054381,41.3293051 L0,39.1540785 L81.570997,154.441088 L175.10574,157.703927 L194.682779,105.498489 L163.141994,85.9214502 L142.477341,45.6797583 L82.6586103,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ardanuç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="102.23565" y="91.9214502">
                      ARD
                    </tspan>
                  </text>
                </g>
                <g id="arhavi" transform="translate(14.138973, 71.782477)">
                  <path
                    d="M0,19.5770393 L16.3141994,0 L58.7311178,8.70090634 L52.2054381,80.4833837 L22.8398792,61.9939577 L13.0513595,34.8036254 L0,19.5770393 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arhavi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.102719" y="34.2779456">
                      ARH
                    </tspan>
                  </text>
                </g>
                <g id="borcka" transform="translate(72.870091, 0.000000)">
                  <path
                    d="M35.8912387,18.489426 L85.9214502,33.7160121 L118.549849,0 L153.353474,7.61329305 L142.477341,54.3806647 L112.024169,95.7099698 C112.024169,95.7099698 65.2567976,93.5347432 61.9939577,93.5347432 C58.7311178,93.5347432 0,80.4833837 0,80.4833837 L11.9637462,44.592145 L31.5407855,39.1540785 L35.8912387,18.489426 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Borçka"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71.7824773" y="60.3806647">
                      BRÇ
                    </tspan>
                  </text>
                </g>
                <g id="hopa" transform="translate(30.453172, 6.525680)">
                  <path
                    d="M0,65.2567976 L23.9274924,55.4682779 L55.4682779,0 L78.3081571,11.9637462 L73.9577039,32.6283988 L54.3806647,38.0664653 L42.4169184,73.9577039 L0,65.2567976 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hopa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.2054381" y="24.489426">
                      HOP
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(84.833837, 93.534743)">
                  <path
                    d="M129.425982,115.287009 L117.462236,125.075529 L79.3957704,100.060423 L0,72.8700906 L31.5407855,48.9425982 L47.8549849,0 L129.425982,115.287009 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.4682779" y="71.2567976">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="murgul" transform="translate(66.344411, 80.483384)">
                  <path
                    d="M66.3444109,13.0513595 L6.52567976,0 L0,71.7824773 L3.26283988,79.3957704 L18.489426,85.9214502 L50.0302115,61.9939577 L66.3444109,13.0513595 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Murgul"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.7522659" y="39.7160121">
                      MUR
                    </tspan>
                  </text>
                </g>
                <g id="savsat" transform="translate(215.347432, 7.613293)">
                  <path
                    d="M10.8761329,0 L132.688822,8.70090634 L152.265861,38.0664653 L157.703927,77.2205438 L112.024169,152.265861 L80.4833837,132.688822 L59.8187311,92.4471299 L0,46.7673716 L10.8761329,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şavşat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="81.570997" y="62.5558912">
                      ŞVT
                    </tspan>
                  </text>
                </g>
                <g id="yusufeli" transform="translate(0.000000, 159.879154)">
                  <path
                    d="M202.296073,58.7311178 L197.945619,117.462236 L165.317221,160.966767 L125.075529,154.441088 L61.9939577,200.120846 L51.1178248,137.039275 L0,110.936556 L9.78851964,76.1329305 L51.1178248,25.0151057 L69.6072508,0 L84.8338369,6.52567976 L164.229607,33.7160121 L202.296073,58.7311178 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yusufeli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="101.148036" y="89.7462236">
                      YSF
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
