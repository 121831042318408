import React, { useEffect } from "react";
import "../turkeymap.css";

export const Hatay = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(106.000000, 3.000000)">
                <g id="altinozu" transform="translate(91.782178, 227.227723)">
                  <path
                    d="M56.1386139,20.4950495 L52.5742574,79.3069307 L37.4257426,75.7425743 L34.7524752,91.7821782 L15.1485149,95.3465347 L6.23762376,73.960396 L8.91089109,65.049505 L0,53.4653465 L18.7128713,16.039604 L40.990099,14.2574257 L48.1188119,0 L56.1386139,20.4950495 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altınözü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.0594059" y="58.5742574">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="antakya" transform="translate(38.316832, 178.217822)">
                  <path
                    d="M59.7029703,122.970297 L32.970297,117.623762 L57.029703,71.2871287 L48.1188119,61.4851485 L39.2079208,60.5940594 L32.0792079,70.3960396 L0,32.970297 L40.0990099,4.45544554 L74.8514851,0 L86.4356436,11.5841584 L78.4158416,31.1881188 L70.3960396,40.0990099 L101.584158,49.009901 L94.4554455,63.2673267 L72.1782178,65.049505 L53.4653465,102.475248 L62.3762376,114.059406 L59.7029703,122.970297 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Antakya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.4257426" y="47.8811881">
                      ANT
                    </tspan>
                  </text>
                </g>
                <g id="arsuz" transform="translate(0.000000, 130.099010)">
                  <path
                    d="M38.3168317,81.0891089 L6.23762376,102.475248 L0,82.8712871 L20.4950495,57.029703 L32.970297,39.2079208 L88.2178218,0 L97.1287129,20.4950495 L77.5247525,33.8613861 L78.4158416,52.5742574 L38.3168317,81.0891089 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arsuz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.2277228" y="45.2079208">
                      ASZ
                    </tspan>
                  </text>
                </g>
                <g id="belen" transform="translate(77.524752, 143.465347)">
                  <path
                    d="M0,20.4950495 L0.891089109,39.2079208 L35.6435644,34.7524752 L36.5346535,0 L19.6039604,7.12871287 L0,20.4950495 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Belen"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13.3663366" y="25.6039604">
                      BLN
                    </tspan>
                  </text>
                </g>
                <g id="defne" transform="translate(58.811881, 238.811881)">
                  <path
                    d="M11.5841584,9.8019802 L20.4950495,23.1683168 L0,47.2277228 L12.4752475,57.029703 L36.5346535,10.6930693 L27.6237624,0.891089109 L18.7128713,0 L11.5841584,9.8019802 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Defne"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.23762376" y="46.990099">
                      DFN
                    </tspan>
                  </text>
                </g>
                <g id="dortyol" transform="translate(77.524752, 19.603960)">
                  <path
                    d="M45.4455446,0 L83.7623762,1.78217822 L66.8316832,53.4653465 L17.8217822,49.9009901 L0,31.1881188 L45.4455446,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dörtyol"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.990099" y="28.2772277">
                      DRT
                    </tspan>
                  </text>
                </g>
                <g id="erzin" transform="translate(42.772277, 0.000000)">
                  <path
                    d="M20.4950495,0 L0.891089109,24.950495 L0,39.2079208 L8.01980198,36.5346535 L34.7524752,50.7920792 L80.1980198,19.6039604 L62.3762376,2.67326733 L20.4950495,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Erzin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.950495" y="23.8217822">
                      ERZ
                    </tspan>
                  </text>
                </g>
                <g id="hassa" transform="translate(134.554455, 21.386139)">
                  <path
                    d="M26.7326733,0 L40.990099,20.4950495 L76.6336634,34.7524752 L58.8118812,85.5445545 L0,84.6534653 L9.8019802,51.6831683 L26.7326733,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hassa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.2970297" y="55.9009901">
                      HAS
                    </tspan>
                  </text>
                </g>
                <g id="iskenderun" transform="translate(88.217822, 91.782178)">
                  <path
                    d="M46.3366337,14.2574257 L25.8415842,51.6831683 L8.91089109,58.8118812 L0,38.3168317 L8.91089109,37.4257426 L11.5841584,20.4950495 L8.91089109,0 L46.3366337,14.2574257 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İskenderun"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.7128713" y="30.0594059">
                      IDN
                    </tspan>
                  </text>
                </g>
                <g id="kirikhan" transform="translate(113.168317, 106.039604)">
                  <path
                    d="M21.3861386,0 L0.891089109,38.3168317 L0,72.1782178 L11.5841584,83.7623762 L3.56435644,103.366337 L19.6039604,103.366337 L44.5544554,94.4554455 L49.9009901,66.8316832 L76.6336634,55.2475248 L80.1980198,0.891089109 L21.3861386,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kırıkhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.5148515" y="43.4257426">
                      KRK
                    </tspan>
                  </text>
                </g>
                <g id="kumlu" transform="translate(157.722772, 161.287129)">
                  <path
                    d="M32.0792079,0 L47.2277228,40.0990099 L38.3168317,55.2475248 L0,39.2079208 L5.34653465,11.5841584 L32.0792079,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kumlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.9306931" y="35.4059406">
                      KML
                    </tspan>
                  </text>
                </g>
                <g id="payas" transform="translate(95.346535, 69.504950)">
                  <path
                    d="M49.009901,3.56435644 L0,0 L1.78217822,22.2772277 L39.2079208,36.5346535 L49.009901,3.56435644 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Payas"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.8217822" y="18.4752475">
                      PYS
                    </tspan>
                  </text>
                </g>
                <g id="reyhanli" transform="translate(108.712871, 200.495050)">
                  <path
                    d="M96.2376238,0.891089109 L104.257426,1.78217822 L115.841584,27.6237624 L95.3465347,37.4257426 L47.2277228,40.0990099 L39.2079208,47.2277228 L31.1881188,26.7326733 L0,17.8217822 L8.01980198,8.91089109 L24.0594059,8.91089109 L49.009901,0 L87.3267327,16.039604 L96.2376238,0.891089109 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Reyhanlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.7920792" y="24.7128713">
                      REY
                    </tspan>
                  </text>
                </g>
                <g id="samandag" transform="translate(6.237624, 211.188119)">
                  <path
                    d="M0,21.3861386 L41.8811881,88.2178218 L65.049505,84.6534653 L52.5742574,74.8514851 L73.0693069,50.7920792 L64.1584158,37.4257426 L32.0792079,0 L0,21.3861386 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Samandağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33.8613861" y="52.3366337">
                      SAM
                    </tspan>
                  </text>
                </g>
                <g id="yayladagi" transform="translate(35.643564, 295.841584)">
                  <path
                    d="M62.3762376,5.34653465 L35.6435644,0 L12.4752475,3.56435644 L0,29.4059406 L13.3663366,33.8613861 L26.7326733,29.4059406 L31.1881188,43.6633663 L64.1584158,63.2673267 L71.2871287,26.7326733 L62.3762376,5.34653465 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yayladağı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.990099" y="34.5148515">
                      YAY
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
