import * as React from "react";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import Data from "../data";

const columns = [
  {
    field: "name",
    headerName: "Adı",
    width: 150,
  },
  {
    field: "surName",
    headerName: "Soyadı",
    width: 150,
  },
  {
    field: "fatherName",
    headerName: "Baba Adı",
    width: 150,
  },
  {
    field: "age",
    headerName: "Yaşı",
    width: 60,
  },
  {
    field: "city",
    headerName: "İl",
    width: 110,
  },
  {
    field: "district",
    headerName: "İlçe",
    width: 110,
  },
  {
    field: "deathDateTime",
    headerName: "Detaylı Görüntüle",
    width: 200,
    renderCell: (params) => (
      <Button variant="outlined">Detaylı Görüntüle</Button>
    ),
  },
];

export default function DeceaseList({ list, handlePageChange, height }) {
  const [listem, setListem] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    list.docs.forEach((item) => {
      item.id = item._id;
      item.city = item.homeCity.il;
      item.district = item.homeDistrict.ilce;
      item.deathDateTime = moment(item.deathDateTime).format("DD-MM-yyyy");
    });

    setListem([...list.docs]);
  }, [list]);

  return (
    <div style={{ height: height || 600, width: "100%" }}>
      <DataGrid
        rows={listem}
        columns={columns}
        pagination
        pageSize={list.limit}
        rowsPerPageOptions={[list.limit]}
        rowCount={list.totalDocs + 1}
        paginationMode="server"
        onPageChange={(numb) => handlePageChange(numb + 1)}
        localeText={{ noResultsOverlayLabel: "Kayıt bulunamadı" }}
        onRowClick={(e) => {
          Data.data = e;
          console.log(e.row._id);
          navigate("/merhum/" + e.row._id);
        }}
      />
    </div>
  );
}
