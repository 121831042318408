import React, { useEffect } from "react";
import "../turkeymap.css";

export const Mersin = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 43.000000)">
                <g id="akdeniz" transform="translate(346.576320, 96.305278)">
                  <path
                    d="M4.29386591,0 L0,8.58773181 L9.20114123,26.9900143 L24.5363766,30.0570613 L4.29386591,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akdeniz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.20114123" y="26.2425107">
                      AKD
                    </tspan>
                  </text>
                </g>
                <g id="anamur" transform="translate(0.000000, 195.677603)">
                  <path
                    d="M0,17.1754636 L6.13409415,33.1241084 L7.36091298,60.1141227 L0.613409415,71.1554922 L34.9643367,84.6504993 L58.2738944,72.9957204 L55.2068474,63.1811698 L61.3409415,52.7532097 L60.7275321,42.9386591 L74.8359486,25.7631954 L79.743224,4.29386591 L69.9286733,0 L0,17.1754636 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Anamur"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.3766049" y="44.6447932">
                      ANM
                    </tspan>
                  </text>
                </g>
                <g id="aydincik" transform="translate(88.944365, 220.827389)">
                  <path
                    d="M19.0156919,42.3252496 L15.3352354,34.9643367 L15.9486448,26.9900143 L0,4.90727532 L14.1084165,0 L20.8559201,5.52068474 L47.8459344,15.9486448 L57.660485,26.9900143 L57.0470756,38.6447932 L49.6861626,42.9386591 L40.4850214,38.6447932 L35.5777461,42.9386591 L19.0156919,42.3252496 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aydıncık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.9900143" y="28.0827389">
                      AYD
                    </tspan>
                  </text>
                </g>
                <g id="bozyazi" transform="translate(55.206847, 199.971469)">
                  <path
                    d="M24.5363766,0 L19.6291013,21.4693295 L5.52068474,38.6447932 L6.13409415,48.4593438 L0,58.8873039 L3.06704708,68.7018545 L23.9229672,71.7689016 L26.3766049,75.4493581 L36.1911555,65.021398 L52.7532097,63.1811698 L49.0727532,55.8202568 L49.6861626,47.8459344 L33.7375178,25.7631954 L32.510699,6.13409415 L24.5363766,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozyazı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.0827389" y="52.0057061">
                      BZY
                    </tspan>
                  </text>
                </g>
                <g id="camliyayla" transform="translate(295.049929, 9.201141)">
                  <path
                    d="M0,31.8972896 L14.1084165,24.5363766 L13.4950071,9.20114123 L38.0313837,0 L41.7118402,27.6034237 L58.8873039,35.5777461 L66.2482168,47.232525 L75.4493581,50.9129815 L74.2225392,55.8202568 L40.4850214,66.2482168 L26.9900143,63.7945792 L18.4022825,48.4593438 L7.36091298,42.9386591 L0,31.8972896 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çamlıyayla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.2168331" y="42.1911555">
                      ÇML
                    </tspan>
                  </text>
                </g>
                <g id="erdemli" transform="translate(209.172611, 47.845934)">
                  <path
                    d="M59.5007133,145.378031 L101.825963,103.052782 L95.6918688,83.4236805 L74.2225392,59.5007133 L50.299572,57.0470756 L49.0727532,41.0984308 L57.0470756,0 L29.4436519,15.3352354 L0.613409415,36.1911555 L0,55.2068474 L44.7788873,121.455064 L42.9386591,136.17689 L59.5007133,145.378031 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Erdemli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.0057061" y="86.3566334">
                      ERD
                    </tspan>
                  </text>
                </g>
                <g id="gulnar" transform="translate(69.928673, 173.594864)">
                  <path
                    d="M0,22.0827389 L22.0827389,0 L28.2168331,6.74750357 L28.2168331,9.81455064 L31.8972896,7.9743224 L37.4179743,10.4279601 L41.7118402,4.90727532 L62.5677603,3.06704708 L79.1298146,7.9743224 L84.0370899,19.0156919 L93.8516405,17.788873 L102.439372,23.3095578 L96.9186876,38.6447932 L114.094151,52.7532097 L114.094151,84.0370899 L109.800285,77.9029957 L97.532097,82.810271 L95.0784593,92.0114123 L76.0627675,85.8773181 L76.6761769,74.2225392 L66.8616262,63.1811698 L39.871612,52.7532097 L33.1241084,47.232525 L19.0156919,52.1398003 L17.788873,32.510699 L9.81455064,26.3766049 L0,22.0827389 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gülnar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56.4336662" y="39.1241084">
                      GLN
                    </tspan>
                  </text>
                </g>
                <g id="mezitli" transform="translate(258.245364, 81.583452)">
                  <path
                    d="M52.7532097,69.3152639 L57.660485,61.3409415 L67.4750357,58.2738944 L70.5420827,52.1398003 L53.3666191,33.1241084 L58.2738944,19.6291013 L43.5520685,0.613409415 L31.2838802,0 L14.721826,8.58773181 L0,7.36091298 L1.22681883,23.3095578 L25.149786,25.7631954 L46.6191155,49.6861626 L52.7532097,69.3152639 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mezitli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.8972896" y="18.8815977">
                      MEZ
                    </tspan>
                  </text>
                </g>
                <g id="mut" transform="translate(65.021398, 91.398003)">
                  <path
                    d="M103.052782,0 L41.7118402,11.6547789 L32.510699,28.8302425 L25.149786,21.4693295 L0,24.5363766 L8.58773181,57.0470756 L46.6191155,87.1041369 L67.4750357,85.2639087 L84.0370899,90.171184 L88.9443652,101.212553 L98.7589158,99.9857347 L107.346648,105.506419 L130.656205,69.9286733 L119.614836,48.4593438 L103.052782,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mut"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.8616262" y="55.0727532">
                      MUT
                    </tspan>
                  </text>
                </g>
                <g id="silifke" transform="translate(166.847361, 79.743224)">
                  <path
                    d="M17.1754636,177.88873 L41.0984308,161.940086 L44.1654779,146.60485 L53.3666191,145.991441 L62.5677603,155.192582 L61.3409415,164.393723 L82.1968616,146.60485 L80.9700428,126.975749 L101.825963,113.480742 L85.2639087,104.279601 L87.1041369,89.5577746 L42.3252496,23.3095578 L42.9386591,4.29386591 L33.7375178,6.13409415 L15.3352354,0 L1.22681883,11.6547789 L18.4022825,61.3409415 L28.8302425,81.5834522 L5.52068474,117.161198 L0,132.496434 L17.1754636,146.60485 L17.1754636,177.88873 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Silifke"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.9129815" y="97.3980029">
                      SLF
                    </tspan>
                  </text>
                </g>
                <g id="tarsus" transform="translate(333.081312, 0.000000)">
                  <path
                    d="M0,9.20114123 L26.9900143,0 L35.5777461,19.6291013 L57.0470756,20.8559201 L66.2482168,31.8972896 L57.0470756,47.232525 L60.7275321,71.1554922 L66.8616262,68.7018545 L86.4907275,74.2225392 L82.1968616,95.6918688 L96.9186876,115.32097 L92.6248217,124.522111 L53.9800285,136.7903 L38.0313837,126.36234 L17.788873,96.3052782 L9.81455064,91.3980029 L2.45363766,75.4493581 L36.1911555,65.021398 L37.4179743,60.1141227 L28.2168331,56.4336662 L20.8559201,44.7788873 L3.68045649,36.8045649 L0,9.20114123 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tarsus"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.6191155" y="99.2382311">
                      TAR
                    </tspan>
                  </text>
                </g>
                <g id="toroslar" transform="translate(258.245364, 41.098431)">
                  <path
                    d="M7.9743224,6.74750357 L36.8045649,0 L44.1654779,11.0413695 L55.2068474,16.5620542 L63.7945792,31.8972896 L77.2895863,34.3509272 L84.6504993,50.299572 L92.6248217,55.2068474 L88.3309558,63.7945792 L97.532097,82.1968616 L88.9443652,84.0370899 L69.3152639,63.7945792 L58.2738944,60.1141227 L43.5520685,41.0984308 L31.2838802,40.4850214 L14.721826,49.0727532 L0,47.8459344 L7.9743224,6.74750357 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Toroslar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.4693295" y="26.8559201">
                      TOR
                    </tspan>
                  </text>
                </g>
                <g id="yenisehir" transform="translate(311.611983, 101.212553)">
                  <path
                    d="M4.90727532,0 L0,13.4950071 L17.1754636,32.510699 L35.5777461,23.9229672 L15.9486448,3.68045649 L4.90727532,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenişehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.6547789" y="20.1084165">
                      YNŞ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
