import React, { useEffect } from "react";
import "../turkeymap.css";

export const Elazig = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 51.000000)">
                <g id="agin" transform="translate(40.391389, 69.843444)">
                  <path
                    d="M17.6712329,0 L36.183953,5.04892368 L24.4031311,28.6105675 L15.9882583,29.4520548 L5.89041096,10.9393346 L17.6712329,0 Z M1.68297456,12.6223092 L17.6712329,40.3913894 L25.2446184,35.3424658 L37.8669276,43.7573386 L35.3424658,47.964775 L15.9882583,46.2818004 L0,20.1956947 L1.68297456,12.6223092 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ağın"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13.4637965" y="14.4148728">
                      AĞN
                    </tspan>
                  </text>
                </g>
                <g id="alacakaya" transform="translate(301.252446, 176.712329)">
                  <path
                    d="M11.7808219,59.7455969 L27.7690802,57.221135 L29.4520548,29.4520548 L55.5381605,31.1350294 L41.2328767,0 L15.146771,16.8297456 L0,15.9882583 L11.7808219,59.7455969 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alacakaya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.6223092" y="30.4031311">
                      ALC
                    </tspan>
                  </text>
                </g>
                <g id="aricak" transform="translate(342.485323, 160.724070)">
                  <path
                    d="M47.1232877,0 L83.3072407,41.2328767 L71.5264188,42.9158513 L14.3052838,47.1232877 L0,15.9882583 L26.927593,0 L47.1232877,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arıcak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.2446184" y="27.037182">
                      ARC
                    </tspan>
                  </text>
                </g>
                <g id="baskil" transform="translate(0.000000, 147.260274)">
                  <path
                    d="M29.4520548,0 L21.8786693,2.52446184 L22.7201566,15.9882583 L6.73189824,26.927593 L0,51.3307241 L5.89041096,61.4285714 L29.4520548,74.0508806 L76.5753425,86.6731898 L98.4540117,79.9412916 L117.808219,99.295499 L131.272016,81.6242661 L112.759295,35.3424658 L126.223092,24.4031311 L111.076321,23.5616438 L65.6360078,19.3542074 L29.4520548,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Baskil"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.2700587" y="55.6477495">
                      BSK
                    </tspan>
                  </text>
                </g>
                <g id="karakocan" transform="translate(308.825832, 0.000000)">
                  <path
                    d="M99.295499,82.4657534 L102.661448,15.9882583 L86.6731898,0 L52.1722114,29.4520548 L23.5616438,24.4031311 L5.04892368,38.7084149 L18.5127202,64.7945205 L0,96.7710372 L27.7690802,112.759295 L63.9530333,88.3561644 L99.295499,82.4657534 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karakoçan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.1722114" y="56.4892368">
                      KRA
                    </tspan>
                  </text>
                </g>
                <g id="keban" transform="translate(21.878669, 112.759295)">
                  <path
                    d="M53.8551859,5.04892368 L56.3796477,11.7808219 L47.964775,15.9882583 L58.0626223,22.7201566 L60.5870841,15.146771 L59.7455969,8.4148728 L69.0019569,2.52446184 L80.7827789,11.7808219 L90.8806262,0 L89.1976517,58.0626223 L43.7573386,53.8551859 L0,30.2935421 L3.36594912,27.7690802 L37.8669276,26.0861057 L34.5009785,3.36594912 L53.8551859,5.04892368 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Keban"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.1722114" y="38.8180039">
                      KBN
                    </tspan>
                  </text>
                </g>
                <g id="kovancilar" transform="translate(238.140900, 82.465753)">
                  <path
                    d="M93.4050881,62.2700587 L81.6242661,66.4774951 L70.6849315,75.7338552 L17.6712329,69.0019569 L5.89041096,66.4774951 L0,48.8062622 L9.25636008,37.8669276 L66.4774951,20.1956947 L70.6849315,14.3052838 L98.4540117,30.2935421 L134.637965,5.89041096 L169.980431,0 L168.297456,5.89041096 L144.735812,16.8297456 L141.369863,45.4403131 L109.393346,46.2818004 L93.4050881,62.2700587 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kovancılar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54.6966732" y="50.5988258">
                      KVN
                    </tspan>
                  </text>
                </g>
                <g id="maden" transform="translate(215.420744, 175.870841)">
                  <path
                    d="M0,75.7338552 L48.8062622,88.3561644 L97.6125245,60.5870841 L85.8317025,16.8297456 L57.221135,0 L32.8180039,21.8786693 L34.5009785,26.927593 L18.5127202,37.0254403 L20.1956947,48.8062622 L0,63.111546 L0,75.7338552 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Maden"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.1232877" y="50.5988258">
                      MDN
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(111.076321, 107.710372)">
                  <path
                    d="M1.68297456,5.04892368 L5.89041096,0 L121.174168,26.927593 L127.064579,47.1232877 L96.7710372,69.0019569 L105.18591,73.2093933 L147.260274,56.3796477 L179.236791,54.6966732 L190.176125,84.9902153 L161.565558,68.1604697 L137.162427,90.0391389 L106.027397,101.819961 L80.7827789,96.7710372 L20.1956947,121.174168 L1.68297456,74.8923679 L14.3052838,63.9530333 L2.4158453e-13,63.111546 L1.68297456,5.04892368 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54.6966732" y="57.3307241">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="palu" transform="translate(290.313112, 88.356164)">
                  <path
                    d="M0,74.0508806 L7.57338552,78.258317 L11.7808219,88.3561644 L35.3424658,69.8434442 L41.2328767,56.3796477 L57.221135,40.3913894 L89.1976517,39.5499022 L92.5636008,10.9393346 L116.125245,0 L139.686888,15.9882583 L127.064579,36.183953 L99.295499,72.3679061 L79.0998043,72.3679061 L52.1722114,88.3561644 L26.0861057,105.18591 L10.9393346,104.344423 L0,74.0508806 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Palu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.6360078" y="60.6966732">
                      PLU
                    </tspan>
                  </text>
                </g>
                <g id="sivrice" transform="translate(117.808219, 204.481409)">
                  <path
                    d="M0,42.074364 L14.3052838,52.1722114 L35.3424658,43.7573386 L52.1722114,44.5988258 L58.0626223,57.221135 L97.6125245,47.1232877 L97.6125245,34.5009785 L117.808219,20.1956947 L116.125245,8.4148728 L94.2465753,17.6712329 L83.3072407,10.0978474 L99.295499,5.04892368 L74.0508806,0 L13.4637965,24.4031311 L0,42.074364 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sivrice"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="64.7945205" y="34.6105675">
                      SVR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
