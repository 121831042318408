import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import DeceaseList from "../components/deceaseList";
import { SearchSide } from "../components/search";

import { useContext } from "../store";

import Service from "../service";

import { useNavigate } from "react-router-dom";

export const Search = () => {
  const [context, dispatch] = useContext();
  const navigate = useNavigate();

  const handleSearch = (page) => {
    Service.getSearchWithPage("deceaseds/search", context.text, page || 1).then(
      (data) => {
        dispatch({
          type: "SEARCH_DECEASE",
          payload: { response: data, name: context.text },
        });
      }
    );
  };

  useEffect(() => {
    if (!context.response) {
      navigate("/");
    }
  }, [context]);

  return (
    <Box sx={{ bgcolor: "white", minHeight: 850 }}>
      <Grid container>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <SearchSide />
        </Grid>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          {context.response && (
            <DeceaseList
              list={context.response}
              handlePageChange={handleSearch}
              height={800}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
