import React, { useEffect } from "react";
import "../turkeymap.css";

export const Gumushane = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(53.000000, 30.000000)">
                <g id="kelkit" transform="translate(95.000000, 162.000000)">
                  <path
                    d="M132,66 L138,91 L170,114 L125,143 L0,105 L7,83 L27,79 L35,0 L93,15 L95,32 L132,66 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kelkit"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="74" y="84">
                      KLK
                    </tspan>
                  </text>
                </g>
                <g id="kose" transform="translate(188.000000, 150.000000)">
                  <path
                    d="M77,0 L90,29 L39,78 L2,44 L0,27 L20,1 L40,9 L77,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Köse"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38" y="37">
                      KÖS
                    </tspan>
                  </text>
                </g>
                <g id="kurtun" transform="">
                  <path
                    d="M101,5 L94,0 L66,15 L57,16 L45,13 L43,22 L51,29 L33,45 L0,91 L12,110 L43,116 L128,20 L118,16 L101,5 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kürtün"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52" y="57">
                      KÜR
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(130.000000, 14.000000)">
                  <path
                    d="M61,37 L84,57 L98,55 L115,35 L117,6 L124,0 L132,5 L135,15 L129,30 L138,34 L150,33 L158,50 L169,46 L171,35 L166,25 L173,20 L184,23 L191,39 L199,46 L197,71 L155,90 L134,129 L135,136 L97,145 L78,137 L58,163 L0,148 L61,37 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="86" y="98">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="siran" transform="translate(31.000000, 150.000000)">
                  <path
                    d="M99,12 L45,0 L13,14 L0,52 L0,75 L18,96 L64,117 L71,95 L91,91 L99,12 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şiran"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42" y="51">
                      ŞİR
                    </tspan>
                  </text>
                </g>
                <g id="torul" transform="translate(43.000000, 19.000000)">
                  <path
                    d="M85,1 L148,32 L87,143 L33,131 L31,123 L11,114 L0,97 L85,1 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Torul"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71" y="78">
                      TRL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
