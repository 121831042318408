import React from "react";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { LinkItUrl } from "react-linkify-it";

const Announcements = () => {
  const params = useLocation();

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {params.state.title}
        </Typography>
        <br />
        <LinkItUrl>
          <Grid variant="h6">{params.state.announcement}</Grid>
        </LinkItUrl>
      </CardContent>
    </Card>
  );
};

export default Announcements;
