import React from "react";

const Store = React.createContext();
Store.displayName = "Store";

const useContext = () => React.useContext(Store);

const Provider = ({ children, state, reducer }) => {
  const [initState, dispatch] = React.useReducer(reducer, state);

  return (
    <Store.Provider value={[initState, dispatch]}>{children}</Store.Provider>
  );
};

export { useContext, Provider };
