import React, { useEffect } from "react";
import "../turkeymap.css";

export const Bitlis = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(23.000000, 3.000000)">
                <g id="adilcevaz" transform="translate(240.612245, 0.000000)">
                  <path
                    d="M0,42.244898 L44.0816327,33.0612245 L94.5918367,0 L128.571429,15.6122449 L113.877551,22.0408163 L122.142857,43.1632653 L139.591837,41.3265306 L131.326531,29.3877551 L149.693878,31.2244898 L145.102041,50.5102041 L120.306122,71.6326531 L67.0408163,75.3061224 L15.6122449,81.7346939 L0,42.244898 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Adilcevaz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.6938776" y="51.9183673">
                      ADC
                    </tspan>
                  </text>
                </g>
                <g id="ahlat" transform="translate(111.122449, 40.408163)">
                  <path
                    d="M8.26530612,79.8979592 L18.3673469,70.7142857 L0,61.5306122 L0.918367347,49.5918367 L35.8163265,40.4081633 L49.5918367,18.3673469 L105.612245,0 L129.489796,4.59183673 L145.102041,44.0816327 L105.612245,62.4489796 L100.102041,76.2244898 L59.6938776,84.4897959 L8.26530612,79.8979592 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ahlat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.2040816" y="45.4897959">
                      AHL
                    </tspan>
                  </text>
                </g>
                <g id="guroymak" transform="translate(93.673469, 120.306122)">
                  <path
                    d="M0,35.8163265 L19.2857143,30.3061224 L25.7142857,0 L77.1428571,4.59183673 L63.3673469,68.877551 L12.8571429,75.3061224 L0,35.8163265 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güroymak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.4081633" y="50.0816327">
                      GRY
                    </tspan>
                  </text>
                </g>
                <g id="hizan" transform="translate(200.204082, 247.040816)">
                  <path
                    d="M119.387755,95.5102041 L115.714286,24.7959184 L97.3469388,5.51020408 L82.6530612,2.75510204 L68.877551,27.5510204 L24.7959184,0 L0,42.244898 L56.0204082,75.3061224 L98.2653061,112.959184 L119.387755,95.5102041 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hizan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="70.7142857" y="58.3469388">
                      HZN
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(76.224490, 189.183673)">
                  <path
                    d="M148.77551,57.8571429 C148.77551,57.8571429 97.3469388,23.877551 96.4285714,23.877551 C95.5102041,23.877551 80.8163265,0 80.8163265,0 L30.3061224,6.42857143 L38.5714286,59.6938776 L17.4489796,59.6938776 L0,87.244898 L56.9387755,103.77551 L89.0816327,82.6530612 L123.979592,100.102041 L148.77551,57.8571429 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71.6326531" y="51.9183673">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="mutki" transform="translate(0.000000, 156.122449)">
                  <path
                    d="M106.530612,39.4897959 L93.6734694,0 L32.1428571,3.67346939 L0,15.6122449 L21.122449,23.877551 L34.8979592,54.1836735 L51.4285714,112.040816 L76.2244898,120.306122 L93.6734694,92.755102 L114.795918,92.755102 L106.530612,39.4897959 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mutki"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="68.877551" y="59.2653061">
                      MTK
                    </tspan>
                  </text>
                </g>
                <g id="tatvan" transform="translate(157.040816, 116.632653)">
                  <path
                    d="M140.510204,135.918367 L146.938776,66.122449 L129.489796,69.7959184 L93.6734694,54.1836735 L82.6530612,63.3673469 L63.3673469,52.3469388 L33.0612245,62.4489796 L51.4285714,31.2244898 L70.7142857,21.122449 L54.1836735,0 L13.7755102,8.26530612 L0,72.5510204 L14.6938776,96.4285714 L67.9591837,130.408163 L112.040816,157.959184 L125.816327,133.163265 L140.510204,135.918367 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tatvan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69.7959184" y="97.8367347">
                      TTV
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
