import React, { useEffect } from "react";
import "../turkeymap.css";

export const Trabzon = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(43.000000, 93.000000)">
                <g id="akcaabat" transform="translate(65.000000, 0.000000)">
                  <path
                    d="M2,0 L19,2 L36,17 L44,29 L56,31 L54,71 L22,88 L21,69 L0,59 L4,42 L2,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akçaabat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21" y="48">
                      AKÇ
                    </tspan>
                  </text>
                </g>
                <g id="arakli" transform="translate(180.000000, 36.000000)">
                  <path
                    d="M3,89 L6,99 L0,114 L9,118 L21,117 L29,134 L41,130 L42,119 L37,109 L44,104 L38,80 L57,11 L46,1 L39,0 L26,35 L25,50 L3,89 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Araklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19" y="78">
                      ARK
                    </tspan>
                  </text>
                </g>
                <g id="arsin" transform="translate(175.000000, 36.000000)">
                  <path
                    d="M0,84 L8,89 L30,50 L31,35 L44,0 L26,0 L11,6 L15,25 L0,84 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arsin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18" y="15">
                      ARS
                    </tspan>
                  </text>
                </g>
                <g id="besikduzu" transform="translate(1.000000, 15.000000)">
                  <path
                    d="M1,21 L9,0 L27,11 L26,35 L13,35 L0,37 L1,21 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beşikdüzü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7" y="24">
                      BŞD
                    </tspan>
                  </text>
                </g>
                <g id="carsibasi" transform="translate(45.000000, 0.000000)">
                  <path
                    d="M0,17 L13,1 L22,0 L24,42 L0,17 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çarşıbaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7" y="18">
                      ÇRŞ
                    </tspan>
                  </text>
                </g>
                <g id="caykara" transform="translate(250.000000, 96.000000)">
                  <path
                    d="M55,0 L57,21 L71,40 L88,47 L94,78 L45,82 L19,79 L0,70 L10,55 L15,2 L55,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çaykara"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31" y="46">
                      ÇYK
                    </tspan>
                  </text>
                </g>
                <g
                  id="dernekpazari"
                  transform="translate(265.000000, 76.000000)"
                >
                  <path
                    d="M2,2 L0,22 L40,20 L41,0 L2,2 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dernekpazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13" y="15">
                      DRN
                    </tspan>
                  </text>
                </g>
                <g id="duzkoy" transform="translate(53.000000, 59.000000)">
                  <path
                    d="M34,29 L15,35 L0,15 L4,6 L12,0 L33,10 L34,29 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Düzköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10" y="19">
                      DÜZ
                    </tspan>
                  </text>
                </g>
                <g id="hayrat" transform="translate(302.000000, 37.000000)">
                  <path
                    d="M14,0 L32,28 L47,80 L36,106 L19,99 L5,80 L3,60 L4,39 L0,21 L14,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hayrat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16" y="60">
                      HYR
                    </tspan>
                  </text>
                </g>
                <g id="koprubasi" transform="translate(235.000000, 78.000000)">
                  <path
                    d="M30,20 L25,73 L15,88 L7,81 L0,65 L3,19 L11,1 L32,0 L30,20 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Köprübaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9" y="30">
                      KPR
                    </tspan>
                  </text>
                </g>
                <g id="macka" transform="translate(39.000000, 66.000000)">
                  <path
                    d="M0,56 L73,91 L96,111 L110,109 L127,89 L129,60 L118,47 L121,20 L108,0 L80,5 L48,22 L29,28 L0,56 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Maçka"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65" y="58">
                      MAÇ
                    </tspan>
                  </text>
                </g>
                <g id="of" transform="translate(267.000000, 28.000000)">
                  <path
                    d="M3,18 L37,0 L49,9 L35,30 L39,48 L0,50 L3,18 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Of"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14" y="32">
                      OF
                    </tspan>
                  </text>
                </g>
                <g id="ortahisar" transform="translate(119.000000, 25.000000)">
                  <path
                    d="M41,61 L49,57 L41,33 L53,10 L44,4 L25,0 L2,6 L0,46 L28,41 L41,61 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ortahisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15" y="24">
                      ORT
                    </tspan>
                  </text>
                </g>
                <g id="surmene" transform="translate(218.000000, 46.000000)">
                  <path
                    d="M19,1 L30,5 L52,0 L49,32 L28,33 L20,51 L17,97 L6,94 L0,70 L19,1 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sürmene"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24" y="20">
                      SÜR
                    </tspan>
                  </text>
                </g>
                <g id="salpazari" transform="translate(0.000000, 50.000000)">
                  <path
                    d="M15,0 L22,61 L15,56 L0,22 L1,2 L15,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şalpazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="3" y="21">
                      ŞLP
                    </tspan>
                  </text>
                </g>
                <g id="tonya" transform="translate(15.000000, 49.000000)">
                  <path
                    d="M53,45 L24,73 L7,62 L0,1 L12,1 L16,7 L29,7 L42,16 L38,25 L53,45 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tonya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16" y="38">
                      TON
                    </tspan>
                  </text>
                </g>
                <g id="vakfikebir" transform="translate(27.000000, 17.000000)">
                  <path
                    d="M1,9 L9,7 L18,0 L42,25 L38,42 L30,48 L17,39 L4,39 L0,33 L1,9 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Vakfıkebir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13" y="28">
                      VKF
                    </tspan>
                  </text>
                </g>
                <g id="yomra" transform="translate(157.000000, 35.000000)">
                  <path
                    d="M11,91 L18,85 L33,26 L29,7 L20,6 L15,0 L3,23 L11,47 L3,51 L0,78 L11,91 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yomra"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10" y="31">
                      YOM
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
