import React, { useEffect } from "react";
import "../turkeymap.css";
export const Adiyaman = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            console.log(event.target.parentElement.id);
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 63.000000)">
                <g id="besni" transform="translate(39.831579, 140.315789)">
                  <path
                    d="M139.410526,100.484211 L109.536842,19.0105263 L63.3684211,0 L47.9789474,1.81052632 L0,70.6105263 L7.24210526,79.6631579 L32.5894737,71.5157895 L100.484211,93.2421053 L114.063158,97.7684211 L139.410526,100.484211 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Besni"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57.9368421" y="51.2631579">
                      BSN
                    </tspan>
                  </text>
                </g>
                <g id="celikhan" transform="translate(152.084211, 0.000000)">
                  <path
                    d="M14.4842105,71.5157895 L15.3894737,60.6526316 L0,40.7368421 L9.05263158,30.7789474 L67.8947368,0 L92.3368421,12.6736842 L78.7578947,44.3578947 L90.5263158,57.9368421 L81.4736842,83.2842105 L14.4842105,71.5157895 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çelikhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.2105263" y="49.4526316">
                      ÇLK
                    </tspan>
                  </text>
                </g>
                <g id="gerger" transform="translate(321.368421, 0.905263)">
                  <path
                    d="M2.71578947,53.4105263 L56.1263158,28.9684211 L73.3263158,0 L108.631579,4.52631579 L105.010526,10.8631579 L100.484211,27.1578947 L82.3789474,41.6421053 L64.2736842,53.4105263 L40.7368421,62.4631579 L41.6421053,68.8 L55.2210526,62.4631579 L57.0315789,72.4210526 L41.6421053,91.4315789 L42.5473684,109.536842 L29.8736842,114.063158 L20.8210526,86.9052632 L0,88.7157895 L2.71578947,53.4105263 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gerger"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.2947368" y="72.0842105">
                      GRG
                    </tspan>
                  </text>
                </g>
                <g id="golbasi" transform="translate(0.000000, 95.052632)">
                  <path
                    d="M39.8315789,115.873684 L30.7789474,102.294737 L14.4842105,104.105263 L0,79.6631579 L13.5789474,45.2631579 L47.0736842,0 L85.0947368,8.14736842 L103.2,45.2631579 L87.8105263,47.0736842 L39.8315789,115.873684 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölbaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.9263158" y="59.4105263">
                      GLB
                    </tspan>
                  </text>
                </g>
                <g id="kahta" transform="translate(240.800000, 43.452632)">
                  <path
                    d="M72.4210526,0 L83.2842105,10.8631579 L80.5684211,46.1684211 L101.389474,44.3578947 L110.442105,71.5157895 L105.010526,79.6631579 L118.589474,89.6210526 L112.252632,93.2421053 L99.5789474,86 L103.2,100.484211 L91.4315789,102.294737 L93.2421053,108.631579 L78.7578947,106.821053 L87.8105263,124.926316 L76.9473684,124.021053 L68.8,96.8631579 L64.2736842,105.915789 L47.9789474,79.6631579 L46.1684211,88.7157895 L67.8947368,126.736842 L66.0842105,132.168421 L36.2105263,133.073684 L5.43157895,133.978947 L17.2,114.968421 L12.6736842,112.252632 L0,49.7894737 L30.7789474,44.3578947 L33.4947368,28.9684211 L72.4210526,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kâhta"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.9263158" y="63.0315789">
                      KHT
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(149.368421, 71.515789)">
                  <path
                    d="M29.8736842,169.284211 L67.8947368,149.368421 L60.6526316,142.126316 L51.6,141.221053 L42.5473684,130.357895 L40.7368421,119.494737 L30.7789474,109.536842 L43.4526316,104.105263 L52.5052632,118.589474 L66.9894737,134.884211 L69.7052632,126.736842 L56.1263158,97.7684211 L62.4631579,84.1894737 L73.3263158,107.726316 L78.7578947,99.5789474 L84.1894737,107.726316 L104.105263,84.1894737 C104.105263,84.1894737 92.3368421,22.6315789 91.4315789,21.7263158 C90.5263158,20.8210526 84.1894737,11.7684211 84.1894737,11.7684211 L17.2,-1.6080704e-15 L9.05263158,8.14736842 L0,87.8105263 L29.8736842,169.284211 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.8315789" y="63.9368421">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="samsat" transform="translate(226.315789, 176.526316)">
                  <path
                    d="M19.9157895,0.905263158 L18.1052632,10.8631579 L10.8631579,12.6736842 L9.05263158,20.8210526 L0.905263158,25.3473684 L0,43.4526316 L20.8210526,39.8315789 L29.8736842,23.5368421 L35.3052632,21.7263158 L35.3052632,12.6736842 L45.2631579,15.3894737 L53.4105263,11.7684211 L51.6,0 L19.9157895,0.905263158 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Samsat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.3894737" y="20.4842105">
                      SAM
                    </tspan>
                  </text>
                </g>
                <g id="sincik" transform="translate(230.842105, 12.673684)">
                  <path
                    d="M13.5789474,0 L82.3789474,30.7789474 L43.4526316,59.7473684 L40.7368421,75.1368421 L9.95789474,80.5684211 L2.71578947,70.6105263 L11.7684211,45.2631579 L0,31.6842105 L13.5789474,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sincik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.6315789" y="40.4">
                      SİN
                    </tspan>
                  </text>
                </g>
                <g id="tut" transform="translate(85.094737, 79.663158)">
                  <path
                    d="M0,23.5368421 L73.3263158,0 L64.2736842,79.6631579 L18.1052632,60.6526316 L0,23.5368421 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tut"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.4" y="44.0210526">
                      TUT
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
