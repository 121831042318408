import React, { useEffect } from "react";
import "../turkeymap.css";

export const Karaman = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(38.000000, 3.000000)">
                <g id="ayranci" transform="translate(246.139535, 0.000000)">
                  <path
                    d="M0,10.8837209 L36.8372093,0 L64.4651163,67.8139535 L105.488372,89.5813953 L101.302326,155.72093 L61.9534884,184.186047 L49.3953488,186.697674 L24.2790698,178.325581 L20.0930233,123.906977 L0,10.8837209 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ayrancı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56.0930233" y="125.72093">
                      AYR
                    </tspan>
                  </text>
                </g>
                <g id="basyayla" transform="translate(22.604651, 227.720930)">
                  <path
                    d="M32.6511628,0 L0,16.744186 L22.6046512,60.2790698 L33.4883721,67.8139535 L31.8139535,75.3488372 L53.5813953,72.8372093 L56.0930233,67.8139535 L46.0465116,50.2325581 L51.9069767,51.0697674 L32.6511628,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Başyayla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.2790698" y="38.6511628">
                      BŞY
                    </tspan>
                  </text>
                </g>
                <g id="ermenek" transform="translate(5.023256, 213.488372)">
                  <path
                    d="M16.744186,146.511628 L113.023256,123.069767 L142.325581,92.9302326 L134.790698,90.4186047 L136.465116,87.0697674 L142.325581,87.0697674 L149.023256,94.6046512 L159.069767,97.9534884 L155.72093,103.813953 L163.255814,107.162791 L169.116279,99.627907 L117.209302,58.6046512 L105.488372,14.2325581 L72,0 L66.9767442,2.51162791 L50.2325581,14.2325581 L69.4883721,65.3023256 L87.0697674,79.5348837 L103.813953,91.255814 L97.1162791,97.1162791 L98.7906977,110.511628 L91.255814,98.7906977 L87.9069767,98.7906977 L74.5116279,87.0697674 L49.3953488,89.5813953 L7.53488372,101.302326 L0,136.465116 L16.744186,146.511628 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ermenek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="82.0465116" y="53.7209302">
                      ERM
                    </tspan>
                  </text>
                </g>
                <g
                  id="kazimkarabekir"
                  transform="translate(49.395349, 85.395349)"
                >
                  <path
                    d="M62.7906977,0 L51.9069767,0 L30.1395349,3.34883721 L29.3023256,27.627907 L15.0697674,38.5116279 L0,61.9534884 L9.20930233,85.3953488 L42.6976744,107.162791 L72,89.5813953 L74.5116279,68.6511628 L87.9069767,66.1395349 L80.372093,47.7209302 L89.5813953,36 L80.372093,8.37209302 L62.7906977,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kazımkarabekir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="54.5581395">
                      KZM
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(76.186047, 10.883721)">
                  <path
                    d="M0.837209302,202.604651 L15.9069767,181.674419 L45.2093023,164.093023 L47.7209302,143.162791 L61.1162791,140.651163 L53.5813953,122.232558 L62.7906977,110.511628 L53.5813953,82.8837209 L36,74.5116279 L85.3953488,24.2790698 L93.7674419,39.3488372 L109.674419,36 L125.581395,45.2093023 L169.953488,-2.97436494e-15 L190.046512,113.023256 L194.232558,167.44186 L174.976744,183.348837 L91.255814,199.255814 L78.6976744,222.697674 L68.6511628,212.651163 L34.3255814,216.837209 L0.837209302,202.604651 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="120.55814" y="128.232558">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sariveliler" transform="translate(0.000000, 244.465116)">
                  <path
                    d="M22.6046512,0 L0,7.53488372 L10.0465116,56.0930233 L12.5581395,70.3255814 L54.4186047,58.6046512 L56.0930233,51.0697674 L45.2093023,43.5348837 L22.6046512,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıveliler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.7209302" y="36.1395349">
                      SAR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
