import React, { useRef } from "react";
import { Grid, Typography, Card } from "@mui/material/";

import {
  InputBase,
  Paper,
  Divider,
  IconButton,
  TextField,
  InputAdornment,
  Input,
  OutlinedInput,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import { useNavigate } from "react-router-dom";

import Service from "../service";

import { useContext } from "../store";

export const SearchSide = () => {
  const navigate = useNavigate();
  const [cities, setCities] = React.useState([]);
  const [city, setCity] = React.useState();
  const [districts, setDistricts] = React.useState([]);
  const [district, setDistrict] = React.useState();
  const [, distpach] = useContext();

  const search = useRef("");

  React.useEffect(() => {
    Service.get("adress/cities").then((data) => setCities(data));
  }, []);

  const handleSetCity = (e) => {
    setCity(e.target.value);
    Service.get("adress/districts?id=" + e.target.value).then((data) => {
      setDistricts(data);
    });
  };

  const handleSetDistrict = (e) => {
    setDistrict(e.target.value);
  };

  const handleSearch = (searchText, page) => {
    searchText &&
      Service.getSearchWithPage("deceaseds/search", searchText, page).then(
        (data) => {
          distpach({
            type: "SEARCH_DECEASE",
            payload: { response: data, name: searchText },
          });
          navigate("/vefatara");
        }
      );
    /*
//backende ekle
    if (city && !district) {
      const cityID = cities.find((item) => item.id === city);

      searchText &&
        Service.getSearchWithPage("deceaseds/search", searchText, 1).then(
          (data) => {
            distpach({
              type: "SEARCH_DECEASE",
              payload: data,
            });
            navigate("/vefatara");
          }
        );
    } else if (city && district) {
      const cityID = cities.find((item) => item.id === city);

      searchText &&
        Service.getSearchWithPage("deceaseds/search", searchText, 1).then(
          (data) => {
            distpach({
              type: "SEARCH_DECEASE",
              payload: data,
            });
            navigate("/vefatara");
          }
        );
    } else {
      searchText &&
        Service.getSearchWithPage("deceaseds/search", searchText, 1).then(
          (data) => {
            distpach({
              type: "SEARCH_DECEASE",
              payload: data,
            });
            navigate("/vefatara");
          }
        );
    }
    */
  };
  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: 4,
      }}
    >
      <Grid
        container
        sx={{
          maxHeight: 120,
          minHeight: 120,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Grid lg={12} md={12}>
          <Typography variant="h6">Arama</Typography>
        </Grid>
        <Grid container lg={12} md={12}>
          <OutlinedInput
            sx={{ m: 1, width: "100%" }}
            placeholder="Cenaze İlanı Ara"
            onChange={(e) => {
              search.current = e.target.value;
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => handleSearch(search.current)}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {/*}
        <Grid container lg={12} md={12}>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-label">İl Seçiniz</InputLabel>
            <Select
              label="İl Seçiniz"
              size="small"
              onChange={handleSetCity}
              sx={{ minWidth: 120 }}
              value={city || ""}
            >
              {cities.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.il}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container lg={12} md={12}>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-label">İlçe Seçiniz</InputLabel>
            <Select
              label="İlçe Seçiniz"
              size="small"
              onChange={handleSetDistrict}
              value={district || ""}
            >
              {districts.map((item) => (
                <MenuItem value={item._id} key={item.id}>
                  {item.ilce}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
              </Grid>{*/}
        </Grid>
      </Grid>
    </Card>
  );
};
