import React from "react";
import { Grid } from "@mui/material";
import Resim from "../../assets/imgs/image.jpg";
import { useNavigate } from "react-router-dom";

export const AdsVerticalCity = ({ ads }) => {
  const navigate = useNavigate();

  return (
    <Grid container sx={{ width: "100%" }}>
      {ads.map((data) => {
        return (
          data.order < 4 && (
            <Grid item md={4} lg={4} sm={4} xs={4}>
              {data.imgSrc === "" ? (
                <Grid sx={{ cursor: "pointer", pr: 2 }}>
                  <img
                    src={Resim}
                    onClick={() => {
                      navigate("/iletisim", { state: { id: data } });
                    }}
                    style={{ width: "100%", height: "auto" }}
                  />
                </Grid>
              ) : (
                <Grid sx={{ cursor: "pointer", pr: 2 }}>
                  <a key={data._id} href={data.redirectUrl} target="_blank">
                    <img
                      src={data.imgSrc}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </a>
                </Grid>
              )}
            </Grid>
          )
        );
      })}
    </Grid>
  );
};
