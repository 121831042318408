import React, { useEffect } from "react";
import "../turkeymap.css";
export const Eskisehir = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 36.000000)">
                <g id="alpu" transform="translate(181.519700, 30.656660)">
                  <path
                    d="M7.26078799,0 L37.1106942,3.22701689 L55.6660413,7.26078799 L69.380863,54.8592871 L46.7917448,106.491557 L4.84052533,104.878049 L0,65.3470919 L25.0093809,37.9174484 L7.26078799,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alpu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.8161351" y="64.2795497">
                      ALP
                    </tspan>
                  </text>
                </g>
                <g id="beylikova" transform="translate(228.311445, 85.515947)">
                  <path
                    d="M22.5891182,0 L0,51.6322702 L17.7485929,73.4146341 L56.4727955,51.6322702 L51.6322702,12.1013133 L22.5891182,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beylikova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.9756098" y="44.1106942">
                      BYL
                    </tspan>
                  </text>
                </g>
                <g id="cifteler" transform="translate(164.577861, 185.553471)">
                  <path
                    d="M68.5741088,-4.26325641e-14 L29.043152,17.7485929 L3.22701689,12.9080675 L0,43.564728 L21.782364,71.8011257 L31.4634146,73.4146341 L43.564728,72.6078799 L79.0619137,52.4390244 L101.651032,54.0525328 L106.491557,41.1444653 L91.9699812,29.8499062 L87.1294559,12.9080675 L68.5741088,-4.26325641e-14 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çifteler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="41.1444653" y="40.8836773">
                      ÇİF
                    </tspan>
                  </text>
                </g>
                <g id="gunyuzu" transform="translate(349.324578, 116.979362)">
                  <path
                    d="M31.4634146,0 L0,11.2945591 L14.521576,30.6566604 L8.87429644,50.0187617 L4.03377111,115.365854 L12.9080675,155.703565 L25.0093809,164.577861 L55.6660413,140.375235 L68.5741088,141.181989 L80.6754221,124.24015 L63.7335835,105.684803 L73.4146341,74.2213884 L65.3470919,63.7335835 L49.2120075,19.3621013 L31.4634146,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Günyüzü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.2363977" y="82.8348968">
                      GÜN
                    </tspan>
                  </text>
                </g>
                <g id="han" transform="translate(133.921201, 229.118199)">
                  <path
                    d="M30.6566604,0 L9.68105066,13.7148218 L0,37.1106942 L14.521576,45.1782364 L42.7579737,41.9512195 L52.4390244,28.2363977 L30.6566604,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Han"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.3621013" y="31.2026266">
                      HAN
                    </tspan>
                  </text>
                </g>
                <g id="inonu" transform="translate(0.000000, 75.834897)">
                  <path
                    d="M52.4390244,1.61350844 L46.7917448,0 L25.0093809,1.61350844 L8.06754221,10.4878049 L0,31.4634146 L14.521576,50.0187617 L42.7579737,50.0187617 L45.1782364,32.2701689 L55.6660413,15.3283302 L52.4390244,1.61350844 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İnönü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.5553471" y="28.782364">
                      İNÖ
                    </tspan>
                  </text>
                </g>
                <g id="mahmudiye" transform="translate(167.804878, 135.534709)">
                  <path
                    d="M78.2551595,23.3958724 L60.5065666,1.61350844 L18.5553471,0 L0.806754221,9.68105066 L7.26078799,27.4296435 L0,62.9268293 L25.8161351,67.7673546 L65.3470919,50.0187617 L73.4146341,41.9512195 L70.1876173,33.8836773 L78.2551595,23.3958724 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mahmudiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.043152" y="33.6228893">
                      MAH
                    </tspan>
                  </text>
                </g>
                <g id="mihalgazi" transform="translate(95.196998, 18.555347)">
                  <path
                    d="M53.2457786,27.4296435 L47.5984991,33.8836773 L33.8836773,32.2701689 L0,19.3621013 L12.9080675,0 L32.2701689,15.3283302 L38.7242026,24.2026266 L53.2457786,27.4296435 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mihalgazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.9080675" y="20.7148218">
                      MİH
                    </tspan>
                  </text>
                </g>
                <g id="mihaliccik" transform="translate(218.630394, 28.236398)">
                  <path
                    d="M0,5.64727955 L21.782364,0 L33.0769231,6.45403377 L44.3714822,0 L76.641651,1.61350844 L84.7091932,4.03377111 L79.8686679,5.64727955 L80.6754221,12.1013133 L124.24015,14.521576 L133.114447,11.2945591 L128.273921,20.1688555 L133.114447,20.9756098 L142.795497,19.3621013 L149.249531,37.9174484 L156.510319,53.2457786 L172.645403,53.2457786 L168.611632,61.3133208 L181.5197,62.9268293 L162.157598,88.7429644 L130.694184,100.037523 L114.559099,87.1294559 L98.424015,109.718574 L66.1538462,108.91182 L61.3133208,69.380863 L32.2701689,57.2795497 L18.5553471,9.68105066 L0,5.64727955 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mihalıççık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="87.1294559" y="55.4052533">
                      MİH
                    </tspan>
                  </text>
                </g>
                <g id="odunpazari" transform="translate(42.757974, 94.390244)">
                  <path
                    d="M0,31.4634146 L3.22701689,53.2457786 L32.2701689,82.2889306 L50.0187617,82.2889306 L62.9268293,76.641651 L75.0281426,79.0619137 L80.6754221,54.0525328 L125.853659,50.8255159 L143.602251,41.1444653 L138.761726,1.61350844 L106.491557,5.64727955 L91.163227,0 L49.2120075,7.26078799 L33.8836773,32.2701689 L0,31.4634146 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Odunpazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="79.0619137" y="31.2026266">
                      ODN
                    </tspan>
                  </text>
                </g>
                <g id="saricakaya" transform="translate(108.105066, 0.000000)">
                  <path
                    d="M40.3377111,45.9849906 L25.8161351,42.7579737 L19.3621013,33.8836773 L0,18.5553471 L11.2945591,4.84052533 L33.8836773,0 L55.6660413,0 L69.380863,6.45403377 L74.2213884,16.1350844 L40.3377111,45.9849906 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıcakaya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.8499062" y="21.521576">
                      SAR
                    </tspan>
                  </text>
                </g>
                <g id="seyitgazi" transform="translate(62.926829, 145.215760)">
                  <path
                    d="M12.1013133,31.4634146 L11.2945591,52.4390244 L0,65.3470919 L21.782364,101.651032 L37.1106942,105.684803 L48.4052533,123.433396 L70.9943715,121.013133 L80.6754221,97.6172608 L101.651032,83.902439 L104.878049,53.2457786 L112.138837,17.7485929 L105.684803,0 L60.5065666,3.22701689 L54.8592871,28.2363977 L42.7579737,25.8161351 L29.8499062,31.4634146 L12.1013133,31.4634146 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Seyitgazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58.8930582" y="59.4390244">
                      SYT
                    </tspan>
                  </text>
                </g>
                <g
                  id="sivrihisar"
                  transform="translate(233.151970, 115.365854)"
                >
                  <path
                    d="M100.037523,0 L83.902439,22.5891182 L51.6322702,21.782364 L12.9080675,43.564728 L4.84052533,54.0525328 L8.06754221,62.120075 L0,70.1876173 L18.5553471,83.0956848 L23.3958724,100.037523 L37.9174484,111.332083 L33.0769231,124.24015 L42.7579737,150.863039 L99.2307692,171.838649 L128.273921,179.099437 L141.181989,166.19137 L129.080675,157.317073 L120.206379,116.979362 L125.046904,51.6322702 L130.694184,32.2701689 L116.172608,12.9080675 L100.037523,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sivrihisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.1538462" y="82.8348968">
                      SİV
                    </tspan>
                  </text>
                </g>
                <g id="tepebasi" transform="translate(42.757974, 16.135084)">
                  <path
                    d="M9.68105066,61.3133208 L15.3283302,61.3133208 L26.6228893,43.564728 L53.2457786,30.6566604 L52.4390244,21.782364 L86.3227017,34.6904315 L100.037523,36.30394 L105.684803,29.8499062 L139.56848,0 L146.022514,14.521576 L163.771107,52.4390244 L138.761726,79.8686679 L106.491557,83.902439 L91.163227,78.2551595 L49.2120075,85.5159475 L33.8836773,110.525328 L0,109.718574 L2.42026266,91.9699812 L12.9080675,75.0281426 L9.68105066,61.3133208 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tepebaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="80.6754221" y="61.8592871">
                      TEP
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
