import React, { useEffect } from "react";
import "../turkeymap.css";
export const Izmir = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(10.000000, 3.000000)">
                <g id="aliaga" transform="translate(135.000000, 113.000000)">
                  <path
                    d="M30.5010887,0.163137599 L29.0865495,5.82129448 L30.5010887,10.0649121 L22.721123,10.7721817 L0.795765118,19.9666867 L0.0884955084,39.0629661 L5.74665239,46.8429319 L39.6955937,35.5266181 L47.4755594,5.82129448 L30.5010887,0.163137599 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aliağa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.5993142" y="30.5030347">
                      ALİ
                    </tspan>
                  </text>
                </g>
                <g id="balcova" transform="translate(147.000000, 228.000000)">
                  <path
                    d="M18.5010887,3.27716247 L2.23388771,0.448084027 L6.47750537,11.0571282 L0.819348488,16.0080154 L10.721123,15.3007458 L18.5010887,3.27716247 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Balçova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="5.77023576" y="8.86262325">
                      BAL
                    </tspan>
                  </text>
                </g>
                <g id="bayindir" transform="translate(223.000000, 240.000000)">
                  <path
                    d="M54.9569567,0.471667397 L66.9805401,22.3970253 L63.444192,49.9805401 L54.9569567,61.2968539 L7.56989286,61.2968539 L0.497196757,45.0296528 L10.3989713,33.7133391 L8.27716247,26.640643 L54.9569567,0.471667397 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bayındır"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.4952508" y="37.8842606">
                      BAY
                    </tspan>
                  </text>
                </g>
                <g id="bayrakli" transform="translate(166.000000, 197.000000)">
                  <path
                    d="M10.8174025,0.328221188 L12.2319417,22.9608487 L0.915627958,22.9608487 L0.208358348,1.74276041 L10.8174025,0.328221188 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bayraklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="1.62289757" y="23.5954222">
                      BAY
                    </tspan>
                  </text>
                </g>
                <g id="bergama" transform="translate(132.000000, 0.000000)">
                  <path
                    d="M0.259417068,31.1198628 L17.9411573,17.6817402 L35.6228976,8.48723532 L63.2064124,0 L103.52078,10.6090441 L114.129824,33.9489413 L113.422555,59.4106472 L85.8390399,60.1179168 L69.5718388,72.1415002 L69.5718388,91.2377797 L88.6681183,113.163138 L58.9627947,113.870407 L60.3773339,123.064912 L50.4755594,118.821294 L33.5010887,113.163138 L33.5010887,108.21225 L29.9647407,106.797711 L23.5993142,112.455868 L18.6484269,105.383172 L27.8429319,86.994162 L25.0138534,69.3124218 L33.5010887,56.5815688 L0.259417068,31.1198628 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bergama"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58.9627947" y="33.8762452">
                      BER
                    </tspan>
                  </text>
                </g>
                <g id="beydag" transform="translate(349.000000, 303.000000)">
                  <path
                    d="M4.634805,0.418662686 L31.5110502,12.4422461 L20.1947364,32.9530647 L11.7075011,37.1966824 L0.391187336,37.903952 L4.634805,0.418662686 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beydağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.58569227" y="25.1004029">
                      BEY
                    </tspan>
                  </text>
                </g>
                <g id="bornova" transform="translate(176.000000, 197.000000)">
                  <path
                    d="M2.23194172,22.9608487 L1.52467211,32.1553536 L5.76828977,37.8135105 L19.2064124,37.1062409 L36.8881526,5.27910846 L21.3282212,1.0354908 L13.5482555,3.15729963 L0.817402498,0.328221188 L2.23194172,22.9608487 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bornova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.719177" y="20.7663438">
                      BOR
                    </tspan>
                  </text>
                </g>
                <g id="buca" transform="translate(170.000000, 229.000000)">
                  <path
                    d="M25.2064124,5.10624091 L21.6700643,12.178937 L33.6936477,25.6170596 L20.2555251,33.3970253 L16.719177,32.6897557 L10.3537505,16.4225547 L0.451976008,17.1298243 L0.451976008,5.81351052 L7.52467211,0.155353637 L11.7682898,5.81351052 L25.2064124,5.10624091 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Buca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="4.69559367" y="14.9353193">
                      BUC
                    </tspan>
                  </text>
                </g>
                <g id="cesme" transform="translate(0.000000, 231.000000)">
                  <path
                    d="M41.0216374,6.64258896 L33.2416717,11.5934762 L28.998054,5.93531935 L27.5835148,15.1298243 L17.6817402,10.178937 L16.9744706,0.277162467 L9.19450493,1.69170169 L9.19450493,16.5443635 L0,17.9589027 L0,25.7388684 L21.2180883,35.640643 L29.7053236,27.8606773 L27.5835148,36.3479126 L35.3634805,42.7133391 L41.0216374,42.7133391 L45.9725246,20.0807115 L41.0216374,6.64258896 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çeşme"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.3890099" y="24.9589027">
                      ÇEŞ
                    </tspan>
                  </text>
                </g>
                <g id="cigli" transform="translate(134.000000, 206.000000)">
                  <path
                    d="M20.184775,0.522726118 L25.1356622,14.6681183 L15.2338877,14.6681183 L8.16119161,18.911736 L1.79576512,18.2044664 L0.381225898,12.5463095 L20.184775,0.522726118 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çiğli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.57573083" y="13.8881526">
                      ÇİĞ
                    </tspan>
                  </text>
                </g>
                <g id="dikili" transform="translate(117.000000, 31.000000)">
                  <path
                    d="M15.2594171,0.11986284 L0.406755259,18.5088727 L10.3085298,23.45976 L20.2103043,40.4342306 L6.77218175,50.3360051 L3.2358337,63.0668581 L13.1376082,78.6267895 L26.5757308,75.0904415 L33.6484269,74.3831719 L42.8429319,55.994162 L40.0138534,38.3124218 L48.5010887,25.5815688 L15.2594171,0.11986284 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dikili"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.7957651" y="57.3360051">
                      DİK
                    </tspan>
                  </text>
                </g>
                <g id="foca" transform="translate(103.000000, 147.000000)">
                  <path
                    d="M32.0884955,5.06296615 L18.6503729,0.819348488 L2.38317189,6.47750537 L0.261363059,24.1592456 L10.8704072,35.4755594 L20.7721817,27.6955937 L25.723069,33.3537505 L35.6248436,26.2810544 L37.7466524,12.8429319 L32.0884955,5.06296615 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Foça"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.2849464" y="19.8429319">
                      FOÇ
                    </tspan>
                  </text>
                </g>
                <g id="gaziemir" transform="translate(158.000000, 245.000000)">
                  <path
                    d="M28.719177,16.6897557 L23.0610202,16.6897557 L0.428392638,8.90978999 L4.6720103,2.5443635 L12.451976,1.12982428 L22.3537505,0.422554667 L28.719177,16.6897557 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gaziemir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.62289757" y="11.6661723">
                      GAZ
                    </tspan>
                  </text>
                </g>
                <g
                  id="guzelbahce"
                  transform="translate(108.000000, 234.000000)"
                >
                  <path
                    d="M25.6739563,0.813510517 L3.04132877,4.34985857 L0.212250329,12.1298243 L3.74859838,19.2025204 L17.186721,27.6897557 L35.5757308,13.5443635 L25.6739563,7.88620662 L25.6739563,0.813510517 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güzelbahçe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8.69948565" y="15.5934762">
                      GÜZ
                    </tspan>
                  </text>
                </g>
                <g
                  id="karabaglar"
                  transform="translate(143.000000, 231.000000)"
                >
                  <path
                    d="M22.5010887,0.277162467 L14.721123,12.3007458 L5.5266181,13.0080154 L0.575730828,16.5443635 L15.4283926,22.90979 L19.6720103,16.5443635 L27.451976,15.1298243 L27.451976,3.81351052 L22.5010887,0.277162467 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karabağlar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.1356622" y="16.4716674">
                      KRB
                    </tspan>
                  </text>
                </g>
                <g id="karaburun" transform="translate(30.000000, 159.000000)">
                  <path
                    d="M41.4342306,58.8390399 L41.4342306,52.4736134 L49.9214659,52.4736134 L46.3851179,36.913682 L27.2888384,0.135662248 L3.94894128,0.135662248 L0.41259323,22.7682898 L6.77801972,43.9863781 L1.82713245,54.5954222 L5.3634805,56.0099614 L7.48528933,48.2299957 L20.2161423,55.3026918 L41.4342306,58.8390399 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karaburun"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.9725246" y="33.3046378">
                      KAR
                    </tspan>
                  </text>
                </g>
                <g id="karsiyaka" transform="translate(154.000000, 198.000000)">
                  <path
                    d="M12.2083583,0.742760408 L12.915628,21.9608487 L5.13566225,22.6681183 L0.184774978,8.52272612 L12.2083583,0.742760408 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karşıyaka"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="1.5993142" y="13.4009173">
                      KRŞ
                    </tspan>
                  </text>
                </g>
                <g id="kemalpasa" transform="translate(191.000000, 202.000000)">
                  <path
                    d="M40.2771625,64.640643 L86.9569567,38.4716674 L59.3734419,21.4971968 L32.4971968,2.40091729 L21.8881526,0.279108458 L4.20641236,32.1062409 L0.670064308,39.178937 L12.6936477,52.6170596 L40.2771625,64.640643 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kemalpaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.7899271" y="39.8135105">
                      KML
                    </tspan>
                  </text>
                </g>
                <g id="kinik" transform="translate(201.000000, 59.000000)">
                  <path
                    d="M44.4225547,0.410647239 L49.3734419,22.3360051 L47.9589027,36.4813973 L43.0080154,41.4322846 L19.6681183,54.1631376 L0.571838848,32.2377797 L0.571838848,13.1415002 L16.8390399,1.11791685 L44.4225547,0.410647239 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kınık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.7899271" y="24.3851179">
                      KIN
                    </tspan>
                  </text>
                </g>
                <g id="kiraz" transform="translate(348.000000, 259.000000)">
                  <path
                    d="M32.5110502,56.4422461 L49.4855208,59.9785941 L62.2163738,59.9785941 L66.4599915,33.1023489 L33.9255894,6.22610375 L17.6583884,5.51883414 L7.04934422,6.93337336 L0.683917726,0.567946867 L5.634805,44.4186627 L32.5110502,56.4422461 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kiraz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.8528933" y="37.2732705">
                      KRZ
                    </tspan>
                  </text>
                </g>
                <g id="konak" transform="translate(165.000000, 219.000000)">
                  <path
                    d="M12.5246721,10.1553536 L5.45197601,15.8135105 L0.501088738,12.2771625 L1.91562796,0.960848707 L13.2319417,0.960848707 L12.5246721,10.1553536 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Konak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="1.20835835" y="10.7899271">
                      KO
                    </tspan>
                  </text>
                </g>
                <g id="menderes" transform="translate(125.000000, 247.000000)">
                  <path
                    d="M0.186720969,14.6897557 L2.3085298,20.3479126 L15.7466524,46.5168881 L4.43033863,62.0768196 L25.6484269,71.9785941 L36.2574711,84.7094471 L47.5737848,87.5385255 L52.5246721,84.0021775 L70.2064124,76.2222118 L56.0610202,30.2496871 L65.2555251,15.3970253 L61.719177,14.6897557 L56.0610202,14.6897557 L33.4283926,6.90978999 L18.5757308,0.544363497 L0.186720969,14.6897557 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Menderes"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.8920446" y="47.1514617">
                      MND
                    </tspan>
                  </text>
                </g>
                <g id="menemen" transform="translate(113.000000, 148.000000)">
                  <path
                    d="M61.6955937,0.526618098 L77.2555251,17.5010887 L84.3282212,50.0354908 L76.5482555,52.1572996 L63.8174025,49.3282212 L53.2083583,50.7427604 L41.184775,58.5227261 L21.3812259,70.5463095 L7.2358337,47.2064124 L8.65037292,34.4755594 L2.99221604,37.3046378 L0.870407209,34.4755594 L10.7721817,26.6955937 L15.723069,32.3537505 L25.6248436,25.2810544 L27.7466524,11.8429319 L61.6955937,0.526618098 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Menemen"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.4775054" y="38.6464809">
                      MNM
                    </tspan>
                  </text>
                </g>
                <g id="narlidere" transform="translate(133.000000, 228.000000)">
                  <path
                    d="M16.2338877,0.448084027 L0.673956288,6.81351052 L0.673956288,13.8862066 L10.5757308,19.5443635 L15.5266181,16.0080154 L20.4775054,11.0571282 L16.2338877,0.448084027 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Narlıdere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="4.91757395" y="13.8135105">
                      NAR
                    </tspan>
                  </text>
                </g>
                <g id="odemis" transform="translate(286.000000, 240.000000)">
                  <path
                    d="M3.9805401,22.3970253 L12.4677754,27.3479126 L18.1259323,21.6897557 L18.8332019,8.25163311 L20.9550107,1.88620662 L28.0277068,0.471667397 L57.0257608,6.12982428 L62.6839177,19.5679469 L67.634805,63.4186627 L63.3911873,100.903952 L37.2222118,100.903952 L26.6131676,104.4403 L26.6131676,68.36955 L0.444192047,49.9805401 L3.9805401,22.3970253 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ödemiş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.9785941" y="50.6151136">
                      ÖDE
                    </tspan>
                  </text>
                </g>
                <g
                  id="seferihisar"
                  transform="translate(88.000000, 258.000000)"
                >
                  <path
                    d="M0.408701249,0.860677257 L1.11597086,15.7133391 L10.3104758,15.7133391 L13.1395542,19.9569567 L10.3104758,27.0296528 L20.2122503,31.9805401 L23.7485984,58.1495157 L30.8212945,53.1986284 L42.1376082,51.0768196 L52.7466524,35.5168881 L39.3085298,9.34791258 L33.6503729,8.64064297 L11.725015,0.153407647 L0.408701249,0.860677257 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Seferihisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.7485984" y="29.0787656">
                      SFR
                    </tspan>
                  </text>
                </g>
                <g id="selcuk" transform="translate(177.000000, 323.000000)">
                  <path
                    d="M47.2044664,19.3184912 L28.8154565,37.0002315 L4.76828977,32.0493442 L3.35375055,26.3911873 L8.30463782,19.3184912 L0.524672108,8.00217748 L18.2064124,0.222211766 L40.1317703,5.17309904 L47.2044664,19.3184912 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Selçuk"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.913682" y="22.7821432">
                      SEL
                    </tspan>
                  </text>
                </g>
                <g id="tire" transform="translate(217.000000, 289.000000)">
                  <path
                    d="M69.444192,0.980540097 L95.6131676,19.36955 L95.6131676,55.4403001 L65.907844,56.1475697 L53.176991,56.8548393 L43.9824861,51.1966824 L27.7152851,42.0021775 L7.20446637,53.3184912 L0.131770267,39.173099 L16.3989713,21.4913588 L13.5698929,12.2968539 L60.9569567,12.2968539 L69.444192,0.980540097 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tire"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.640643" y="36.9785941">
                      TİR
                    </tspan>
                  </text>
                </g>
                <g id="torbali" transform="translate(181.000000, 254.000000)">
                  <path
                    d="M9.25552509,8.39702531 L0.0610201578,23.2496871 L14.2064124,69.2222118 L36.1317703,74.173099 L52.3989713,56.4913588 L49.5698929,47.2968539 L42.4971968,31.0296528 L52.3989713,19.7133391 L50.2771625,12.640643 L22.6936477,0.617059597 L9.25552509,8.39702531 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Torbalı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.45003" y="39.444192">
                      TOR
                    </tspan>
                  </text>
                </g>
                <g id="urla" transform="translate(41.000000, 214.000000)">
                  <path
                    d="M58.0177454,44.1534076 L80.6503729,52.640643 L86.3085298,53.3479126 L84.186721,47.6897557 L70.029496,38.1869609 L67.2122503,32.1298243 L70.7485984,24.3498586 L64.3831719,27.178937 L58.725015,23.642589 L55.8959366,9.49719676 L45.2868924,8.78992715 L43.8723532,26.4716674 L48.1159709,33.5443635 L45.2868924,37.0807115 L36.0923875,28.5934762 L37.5069267,21.5207801 L27.6051522,9.49719676 L30.4342306,3.83903988 L9.21614231,0.302691827 L12.0452207,8.08265754 L2.14344621,6.66811832 L4.97252465,16.5698929 L0.0216373795,23.642589 L4.97252465,37.0807115 L0.0216373795,59.7133391 L5.67979426,63.2496871 L9.92341192,64.6642263 L13.45976,79.5168881 L25.4833433,83.7605058 L31.8487698,69.6151136 L31.8487698,61.8351479 L48.1159709,59.7133391 L47.4087012,44.8606773 L58.0177454,44.1534076 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Urla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.4833433" y="46.2025204">
                      URL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
