import React, { useEffect } from "react";
import "../turkeymap.css";

export const Batman = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(105.000000, 3.000000)">
                <g id="besiri" transform="translate(30.659341, 169.120879)">
                  <path
                    d="M85.0549451,110.769231 L150.32967,95.9340659 L155.274725,62.3076923 L85.0549451,49.4505495 L66.2637363,28.6813187 L85.0549451,10.8791209 L17.8021978,0 L0,36.5934066 L33.6263736,42.5274725 L29.6703297,76.1538462 L85.0549451,110.769231 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beşiri"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="64.2857143" y="69.2967033">
                      BŞR
                    </tspan>
                  </text>
                </g>
                <g id="gercus" transform="translate(11.868132, 274.945055)">
                  <path
                    d="M168.131868,37.5824176 L131.538462,73.1868132 L33.6263736,85.0549451 L0,17.8021978 L10.8791209,0 L41.5384615,4.94505495 L51.4285714,31.6483516 L134.505495,30.6593407 L149.340659,21.7582418 L168.131868,37.5824176 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gercüş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.2637363" y="61.3846154">
                      GER
                    </tspan>
                  </text>
                </g>
                <g id="hasankeyf" transform="translate(53.406593, 265.054945)">
                  <path
                    d="M173.076923,24.7252747 L159.230769,3.95604396 L126.593407,0 L62.3076923,14.8351648 L0,14.8351648 L9.89010989,42.5274725 L93.956044,40.5494505 L107.802198,31.6483516 L126.593407,47.4725275 L173.076923,24.7252747 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hasankeyf"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="79.1208791" y="26.7692308">
                      HSN
                    </tspan>
                  </text>
                </g>
                <g id="kozluk" transform="translate(34.615385, 40.549451)">
                  <path
                    d="M13.8461538,128.571429 L24.7252747,102.857143 L0.989010989,64.2857143 L65.2747253,51.4285714 L116.703297,0 L134.505495,63.2967033 L109.78022,116.703297 L81.0989011,139.450549 L13.8461538,128.571429 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kozluk"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="64.2857143" y="89.0769231">
                      KOZ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(0.000000, 205.714286)">
                  <path
                    d="M22.7472527,69.2307692 L0,56.3736264 L30.6593407,0 L64.2857143,5.93406593 L60.3296703,39.5604396 L115.714286,74.1758242 L53.4065934,74.1758242 L22.7472527,69.2307692 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.7142857" y="53.4725275">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sason" transform="translate(34.615385, 0.000000)">
                  <path
                    d="M0.989010989,104.835165 C0.989009162,104.835167 26.7032967,40.5494505 26.7032967,40.5494505 L46.4835165,25.7142857 L78.1318681,0 L101.868132,8.9010989 L116.703297,40.5494505 L65.2747253,91.978022 C65.2747253,91.978022 0.989012816,104.835163 0.989010989,104.835165 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sason"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60.3296703" y="53.4725275">
                      SSN
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
