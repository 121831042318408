import React, { useEffect } from "react";
import "../turkeymap.css";
export const Mus = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 13.000000)">
                <g id="bulanik" transform="translate(182.186788, 65.626424)">
                  <path
                    d="M0,56.8109339 C0,56.8109339 24.4874715,42.118451 24.4874715,42.118451 C24.4874715,42.118451 74.4419134,29.3849658 74.4419134,29.3849658 L92.0728929,0 L138.109339,28.405467 L175.330296,79.3394077 L175.330296,108.724374 L115.580866,128.314351 L101.867882,151.822323 L31.3439636,127.334852 L27.4259681,84.2369021 L0,56.8109339 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bulanık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="98.929385" y="75.5444191">
                      BUL
                    </tspan>
                  </text>
                </g>
                <g id="haskoy" transform="translate(160.637813, 257.608200)">
                  <path
                    d="M9.79498861,0 L0,44.0774487 L65.6264237,40.1594533 L86.1958998,35.261959 L93.0523918,1.95899772 L9.79498861,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hasköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.1799544" y="24.6104784">
                      HAS
                    </tspan>
                  </text>
                </g>
                <g id="korkut" transform="translate(169.453303, 192.961276)">
                  <path
                    d="M44.0774487,0.103535614 L37.2209567,31.3987765 L5.87699317,12.8172272 L0,29.442824 L11.7539863,50.9583021 L0.979498861,64.64997 L84.2369021,66.6059226 L95.0113895,56.8261598 L75.4214123,47.046397 L76.4009112,34.3327054 L114.601367,24.5529426 L44.0774487,0.103535614 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Korkut"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="41.1389522" y="48.118451">
                      KOR
                    </tspan>
                  </text>
                </g>
                <g id="malazgirt" transform="translate(274.259681, 0.000000)">
                  <path
                    d="M0,65.6264237 L11.7539863,45.0569476 L49.9544419,0 L112.642369,59.7494305 L155.740319,169.453303 L108.724374,179.248292 L83.2574032,174.350797 L83.2574032,144.965831 L46.0364465,94.0318907 L0,65.6264237 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Malazgirt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="78.3599089" y="97.0933941">
                      MLZ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(23.507973, 149.863326)">
                  <path
                    d="M137.129841,151.822323 L101.867882,164.555809 L70.523918,190.022779 L40.1594533,124.396355 L12.7334852,122.437358 L0,93.0523918 L14.6924829,34.2824601 L45.0569476,12.7334852 L186.104784,0 L190.022779,42.118451 L183.166287,74.4419134 L151.822323,55.8314351 L145.94533,72.4829157 L157.699317,94.0318907 L146.924829,107.744875 L137.129841,151.822323 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="76.4009112" y="84.3599089">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="varto" transform="translate(0.000000, 61.708428)">
                  <path
                    d="M146.924829,61.7084282 L107.744875,8.81548975 L73.4624146,0 L26.4464692,3.91799544 L0,23.5079727 L21.5489749,62.6879271 L68.5649203,100.888383 L209.612756,88.1548975 L182.186788,60.7289294 L146.924829,61.7084282 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Varto"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="82.2779043" y="61.8314351">
                      VRT
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
