import React, { useEffect } from "react";
import "../turkeymap.css";

export const Yalova = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 101.483412)">
                <g id="altinova" transform="translate(339.184729, 0.000000)">
                  <path
                    d="M24.7336283,27.9937198 L63.4941634,0 L89.3345201,34.453809 L52.7273481,51.6807135 L18.2735391,135.661873 L1.0466346,135.661873 L24.7336283,27.9937198 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altınova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.8071697" y="28.5336306">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="armutlu" transform="translate(0.000000, 79.248768)">
                  <path
                    d="M122.741694,93.0202764 L66.7542549,90.8669134 L0,47.7996521 L10.7668153,19.8059323 L75.3677071,0.425664791 L170.115682,47.7996521 L122.741694,93.0202764 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Armutlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60.2941657" y="44.0328368">
                      ARM
                    </tspan>
                  </text>
                </g>
                <g id="cinarcik" transform="translate(75.022167, 42.266010)">
                  <path
                    d="M0.345539654,37.4084234 L56.3329792,0.801251372 L148.927591,2.95461443 L120.933871,89.0891369 L95.0935143,84.7824108 L0.345539654,37.4084234 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çınarcık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71.4065207" y="35.7949712">
                      ÇIN
                    </tspan>
                  </text>
                </g>
                <g id="ciftlikkoy" transform="translate(296.918719, 16.906404)">
                  <path
                    d="M0.245383233,28.3142203 L36.8525553,0.320500549 L66.9996381,11.0873159 L43.3126445,118.755469 L15.3189247,120.908832 L0.245383233,28.3142203 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çiftlikköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.6256508" y="41.7743095">
                      ÇİF
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(216.613300, 44.379310)">
                  <path
                    d="M35.3301777,0.84131394 L80.550802,0.84131394 L95.6243434,93.4359256 L26.7167254,95.5892886 L0.876368688,91.2825625 L35.3301777,0.84131394 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33.1768146" y="50.9085752">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="termal" transform="translate(195.480296, 44.379310)">
                  <path
                    d="M28.4694628,0.84131394 L56.4631826,0.84131394 L22.0093736,91.2825625 L0.475743002,86.9758364 L28.4694628,0.84131394 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Termal"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.1896552" y="38.6995074">
                      TER
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
