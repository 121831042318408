import React, { useEffect } from "react";
import "../turkeymap.css";

export const Duzce = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(100.000000, 100.000000)">
                <g id="akcakoca" transform="translate(34.000000, 0.000000)">
                  <path
                    d="M88,0 L38,6 L8,12 L12,29 L0,55 L9,56 L35,52 L56,42 L86,44 L121,33 L110,29 L100,12 L88,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akçakoca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46" y="25">
                      AKÇ
                    </tspan>
                  </text>
                </g>
                <g id="cumayeri" transform="translate(6.000000, 55.000000)">
                  <path
                    d="M28,0 L15,10 L0,21 L22,21 L38,15 L37,1 L28,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Cumayeri"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18" y="13">
                      CUM
                    </tspan>
                  </text>
                </g>
                <g id="cilimli" transform="translate(43.000000, 52.000000)">
                  <path
                    d="M26,0 L0,4 L1,18 L3,33 L12,43 L36,24 L26,17 L26,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çilimli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8" y="23">
                      ÇİL
                    </tspan>
                  </text>
                </g>
                <g id="golyaka" transform="translate(15.000000, 95.000000)">
                  <path
                    d="M40,0 L0,22 L9,42 L0,56 L8,62 L32,61 L48,22 L50,10 L40,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölyaka"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17" y="30">
                      GÖL
                    </tspan>
                  </text>
                </g>
                <g id="gumusova" transform="translate(0.000000, 70.000000)">
                  <path
                    d="M44,0 L27,6 L6,6 L4,20 L0,37 L3,45 L16,47 L55,25 L46,15 L44,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gümüşova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13" y="25">
                      GÜM
                    </tspan>
                  </text>
                </g>
                <g id="kaynasli" transform="translate(47.000000, 103.000000)">
                  <path
                    d="M0,53 L28,50 L44,63 L90,54 L95,13 L61,0 L42,15 L16,14 L0,53 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kaynaşlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42" y="35">
                      KYN
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(55.000000, 42.000000)">
                  <path
                    d="M65,2 L35,0 L14,10 L14,27 L24,34 L-1.77635684e-15,53 L10,63 L8,75 L34,76 L53,61 L87,74 L100,54 L74,39 L67,31 L61,32 L57,27 L63,24 L60,14 L65,2 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37" y="43">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="yigilca" transform="translate(115.000000, 33.000000)">
                  <path
                    d="M40,0 L49,0 L121,9 L114,40 L92,55 L40,63 L14,48 L7,40 L22,30 L28,36 L30,31 L38,31 L35,25 L19,25 L3,33 L0,23 L5,11 L40,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yığılca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58" y="31">
                      YIĞ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
