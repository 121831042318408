import React, { useEffect } from "react";
import "../turkeymap.css";

export const Manisa = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(22.000000, 3.000000)">
                <g id="ahmetli" transform="translate(130.000000, 205.000000)">
                  <path
                    d="M24.173913,61.0869565 L38.2608696,32.9130435 L31.2173913,18.826087 L32.7826087,8.65217391 L17.9130435,0.0434782609 L1.47826087,3.95652174 L0.695652174,17.2608696 L8.52173913,34.4782609 L4.60869565,54.826087 L14,69.6956522 L16.3478261,62.6521739 L24.173913,61.0869565 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ahmetli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.4347826" y="30.5217391">
                      AHM
                    </tspan>
                  </text>
                </g>
                <g id="akhisar" transform="translate(76.000000, 56.000000)">
                  <path
                    d="M142.347826,44.9565217 L132.173913,26.9565217 L111.043478,11.3043478 L106.347826,0.347826087 L86,20.6956522 L86,30.8695652 L51.5652174,48.0869565 L29.6521739,40.2608696 L6.17391304,49.6521739 L0.695652174,55.1304348 L4.60869565,77.826087 L39.0434783,84.8695652 L48.4347826,108.347826 L71.1304348,119.304348 L91.4782609,119.304348 L112.608696,125.565217 L112.608696,108.347826 L134.521739,88.7826087 L136.086957,55.9130435 L142.347826,44.9565217 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="78.9565217" y="74.6521739">
                      AKH
                    </tspan>
                  </text>
                </g>
                <g id="alasehir" transform="translate(211.000000, 238.000000)">
                  <path
                    d="M117.695652,49.2173913 L116.913043,55.4782609 L82.4782609,75.826087 L80.1304348,82.8695652 L90.3043478,97.7391304 L77,103.217391 L66.0434783,116.521739 L49.6086957,114.956522 L54.3043478,85.2173913 L18.3043478,55.4782609 L0.304347826,54.6956522 L23.7826087,21.826087 L41.7826087,15.5652174 L55.0869565,0.695652174 L58.2173913,14.7826087 L50.3913043,23.3913043 L87.9565217,39.826087 L95,33.5652174 L117.695652,49.2173913 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alaşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.3913043" y="53.8695652">
                      ALA
                    </tspan>
                  </text>
                </g>
                <g id="demirci" transform="translate(259.000000, 83.000000)">
                  <path
                    d="M70.4782609,21.8695652 L62.6521739,0.739130435 L28.2173913,3.86956522 L0.0434782609,77.4347826 L10.2173913,94.6521739 L8.65217391,103.26087 L18.0434783,98.5652174 L19.6086957,100.913043 L6.30434783,115 L7.08695652,118.913043 L20.3913043,116.565217 L19.6086957,118.913043 L32.1304348,116.565217 L49.3478261,122.043478 L55.6086957,89.173913 L91.6086957,70.3913043 L121.347826,71.173913 L122.913043,64.1304348 L109.608696,55.5217391 L113.521739,44.5652174 L90.826087,20.3043478 L70.4782609,21.8695652 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Demirci"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.1304348" y="57.0434783">
                      DMR
                    </tspan>
                  </text>
                </g>
                <g
                  id="golmarmara"
                  transform="translate(141.000000, 175.000000)"
                >
                  <path
                    d="M21.7826087,38.6521739 L6.91304348,30.0434783 L0.652173913,19.8695652 L6.91304348,8.91304348 L6.13043478,0.304347826 L26.4782609,0.304347826 L47.6086957,6.56521739 L59.3478261,12.0434783 L41.3478261,32.3913043 L33.5217391,31.6086957 L29.6086957,35.5217391 L25.6956522,37.0869565 L24.9130435,39.4347826 L21.7826087,38.6521739 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölmarmara"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.2173913" y="20.6086957">
                      GÖL
                    </tspan>
                  </text>
                </g>
                <g id="gordes" transform="translate(188.000000, 86.000000)">
                  <path
                    d="M99.2173913,0.869565217 L67.1304348,4 L56.173913,12.6086957 L30.3478261,14.9565217 L24.0869565,25.9130435 L22.5217391,58.7826087 L0.608695652,78.3478261 L0.608695652,95.5652174 L12.3478261,101.043478 L39.7391304,103.391304 L37.3913043,86.9565217 L56.9565217,72.0869565 L71.0434783,74.4347826 L99.2173913,0.869565217 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gördes"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.7826087" y="50.9130435">
                      GÖR
                    </tspan>
                  </text>
                </g>
                <g id="kirkagac" transform="translate(105.000000, 20.000000)">
                  <path
                    d="M77.3478261,36.3478261 L69.5217391,21.4782609 L69.5217391,3.47826087 L64.826087,0.347826087 L48.3913043,8.17391304 L38.2173913,7.39130435 L37.4347826,40.2608696 L10.0434783,48.8695652 L0.652173913,76.2608696 L22.5652174,84.0869565 L57,66.8695652 L57,56.6956522 L77.3478261,36.3478261 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kırkağaç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.6956522" y="44.1304348">
                      KRK
                    </tspan>
                  </text>
                </g>
                <g id="koprubasi" transform="translate(225.000000, 158.000000)">
                  <path
                    d="M42.6521739,28.2608696 L37.173913,29.0434783 L30.9130435,43.9130435 L27,45.4782609 L24.6521739,54.0869565 L18.3913043,58 L6.65217391,50.173913 L2.73913043,31.3913043 L0.391304348,14.9565217 L19.9565217,0.0869565217 L34.0434783,2.43478261 L44.2173913,19.6521739 L42.6521739,28.2608696 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Köprübaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.2608696" y="26.6521739">
                      KÖP
                    </tspan>
                  </text>
                </g>
                <g id="kula" transform="translate(261.000000, 199.000000)">
                  <path
                    d="M85.6956522,16.2173913 L67.6956522,88.2173913 L45,72.5652174 L37.9565217,78.826087 L0.391304348,62.3913043 L8.2173913,53.7826087 L5.08695652,39.6956522 L5.86956522,24.0434783 L1.17391304,16.2173913 L8.2173913,6.82608696 L17.6086957,2.91304348 L30.1304348,0.565217391 L47.3478261,6.04347826 L69.2608696,9.95652174 L85.6956522,16.2173913 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kula"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.9565217" y="38.0869565">
                      KUL
                    </tspan>
                  </text>
                </g>
                <g id="salihli" transform="translate(154.000000, 187.000000)">
                  <path
                    d="M115.217391,18.826087 L105.826087,16.4782609 L103.478261,22.7391304 L85.4782609,36.826087 L83.9130435,32.9130435 L89.3913043,29 L77.6521739,21.173913 L73.7391304,2.39130435 L46.3478261,0.0434782609 L28.3478261,20.3913043 L36.173913,25.0869565 L30.6956522,35.2608696 L22.0869565,32.1304348 L16.6086957,32.9130435 L11.9130435,27.4347826 L8.7826087,26.6521739 L7.2173913,36.826087 C7.2173913,36.826087 14.2870516,50.9130435 14.2608696,50.9130435 C14.2870516,50.9130435 -0.578657364,79.0869565 0.173913043,79.0869565 C0.986154105,79.0869565 32.2608696,85.3478261 32.2608696,85.3478261 L38.5217391,100.217391 L45.5652174,107.26087 L57.3043478,105.695652 L80.7826087,72.826087 L98.7826087,66.5652174 L112.086957,51.6956522 L112.869565,36.0434783 L108.173913,28.2173913 L115.217391,18.826087 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Salihli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48.6956522" y="55.5652174">
                      SLH
                    </tspan>
                  </text>
                </g>
                <g id="sarigol" transform="translate(277.000000, 293.000000)">
                  <path
                    d="M50.9130435,0.47826087 L16.4782609,20.826087 L14.1304348,27.8695652 L24.3043478,42.7391304 L11,48.2173913 L0.0434782609,61.5217391 L7.08695652,63.8695652 L18.826087,67 L28.2173913,65.4347826 L42.3043478,44.3043478 L68.1304348,30.2173913 L65.7826087,16.9130435 L50.9130435,0.47826087 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıgöl"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.2608696" y="31.7391304">
                      SAR
                    </tspan>
                  </text>
                </g>
                <g id="saruhanli" transform="translate(50.000000, 111.000000)">
                  <path
                    d="M97.1304348,64.3043478 L74.4347826,53.3478261 L65.0434783,29.8695652 L30.6086957,22.826087 L26.6956522,0.130434783 L0.869565217,14.2173913 L4.7826087,28.3043478 L21.2173913,47.8695652 L13.3913043,58.826087 L37.6521739,79.173913 L54.8695652,102.652174 L71.3043478,97.173913 L81.4782609,97.9565217 L97.9130435,94.0434783 L91.6521739,83.8695652 L97.9130435,72.9130435 L97.1304348,64.3043478 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Saruhanlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.2608696" y="64.2608696">
                      SRH
                    </tspan>
                  </text>
                </g>
                <g id="selendi" transform="translate(308.000000, 153.000000)">
                  <path
                    d="M72.3478261,1.17391304 L42.6086957,0.391304348 L6.60869565,19.173913 L0.347826087,52.0434783 L22.2608696,55.9565217 L38.6956522,62.2173913 L68.4347826,52.826087 L84.8695652,35.6086957 L84.8695652,30.9130435 L80.9565217,17.6086957 L72.3478261,1.17391304 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Selendi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.5652174" y="30.0869565">
                      SEL
                    </tspan>
                  </text>
                </g>
                <g id="soma" transform="translate(67.000000, 0.000000)">
                  <path
                    d="M76.2173913,27.3913043 L66.826087,19.5652174 L44.1304348,19.5652174 L19.0869565,3.13043478 L7.34782609,0 L0.304347826,11.7391304 L12.0434783,37.5652174 L11.2608696,64.9565217 L16.7391304,90 L15.173913,105.652174 L38.6521739,96.2608696 L48.0434783,68.8695652 L75.4347826,60.2608696 L76.2173913,27.3913043 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Soma"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.3913043" y="47.6956522">
                      SOM
                    </tspan>
                  </text>
                </g>
                <g id="sehzadeler" transform="translate(54.000000, 169.000000)">
                  <path
                    d="M9.39130435,0.826086957 L2.34782609,7.86956522 L3.91304348,18.826087 L0,57.173913 L29.7391304,78.3043478 L39.9130435,62.6521739 L50.8695652,44.6521739 L33.6521739,21.173913 L9.39130435,0.826086957 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şehzadeler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.2173913" y="46.173913">
                      ŞEH
                    </tspan>
                  </text>
                </g>
                <g id="turgutlu" transform="translate(83.000000, 208.000000)">
                  <path
                    d="M61,66.6956522 L60.2173913,81.5652174 L53.9565217,87.826087 L44.5652174,82.3478261 L31.2608696,58.0869565 L0.739130435,39.3043478 L21.8695652,5.65217391 L38.3043478,0.173913043 L48.4782609,0.956521739 L47.6956522,13.4782609 L55.5217391,31.4782609 L51.6086957,51.826087 L61,66.6956522 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Turgutlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.5652174" y="39.2608696">
                      TRG
                    </tspan>
                  </text>
                </g>
                <g id="yunusemre" transform="translate(0.000000, 125.000000)">
                  <path
                    d="M50.8695652,0.217391304 L17.2173913,1 L19.5652174,11.173913 L8.60869565,6.47826087 L0,39.3478261 L17.2173913,58.1304348 L25.0434783,94.1304348 L42.2608696,98.826087 L54,101.173913 L57.9130435,62.826087 L56.3478261,51.8695652 L63.3913043,44.826087 L71.2173913,33.8695652 L54.7826087,14.3043478 L50.8695652,0.217391304 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yunusemre"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.7391304" y="45.5652174">
                      YNS
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
