import React, { useEffect } from "react";
import "../turkeymap.css";
export const Agri = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 43.000000)">
                <g id="diyadin" transform="translate(214.624782, 72.792321)">
                  <path
                    d="M0,76.5445026 L66.0383944,115.56719 L86.3001745,95.3054101 L95.3054101,74.2931937 L89.3019197,36.7713787 L78.0453752,20.2617801 L78.0453752,6.0034904 L43.5253054,0 L19.5113438,28.5165794 L0,76.5445026 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Diyadin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="44.2757417" y="57.7801047">
                      DYN
                    </tspan>
                  </text>
                </g>
                <g
                  id="dogubayazit"
                  transform="translate(231.134380, 36.020942)"
                >
                  <path
                    d="M0,14.2582897 L11.2565445,0 L46.5270506,12.7574171 L156.841187,19.5113438 L198.86562,10.5061082 L196.614311,82.547993 L124.572426,105.811518 L78.7958115,111.064572 L72.7923211,73.5427574 L61.5357766,57.0331588 L61.5357766,42.7748691 L27.0157068,36.7713787 L0,14.2582897 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Doğubayazıt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="112.565445" y="59.2809773">
                      DBY
                    </tspan>
                  </text>
                </g>
                <g id="eleskirt" transform="translate(0.000000, 10.506108)">
                  <path
                    d="M124.572426,0 L84.0488656,12.7574171 L49.5287958,16.5095986 L0,50.2792321 L39.7731239,87.8010471 L111.064572,83.2984293 L125.322862,67.539267 L138.830716,15.008726 L124.572426,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eleşkirt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69.7905759" y="56.2792321">
                      ELŞ
                    </tspan>
                  </text>
                </g>
                <g id="hamur" transform="translate(114.066318, 87.050611)">
                  <path
                    d="M20.2617801,87.8010471 L59.2844677,91.5532286 L77.2949389,80.2966841 L72.7923211,23.2635253 L56.2827225,11.2565445 L54.7818499,0 L0,9.0052356 L24.7643979,27.0157068 L10.5061082,55.5322862 L20.2617801,87.8010471 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hamur"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.2722513" y="48.7748691">
                      HMR
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(111.064572, 0.000000)">
                  <path
                    d="M105.061082,49.5287958 L105.811518,15.7591623 L70.5410122,0 L13.5078534,10.5061082 L27.7661431,25.5148342 L14.2582897,78.0453752 L0,93.8045375 L3.0017452,96.0558464 L57.7835951,87.0506108 L59.2844677,99.0575916 L75.7940663,110.314136 L105.061082,49.5287958 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53.2809773" y="55.5287958">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="patnos" transform="translate(75.794066, 174.851658)">
                  <path
                    d="M97.556719,3.7521815 L58.5340314,0 L0,21.7626527 L33.0191972,105.811518 L74.2931937,78.7958115 L106.561955,42.7748691 L111.064572,11.2565445 L97.556719,3.7521815 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Patnos"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48.0279232" y="45.7731239">
                      PTN
                    </tspan>
                  </text>
                </g>
                <g id="taslicay" transform="translate(186.858639, 49.528796)">
                  <path
                    d="M4.5026178,117.818499 L27.7661431,99.8080279 L47.2774869,51.7801047 L71.2914485,23.2635253 L44.2757417,0.7504363 L29.2670157,0 L0,60.7853403 L4.5026178,117.818499 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Taşlıçay"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.7661431" y="45.7731239">
                      TŞL
                    </tspan>
                  </text>
                </g>
                <g id="tutak" transform="translate(27.766143, 93.804538)">
                  <path
                    d="M106.561955,81.0471204 L96.8062827,49.5287958 L111.064572,20.2617801 L86.3001745,2.2513089 L83.2984293,0 L12.0069808,3.7521815 L18.0104712,37.521815 L0,57.0331588 L48.0279232,102.809773 L106.561955,81.0471204 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tutak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.2722513" y="49.5253054">
                      TTK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
