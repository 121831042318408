import React, { useEffect } from "react";
import "../turkeymap.css";

export const Kutahya = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 4.000000)">
                <g id="altintas" transform="translate(261.000000, 221.000000)">
                  <path
                    d="M77.8983051,2.19915254 L0.461864407,67.7923729 L5.9279661,82.3686441 L39.6355932,86.9237288 L56.9449153,75.9915254 L70.6101695,89.6567797 L107.961864,83.279661 L126.182203,31.3516949 L96.1186441,0.377118644 L77.8983051,2.19915254 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altıntaş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="67.8771186" y="51.1059322">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="aslanapa" transform="translate(245.000000, 159.000000)">
                  <path
                    d="M93.8983051,64.1991525 L24.6610169,0.427966102 L12.8177966,6.80508475 L10.0847458,45.9788136 L17.3728814,66.0211864 L0.974576271,82.4194915 L16.4618644,129.792373 L93.8983051,64.1991525 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aslanapa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.8601695" y="64.8220339">
                      ASL
                    </tspan>
                  </text>
                </g>
                <g
                  id="cavdarhisar"
                  transform="translate(206.000000, 204.000000)"
                >
                  <path
                    d="M49.0847458,0.978813559 L56.3728814,21.0211864 L39.9745763,37.4194915 L11.7330508,32.8644068 L0.800847458,15.5550847 L49.0847458,0.978813559 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çavdarhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.5762712" y="24.3771186">
                      ÇAV
                    </tspan>
                  </text>
                </g>
                <g id="domanic" transform="translate(190.000000, 0.000000)">
                  <path
                    d="M88.7711864,14.5762712 L8.60169492,0 L0.402542373,35.529661 L35.0211864,65.5932203 L91.5042373,62.8601695 L88.7711864,14.5762712 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Domaniç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.4872881" y="39.7966102">
                      DOM
                    </tspan>
                  </text>
                </g>
                <g
                  id="dumlupinar"
                  transform="translate(289.000000, 296.000000)"
                >
                  <path
                    d="M79.9618644,8.27966102 L42.6101695,14.6567797 L28.9449153,0.991525424 L11.6355932,11.9237288 L0.703389831,27.4110169 L20.7457627,35.6101695 L25.3008475,62.029661 L79.9618644,8.27966102 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dumlupınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.0338983" y="28.9449153">
                      DUM
                    </tspan>
                  </text>
                </g>
                <g id="emet" transform="translate(103.000000, 119.000000)">
                  <path
                    d="M154.817797,46.8050847 L152.084746,85.9788136 L103.800847,100.555085 L63.7161017,112.398305 L79.2033898,81.4237288 L0.855932203,64.1144068 L16.3432203,13.0974576 L39.1186441,0.343220339 L78.2923729,17.6525424 L109.266949,45.8940678 L154.817797,46.8050847 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Emet"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="68.2711864" y="54.7161017">
                      EMT
                    </tspan>
                  </text>
                </g>
                <g id="gediz" transform="translate(93.000000, 219.000000)">
                  <path
                    d="M152.974576,22.4194915 L173.927966,84.3686441 L140.220339,83.4576271 L101.04661,102.588983 L47.2966102,120.809322 L0.834745763,119.898305 L0.834745763,114.432203 L56.4067797,61.5932203 L59.1398305,39.7288136 L43.6525424,24.2415254 L50.029661,14.220339 L73.7161017,12.3983051 L113.800847,0.555084746 L124.733051,17.8644068 L152.974576,22.4194915 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gediz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="96.4915254" y="57.6610169">
                      GDZ
                    </tspan>
                  </text>
                </g>
                <g id="hisarcik" transform="translate(102.000000, 183.000000)">
                  <path
                    d="M1.8559322,0.11440678 L0.944915254,30.1779661 L41.940678,50.220339 L64.7161017,48.3983051 L80.2033898,17.4237288 L1.8559322,0.11440678 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hisarcık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33.7415254" y="30.8008475">
                      HİS
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(264.000000, 68.000000)">
                  <path
                    d="M30.2584746,0.326271186 L19.3262712,8.52542373 L0.194915254,52.2542373 L12.9491525,76.8516949 L5.66101695,91.4279661 L74.8983051,155.199153 L93.1186441,153.377119 L123.182203,184.351695 L166,150.644068 L141.402542,109.648305 L154.15678,95.9830508 L155.067797,71.3855932 L122.271186,38.5889831 L118.627119,13.9915254 L85.8305085,13.9915254 L71.2542373,14.9025424 L30.2584746,0.326271186 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="77.6313559" y="90.2288136">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="pazarlar" transform="translate(79.000000, 255.000000)">
                  <path
                    d="M48.5423729,0.995762712 L19.3898305,22.8601695 L13.9237288,40.1694915 L0.258474576,43.8135593 L10.279661,62.9449153 L38.5211864,40.1694915 L40.3432203,22.8601695 L48.5423729,0.995762712 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pazarlar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.2118644" y="34.4152542">
                      PAZ
                    </tspan>
                  </text>
                </g>
                <g id="simav" transform="translate(0.000000, 106.000000)">
                  <path
                    d="M119.34322,26.0974576 L105.677966,26.0974576 L81.0805085,0.588983051 L76.5254237,36.1186441 L33.7076271,69.8262712 L6.37711864,68.0042373 L0,75.2923729 L14.5762712,99.8898305 L10.9322034,111.733051 L20.0423729,136.330508 L43.7288136,133.597458 L70.1483051,161.838983 L65.5932203,174.59322 L81.0805085,184.614407 L79.2584746,192.813559 L93.8347458,189.169492 L98.3898305,171.860169 L125.720339,151.817797 L135.741525,138.152542 L143.940678,127.220339 L102.944915,107.177966 L103.855932,77.1144068 L119.34322,26.0974576 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Simav"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.8389831" y="101.423729">
                      SMV
                    </tspan>
                  </text>
                </g>
                <g id="saphane" transform="translate(89.000000, 243.000000)">
                  <path
                    d="M47.6525424,0.241525424 L37.6313559,13.9067797 L30.3432203,34.8601695 L28.5211864,52.1694915 L0.279661017,74.9449153 L4.83474576,90.4322034 L60.4067797,37.5932203 L63.1398305,15.7288136 L47.6525424,0.241525424 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şaphane"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.1864407" y="29.1059322">
                      ŞAP
                    </tspan>
                  </text>
                </g>
                <g id="tavsanli" transform="translate(81.000000, 35.000000)">
                  <path
                    d="M109.402542,0.529661017 L86.6271186,15.1059322 L72.9618644,43.3474576 L55.6525424,57.0127119 L13.7457627,49.7245763 L0.0805084746,71.5889831 L24.6779661,97.0974576 L38.3432203,97.0974576 L61.1186441,84.3432203 L100.292373,101.652542 L131.266949,129.894068 L176.817797,130.805085 L188.661017,124.427966 L195.949153,109.851695 L183.194915,85.2542373 L202.326271,41.5254237 L213.258475,33.3262712 L200.504237,27.8601695 L144.021186,30.5932203 L109.402542,0.529661017 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tavşanlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="120.334746" y="65.8347458">
                      TAV
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
