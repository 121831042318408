import React, { useEffect } from "react";
import "../turkeymap.css";

export const Sirnak = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 71.000000)">
                <g id="beytussebap" transform="translate(250.181818, 0.000000)">
                  <path
                    d="M144.20202,95.5555556 L179.818182,2.60606061 L163.313131,5.21212121 L55.5959596,0 L33.010101,39.0909091 L0,78.1818182 L144.20202,95.5555556 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beytüşşebap"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="85.1313131" y="45.959596">
                      BYT
                    </tspan>
                  </text>
                </g>
                <g id="cizre" transform="translate(100.767677, 123.353535)">
                  <path
                    d="M86,0 L70.3636364,36.4848485 L49.5151515,27.7979798 L0,72.969697 L9.55555556,1.73737374 L86,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Cizre"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.5656566" y="17.2929293">
                      CZR
                    </tspan>
                  </text>
                </g>
                <g id="guclukonak" transform="translate(51.252525, 53.858586)">
                  <path
                    d="M0,0 L21.7171717,14.7676768 L63.4141414,13.8989899 L59.0707071,71.2323232 L2.60606061,57.3333333 L10.4242424,27.7979798 L0,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güçlükonak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.5353535" y="41.6161616">
                      GÜÇ
                    </tspan>
                  </text>
                </g>
                <g id="idil" transform="translate(0.000000, 111.191919)">
                  <path
                    d="M53.8585859,0 L6.94949495,16.5050505 L0,60.8080808 L0,77.3131313 L22.5858586,75.5757576 L19.979798,112.060606 L100.767677,85.1313131 L110.323232,13.8989899 L53.8585859,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İdil"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.5959596" y="44.2222222">
                      İDL
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(110.323232, 13.030303)">
                  <path
                    d="M4.34343434,54.7272727 L39.959596,53.8585859 L62.5454545,26.9292929 L108.585859,0 L172.868687,26.0606061 L139.858586,65.1515152 L142.464646,94.6868687 L77.3131313,110.323232 L0,112.060606 L4.34343434,54.7272727 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="74.7070707" y="74.6262626">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="silopi" transform="translate(171.131313, 107.717172)">
                  <path
                    d="M0,52.1212121 L13.030303,93.8181818 C13.030303,93.8181818 61.6767677,78.1818182 61.6767677,77.3131313 C61.6767677,76.4444444 99.030303,20.8484848 99.030303,20.8484848 L81.6565657,0 L15.6363636,15.6363636 L0,52.1212121 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Silopi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.0909091" y="46.8282828">
                      SLP
                    </tspan>
                  </text>
                </g>
                <g id="uludere" transform="translate(250.181818, 78.181818)">
                  <path
                    d="M0,0 L144.20202,17.3737374 L162.444444,39.959596 L154.626263,48.6464646 L112.929293,33.8787879 L71.2323232,52.1212121 L19.979798,50.3838384 L2.60606061,29.5353535 L0,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Uludere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54.7272727" y="32.0606061">
                      ULD
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
