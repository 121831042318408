import React, { useEffect } from "react";
import "../turkeymap.css";

export const Denizli = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(84.000000, 3.000000)">
                <g id="acipayam" transform="translate(67.000000, 178.000000)">
                  <path
                    d="M102.925373,12.0746269 L94.8656716,2.67164179 L75.3880597,0.656716418 L74.0447761,24.1641791 L45.1641791,38.2686567 L37.1044776,87.2985075 L7.55223881,109.462687 L0.835820896,118.865672 L13.5970149,128.940299 L35.761194,129.61194 L47.1791045,116.850746 L67.3283582,108.119403 L83.4477612,113.492537 L95.5373134,96.7014925 L102.253731,85.9552239 L117.029851,66.4776119 L116.358209,60.4328358 L100.238806,49.6865672 L103.597015,30.880597 L98.2238806,15.4328358 L102.925373,12.0746269 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Acıpayam"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="67.3283582" y="76.1641791">
                      ACI
                    </tspan>
                  </text>
                </g>
                <g id="babadag" transform="translate(30.000000, 130.000000)">
                  <path
                    d="M19.0298507,31.1940299 L0.895522388,8.35820896 L19.7014925,0.970149254 L34.4776119,0.970149254 L36.4925373,12.3880597 L41.8656716,13.0597015 L36.4925373,37.9104478 L19.0298507,31.1940299 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Babadağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.358209" y="22.7462687">
                      BBD
                    </tspan>
                  </text>
                </g>
                <g id="baklan" transform="translate(153.000000, 77.000000)">
                  <path
                    d="M47.8208955,37.8507463 L21.6268657,64.0447761 L13.5671642,63.3731343 L0.134328358,55.9850746 L27,0.910447761 L47.8208955,37.8507463 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Baklan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.2686567" y="44.1791045">
                      BAK
                    </tspan>
                  </text>
                </g>
                <g id="bekilli" transform="translate(126.000000, 35.000000)">
                  <path
                    d="M13.7014925,21.4179104 L18.4029851,22.0895522 L16.3880597,18.0597015 L2.95522388,20.7462687 L0.268656716,19.4029851 L2.95522388,14.0298507 L23.1044776,0.597014925 L30.4925373,0.597014925 L51.3134328,15.3731343 L60.7164179,18.0597015 L49.2985075,37.5373134 L13.7014925,21.4179104 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bekilli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.1641791" y="23.0447761">
                      BKL
                    </tspan>
                  </text>
                </g>
                <g id="beyagac" transform="translate(32.000000, 251.000000)">
                  <path
                    d="M35.8358209,45.8656716 L18.3731343,33.1044776 L0.910447761,19 L34.4925373,0.194029851 L28.4477612,17.6567164 L42.5522388,36.4626866 L35.8358209,45.8656716 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beyağaç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.3731343" y="30.0298507">
                      BEY
                    </tspan>
                  </text>
                </g>
                <g id="bozkurt" transform="translate(157.000000, 114.000000)">
                  <path
                    d="M43.8208955,0.850746269 L39.7910448,19.6567164 L20.9850746,74.0597015 L12.9253731,76.0746269 L4.86567164,66.6716418 L0.835820896,39.1343284 L9.56716418,26.3731343 L17.6268657,27.0447761 L43.8208955,0.850746269 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozkurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.5820896" y="47.4776119">
                      BOZ
                    </tspan>
                  </text>
                </g>
                <g id="buldan" transform="translate(16.000000, 45.000000)">
                  <path
                    d="M43.1044776,0.671641791 L20.9402985,12.761194 L8.85074627,30.8955224 L0.791044776,32.238806 L8.17910448,40.2985075 L29.6716418,57.761194 L55.1940299,57.0895522 L63.2537313,51.0447761 L63.9253731,41.641791 L43.1044776,0.671641791 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Buldan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.0149254" y="37.8955224">
                      BUL
                    </tspan>
                  </text>
                </g>
                <g id="cal" transform="translate(112.000000, 56.000000)">
                  <path
                    d="M5.53731343,14.5223881 L8.2238806,13.8507463 L14.2686567,4.44776119 L27.0298507,0.417910448 L63.2985075,16.5373134 L68,21.9104478 L41.1343284,76.9850746 L20.9850746,75.641791 L8.89552239,46.0895522 L12.9253731,35.3432836 L0.835820896,35.3432836 L5.53731343,14.5223881 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çal"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.3880597" y="38.3134328">
                      ÇAL
                    </tspan>
                  </text>
                </g>
                <g id="cameli" transform="translate(80.000000, 286.000000)">
                  <path
                    d="M70.4477612,5.49253731 L50.2985075,41.0895522 L48.2835821,55.1940299 L40.2238806,70.641791 L30.8208955,74 L16.0447761,67.2835821 L3.95522388,51.8358209 L5.29850746,35.0447761 L0.597014925,20.9402985 L22.761194,21.6119403 L34.1791045,8.85074627 L54.3283582,0.119402985 L70.4477612,5.49253731 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çameli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.4328358" y="44.7313433">
                      ÇAM
                    </tspan>
                  </text>
                </g>
                <g id="cardak" transform="translate(177.000000, 133.000000)">
                  <path
                    d="M19.7910448,0.656716418 L37.9253731,15.4328358 L27.1791045,18.119403 L29.8656716,23.4925373 L39.9402985,24.1641791 L48,28.8656716 L52.7014925,27.5223881 L54.7164179,32.2238806 L0.985074627,55.0597015 L19.7910448,0.656716418 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çardak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.119403" y="36.5373134">
                      ÇDK
                    </tspan>
                  </text>
                </g>
                <g id="civril" transform="translate(175.000000, 0.000000)">
                  <path
                    d="M0.298507463,72.5373134 L5,77.9104478 L25.8208955,114.850746 L41.9402985,101.41791 L60.7462687,96.0447761 L73.5074627,83.9552239 L93.6567164,63.8059701 L90.2985075,51.7164179 L83.5820896,42.3134328 L82.9104478,24.8507463 L70.8208955,10.7462687 L47.9850746,0 L33.880597,12.761194 L21.7910448,18.8059701 L11.7164179,53.0597015 L0.298507463,72.5373134 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çivril"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.9552239" y="52.6716418">
                      ÇİV
                    </tspan>
                  </text>
                </g>
                <g id="guney" transform="translate(59.000000, 45.000000)">
                  <path
                    d="M0.104477612,0.671641791 L53.1641791,2.68656716 L58.5373134,25.5223881 L53.8358209,46.3432836 L38.3880597,50.3731343 L20.9253731,41.641791 L0.104477612,0.671641791 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güney"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.3283582" y="27.1492537">
                      GÜN
                    </tspan>
                  </text>
                </g>
                <g id="honaz" transform="translate(112.000000, 131.000000)">
                  <path
                    d="M54.5671642,9.37313433 L41.1343284,1.98507463 L20.9850746,0.641791045 L2.85074627,11.3880597 L0.835820896,34.2238806 L12.2537313,49.6716418 L30.3880597,47.6567164 L49.8656716,49.6716418 L45.8358209,22.1343284 L54.5671642,9.37313433 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Honaz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.641791" y="27.7910448">
                      HON
                    </tspan>
                  </text>
                </g>
                <g id="kale" transform="translate(0.000000, 185.000000)">
                  <path
                    d="M32.9104478,85 L25.5223881,81.641791 L18.8059701,58.8059701 L3.35820896,42.0149254 L2.68656716,33.2835821 L4.70149254,33.2835821 L5.37313433,38.6567164 L7.3880597,37.3134328 L7.3880597,33.2835821 L13.4328358,31.2686567 L13.4328358,28.5820896 L7.3880597,30.5970149 L4.70149254,27.238806 L6.71641791,22.5373134 L0,13.8059701 L10.0746269,11.119403 L22.8358209,0.373134328 L33.5820896,16.4925373 L29.5522388,30.5970149 L49.7014925,37.3134328 L66.4925373,66.1940299 L32.9104478,85 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kale"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.1940299" y="52.3731343">
                      KLE
                    </tspan>
                  </text>
                </g>
                <g
                  id="merkezefendi"
                  transform="translate(66.000000, 120.000000)"
                >
                  <path
                    d="M5.86567164,23.0597015 L14.5970149,0.895522388 L35.4179104,4.92537313 L36.761194,13.6567164 L19.9701493,55.2985075 L0.492537313,47.9104478 L5.86567164,23.0597015 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkezefendi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.9104478" y="30.7313433">
                      MZE
                    </tspan>
                  </text>
                </g>
                <g id="pamukkale" transform="translate(79.000000, 86.000000)">
                  <path
                    d="M45.2537313,94.6716418 L33.8358209,98.0298507 L6.97014925,89.2985075 L23.761194,47.6567164 L22.4179104,38.9253731 L1.59701493,34.8955224 L0.253731343,10.0447761 L0.925373134,0.641791045 L18.3880597,9.37313433 L33.8358209,5.34328358 L45.9253731,5.34328358 L41.8955224,16.0895522 L53.9850746,45.641791 L35.8507463,56.3880597 L33.8358209,79.2238806 L45.2537313,94.6716418 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pamukkale"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.4029851" y="29.8059701">
                      PAM
                    </tspan>
                  </text>
                </g>
                <g id="saraykoy" transform="translate(30.000000, 96.000000)">
                  <path
                    d="M50.5970149,24.8955224 L49.2537313,0.0447761194 L41.1940299,6.08955224 L15.6716418,6.76119403 L15,12.8059701 L1.56716418,19.5223881 L0.895522388,42.358209 L19.7014925,34.9701493 L34.4776119,34.9701493 L36.4925373,46.3880597 L41.8656716,47.0597015 L50.5970149,24.8955224 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarayköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.7313433" y="25.1791045">
                      SRY
                    </tspan>
                  </text>
                </g>
                <g
                  id="serinhisar"
                  transform="translate(112.000000, 178.000000)"
                >
                  <path
                    d="M30.3880597,0.656716418 L29.0447761,24.1641791 L0.164179104,38.2686567 L0.835820896,6.02985075 L12.2537313,2.67164179 L30.3880597,0.656716418 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Serinhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.56716418" y="20.4179104">
                      SRN
                    </tspan>
                  </text>
                </g>
                <g id="tavas" transform="translate(22.000000, 161.000000)">
                  <path
                    d="M52.5522388,126.462687 L38.4477612,107.656716 L44.4925373,90.1940299 L27.7014925,61.3134328 L7.55223881,54.5970149 L11.5820896,40.4925373 L0.835820896,24.3731343 L18.9701493,16.9850746 L27.0298507,0.194029851 L44.4925373,6.91044776 L63.9701493,14.2985075 L90.8358209,23.0298507 L90.1641791,55.9402985 L82.1044776,104.298507 L52.5522388,126.462687 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tavas"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.880597" y="50.8507463">
                      TVS
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
