import React, { useEffect } from "react";
import "../turkeymap.css";

export const Yozgat = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 17.000000)">
                <g
                  id="akdagmadeni"
                  transform="translate(273.351548, 72.058288)"
                >
                  <path
                    d="M58.7431694,10.9653916 L56.3934426,27.4134791 L34.4626594,55.6102004 L31.3296903,72.8415301 L18.0145719,72.8415301 L19.5810565,55.6102004 L12.5318761,54.8269581 L0,86.1566485 L17.2313297,88.5063752 L35.2459016,80.6739526 L72.0582878,89.2896175 L72.8415301,97.1220401 L63.442623,111.220401 L118.269581,110.437158 L141.766849,119.836066 L156.648452,94.7723133 L150.382514,65.7923497 L134.717668,25.8469945 L117.486339,13.3151184 L123.752277,0 L58.7431694,10.9653916 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akdağmadeni"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="90.856102" y="60.8269581">
                      AKD
                    </tspan>
                  </text>
                </g>
                <g id="aydincik" transform="translate(221.657559, 0.000000)">
                  <path
                    d="M33.6794171,42.295082 L41.5118397,21.9307832 L65.7923497,13.3151184 L61.0928962,0 L16.4480874,0 L5.48269581,3.91621129 L1.56648452,17.2313297 L9.3989071,25.8469945 L0,34.4626594 L2.34972678,49.3442623 L33.6794171,42.295082 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aydıncık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.3642987" y="21.6648452">
                      AYD
                    </tspan>
                  </text>
                </g>
                <g
                  id="bogazliyan"
                  transform="translate(180.145719, 185.628415)"
                >
                  <path
                    d="M110.437158,41.5118397 L96.3387978,44.6448087 L86.1566485,34.4626594 L62.6593807,42.295082 L44.6448087,31.3296903 L47.7777778,5.68434189e-14 L18.7978142,18.0145719 L3.97903932e-13,36.8123862 L25.0637523,44.6448087 L31.3296903,63.442623 L49.3442623,86.1566485 L25.0637523,116.703097 L25.0637523,137.850638 L41.5118397,135.500911 L49.3442623,144.116576 L81.4571949,145.68306 L93.989071,132.367942 L123.752277,115.919854 L123.752277,110.437158 L114.35337,101.038251 L115.136612,93.989071 L126.102004,91.6393443 L110.437158,75.9744991 L108.870674,48.56102 L110.437158,41.5118397 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Boğazlıyan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.7923497" y="72.575592">
                      BĞZ
                    </tspan>
                  </text>
                </g>
                <g id="candir" transform="translate(289.016393, 234.189435)">
                  <path
                    d="M33.6794171,40.7285974 L17.2313297,43.0783242 L1.56648452,27.4134791 L0,0 L33.6794171,40.7285974 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Candır"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7.04918033" y="30.28051">
                      ÇND
                    </tspan>
                  </text>
                </g>
                <g id="cayiralan" transform="translate(289.016393, 182.495446)">
                  <path
                    d="M126.102004,9.3989071 L115.136612,32.8961749 L79.8907104,57.9599271 L33.6794171,92.4225865 L0,51.6939891 L1.56648452,44.6448087 L13.3151184,10.9653916 L47.7777778,0.783242259 L102.604736,0 L126.102004,9.3989071 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çayıralan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.1275046" y="42.0291439">
                      ÇYR
                    </tspan>
                  </text>
                </g>
                <g id="cekerek" transform="translate(254.553734, 13.315118)">
                  <path
                    d="M32.8961749,0 L56.3934426,22.7140255 L79.8907104,26.6302368 L66.575592,72.8415301 L50.1275046,76.7577413 L32.8961749,79.8907104 L8.61566485,61.0928962 L0,28.9799636 L8.61566485,8.61566485 L32.8961749,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çekerek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33.6794171" y="46.7285974">
                      ÇEK
                    </tspan>
                  </text>
                </g>
                <g id="kadisehri" transform="translate(321.129326, 29.763206)">
                  <path
                    d="M13.3151184,10.1821494 L43.0783242,0 L65.7923497,13.3151184 L75.9744991,42.295082 L10.9653916,53.2604736 L0,56.3934426 L13.3151184,10.1821494 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kadışehri"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33.6794171" y="31.0637523">
                      KAD
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(62.659381, 52.477231)">
                  <path
                    d="M0,14.8816029 L17.2313297,8.61566485 L43.8615665,21.147541 L64.2258652,14.0983607 L108.087432,0 L114.35337,6.26593807 L113.570128,13.3151184 L150.382514,7.04918033 L151.165756,14.0983607 L135.500911,25.0637523 L139.417122,51.6939891 L133.151184,65.0091075 L165.264117,133.151184 L136.284153,151.165756 L97.1220401,104.17122 L81.4571949,102.604736 L75.1912568,93.2058288 L65.0091075,79.1074681 L18.7978142,54.0437158 L0,14.8816029 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="83.0236794" y="56.1275046">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="saraykent" transform="translate(291.366120, 83.023679)">
                  <path
                    d="M40.7285974,0 L29.7632058,3.13296903 L13.3151184,7.04918033 L15.6648452,24.28051 L1.56648452,44.6448087 L0,61.8761384 L13.3151184,61.8761384 L17.2313297,44.6448087 L38.3788707,16.4480874 L40.7285974,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Saraykent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.7978142" y="16.9653916">
                      SRY
                    </tspan>
                  </text>
                </g>
                <g id="sarikaya" transform="translate(224.790528, 152.732240)">
                  <path
                    d="M112.003643,30.5464481 L77.5409836,40.7285974 L65.7923497,74.4080146 L51.6939891,77.5409836 L41.5118397,67.3588342 L18.0145719,75.1912568 L0,64.2258652 L3.13296903,32.8961749 L15.6648452,25.0637523 L28.1967213,30.5464481 L39.1621129,26.6302368 L37.5956284,8.61566485 L48.56102,5.48269581 L65.7923497,7.83242259 L83.8069217,0 L120.619308,8.61566485 L121.40255,16.4480874 L112.003643,30.5464481 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıkaya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.3442623" y="43.5956284">
                      SAR
                    </tspan>
                  </text>
                </g>
                <g id="sorgun" transform="translate(195.810565, 42.295082)">
                  <path
                    d="M17.2313297,17.2313297 L28.1967213,7.04918033 L58.7431694,0 L67.3588342,32.1129326 L91.6393443,50.9107468 L108.870674,47.7777778 L111.220401,65.0091075 L97.1220401,85.3734062 L90.0728597,84.5901639 L77.5409836,115.919854 L66.575592,119.052823 L68.1420765,137.067395 L57.1766849,140.983607 L44.6448087,135.500911 L32.1129326,143.333333 L0,75.1912568 L6.26593807,61.8761384 L2.34972678,35.2459016 L18.0145719,24.28051 L17.2313297,17.2313297 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sorgun"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.7285974" y="72.575592">
                      SOR
                    </tspan>
                  </text>
                </g>
                <g id="sefaatli" transform="translate(106.520947, 145.683060)">
                  <path
                    d="M0,57.1766849 L18.7978142,70.4918033 L54.0437158,95.5555556 L73.6247723,76.7577413 L92.4225865,57.9599271 L53.2604736,10.9653916 L37.5956284,9.3989071 L31.3296903,0 L24.28051,21.9307832 L7.83242259,37.5956284 L0,57.1766849 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şefaatli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.3788707" y="52.9945355">
                      ŞEF
                    </tspan>
                  </text>
                </g>
                <g
                  id="yenifakili"
                  transform="translate(160.564663, 222.440801)"
                >
                  <path
                    d="M19.5810565,0 L0,18.7978142 L19.5810565,28.1967213 L17.2313297,51.6939891 L39.9453552,73.6247723 L44.6448087,79.8907104 L68.9253188,49.3442623 L50.9107468,26.6302368 L44.6448087,7.83242259 L19.5810565,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenifakılı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.8961749" y="37.3296903">
                      YNF
                    </tspan>
                  </text>
                </g>
                <g id="yerkoy" transform="translate(0.000000, 67.358834)">
                  <path
                    d="M29.7632058,11.7486339 L4.69945355,18.0145719 L0,28.1967213 L14.8816029,51.6939891 L40.7285974,68.1420765 L74.4080146,75.1912568 L99.4717668,107.304189 L100.255009,113.570128 L91.6393443,114.35337 L93.989071,129.234973 L106.520947,135.500911 L114.35337,115.919854 L130.801457,100.255009 L137.850638,78.3242259 L127.668488,64.2258652 L81.4571949,38.3788707 L62.6593807,0 L29.7632058,11.7486339 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yerköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="75.9744991" y="64.7431694">
                      YER
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
