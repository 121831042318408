import React, { useEffect } from "react";
import "../turkeymap.css";

export const Burdur = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(6.000000, 26.000000)">
                <g id="aglasun" transform="translate(307.000000, 49.000000)">
                  <path
                    d="M9,0 L33,14 L73,29 L77,54 L25,59 L17,43 L0,34 L9,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ağlasun"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31" y="40">
                      AĞL
                    </tspan>
                  </text>
                </g>
                <g id="altinyayla" transform="translate(0.000000, 237.000000)">
                  <path
                    d="M0,26 L15,0 L70,9 L78,39 L62,47 L26,74 L11,76 L8,68 L21,46 L10,37 L8,31 L0,26 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altınyayla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="35">
                      ALY
                    </tspan>
                  </text>
                </g>
                <g id="bucak" transform="translate(240.000000, 103.000000)">
                  <path
                    d="M3,54 L17,41 L38,26 L53,26 L92,5 L144,0 L159,24 L162,31 L159,37 L164,52 L162,59 L165,61 L171,57 L181,62 L183,66 L155,102 L135,104 L98,94 L76,99 L51,93 L0,71 L3,54 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bucak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="97" y="57">
                      BCK
                    </tspan>
                  </text>
                </g>
                <g id="cavdir" transform="translate(48.000000, 169.000000)">
                  <path
                    d="M30,107 L52,117 L80,102 L83,59 L42,42 L10,0 L0,16 L10,22 L22,77 L30,107 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çavdır"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47" y="77">
                      ÇVD
                    </tspan>
                  </text>
                </g>
                <g id="celtikci" transform="translate(278.000000, 83.000000)">
                  <path
                    d="M29,0 C29,0 2,18 2,19 C2,20 0,46 0,46 L15,46 L54,25 L46,9 L29,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çeltikçi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19" y="23">
                      ÇLT
                    </tspan>
                  </text>
                </g>
                <g id="golhisar" transform="translate(15.000000, 185.000000)">
                  <path
                    d="M0,52 L15,25 L33,0 L43,6 L55,61 L0,52 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24" y="43">
                      GLH
                    </tspan>
                  </text>
                </g>
                <g id="karamanli" transform="translate(79.000000, 121.000000)">
                  <path
                    d="M1,19 L0,10 L92,0 L96,5 L87,47 L92,73 L64,60 L27,20 L1,19 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karamanlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57" y="29">
                      KML
                    </tspan>
                  </text>
                </g>
                <g id="kemer" transform="translate(166.000000, 113.000000)">
                  <path
                    d="M74,61 L10,92 L5,81 L0,55 L9,13 L24,0 L57,5 L66,27 L77,44 L74,61 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kemer"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27" y="45">
                      KMR
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(171.000000, 0.000000)">
                  <path
                    d="M0,121 L4,126 L19,113 L52,118 L61,141 L72,157 L86,144 L107,129 L109,102 L136,83 L145,49 L122,9 L119,4 L110,0 L78,10 L46,18 L40,34 L32,34 L28,25 L23,19 L6,53 L14,76 L15,100 L0,121 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69" y="70">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="tefenni" transform="translate(58.000000, 140.000000)">
                  <path
                    d="M73,88 L118,65 L113,54 L85,41 L48,1 L22,0 L0,29 L32,71 L73,88 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tefenni"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54" y="54">
                      TFN
                    </tspan>
                  </text>
                </g>
                <g id="yesilova" transform="translate(52.000000, 16.000000)">
                  <path
                    d="M27,115 L3,99 L8,71 L0,48 L7,43 L19,40 L99,6 L125,0 L142,3 L125,37 L133,59 L134,84 L119,105 L27,115 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yeşilova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="67" y="71">
                      YŞV
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
