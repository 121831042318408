import React, { useEffect } from "react";
import "../turkeymap.css";

export const Samsun = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 76.000000)">
                <g id="19-mayis" transform="translate(200.617496, 39.090909)">
                  <path
                    d="M45.728988,38.3533448 L33.9279588,22.864494 L32.4528302,5.16295026 L27.2898799,3.68782161 L28.0274443,9.58833619 L25.077187,9.58833619 L22.1269297,2.95025729 L12.5385935,0 L2.21269297,3.68782161 L0,14.7512864 L9.58833619,46.4665523 L21.3893654,46.4665523 L28.0274443,42.0411664 L45.728988,38.3533448 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="19 Mayıs"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13.2761578" y="28.1269297">
                      ODM
                    </tspan>
                  </text>
                </g>
                <g id="alacam" transform="translate(101.783877, 18.439108)">
                  <path
                    d="M60.4802744,0 L30.9777015,8.85077187 L22.864494,19.9142367 L6.6380789,30.2401372 L2.95025729,43.516295 L0,56.7924528 L8.11320755,73.0188679 L4.42538593,82.6072041 L9.58833619,89.245283 L12.5385935,94.4082333 L23.6020583,89.245283 L21.3893654,68.593482 L67.1183533,24.3396226 L66.380789,9.58833619 L60.4802744,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alaçam"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.5385935" y="46.5660377">
                      ALÇ
                    </tspan>
                  </text>
                </g>
                <g id="asarcik" transform="translate(244.871355, 157.101201)">
                  <path
                    d="M7.37564322,22.864494 L36.8782161,28.0274443 L44.9914237,30.9777015 L53.8421955,28.7650086 L56.7924528,20.651801 L53.8421955,14.0137221 L43.516295,5.90051458 L38.3533448,2.21269297 L25.8147513,4.42538593 L12.5385935,0 L1.47512864,5.16295026 L0,11.8010292 L7.37564322,22.864494 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Asarcık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.864494" y="17.8010292">
                      ASR
                    </tspan>
                  </text>
                </g>
                <g id="atakum" transform="translate(210.205832, 77.444254)">
                  <path
                    d="M59.0051458,19.9142367 L49.4168096,15.4888508 L36.1406518,0 L18.4391081,3.68782161 L11.8010292,8.11320755 L0,8.11320755 L3.68782161,25.8147513 L9.58833619,31.7152659 L24.3396226,44.9914237 L27.2898799,56.0548885 L34.6655232,50.1543739 L39.8284734,24.3396226 L59.0051458,19.9142367 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Atakum"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.4391081" y="25.9142367">
                      ATK
                    </tspan>
                  </text>
                </g>
                <g id="ayvacik" transform="translate(277.324185, 160.051458)">
                  <path
                    d="M11.0634648,2.95025729 L36.1406518,0 L55.3173242,2.21269297 L53.8421955,12.5385935 L57.5300172,12.5385935 L61.2178388,3.68782161 L67.1183533,16.9639794 L64.9056604,36.1406518 L61.2178388,35.4030875 L57.5300172,33.9279588 L44.9914237,37.6157804 L28.7650086,39.8284734 L19.1766724,43.516295 L1.47512864,39.0909091 L0,32.4528302 L4.42538593,25.077187 L12.5385935,28.0274443 L21.3893654,25.8147513 L24.3396226,17.7015437 L21.3893654,11.0634648 L11.0634648,2.95025729 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ayvacık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.1406518" y="26.651801">
                      AYV
                    </tspan>
                  </text>
                </g>
                <g id="bafra" transform="translate(123.173242, 0.000000)">
                  <path
                    d="M109.897084,44.2538593 L112.847341,29.5025729 L109.15952,25.8147513 L103.259005,17.7015437 L100.308748,9.58833619 L81.8696398,0 L66.380789,8.11320755 L39.0909091,18.4391081 L44.9914237,28.0274443 L45.728988,42.7787307 L0,87.0325901 L2.21269297,107.684391 L14.0137221,98.0960549 L24.3396226,94.4082333 L28.7650086,92.9331046 L33.9279588,92.1955403 L33.9279588,97.3584906 L23.6020583,98.8336192 L37.6157804,118.010292 L48.6792453,123.173242 L64.9056604,122.435678 L75.9691252,126.861063 L96.6209262,109.15952 L90.7204117,103.259005 L87.0325901,85.5574614 L77.4442539,53.8421955 L79.6569468,42.7787307 L89.9828473,39.0909091 L99.5711835,42.0411664 L101.783877,34.6655232 L96.6209262,24.3396226 L96.6209262,17.7015437 L107.684391,29.5025729 L108.421955,37.6157804 L104.734134,42.7787307 L109.897084,44.2538593 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bafra"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.4168096" y="80.4939966">
                      BFR
                    </tspan>
                  </text>
                </g>
                <g id="canik" transform="translate(252.984563, 107.684391)">
                  <path
                    d="M4.42538593,49.4168096 L0,34.6655232 L2.95025729,23.6020583 L5.90051458,12.5385935 L13.2761578,5.90051458 L26.5523156,0 L38.3533448,9.58833619 L37.6157804,16.2264151 L29.5025729,17.7015437 L24.3396226,21.3893654 L31.7152659,36.8782161 L25.8147513,42.0411664 L30.2401372,51.6295026 L17.7015437,53.8421955 L4.42538593,49.4168096 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Canik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.3259005" y="35.5025729">
                      CNK
                    </tspan>
                  </text>
                </g>
                <g id="carsamba" transform="translate(306.826758, 84.819897)">
                  <path
                    d="M0,27.2898799 L14.7512864,16.2264151 L19.1766724,0.737564322 L44.9914237,0 L43.516295,4.42538593 L48.6792453,5.90051458 L49.4168096,1.47512864 L59.0051458,5.16295026 L53.8421955,16.9639794 L60.4802744,44.2538593 L41.3036021,65.6432247 L34.6655232,61.9554031 L30.9777015,67.1183533 L25.8147513,77.4442539 L6.6380789,75.2315609 L15.4888508,48.6792453 L0,27.2898799 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çarşamba"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.5523156" y="39.9279588">
                      ÇRŞ
                    </tspan>
                  </text>
                </g>
                <g id="havza" transform="translate(118.747856, 118.010292)">
                  <path
                    d="M42.0411664,0 L53.1046312,5.16295026 L69.3310463,4.42538593 L80.3945111,8.85077187 L69.3310463,28.7650086 L73.0188679,61.2178388 L61.9554031,61.2178388 L58.2675815,74.4939966 L45.728988,80.3945111 L44.2538593,88.5077187 L30.9777015,86.2950257 L22.1269297,73.7564322 L0,70.806175 L0.737564322,56.0548885 L25.077187,44.9914237 L25.077187,35.4030875 L33.9279588,3.68782161 L42.0411664,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Havza"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.7787307" y="51.728988">
                      HVZ
                    </tspan>
                  </text>
                </g>
                <g id="ilkadim" transform="translate(237.495712, 97.358491)">
                  <path
                    d="M15.4888508,44.9914237 L7.37564322,45.728988 L0,36.1406518 L7.37564322,30.2401372 L12.5385935,4.42538593 L31.7152659,0 L38.3533448,1.47512864 L42.0411664,10.3259005 L29.5025729,16.2264151 L21.3893654,22.864494 L15.4888508,44.9914237 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İlkadim"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.4888508" y="17.8010292">
                      İLK
                    </tspan>
                  </text>
                </g>
                <g id="kavak" transform="translate(188.078902, 109.159520)">
                  <path
                    d="M61.9554031,73.7564322 L64.1680961,70.806175 L56.7924528,59.7427101 L58.2675815,53.1046312 L69.3310463,47.941681 L64.9056604,33.1903945 L56.7924528,33.9279588 L49.4168096,24.3396226 L46.4665523,13.2761578 L31.7152659,0 L11.0634648,17.7015437 L-1.31017743e-15,37.6157804 L3.68782161,70.0686106 L55.3173242,70.0686106 L61.9554031,73.7564322 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kavak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.2401372" y="44.3533448">
                      KVK
                    </tspan>
                  </text>
                </g>
                <g id="ladik" transform="translate(163.001715, 179.228130)">
                  <path
                    d="M17.7015437,0 L28.7650086,0 L80.3945111,0 L87.0325901,3.68782161 L83.3447684,19.1766724 L64.1680961,35.4030875 L39.0909091,33.1903945 L0,27.2898799 L1.47512864,19.1766724 L14.0137221,13.2761578 L17.7015437,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ladik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="44.2538593" y="20.7512864">
                      LDK
                    </tspan>
                  </text>
                </g>
                <g
                  id="salipazari"
                  transform="translate(338.542024, 129.073756)"
                >
                  <path
                    d="M28.7650086,0 L8.85077187,22.1269297 L2.21269297,26.5523156 L0,34.6655232 L5.90051458,48.6792453 L3.68782161,67.1183533 L10.3259005,67.8559177 L40.5660377,56.7924528 L56.7924528,34.6655232 L44.9914237,22.864494 L36.8782161,17.7015437 L34.6655232,5.90051458 L28.7650086,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Salıpazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.1766724" y="41.4030875">
                      SLP
                    </tspan>
                  </text>
                </g>
                <g id="tekkekoy" transform="translate(277.324185, 112.109777)">
                  <path
                    d="M14.0137221,5.16295026 L22.1269297,4.42538593 L29.5025729,0 L44.9914237,21.3893654 L36.1406518,47.941681 L11.0634648,50.8919383 L5.90051458,47.2041166 L1.47512864,37.6157804 L7.37564322,32.4528302 L0,16.9639794 L5.16295026,13.2761578 L13.2761578,11.8010292 L14.0137221,5.16295026 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tekkeköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.9639794" y="32.5523156">
                      TKY
                    </tspan>
                  </text>
                </g>
                <g id="terme" transform="translate(360.668954, 89.982847)">
                  <path
                    d="M5.16295026,0 L18.4391081,5.90051458 L28.7650086,13.2761578 L39.8284734,16.9639794 L42.7787307,34.6655232 L49.4168096,41.3036021 L69.3310463,44.9914237 L60.4802744,53.8421955 L38.3533448,59.7427101 L34.6655232,73.7564322 L22.864494,61.9554031 L14.7512864,56.7924528 L12.5385935,44.9914237 L6.6380789,39.0909091 L0,11.8010292 L5.16295026,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Terme"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.8147513" y="45.0909091">
                      TRM
                    </tspan>
                  </text>
                </g>
                <g id="vezirkopru" transform="translate(0.000000, 84.000000)">
                  <path
                    d="M0,43.5986278 L3.68782161,36.9605489 L9.58833619,35.4854202 L11.8010292,27.3722127 L17.7015437,28.8473413 L19.1766724,22.9468268 L16.9639794,18.5214408 L44.9914237,0.0823327616 L44.9914237,4.5077187 L51.6295026,5.98284734 L53.1046312,17.7838765 L61.9554031,25.897084 L67.1183533,36.9605489 L64.1680961,40.6483705 L66.380789,42.8610635 L75.2315609,39.1732419 L87.0325901,43.5986278 L85.5574614,49.4991424 L89.9828473,50.2367067 L93.670669,42.8610635 L103.259005,41.3859348 L106.946827,34.0102916 L122.435678,32.535163 L124.64837,39.9108062 L121.698113,50.2367067 L125.385935,50.2367067 L133.499142,34.0102916 L131.286449,29.5849057 L132.024014,25.897084 L146.7753,14.8336192 L160.789022,34.0102916 L152.675815,37.6981132 L143.825043,69.4133791 L143.825043,79.0017153 L119.48542,90.0651801 L78.9193825,93.0154374 L68.593482,93.7530017 L53.8421955,79.0017153 L40.5660377,76.7890223 L14.0137221,65.7255575 L0,43.5986278 Z M106.209262,17.0463122 L101.046312,24.4219554 L92.1955403,32.535163 L81.1320755,34.7478559 L87.0325901,36.2229846 L94.4082333,34.7478559 L103.996569,31.0600343 L109.15952,25.1595197 L111.372213,23.6843911 L106.209262,17.0463122 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Vezirköprü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="84.8198971" y="73.2006861">
                      VZR
                    </tspan>
                  </text>
                </g>
                <g id="yakakent" transform="translate(108.421955, 24.339623)">
                  <path
                    d="M24.3396226,2.95025729 L13.2761578,0 L0,24.3396226 L16.9639794,14.0137221 L24.3396226,2.95025729 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yakakent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.3259005" y="11.1629503">
                      YKK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
