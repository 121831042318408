import React, { useEffect } from "react";
import "../turkeymap.css";

export const Kayseri = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(33.000000, 3.000000)">
                <g id="akkisla" transform="translate(205.616698, 41.669829)">
                  <path
                    d="M13.6622391,4.09867173 L56.0151803,0 L56.6982922,12.9791271 L36.2049336,56.6982922 L15.028463,62.8462998 L4.78178368,60.113852 L6.14800759,40.3036053 L0,34.1555977 L13.6622391,4.09867173 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akkışla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.7609108" y="36.056926">
                      AKK
                    </tspan>
                  </text>
                </g>
                <g id="bunyan" transform="translate(142.770398, 66.261860)">
                  <path
                    d="M20.4933586,104.516129 L32.7893738,105.199241 L42.3529412,99.7343454 L52.5996205,103.833017 L71.7267552,83.3396584 L77.8747628,38.2542694 L67.6280835,35.5218216 L68.9943074,15.711575 L62.8462998,9.56356736 L19.1271347,8.19734345 L10.2466793,0 L0,10.2466793 L7.5142315,36.8880455 L13.6622391,51.2333966 L6.14800759,71.0436433 L20.4933586,104.516129 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bünyan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.2258065" y="42.8880455">
                      BÜN
                    </tspan>
                  </text>
                </g>
                <g id="develi" transform="translate(66.944972, 178.292220)">
                  <path
                    d="M55.3320683,0 L40.3036053,1.36622391 L30.740038,2.73244782 L6.14800759,23.9089184 L0,53.9658444 L56.6982922,78.5578748 L66.2618596,127.741935 L94.2694497,114.079696 L122.27704,101.783681 L131.840607,86.0721063 L141.404175,66.2618596 L115.44592,54.6489564 L99.0512334,60.7969639 L79.9240987,51.2333966 L71.7267552,23.2258065 L56.6982922,12.9791271 L55.3320683,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Develi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.2049336" y="44.2542694">
                      DVL
                    </tspan>
                  </text>
                </g>
                <g id="felahiye" transform="translate(74.459203, 29.373814)">
                  <path
                    d="M62.8462998,23.2258065 L53.9658444,0 L39.6204934,2.04933586 L30.056926,4.09867173 L28.6907021,10.2466793 L37.5711575,18.4440228 L36.8880455,23.2258065 L11.6129032,37.5711575 L0,49.1840607 L8.19734345,58.7476281 L22.5426945,59.43074 L56.0151803,35.5218216 L62.8462998,23.2258065 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Felahiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.8880455" y="36.056926">
                      FEL
                    </tspan>
                  </text>
                </g>
                <g id="hacilar" transform="translate(68.994307, 135.256167)">
                  <path
                    d="M2.73244782,0 L0,15.028463 L11.6129032,20.4933586 L28.6907021,45.7685009 L38.9373814,44.402277 L39.6204934,26.6413662 L26.6413662,10.2466793 L11.6129032,6.14800759 L2.73244782,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hacılar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.1271347" y="25.1271347">
                      HCL
                    </tspan>
                  </text>
                </g>
                <g id="incesu" transform="translate(15.711575, 115.445920)">
                  <path
                    d="M15.028463,62.1631879 L19.1271347,48.5009488 L10.2466793,38.2542694 L10.2466793,18.4440228 L0,9.56356736 L33.4724858,0 L57.3814042,13.6622391 L53.2827324,34.8387097 L64.8956357,40.3036053 L81.9734345,65.5787476 L57.3814042,86.7552182 L15.028463,62.1631879 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İncesu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.1062619" y="43.5711575">
                      İNC
                    </tspan>
                  </text>
                </g>
                <g id="kocasinan" transform="translate(15.711575, 52.599620)">
                  <path
                    d="M137.305503,13.6622391 L135.256167,6.83111954 L121.593928,0 L114.762808,12.2960152 L81.2903226,36.2049336 L66.9449715,35.5218216 L58.7476281,25.9582543 L31.4231499,24.5920304 L24.5920304,17.0777989 L10.2466793,19.1271347 L0,72.4098672 L33.4724858,62.8462998 L57.3814042,76.5085389 L79.9240987,81.2903226 L94.2694497,62.8462998 L112.713472,63.5294118 L121.593928,52.5996205 L134.573055,50.5502846 L127.058824,23.9089184 L137.305503,13.6622391 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kocasinan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="61.4800759" y="58.5996205">
                      KOC
                    </tspan>
                  </text>
                </g>
                <g id="melikgazi" transform="translate(71.726755, 103.149905)">
                  <path
                    d="M0,32.1062619 L1.36622391,25.9582543 L23.9089184,30.740038 L38.2542694,12.2960152 L56.6982922,12.9791271 L65.5787476,2.04933586 L78.5578748,0 L84.7058824,14.345351 L77.1916509,34.8387097 L66.2618596,32.1062619 L52.5996205,37.5711575 L43.0360531,56.0151803 L50.5502846,75.142315 L36.2049336,76.5085389 L36.8880455,58.7476281 L23.9089184,42.3529412 L8.88045541,38.2542694 L0,32.1062619 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Melikgazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.4231499" y="34.6907021">
                      MLK
                    </tspan>
                  </text>
                </g>
                <g id="ozvatan" transform="translate(128.425047, 0.000000)">
                  <path
                    d="M0,29.373814 L39.6204934,0 L50.5502846,10.2466793 L22.5426945,59.43074 L8.88045541,52.5996205 L0,29.373814 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Özvatan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.345351" y="33.3244782">
                      ÖZV
                    </tspan>
                  </text>
                </g>
                <g id="pinarbasi" transform="translate(195.370019, 28.690702)">
                  <path
                    d="M66.2618596,12.9791271 L71.7267552,10.2466793 L90.8538899,17.0777989 L124.326376,0 L146.86907,20.4933586 L175.559772,36.8880455 L130.474383,107.931689 L79.2409867,146.185958 L56.0151803,154.383302 L38.9373814,148.235294 L9.56356736,150.967742 L0,141.404175 L19.1271347,120.910816 L25.2751423,75.8254269 L46.4516129,69.6774194 L66.9449715,25.9582543 L66.2618596,12.9791271 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pınarbaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="90.8538899" y="70.2125237">
                      PNB
                    </tspan>
                  </text>
                </g>
                <g id="sarioglan" transform="translate(150.967742, 10.246679)">
                  <path
                    d="M28.0075901,0 L32.7893738,7.5142315 L22.5426945,18.4440228 L38.2542694,20.4933586 L52.5996205,32.7893738 L68.3111954,35.5218216 L54.6489564,65.5787476 L10.9297913,64.2125237 L2.04933586,56.0151803 L0,49.1840607 L28.0075901,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıoğlan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.5920304" y="51.085389">
                      SRO
                    </tspan>
                  </text>
                </g>
                <g id="sariz" transform="translate(241.821632, 136.622391)">
                  <path
                    d="M84.0227704,0 L94.2694497,34.8387097 L83.3396584,40.9867173 L88.1214421,59.43074 L70.3605313,63.5294118 L38.2542694,109.981025 L29.373814,103.833017 L27.3244782,86.7552182 L0,73.7760911 L9.56356736,46.4516129 L32.7893738,38.2542694 L84.0227704,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarız"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.1840607" y="55.1840607">
                      SRZ
                    </tspan>
                  </text>
                </g>
                <g id="talas" transform="translate(114.762808, 135.256167)">
                  <path
                    d="M34.1555977,2.73244782 L48.5009488,35.5218216 L7.5142315,43.0360531 L0,23.9089184 L9.56356736,5.46489564 L23.2258065,0 L34.1555977,2.73244782 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Talas"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.4440228" y="26.4933586">
                      TLS
                    </tspan>
                  </text>
                </g>
                <g id="tomarza" transform="translate(122.277040, 165.996205)">
                  <path
                    d="M129.108159,17.0777989 L112.030361,10.9297913 L82.6565465,13.6622391 L73.0929791,4.09867173 L62.8462998,0 L52.5996205,5.46489564 L40.9867173,4.78178368 L0,12.2960152 L1.36622391,25.2751423 L16.3946869,35.5218216 L24.5920304,63.5294118 L43.7191651,73.0929791 L60.113852,66.9449715 L86.0721063,78.5578748 L119.544592,44.402277 L129.108159,17.0777989 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tomarza"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.43074" y="44.9373814">
                      TOM
                    </tspan>
                  </text>
                </g>
                <g id="yahyali" transform="translate(62.163188, 232.258065)">
                  <path
                    d="M71.0436433,74.459203 L61.4800759,24.5920304 L4.78178368,0 L16.3946869,20.4933586 L0,36.8880455 L6.83111954,44.402277 L12.2960152,92.9032258 L0.683111954,122.27704 L62.8462998,127.741935 L64.2125237,83.3396584 L71.0436433,74.459203 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yahyalı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.4231499" y="64.0645161">
                      YHY
                    </tspan>
                  </text>
                </g>
                <g id="yesilhisar" transform="translate(0.000000, 177.609108)">
                  <path
                    d="M30.740038,0 L0.683111954,24.5920304 L0,36.8880455 L0,45.085389 L16.3946869,46.4516129 L28.0075901,82.6565465 L62.1631879,91.5370019 L78.5578748,75.142315 L66.9449715,54.6489564 L73.0929791,24.5920304 L30.740038,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yeşilhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.2542694" y="46.3036053">
                      YŞL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
