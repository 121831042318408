import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

import moment from "moment";

import { Helmet } from "react-helmet";

import { AdsVerticalMefta } from "../components/ads/ads-vertical-mefta";
import { AdsHorizontalMefta } from "../components/ads/ads-horizontal-mefta";
import { AdsHorizontalMefta2 } from "../components/ads/ads-horizontal-mefta2";

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { useNavigate, useParams } from "react-router-dom";
import Service from "../service";

const containerStyle = {
  width: "350px",
  height: "350px",
};

export const Decease = () => {
  const [data, setData] = useState();
  const [ads, setAds] = useState([]);
  const [map, setMap] = React.useState(null);
  //const [center, setCenter] = React.useState({});

  const [zm, setZm] = React.useState(10);

  const navigate = useNavigate();
  const params = useParams();

  const [mesaj, setMesaj] = useState();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC1_tgEaifWhH_LttBn8SigXkL06mZHMEw",
  });

  useEffect(() => {
    moment.locale();

    Service.get("deceaseds/user?id=" + params.id).then((result) => {
      if (!(result === "not found")) {
        //setCenter({ lat: result.lat, lng: result.long });

        setData(result);
        setMesaj(
          result.name +
            " " +
            result.surName +
            " vefat etmiştir. Cenazesi, " +
            result.comment +
            " mütakip " +
            result.prayerPlace +
            " 'dan kaldırılıp." +
            result.burialPlace +
            " 'da defnedilecektir. Babası " +
            result.fatherName
        );
        Service.get("selaDepo/getDeceased?id=" + params.id).then((result) => {
          setAds([...result]);
        });
      }
    });
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    if (data) {
      const bounds = new window.google.maps.LatLngBounds({
        lat: data.lat,
        lng: data.long,
      });
      map.fitBounds(bounds);
      setMap(map);
    }
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  //console.log(center);

  if (data && Object.keys(data).length > 0) {
    return (
      <Box sx={{ bgcolor: "white", pt: 2 }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Sela.TC - {data.name + " " + data.surName + " merhum sayfası."}
          </title>
          <meta
            name="description"
            content={
              " Sela.TC - " +
              data.name +
              " " +
              data.surName +
              " merhum sayfası."
            }
          ></meta>
        </Helmet>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid sx={{ pl: 5 }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                }}
              >
                <ListItem>
                  <Typography variant="h4">Merhum Kişisel Bilgiler</Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">Adı : {data.name}</Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">Soyadı : {data.surName}</Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">
                    Anne Adı : {data.motherName}
                  </Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">
                    Baba Adı : {data.fatherName}
                  </Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">Yaşı : {data.age}</Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">
                    Cenaze Namaz Vakti : {data.comment}
                  </Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">
                    Cenaze Namaz Yeri : {data.prayerPlace}
                  </Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <Typography variant="h6">
                    Defin Yeri : {data.burialPlace}
                  </Typography>
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
              <EmailShareButton
                quote={mesaj}
                url={"https://sela.tc/merhum/" + params.id}
                hashtag={"#sela.tc"}
              >
                <EmailIcon size={"2.5rem"} />
              </EmailShareButton>
              <FacebookShareButton
                quote={mesaj}
                url={"https://sela.tc/merhum/" + params.id}
                hashtag={"#sela.tc"}
              >
                <FacebookIcon size={"2.5rem"} />
              </FacebookShareButton>
              <WhatsappShareButton
                title={mesaj}
                url={"https://sela.tc/merhum/" + params.id}
                hashtag={"#sela.tc"}
              >
                <WhatsappIcon size={"2.5rem"} />
              </WhatsappShareButton>
              <TwitterShareButton
                quote={mesaj}
                url={"https://sela.tc/merhum/" + params.id}
                hashtag={"#sela.tc"}
              >
                <TwitterIcon size={"2.5rem"} />
              </TwitterShareButton>

              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{ lat: data.lat, lng: data.long }}
                  zoom={zm}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <Marker
                    position={{
                      lat: data.lat,
                      lng: data.long,
                    }}
                  />
                  {/* Child components, such as markers, info windows, etc. */}
                  <></>
                </GoogleMap>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <AdsHorizontalMefta ads={ads} />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <AdsHorizontalMefta2 ads={ads} />
          </Grid>
          <Grid container lg={12} md={12} sm={12} xs={12}>
            <Grid lg={2} md={2} sm={0} xs={0} />
            <Grid lg={8} md={8} sm={12} xs={12}>
              <AdsVerticalMefta ads={ads} />
            </Grid>
            <Grid lg={2} md={2} sm={0} xs={0} />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
};

export default React.memo(Decease);
