import React, { useEffect } from "react";
import "../turkeymap.css";

export const Diyarbakir = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 30.000000)">
                <g id="baglar" transform="translate(133.648649, 205.557432)">
                  <path
                    d="M87.8885135,0 L92.972973,29.0540541 L18.1587838,39.222973 L0.726351351,21.7905405 L5.81081081,7.98986486 L87.8885135,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bağlar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.8547297" y="24.8851351">
                      BAĞ
                    </tspan>
                  </text>
                </g>
                <g id="bismil" transform="translate(262.212838, 165.608108)">
                  <path
                    d="M131.469595,16.7060811 L108.226351,58.1081081 L124.932432,67.5506757 L116.942568,80.625 L45.0337838,85.7094595 L2.17905405,58.8344595 L0,18.1587838 L28.3277027,0 L52.2972973,21.0641892 L71.9087838,8.71621622 L131.469595,16.7060811 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bismil"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.3716216" y="53.9391892">
                      BİS
                    </tspan>
                  </text>
                </g>
                <g id="cermik" transform="translate(21.064189, 132.195946)">
                  <path
                    d="M63.9189189,0.726351351 L53.75,0 L14.527027,37.0439189 L7.98986486,47.9391892 L14.527027,51.5709459 L0,65.3716216 L70.4560811,98.0574324 L78.4459459,53.75 L100.236486,47.9391892 L63.9189189,0.726351351 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çermik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.7601351" y="43.7702703">
                      ÇER
                    </tspan>
                  </text>
                </g>
                <g id="cinar" transform="translate(145.996622, 224.442568)">
                  <path
                    d="M118.39527,0 L161.25,26.875 L162.702703,47.9391892 L148.175676,53.0236486 L80.625,79.1722973 L10.1689189,82.0777027 L0,63.1925676 L5.81081081,20.3378378 L80.625,10.1689189 L118.39527,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="68.277027" y="43.0439189">
                      ÇNR
                    </tspan>
                  </text>
                </g>
                <g id="cungus" transform="translate(0.000000, 117.668919)">
                  <path
                    d="M84.9831081,15.2533784 L74.8141892,14.527027 L35.5912162,51.5709459 L32.6858108,43.5810811 L5.08445946,39.9493243 L6.45129595e-16,32.6858108 L5.08445946,26.875 L8.71621622,27.6013514 L10.8952703,8.71621622 L45.0337838,0 L87.1621622,10.8952703 L84.9831081,15.2533784 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çüngüş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.5067568" y="29.2432432">
                      ÇÜN
                    </tspan>
                  </text>
                </g>
                <g id="dicle" transform="translate(143.091216, 76.266892)">
                  <path
                    d="M104.594595,43.5810811 L89.3412162,53.0236486 L61.0135135,51.5709459 L49.3918919,41.402027 L17.4324324,47.9391892 L0,26.1486486 L1.4527027,2.17905405 L23.9695946,3.63175676 L73.3614865,0 L66.8243243,12.347973 L80.625,15.9797297 L79.8986486,33.4121622 L104.594595,43.5810811 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dicle"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.6756757" y="24.8851351">
                      DİC
                    </tspan>
                  </text>
                </g>
                <g id="egil" transform="translate(160.523649, 117.668919)">
                  <path
                    d="M71.9087838,11.6216216 L67.5506757,30.5067568 L53.75,41.402027 L16.7060811,43.5810811 L1.4527027,31.2331081 L0,6.53716216 L31.9594595,0 L43.5810811,10.1689189 L71.9087838,11.6216216 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eğil"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.3378378" y="22.7060811">
                      EĞL
                    </tspan>
                  </text>
                </g>
                <g id="ergani" transform="translate(84.983108, 102.415541)">
                  <path
                    d="M58.1081081,0 L43.5810811,2.17905405 L2.17905405,26.1486486 L0,30.5067568 L36.3175676,77.7195946 L14.527027,83.5304054 L6.53716216,127.837838 L25.4222973,140.185811 L49.3918919,124.932432 L54.4763514,111.131757 L76.9932432,46.4864865 L75.5405405,21.7905405 L58.1081081,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ergani"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.5912162" y="43.0439189">
                      ERG
                    </tspan>
                  </text>
                </g>
                <g id="hani" transform="translate(209.915541, 69.729730)">
                  <path
                    d="M37.7702703,50.1182432 L13.0743243,39.9493243 L13.8006757,22.5168919 L0,18.8851351 L6.53716216,6.53716216 L16.7060811,5.08445946 L31.9594595,0 L61.0135135,14.527027 L66.097973,26.875 L47.9391892,32.6858108 L47.9391892,49.3918919 L37.7702703,50.1182432 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hani"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.7804054" y="25.6114865">
                      HNİ
                    </tspan>
                  </text>
                </g>
                <g id="hazro" transform="translate(285.456081, 100.236486)">
                  <path
                    d="M37.0439189,0 L0,10.1689189 L1.4527027,30.5067568 L10.8952703,51.5709459 L46.4864865,41.402027 L58.8344595,15.2533784 L37.0439189,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hazro"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.2432432" y="26.3378378">
                      HAZ
                    </tspan>
                  </text>
                </g>
                <g id="kayapinar" transform="translate(139.459459, 148.902027)">
                  <path
                    d="M0,64.6452703 L82.0777027,56.6554054 L37.7702703,12.347973 L22.5168919,0 L0,64.6452703 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kayapınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.6858108" y="44.4966216">
                      KYP
                    </tspan>
                  </text>
                </g>
                <g id="kocakoy" transform="translate(247.685811, 110.405405)">
                  <path
                    d="M37.7702703,0 C37.7702703,0 10.8952703,8.71621622 10.1689189,8.71621622 C9.44256757,8.71621622 0,9.44256757 0,9.44256757 L2.90540541,25.4222973 L17.4324324,31.9594595 L39.222973,20.3378378 L37.7702703,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kocaköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.527027" y="19.8006757">
                      KOC
                    </tspan>
                  </text>
                </g>
                <g id="kulp" transform="translate(321.773649, 0.000000)">
                  <path
                    d="M108.226351,50.1182432 L85.7094595,1.4527027 L65.3716216,0 L50.1182432,7.98986486 L1.4527027,38.4966216 L0,64.6452703 L22.5168919,90.7939189 L61.7398649,116.942568 L74.8141892,108.226351 L93.6993243,61.0135135 L108.226351,50.1182432 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kulp"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.0337838" y="53.9391892">
                      KLP
                    </tspan>
                  </text>
                </g>
                <g id="lice" transform="translate(241.875000, 34.138514)">
                  <path
                    d="M15.9797297,84.9831081 L15.9797297,68.277027 L34.1385135,62.4662162 L29.0540541,50.1182432 L0,35.5912162 L10.8952703,0 L81.3513514,4.35810811 L79.8986486,29.7804054 L102.415541,56.6554054 L80.625,66.097973 L43.5810811,76.2668919 L15.9797297,84.9831081 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Lice"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.7601351" y="37.9594595">
                      LİC
                    </tspan>
                  </text>
                </g>
                <g id="silvan" transform="translate(290.540541, 90.793919)">
                  <path
                    d="M53.75,0 L31.9594595,9.44256757 L53.75,24.6959459 L41.402027,50.8445946 L5.81081081,61.0135135 L0,74.8141892 L23.9695946,95.8783784 C23.9695946,95.8783784 42.8547297,83.5304054 43.5810811,83.5304054 C44.3074324,83.5304054 103.141892,91.5202703 103.141892,91.5202703 L115.489865,64.6452703 L123.47973,45.7601351 L106.047297,17.4324324 L92.972973,26.1486486 L53.75,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Silvan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="72.6351351" y="55.3918919">
                      SLV
                    </tspan>
                  </text>
                </g>
                <g id="sur" transform="translate(221.537162, 119.847973)">
                  <path
                    d="M5.08445946,114.763514 C5.0844587,114.763512 42.8547297,104.594595 42.8547297,104.594595 L40.6756757,63.9189189 L69.0033784,45.7601351 L74.8141892,31.9594595 L65.3716216,10.8952703 L43.5810811,22.5168919 L29.0540541,15.9797297 L26.1486486,0 L10.8952703,9.44256757 L6.53716216,28.3277027 L0,85.7094595 C0,85.7094595 5.08446022,114.763515 5.08445946,114.763514 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sur"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.6858108" y="45.9493243">
                      SUR
                    </tspan>
                  </text>
                </g>
                <g id="yenisehir" transform="translate(177.229730, 148.175676)">
                  <path
                    d="M0,13.0743243 L37.0439189,10.8952703 L50.8445946,0 L44.3074324,57.3817568 L0,13.0743243 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenişehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.6013514" y="29.2432432">
                      YNŞ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
