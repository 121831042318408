import React, { useEffect } from "react";
import "../turkeymap.css";

export const Hakkari = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 40.000000)">
                <g id="cukurca" transform="translate(0.000000, 147.812500)">
                  <path
                    d="M196.394231,48.5817308 L168.485577,78.5576923 L72.3557692,74.4230769 L20.6730769,52.7163462 L0,24.8076923 L10.3365385,0 L59.9519231,34.1105769 L178.822115,26.875 L196.394231,48.5817308 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çukurca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="107.5" y="57.6826923">
                      ÇUK
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(10.336538, 2.067308)">
                  <path
                    d="M32.0432692,60.9855769 L123.004808,39.2788462 L187.091346,0 L199.495192,44.4471154 L160.216346,66.1538462 L168.485577,172.620192 L50.6490385,180.889423 L0,146.778846 L32.0432692,60.9855769 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="87.8605769" y="107.298077">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="semdinli" transform="translate(252.211538, 110.600962)">
                  <path
                    d="M109.567308,0 L173.653846,48.5817308 L177.788462,107.5 L125.072115,97.1634615 L41.3461538,174.6875 L14.4711538,131.274038 L32.0432692,89.9278846 L0,72.3557692 L40.3125,26.875 L109.567308,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şemdinli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="83.7259615" y="70.0865385">
                      ŞEM
                    </tspan>
                  </text>
                </g>
                <g id="yuksekova" transform="translate(170.552885, 0.000000)">
                  <path
                    d="M39.2788462,45.4807692 L126.105769,0 L168.485577,7.23557692 L191.225962,27.9086538 L191.225962,110.600962 L121.971154,137.475962 L81.6586538,182.956731 L39.2788462,181.923077 L25.8413462,196.394231 L7.23557692,173.653846 L0,68.2211538 L39.2788462,45.4807692 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yüksekova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="80.62500" y="94.8942308">
                      YÜK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
