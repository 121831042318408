import React, { useEffect } from "react";
import "../turkeymap.css";

export const Erzincan = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 60.000000)">
                <g id="cayirli" transform="translate(219.415482, 14.944708)">
                  <path
                    d="M76.7614534,79.478673 C76.7614526,79.4786731 94.4233807,78.1200632 94.4233807,78.1200632 L116.840442,55.7030016 L85.5924171,23.7756714 L93.7440758,15.6240126 L38.7203791,0 L30.5687204,11.5481833 L0,31.2480253 L17.6619273,46.192733 L77.4407583,57.7409163 C77.4407583,57.7409163 76.7614542,79.4786729 76.7614534,79.478673 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çayırlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53.6650869" y="37.2480253">
                      ÇYR
                    </tspan>
                  </text>
                </g>
                <g id="ilic" transform="translate(0.000000, 79.478673)">
                  <path
                    d="M85.5924171,103.933649 L96.4612954,90.3475513 L62.4960506,55.7030016 L62.4960506,11.5481833 L13.5860979,0 L0,25.1342812 L17.6619273,75.4028436 L23.0963665,74.7235387 L23.7756714,78.1200632 L16.9826224,81.5165877 L42.1169036,92.385466 L59.0995261,89.6682464 L85.5924171,103.933649 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İliç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33.9652449" y="50.8341232">
                      İLÇ
                    </tspan>
                  </text>
                </g>
                <g id="kemah" transform="translate(62.496051, 45.513428)">
                  <path
                    d="M33.9652449,124.312796 L127.030016,86.9510269 L133.14376,51.6271722 L112.764613,48.9099526 L78.7993681,18.3412322 L89.6682464,7.47235387 L70.6477093,5.43443918 L58.4202212,0 L27.1721959,45.5134281 L0,45.5134281 L0,89.6682464 L33.9652449,124.312796 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kemah"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56.3823065" y="77.3270142">
                      KMH
                    </tspan>
                  </text>
                </g>
                <g id="kemaliye" transform="translate(7.472354, 160.995261)">
                  <path
                    d="M2.71721959,8.15165877 L8.83096367,21.0584518 L23.7756714,33.28594 L25.1342812,40.7582938 L41.4375987,46.192733 L47.5513428,55.7030016 L67.2511848,57.7409163 L67.9304897,84.9131122 L57.7409163,78.7993681 L39.399684,61.1374408 L12.2274882,63.8546603 L2.03791469,59.0995261 L0,9.51026856 L2.71721959,8.15165877 Z M70.5834028,59.778831 L100.443648,33.9652449 L78.0484642,22.4170616 L51.5814284,8.15165877 L34.6153798,10.8688784 L9.50562789,0 L4.75513428,6.11374408 L12.2201957,16.9826224 L28.5076023,30.5687204 L46.1522928,44.8341232 L50.9027865,51.6271722 L65.8329092,50.9478673 L70.5834028,59.778831 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kemaliye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.2685624" y="37.2480253">
                      KML
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(133.143760, 20.379147)">
                  <path
                    d="M1.35860979,0 L86.271722,25.8135861 L103.933649,40.7582938 L90.3475513,71.3270142 L127.709321,82.1958926 L122.954186,96.4612954 L95.1026856,119.557662 L56.3823065,112.085308 L62.4960506,76.7614534 L42.1169036,74.0442338 L8.15165877,43.4755134 L18.3412322,32.6066351 L0,30.5687204 L1.35860979,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56.3823065" y="53.5513428">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="otlukbeli" transform="translate(258.135861, 0.000000)">
                  <path
                    d="M87.6303318,23.0963665 C87.6303322,23.096366 76.0821485,12.906793 76.0821485,12.906793 L89.6682464,0.679304897 L23.7756714,0 L0,14.9447077 L55.0236967,30.5687204 C55.0236967,30.5687204 87.6303313,23.096367 87.6303318,23.0963665 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Otlukbeli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.399684" y="15.5102686">
                      OTL
                    </tspan>
                  </text>
                </g>
                <g id="refahiye" transform="translate(3.396524, 4.755134)">
                  <path
                    d="M129.747235,46.192733 L131.105845,15.6240126 L99.8578199,1.35860979 L95.7819905,6.79304897 L86.9510269,6.79304897 L70.6477093,0 L68.6097946,18.3412322 L44.1548183,20.3791469 L35.3238547,29.8894155 L19.699842,23.0963665 L0,35.3238547 L19.699842,55.7030016 L10.1895735,74.7235387 L59.0995261,86.271722 L86.271722,86.271722 L117.519747,40.7582938 L129.747235,46.192733 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Refahiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.892575" y="55.5892575">
                      REF
                    </tspan>
                  </text>
                </g>
                <g id="tercan" transform="translate(296.176935, 23.096367)">
                  <path
                    d="M16.9826224,7.47235387 L48.9099526,0 L84.2338073,7.47235387 L104.612954,49.5892575 L133.823065,82.1958926 L111.406003,88.3096367 L75.4028436,88.9889415 L60.4581359,76.7614534 L21.7377567,78.7993681 L0,88.3096367 L0,71.3270142 L17.6619273,69.9684044 L40.0789889,47.5513428 L8.83096367,15.6240126 L16.9826224,7.47235387 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tercan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="61.8167457" y="45.399684">
                      TRC
                    </tspan>
                  </text>
                </g>
                <g id="uzumlu" transform="translate(223.491311, 61.137441)">
                  <path
                    d="M13.5860979,0 L73.3649289,11.5481833 L72.685624,33.9652449 L72.685624,50.2685624 L37.3617694,41.4375987 L7.54179938e-17,30.5687204 L13.5860979,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Üzümlü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.0031596" y="27.0584518">
                      ÜZM
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
