import React, { useEffect } from "react";
import "../turkeymap.css";

export const Karabuk = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(86.000000, 56.000000)">
                <g id="eflani" transform="translate(171.000000, 0.000000)">
                  <path
                    d="M68,4 L28,0 L10,5 L21,22 L8,35 L0,58 L16,79 L23,98 L36,104 L44,73 L86,58 L93,49 L77,45 L68,4 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eflani"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33" y="50">
                      EFL
                    </tspan>
                  </text>
                </g>
                <g id="eskipazar" transform="translate(41.000000, 168.000000)">
                  <path
                    d="M136,27 L127,44 L102,70 L75,86 L62,73 L23,64 L11,52 L6,21 L0,11 L16,12 L35,15 L58,0 L75,17 L95,12 L113,22 L127,21 L136,27 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eskipazar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53" y="44">
                      ESK
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(51.000000, 101.000000)">
                  <path
                    d="M121,41 L89,21 L88,4 L73,0 L42,22 L31,19 L0,60 L6,79 L25,82 L48,67 L65,84 L85,79 L103,89 L117,88 L121,41 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="63" y="53">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="ovacik" transform="translate(168.000000, 134.000000)">
                  <path
                    d="M34,0 L52,6 L71,35 L60,61 L34,67 L9,61 L0,55 L4,8 L34,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ovacık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28" y="37">
                      OVA
                    </tspan>
                  </text>
                </g>
                <g id="safranbolu" transform="translate(82.000000, 5.000000)">
                  <path
                    d="M99,0 L87,2 L60,19 L36,51 L4,65 L9,92 L0,115 L11,118 L42,96 L57,100 L58,117 L90,137 L120,129 L125,99 L112,93 L105,73 L89,53 L97,30 L110,17 L99,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Safranbolu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="67" y="80">
                      SAF
                    </tspan>
                  </text>
                </g>
                <g id="yenice" transform="translate(0.000000, 70.000000)">
                  <path
                    d="M86,0 L53,16 L15,37 L12,72 L0,85 L8,107 L24,104 L41,109 L57,110 L51,91 L82,50 L91,27 L86,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenice"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33" y="59">
                      YNC
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
