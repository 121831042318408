import React, { useEffect } from "react";
import "../turkeymap.css";

export const Sakarya = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(48.000000, 3.000000)">
                <g id="adapazari" transform="translate(97.000000, 77.000000)">
                  <path
                    d="M4,0 L0,28 L9,32 L18,69 L35,73 L43,60 L61,53 C67,45 70,41 70,41 C70,41 71.3333333,38.6666667 74,34 L39,29 L30,19 L32,9 L4,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Adapazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35" y="45">
                      ADA
                    </tspan>
                  </text>
                </g>
                <g id="akyazi" transform="translate(156.000000, 118.000000)">
                  <path
                    d="M11 0 2 12 0 45 22 82 8 105 25 115 78 115 89 103 95 64 70 61 35 41 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akyazı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.9865772" y="92.114094">
                      AKY
                    </tspan>
                  </text>
                </g>
                <g id="arifiye" transform="translate(107.000000, 146.000000)">
                  <path
                    d="M2 12 7 15 6 29 12 31 25 19 25 4 8 0 0 4 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arifiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7.9261745" y="12.8322148">
                      ARİ
                    </tspan>
                  </text>
                </g>
                <g id="erenler" transform="translate(115.000000, 130.000000)">
                  <path
                    d="M0 16 17 20 17 35 4 47 12 52 41 33 43 0 25 7 17 20 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Erenler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.5033557" y="22.7516779">
                      ERN
                    </tspan>
                  </text>
                </g>
                <g id="ferizli" transform="translate(140.000000, 40.000000)">
                  <path
                    d="M33 48 2 44 9 23 0 11 12 0 30 13 26 28 34 40 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ferizli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.2080537" y="40.7718121">
                      FER
                    </tspan>
                  </text>
                </g>
                <g id="geyve" transform="translate(13.000000, 175.000000)">
                  <path
                    d="M151 48 105 63 101 91 64 97 47 96 29 76 12 76 0 62 41 64 61 56 67 14 78 4 100 0 106 2 114 7 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Geyve"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="104.597315" y="49.6912752">
                      GYV
                    </tspan>
                  </text>
                </g>
                <g id="hendek" transform="translate(167.000000, 80.000000)">
                  <path
                    d="M7 0 6 8 4 31 0 38 24 79 59 99 84 102 93 88 84 68 72 66 69 58 73 41 75 27 53 20 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hendek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="41.8187919" y="65.6308725">
                      HND
                    </tspan>
                  </text>
                </g>
                <g
                  id="karapurcek"
                  transform="translate(127.000000, 163.000000)"
                >
                  <path
                    d="M0 19 37 60 51 37 29 0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karapürçek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.3758389" y="36.9060403">
                      KRP
                    </tspan>
                  </text>
                </g>
                <g id="karasu" transform="translate(140.000000, 11.000000)">
                  <path
                    d="M0 0 95 28 92 46 80 89 34 69 26 57 30 42 12 29 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karasu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.3221477" y="51.9865772">
                      KRS
                    </tspan>
                  </text>
                </g>
                <g id="kaynarca" transform="translate(101.000000, 0.000000)">
                  <path
                    d="M19,0 C19.0000015,-1.75007142e-06 39,11 39,11 L51,40 L39,51 L48,63 L41,84 L28,86 L0,77 C0,77 18.9999985,1.75007142e-06 19,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kaynarca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.3422819" y="62.5704698">
                      KAY
                    </tspan>
                  </text>
                </g>
                <g id="kocaali" transform="translate(220.000000, 39.000000)">
                  <path
                    d="M15,0 C14.9999983,-9.94675929e-07 58,4 58,4 L62,21 L50,47 L22,68 L0,61 L12,18 C12,18 15.0000017,9.94675929e-07 15,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kocaali"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.7651007" y="37.3154362">
                      KCL
                    </tspan>
                  </text>
                </g>
                <g id="pamukova" transform="translate(0.000000, 182.000000)">
                  <path
                    d="M80 7 74 49 54 57 13 55 10 34 0 28 26 8 61 0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pamukova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.4899329" y="38.0671141">
                      PAM
                    </tspan>
                  </text>
                </g>
                <g id="sapanca" transform="translate(61.000000, 158.000000)">
                  <path
                    d="M48 0 41 5 33 6 19 4 15 4 0 24 19 31 30 21 52 17 53 3 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sapanca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.4765101" y="14.9530201">
                      SAP
                    </tspan>
                  </text>
                </g>
                <g id="serdivan" transform="translate(83.000000, 105.000000)">
                  <path
                    d="M32 41 24 45 0 45 2 28 14 7.10542736e-15 23 4 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Serdivan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="4.0939597" y="35.5100671">
                      SDV
                    </tspan>
                  </text>
                </g>
                <g id="sogutlu" transform="translate(127.000000, 84.000000)">
                  <path
                    d="M2 2 0 12 9 22 44 27 46 4 15 0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sögütlü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.7516779" y="18.3489933">
                      SÖĞ
                    </tspan>
                  </text>
                </g>
                <g id="tarakli" transform="translate(114.000000, 223.000000)">
                  <path
                    d="M67 10 47 38 53 61 38 75 29 71 12 63 14 47 0 43 4 15 50 0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Taraklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.5033557" y="37.5973154">
                      TRK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
