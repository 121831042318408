import React, { useEffect } from "react";
import "../turkeymap.css";

export const Aksaray = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            console.log(event.target.parentElement.id);
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(56.000000, 4.000000)">
                <g id="agacoren" transform="translate(137.000000, 36.000000)">
                  <path
                    d="M34,79 L0,69 L18,14 L43,13 L54,0 L78,37 L34,79 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ağaçören"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30" y="41">
                      AĞÇ
                    </tspan>
                  </text>
                </g>
                <g id="eskil" transform="translate(0.000000, 159.000000)">
                  <path
                    d="M91,28 L88,40 L83,40 L83,32 L78,27 L79,21 L39,0 L8,56 L0,91 L52,197 L80,197 L76,62 L96,38 L91,28 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eskil"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30" y="89">
                      ESK
                    </tspan>
                  </text>
                </g>
                <g id="gulagac" transform="translate(256.000000, 150.000000)">
                  <path
                    d="M11,73 L0,64 L4,43 L39,26 L49,0 L63,12 L66,30 L53,55 L52,71 L11,73 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gülağaç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25" y="55">
                      GÜL
                    </tspan>
                  </text>
                </g>
                <g id="guzelyurt" transform="translate(250.000000, 221.000000)">
                  <path
                    d="M58,0 L73,39 L37,60 L0,29 L17,2 L58,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güzelyurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29" y="31">
                      GZL
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(76.000000, 105.000000)">
                  <path
                    d="M229,45 L197,29 L174,35 L112,28 L95,10 L61,0 L48,15 L52,28 L62,38 L58,42 L46,42 L29,48 L25,68 L15,82 L20,92 L0,116 L4,251 L18,251 L143,236 L211,176 L174,145 L191,118 L180,109 L184,88 L219,71 L229,45 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="88" y="127">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="ortakoy" transform="translate(171.000000, 21.000000)">
                  <path
                    d="M102,113 L103,62 L82,52 L50,3 L39,0 L20,15 L44,52 L0,94 L17,112 L79,119 L102,113 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ortaköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51" y="81">
                      ORT
                    </tspan>
                  </text>
                </g>
                <g id="sariyahsi" transform="translate(138.000000, 0.000000)">
                  <path
                    d="M17,51 L6,41 L0,35 L4,19 L32,2 L42,8 L53,2.22044605e-16 L63,4 L72,22 L53,37 L42,50 L17,51 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıyahşi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22" y="30">
                      SRY
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
