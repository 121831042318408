import { Grid } from "@mui/material";
import React from "react";
import Resim from "../../assets/imgs/uzun.jpg";
import { useNavigate } from "react-router-dom";

const AdsHorizontal = ({ ads }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        width: "85%",
        height: "100%",
        cursor: "pointer",
      }}
    >
      {ads.map((data) => {
        return (
          data.order === 4 && (
            <Grid container sx={{ width: "100%", height: "auto" }}>
              {data.imgSrc === "" ? (
                <img
                  src={Resim}
                  onClick={() => {
                    navigate("/iletisim", { state: { id: data } });
                  }}
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                //  <a key={data._id} href={data.redirectUrl} target="_blank">

                <img
                  src={data.imgSrc}
                  onClick={() => {
                    navigate("/iletisim", { state: { id: data } });
                  }}
                  style={{ width: "100%", height: "auto" }}
                />

                //  </a>
              )}
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default AdsHorizontal;
