import React, { useEffect } from "react";
import "../turkeymap.css";
export const Kars = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(37.000000, 3.000000)">
                <g id="akyaka" transform="translate(295.200000, 37.800000)">
                  <path
                    d="M30.6,0 L40.5,27.9 L55.8,45.9 L52.2,102.6 L36,83.7 L36,66.6 L0.9,65.7 L0,28.8 L30.6,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akyaka"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18" y="48.3">
                      AKY
                    </tspan>
                  </text>
                </g>
                <g id="arpacay" transform="translate(205.200000, 0.000000)">
                  <path
                    d="M73.8,9 L120.6,37.8 L90,66.6 L90.9,103.5 L70.2,127.8 L41.4,116.1 L38.7,96.3 L0,56.7 L29.7,36.9 L44.1,0 L73.8,9 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arpaçay"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.3" y="66.3">
                      ARP
                    </tspan>
                  </text>
                </g>
                <g id="digor" transform="translate(241.200000, 162.900000)">
                  <path
                    d="M76.5,15.3 L55.8,0 L39.6,13.5 L7.2,18.9 L0,63.9 L77.4,124.2 L110.7,117 L121.5,106.2 L113.4,82.8 L88.2,54 L76.5,15.3 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Digor"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53.1" y="70.8">
                      DGR
                    </tspan>
                  </text>
                </g>
                <g id="kagizman" transform="translate(150.300000, 226.800000)">
                  <path
                    d="M168.3,60.3 L117,100.8 L48.6,113.4 L0,128.7 L17.1,87.3 L6.3,31.5 L32.4,4.5 L90.9,0 L168.3,60.3 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kağızman"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="67.5" y="59.1">
                      KĞZ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(151.200000, 103.500000)">
                  <path
                    d="M196.2,36.9 L166.5,74.7 L145.8,59.4 L129.6,72.9 L97.2,78.3 L90,123.3 L31.5,127.8 L38.7,104.4 L6.3,76.5 L0,33.3 L95.4,12.6 L124.2,24.3 L144.9,0 L180,0.9 L180.9,18.9 L196.2,36.9 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="82.8" y="50.1">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sarikamis" transform="translate(0.000000, 206.100000)">
                  <path
                    d="M89.1,0 L53.1,44.1 L0,57.6 L0,64.8 L9.9,85.5 L54,94.5 L108.9,153.9 L150.3,149.4 L167.4,108 L156.6,52.2 L134.1,56.7 L140.4,33.3 L126.9,9 L89.1,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıkamış"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="95.4" y="82.5">
                      SRK
                    </tspan>
                  </text>
                </g>
                <g id="selim" transform="translate(89.100000, 136.800000)">
                  <path
                    d="M62.1,0 L0,9 L0,69.3 L37.8,78.3 L51.3,102.6 L45,126 L67.5,121.5 L93.6,94.5 L100.8,71.1 L68.4,43.2 L62.1,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Selim"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.6" y="51.9">
                      SLM
                    </tspan>
                  </text>
                </g>
                <g id="susuz" transform="translate(151.200000, 56.700000)">
                  <path
                    d="M54,0 L45,0.9 L10.8,15.3 L0,80.1 L95.4,59.4 L92.7,38.7 L54,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Susuz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.1" y="42.9">
                      SUS
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
