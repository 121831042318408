import React, { useEffect } from "react";
import "../turkeymap.css";

export const Ardahan = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(48.000000, 3.000000)">
                <g id="cildir" transform="translate(178.263666, 76.398714)">
                  <path
                    d="M23.1511254,0 L75.2411576,21.9935691 L60.192926,39.3569132 L85.659164,67.1382637 L122.700965,54.4051447 L160.900322,81.0289389 L158.585209,107.652733 L120.385852,96.0771704 L101.864952,143.536977 L63.6655949,169.003215 L52.0900322,170.160772 L0,84.5016077 L28.9389068,77.5562701 L20.8360129,31.2540193 L23.1511254,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çıldır"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.5080386" y="100.919614">
                      ÇIL
                    </tspan>
                  </text>
                </g>
                <g id="damal" transform="translate(94.604502, 64.823151)">
                  <path
                    d="M0,16.2057878 L32.4115756,52.0900322 L104.180064,42.829582 L106.495177,11.5755627 L31.2540193,0 L0,16.2057878 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Damal"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.0900322" y="29.1511254">
                      DML
                    </tspan>
                  </text>
                </g>
                <g id="gole" transform="translate(0.000000, 222.250804)">
                  <path
                    d="M186.366559,42.829582 L172.475884,126.173633 L92.6045016,137.749196 L33.5691318,81.0289389 L0,50.9324759 L11.5755627,18.5209003 L92.6045016,0 L186.366559,42.829582 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Göle"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="99.5498392" y="61.562701">
                      GÖL
                    </tspan>
                  </text>
                </g>
                <g id="hanak" transform="translate(63.665595, 81.028939)">
                  <path
                    d="M0,15.0482315 L5.78778135,56.7202572 L60.192926,76.3987138 L114.598071,79.8713826 L143.536977,72.926045 L135.434084,26.6237942 L64.8231511,35.8842444 L31.2540193,0 L0,15.0482315 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hanak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.8842444" y="44.1993569">
                      HNK
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(11.575563, 137.749196)">
                  <path
                    d="M166.688103,23.1511254 L113.440514,19.6784566 L57.8778135,0 L9.26045016,78.7138264 L0,103.022508 L81.0289389,84.5016077 L174.790997,127.33119 L218.778135,108.810289 L166.688103,23.1511254 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="114.598071" y="67.3504823">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="posof" transform="translate(42.829582, 0.000000)">
                  <path
                    d="M0,64.8231511 L17.3633441,12.733119 L87.9742765,0 L84.5016077,35.8842444 L109.967846,34.7266881 L158.585209,76.3987138 L83.3440514,64.8231511 L52.0900322,81.0289389 L20.8360129,96.0771704 L0,64.8231511 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Posof"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.3022508" y="46.5144695">
                      PSF
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
