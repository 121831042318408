import React, { useEffect } from "react";
import "../turkeymap.css";

export const Van = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(69.000000, 3.000000)">
                <g id="bahcesaray" transform="translate(12.631579, 272.842105)">
                  <path
                    d="M34.1052632,0 L30.3157895,15.1578947 L41.6842105,25.2631579 L36.6315789,44.8421053 L26.5263158,53.0526316 L2.52631579,54.9473684 L0,6.31578947 L34.1052632,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bahçesaray"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.7894737" y="33.7894737">
                      BHÇ
                    </tspan>
                  </text>
                </g>
                <g id="baskale" transform="translate(198.315789, 204.000000)">
                  <path
                    d="M0,140.842105 L39.1578947,116.842105 L46.7368421,143.368421 L99.7894737,115.578947 L96.6315789,104.842105 L68.8421053,105.473684 L100.421053,6.94736842 L72.6315789,0 L32.2105263,9.47368421 L5.05263158,41.0526316 L0,140.842105 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Başkale"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.7894737" y="70.4210526">
                      BAŞ
                    </tspan>
                  </text>
                </g>
                <g id="caldiran" transform="translate(152.842105, 0.000000)">
                  <path
                    d="M77.6842105,81.4736842 L77.6842105,40.4210526 L46.1052632,0 L6.94736842,4.42105263 L0,22.1052632 L30.3157895,104.210526 L77.6842105,81.4736842 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çaldıran"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.3684211" y="48.3157895">
                      ÇAL
                    </tspan>
                  </text>
                </g>
                <g id="catak" transform="translate(39.157895, 248.842105)">
                  <path
                    d="M65.0526316,0 L77.0526316,39.1578947 L60,39.7894737 L60.6315789,73.8947368 L91.5789474,111.157895 L13.2631579,107.368421 L0,77.0526316 L10.1052632,68.8421053 L15.1578947,49.2631579 L3.78947368,39.1578947 L7.57894737,24 L65.0526316,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çatak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.6842105" y="72.3157895">
                      ÇTK
                    </tspan>
                  </text>
                </g>
                <g id="edremit" transform="translate(68.210526, 202.736842)">
                  <path
                    d="M26.5263158,0 L25.2631579,10.7368421 L15.1578947,12 L1.89473684,25.2631579 L0,34.7368421 L39.1578947,27.7894737 L56.8421053,10.1052632 L26.5263158,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Edremit"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.0526316" y="19.2631579">
                      EDR
                    </tspan>
                  </text>
                </g>
                <g id="ercis" transform="translate(25.894737, 6.315789)">
                  <path
                    d="M109.894737,32.8421053 L54.3157895,0 L34.7368421,15.1578947 L19.5789474,24.6315789 L30.9473684,30.9473684 L27.1578947,56.8421053 L0,87.7894737 L23.3684211,98.5263158 L44.8421053,94.7368421 L54.9473684,94.7368421 L65.6842105,84 L84,82.1052632 L98.5263158,83.3684211 L109.894737,32.8421053 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Erciş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54.9473684" y="58.4210526">
                      ECŞ
                    </tspan>
                  </text>
                </g>
                <g id="gevas" transform="translate(0.000000, 204.631579)">
                  <path
                    d="M4.42105263,13.2631579 L18.9473684,0 L31.5789474,11.3684211 L33.4736842,33.4736842 L68.2105263,32.8421053 L107.368421,25.8947368 L104.210526,44.2105263 L46.7368421,68.2105263 L12.6315789,74.5263158 L0,61.2631579 L4.42105263,13.2631579 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gevas"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.5789474" y="55.8947368">
                      GVŞ
                    </tspan>
                  </text>
                </g>
                <g id="gurpinar" transform="translate(99.157895, 185.052632)">
                  <path
                    d="M31.5789474,174.947368 L43.5789474,173.052632 L99.1578947,159.789474 L104.210526,60 L131.368421,28.4210526 L109.263158,6.31578947 L70.1052632,0 L25.8947368,27.7894737 L8.21052632,45.4736842 L5.05263158,63.7894737 L17.0526316,102.947368 L0,103.578947 L0.631578947,137.684211 L31.5789474,174.947368 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gürpınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56.8421053" y="88.7368421">
                      GÜR
                    </tspan>
                  </text>
                </g>
                <g id="ipekyolu" transform="translate(94.105263, 129.473684)">
                  <path
                    d="M72.6315789,7.57894737 L52.4210526,10.7368421 L37.8947368,0 L17.6842105,64.4210526 L0,65.0526316 L0.631578947,73.2631579 L30.9473684,83.3684211 L75.1578947,55.5789474 L72.6315789,7.57894737 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İpekyolu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.5789474" y="49.5789474">
                      İPK
                    </tspan>
                  </text>
                </g>
                <g id="muradiye" transform="translate(124.421053, 22.105263)">
                  <path
                    d="M28.4210526,0 L11.3684211,17.0526316 L0,67.5789474 L18.3157895,75.1578947 L3.15789474,83.3684211 L7.57894737,107.368421 L22.1052632,118.105263 L42.3157895,114.947368 L58.7368421,82.1052632 L28.4210526,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Muradiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24" y="68.5263158">
                      MRD
                    </tspan>
                  </text>
                </g>
                <g id="ozalp" transform="translate(166.736842, 81.473684)">
                  <path
                    d="M2.52631579,103.578947 L-1.70530257e-13,55.5789474 L16.4210526,22.7368421 L63.7894737,1.42108547e-14 L88.4210526,30.9473684 L41.6842105,109.894737 L2.52631579,103.578947 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Özalp"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.7368421" y="60.3157895">
                      ÖZL
                    </tspan>
                  </text>
                </g>
                <g id="saray" transform="translate(208.421053, 112.421053)">
                  <path
                    d="M0,78.9473684 L46.7368421,0 L62.5263158,91.5789474 L22.1052632,101.052632 L0,78.9473684 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="saray"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.4210526" y="76.7368421">
                      SRY
                    </tspan>
                  </text>
                </g>
                <g id="tusba" transform="translate(56.210526, 105.473684)">
                  <path
                    d="M75.7894737,24 L71.3684211,-8.52651283e-14 L60.6315789,6.94736842 L41.6842105,22.1052632 L34.1052632,19.5789474 L26.5263158,22.1052632 L25.8947368,32.8421053 L13.2631579,41.0526316 L0,68.8421053 L4.42105263,70.7368421 L10.7368421,63.1578947 L15.7894737,65.0526316 L11.3684211,67.5789474 L36,80.8421053 L37.8947368,89.0526316 L55.5789474,88.4210526 L75.7894737,24 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tuşba"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.2105263" y="52.7368421">
                      TUŞ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
