import React, { useEffect } from "react";
import "../turkeymap.css";
export const Sinop = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(58.000000, 38.000000)">
                <g id="ayancik" transform="translate(22.000000, 44.000000)">
                  <path
                    d="M117,5 L68,9 L44,0 L36,3 L24,4 L24,24 L29,35 L13,49 L0,84 L68,91 L75,79 L90,95 L119,92 L120,66 L111,53 L117,32 L117,5 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ayancık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62" y="51">
                      AYN
                    </tspan>
                  </text>
                </g>
                <g id="boyabat" transform="translate(48.000000, 123.000000)">
                  <path
                    d="M123,113 L94,116 L53,141 L6,134 L0,98 L4,62 L36,43 L42,12 L49,0 L64,16 L93,13 L102,12 L111,17 L153,25 L168,38 L162,44 L141,38 L123,46 L129,61 L123,113 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Boyabat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69" y="76">
                      BOY
                    </tspan>
                  </text>
                </g>
                <g id="dikmen" transform="translate(210.000000, 132.000000)">
                  <path
                    d="M109,20 L69,0 L40,7 L6,29 L0,35 L15,51 L46,67 L70,63 L86,72 L91,53 L109,20 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dikmen"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48" y="37">
                      DKM
                    </tspan>
                  </text>
                </g>
                <g id="duragan" transform="translate(171.000000, 161.000000)">
                  <path
                    d="M125,43 L121,60 L132,82 L127,95 L120,105 L108,116 L92,119 L79,115 L74,109 L73,102 L63,92 L55,79 L55,72 L45,68 L42,68 L44,72 L6,97 L0,75 L6,23 L0,8 L18,0 L39,6 L54,22 L85,38 L109,34 L125,43 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Durağan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="78" y="74">
                      DUR
                    </tspan>
                  </text>
                </g>
                <g id="erfelek" transform="translate(133.000000, 29.000000)">
                  <path
                    d="M34,0 L6,20 L6,47 L0,68 L9,81 L8,107 L17,106 L22,83 L32,67 L47,56 L55,39 L46,12 L34,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Erfelek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19" y="40">
                      ERF
                    </tspan>
                  </text>
                </g>
                <g id="gerze" transform="translate(159.000000, 73.000000)">
                  <path
                    d="M120,59 L106,54 L85,35 L83,23 L64,0 L43,23 L4,37 L0,67 L42,75 L57,88 L91,66 L120,59 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gerze"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="44" y="51">
                      GER
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(150.000000, 0.000000)">
                  <path
                    d="M73,73 L67,59 L69,38 L71,33 L91,29 L94,22 L84,19 L68,26 L56,19 L51,6 L37,0 L25,3 L20,24 L17,29 L29,41 L38,68 L30,85 L15,96 L5,112 L0,135 L9,140 L13,110 L52,96 L73,73 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43" y="46">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sarayduzu" transform="translate(101.000000, 236.000000)">
                  <path
                    d="M70,0 L41,3 L0,28 L48,53 L51,43 L62,38 L61,29 L76,22 L70,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Saraydüzü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="22">
                      SRY
                    </tspan>
                  </text>
                </g>
                <g id="turkeli" transform="translate(0.000000, 48.000000)">
                  <path
                    d="M46,0 L31,3 L14,6 L0,1 L13,42 L9,72 L14,79 L22,80 L35,45 L51,31 L46,20 L46,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Türkeli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18" y="28">
                      TRK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
