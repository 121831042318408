import React, { useEffect } from "react";
import "../turkeymap.css";
export const Cankiri = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(15.000000, 48.000000)">
                <g
                  id="atkaracalar"
                  transform="translate(106.000000, 26.000000)"
                >
                  <path
                    d="M39,93 L16,89 L0,54 L13,16 L9,0 L28,7 L35,13 L48,16 L46,29 L50,61 L39,93 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Atkaracalar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17" y="48">
                      ATK
                    </tspan>
                  </text>
                </g>
                <g id="bayramoren" transform="translate(115.000000, 0.000000)">
                  <path
                    d="M0,26 L11,0 L26,3 L51,1 L68,5 L84,34 L82,43 L64,52 L37,55 L39,42 L26,39 L19,33 L0,26 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bayramören"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="26">
                      BYÖ
                    </tspan>
                  </text>
                </g>
                <g id="cerkes" transform="translate(0.000000, 26.000000)">
                  <path
                    d="M122,89 L94,96 L82,119 L64,113 L39,98 L0,96 L5,73 L3,59 L30,43 L55,17 L64,0 L89,6 L115,0 L119,16 L106,54 L122,89 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çerkeş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60" y="64">
                      ÇKŞ
                    </tspan>
                  </text>
                </g>
                <g id="eldivan" transform="translate(205.000000, 152.000000)">
                  <path
                    d="M33,77 L8,72 L14,42 L8.8817842e-16,17 L10,0 L34,0 L57,32 L55,50 L33,77 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eldivan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25" y="37">
                      ELD
                    </tspan>
                  </text>
                </g>
                <g id="ilgaz" transform="translate(211.000000, 4.000000)">
                  <path
                    d="M94,72 L93,32 L101,7 L75,0 L47,2 L18,5 L1,17 L9,27 L0,51 L3,65 L13,76 L27,75 L49,89 L72,85 L94,72 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ilgaz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48" y="45">
                      ILG
                    </tspan>
                  </text>
                </g>
                <g
                  id="kizilirmak"
                  transform="translate(310.000000, 195.000000)"
                >
                  <path
                    d="M82,2 L48,0 L26,20 L20,44 L0,58 L35,74 L74,54 L93,24 L82,2 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kızılırmak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="44" y="40">
                      KZL
                    </tspan>
                  </text>
                </g>
                <g id="korgun" transform="translate(179.000000, 79.000000)">
                  <path
                    d="M45,1 L20,21 L1,24 L6,44 L0,57 L6,77 L26,90 L36,73 L60,73 L78,52 L86,28 L81,14 L59,0 L45,1 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Korgun"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33" y="44">
                      KRG
                    </tspan>
                  </text>
                </g>
                <g id="kursunlu" transform="translate(145.000000, 21.000000)">
                  <path
                    d="M0,98 L11,66 L7,34 L34,31 L52,22 L54,13 L67,-1.77635684e-15 L75,10 L66,34 L69,48 L79,59 L54,79 L36,82 L40,102 L34,115 L0,98 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kurşunlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29" y="64">
                      KRŞ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(238.000000, 89.000000)">
                  <path
                    d="M154,108 L161,102 L164,79 L149,77 L150,53 L138,58 L118,91 L105,91 L54,34 L51,7 L45,0 L22,4 L27,18 L19,42 L1,63 L24,95 L22,113 L0,140 L50,157 L72,164 L92,150 L98,126 L120,106 L154,108 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56" y="106">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="orta" transform="translate(82.000000, 115.000000)">
                  <path
                    d="M97,21 L63,4 L40,0 L12,7 L0,30 L28,37 L14,53 L20,67 L45,84 L61,73 L55,51 L81,50 L103,41 L97,21 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Orta"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54" y="31">
                      ORT
                    </tspan>
                  </text>
                </g>
                <g id="sabanozu" transform="translate(127.000000, 156.000000)">
                  <path
                    d="M86,68 L60,69 L46,93 L8,66 L0,43 L16,32 L10,10 L36,9 L58,0 L78,13 L92,38 L86,68 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şabanözü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40" y="39">
                      ŞBN
                    </tspan>
                  </text>
                </g>
                <g id="yaprakli" transform="translate(283.000000, 76.000000)">
                  <path
                    d="M105,66 L108,54 L69,4 L22,0 L0,13 L6,20 L9,47 L60,104 L73,104 L93,71 L105,66 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yapraklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="44" y="53">
                      YPR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
