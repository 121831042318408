import React, { useEffect } from "react";
import "../turkeymap.css";

export const Balikesir = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(12.000000, 3.000000)">
                <g id="altieylul" transform="translate(177.068404, 209.902280)">
                  <path
                    d="M77.8816382,41.0950481 L70.6349962,16.0611939 L71.9525674,0.250338543 L60.0944259,12.10848 L42.3072137,21.9902646 L6.73278923,16.0611939 L0.14493284,36.4835487 L11.3442887,40.4362625 L31.1078578,87.8688285 L44.9423563,81.2809721 L48.2362844,74.6931157 L53.5065696,62.1761886 L46.2599275,43.7301907 L77.8816382,41.0950481 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altıeylül"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.9311569" y="38.3581964">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="ayvalik" transform="translate(0.000000, 268.534202)">
                  <path
                    d="M27.6689968,9.47333748 L15.1520697,11.4496944 L11.1993559,25.9429784 L8.5642133,18.0375508 L0,25.2841928 L3.29392819,28.578121 L21.0811404,32.5308348 L25.6926399,51.6356183 L39.5271383,34.5071917 L56.6555649,21.9902646 L32.9392819,14.7436226 L27.6689968,9.47333748 Z M9.88178458,4.86183801 L5.92907075,5.52062365 L13.1757128,14.084837 L4.61149947,14.7436226 L3.29392819,8.81455184 L1.97635692,0.250338543 L11.8581415,1.56790982 L9.88178458,4.86183801 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ayvalık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.4922959" y="29.1351975">
                      AYV
                    </tspan>
                  </text>
                </g>
                <g id="balya" transform="translate(118.436482, 155.960912)">
                  <path
                    d="M102.256707,12.6882114 L96.3276361,7.41792629 L90.3985653,8.73549757 L87.7634228,1.48885554 L81.1755664,0.830069904 L67.9998536,0.830069904 L46.9187132,8.73549757 L28.4727153,41.6747795 L0.14493284,54.1917066 L12.66186,66.0498481 L44.2835706,71.3201332 L54.1653552,66.0498481 L65.364711,70.0025619 L102.256707,12.6882114 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Balya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54.6001537" y="38.9379278">
                      BLY
                    </tspan>
                  </text>
                </g>
                <g id="bandirma" transform="translate(188.794788, 65.667752)">
                  <path
                    d="M95.1418219,0.869597043 L56.9322549,7.45745343 L48.3680416,1.52838268 L44.4153277,1.52838268 L24.6517586,14.7040955 L0.276689968,9.43381034 L0.276689968,16.0216667 L33.8747575,34.4676646 L32.5571863,44.3494492 L38.486257,45.6670205 L49.0268272,35.1264502 L51.6619698,31.1737364 L65.4964682,31.1737364 L71.4255389,38.4203784 L72.0843246,54.2312338 L68.1316107,56.8663763 L76.695824,63.4542327 L84.6012517,60.8190901 L82.6248948,52.2548768 L83.9424661,41.7143066 L78.6721809,29.1973795 L94.4830363,8.7750247 L95.1418219,0.869597043 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bandırma"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.8737694" y="23.8253852">
                      BAN
                    </tspan>
                  </text>
                </g>
                <g id="bigadic" transform="translate(222.801303, 250.944625)">
                  <path
                    d="M120.426015,55.3906965 L79.5813051,13.2284156 L57.8413791,8.61691615 L32.1487392,0.0527028511 L0.527028511,2.6878454 L7.77367053,21.1338433 L2.50338543,33.6507704 L32.8075248,47.4852688 L49.9359514,44.1913406 L78.9225195,57.3670534 L120.426015,55.3906965 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bigadiç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.8301797" y="27.6199905">
                      BGÇ
                    </tspan>
                  </text>
                </g>
                <g id="burhaniye" transform="translate(44.560261, 238.045603)">
                  <path
                    d="M18.0243751,1.7523698 L0.23716283,22.8335102 L21.3183033,30.0801523 L27.9061596,43.9146507 L53.5987995,36.009223 L53.5987995,32.0565092 L41.0818724,27.4450097 L37.1291586,3.72872671 L29.2237309,0.434798521 L18.0243751,1.7523698 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Burhaniye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.4355305" y="24.0493723">
                      BRH
                    </tspan>
                  </text>
                </g>
                <g id="dursunbey" transform="translate(280.260586, 208.729642)">
                  <path
                    d="M50.4498042,4.05811953 L29.3686638,16.5750467 L22.7808074,29.0919738 L6.96995206,37.6561871 L0.38209567,50.8318999 L22.1220217,55.4433993 L62.9667313,97.6056802 L66.2606595,104.852322 L86.0242287,106.169893 L116.987154,81.7948249 L120.281082,56.102185 L130.162866,40.2913296 L120.281082,31.7271163 L103.152655,35.0210445 L89.3181569,23.8216887 L84.7066574,4.05811953 L76.1424441,0.764191341 L50.4498042,4.05811953 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dursunbey"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.0839586" y="50.071405">
                      DRS
                    </tspan>
                  </text>
                </g>
                <g id="edremit" transform="translate(8.208469, 186.449511)">
                  <path
                    d="M93.2445193,12.5037514 L58.3288804,0.645609926 L44.494382,9.86860886 L23.4132416,10.5273945 L12.8726714,17.1152509 L0.355744245,46.7606046 L37.9065256,46.101819 L46.4707389,41.4903195 L55.0349522,47.4193903 L54.3761666,53.348461 L65.5755225,52.0308897 L93.2445193,12.5037514 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Edremit"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.9764667" y="29.5304688">
                      EDR
                    </tspan>
                  </text>
                </g>
                <g id="erdek" transform="translate(165.342020, 22.280130)">
                  <path
                    d="M15.1652454,10.6591516 L9.89496029,5.38886652 L3.30710391,14.6118655 L3.30710391,20.5409362 L13.8476741,17.9057937 L11.2125316,13.2942942 L15.1652454,10.6591516 Z M5.94224646,0.777367053 L0.671961351,2.75372397 L0.671961351,4.73008088 L7.25981774,3.41250961 L5.94224646,0.777367053 Z M31.6348864,17.247008 L29.6585294,21.8585075 L34.2700289,23.8348644 L33.6112433,28.4463639 L27.0233869,24.49365 L19.7767449,22.5172931 L19.7767449,18.5645793 L23.7294587,18.5645793 L23.0706731,11.9767229 L26.3646013,12.6355085 L27.6821725,15.9294367 L31.6348864,17.247008 Z M42.8342422,9.34158035 L94.219522,22.5172931 L94.8783077,30.4227208 L71.8208103,44.9160048 L67.8680965,44.9160048 L52.0572412,38.9869341 L43.4930279,26.470007 L36.2463858,17.9057937 L42.8342422,9.34158035 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Erdek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58.4211104" y="31.6385829">
                      ERD
                    </tspan>
                  </text>
                </g>
                <g id="gomec" transform="translate(26.970684, 260.325733)">
                  <path
                    d="M17.8267394,0.553379936 L0.698312777,17.6818065 L5.96859788,22.9520916 L29.6848809,30.1987337 L45.4957362,21.6345204 L38.9078798,7.80002196 L17.8267394,0.553379936 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gömeç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.9676097" y="19.5564543">
                      GÖM
                    </tspan>
                  </text>
                </g>
                <g id="gonen" transform="translate(145.407166, 75.048860)">
                  <path
                    d="M77.2623797,25.0865571 L75.9448084,34.9683417 L62.11031,67.9076236 L54.2048823,81.742122 L41.0291696,81.742122 L19.9480291,89.6475497 L14.0189584,58.6846247 L0.843245617,38.9210555 L20.6068148,17.1811295 L27.1946712,4.00541668 L43.6643121,0.0527028511 L43.6643121,6.64055924 L77.2623797,25.0865571 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gönen"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.2409691" y="44.7484171">
                      GÖN
                    </tspan>
                  </text>
                </g>
                <g id="havran" transform="translate(72.703583, 198.175896)">
                  <path
                    d="M45.8778319,11.9767229 L58.394759,23.8348644 L54.4420452,43.5984336 L29.4081909,54.7977894 L25.4554771,71.926216 L12.9385499,67.3147165 L8.98583611,43.5984336 L1.08040845,40.3045054 L28.7494053,0.777367053 L45.8778319,11.9767229 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Havran"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.9375618" y="37.5676536">
                      HAV
                    </tspan>
                  </text>
                </g>
                <g id="ivrindi" transform="translate(97.328990, 221.628664)">
                  <path
                    d="M86.4722029,4.3348095 L79.8843465,24.7571643 L57.4856348,39.2504483 L54.1917066,55.0613037 L44.309922,52.4261611 L38.3808513,62.3079457 L0.830069904,52.4261611 L0.830069904,48.4734473 L4.78278374,31.3450207 L29.816638,20.1456648 L33.7693518,0.38209567 L65.3910625,5.65238078 L75.2728471,0.38209567 L86.4722029,4.3348095 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İvrindi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.4980785" y="33.2196684">
                      İVR
                    </tspan>
                  </text>
                </g>
                <g id="karesi" transform="translate(182.931596, 166.514658)">
                  <path
                    d="M52.2548768,0.816894192 L37.7615928,2.13446547 L0.869597043,59.448816 L36.4440215,65.3778868 L54.2312338,55.4961022 L66.0893753,43.6379607 L78.6063024,37.7088899 L54.8900194,21.8980346 L58.8427332,6.74596494 L52.2548768,0.816894192 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karesi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.6085349" y="37.6071808">
                      KAR
                    </tspan>
                  </text>
                </g>
                <g id="kepsut" transform="translate(247.426710, 182.931596)">
                  <path
                    d="M48.3680416,0.210811404 L31.239615,16.6804524 L14.1111884,21.2919518 L1.59426125,27.2210226 L0.276689968,43.0318779 L7.52333199,68.0657322 L33.2159719,76.6299455 L39.8038283,63.4542327 L55.6146836,54.8900194 L62.20254,42.3730923 L83.2836804,29.8561651 L71.4255389,11.4101673 L48.3680416,0.210811404 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kepsut"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.3804853" y="38.3186693">
                      KPS
                    </tspan>
                  </text>
                </g>
                <g id="manyas" transform="translate(199.348534, 109.055375)">
                  <path
                    d="M74.0475058,17.431468 L74.7062914,20.7253962 L50.9900084,43.1241079 L50.3312228,55.641035 L42.4257951,54.3234637 L35.8379387,58.2761776 L21.3446547,59.5937489 L15.4155839,54.3234637 L9.48651319,55.641035 L6.85137064,48.394393 L0.263514255,47.7356074 L8.16894192,33.901109 L22.0034403,0.961827032 L27.9325111,2.27939831 L25.2973685,4.25575522 L30.5676536,8.86725469 L33.8615818,13.4787542 L37.15551,10.184826 L44.402152,8.20846906 L57.5778648,13.4787542 L66.1420781,20.0666105 L74.0475058,17.431468 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Manyas"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.0497383" y="33.1406141">
                      MNY
                    </tspan>
                  </text>
                </g>
                <g id="marmara" transform="translate(178.241042, 0.000000)">
                  <path
                    d="M35.8642902,11.1993559 L13.4655785,21.0811404 L0.289865681,15.1520697 L2.92500823,1.97635692 L21.3710061,0 L35.8642902,11.1993559 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Marmara"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.65373495" y="13.0740036">
                      MAR
                    </tspan>
                  </text>
                </g>
                <g id="savastepe" transform="translate(151.270358, 246.254072)">
                  <path
                    d="M37.1423343,4.08447096 L56.9059035,51.5170369 L48.3416902,50.8582513 L40.4362625,44.2703949 L21.331479,44.2703949 L0.250338543,30.4358965 L3.54426673,14.6250412 L25.9429784,0.131757128 L37.1423343,4.08447096 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Savaştepe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.8135637" y="28.3578304">
                      SVŞ
                    </tspan>
                  </text>
                </g>
                <g id="sindirgi" transform="translate(221.628664, 283.778502)">
                  <path
                    d="M124.892581,29.8034623 L121.598653,22.5568203 L80.0951579,24.5331772 L51.1085898,11.3574644 L33.9801632,14.6513926 L3.67602386,0.816894192 L0.38209567,7.40475058 L4.3348095,10.0398931 L4.3348095,25.1919628 L10.9226659,37.7088899 L14.8753797,46.9318889 L32.662592,60.1076017 L41.2268053,75.2596713 L62.9667313,73.2833144 L72.1897303,66.0366724 L128.845295,60.7663873 L130.821652,52.202174 L120.281082,35.0737474 L124.892581,29.8034623 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sındırgı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.4961022" y="46.8301797">
                      SDG
                    </tspan>
                  </text>
                </g>
                <g id="susurluk" transform="translate(234.527687, 128.990228)">
                  <path
                    d="M39.5271383,0.790542766 L15.8108553,23.1892545 L15.1520697,35.7061816 L7.24664202,34.3886103 L0.658785638,38.3413242 L7.24664202,44.2703949 L3.29392819,59.4224646 L27.0102112,75.2333199 L44.1386378,70.6218204 L61.2670644,54.1521795 L48.0913516,23.1892545 L46.1149947,6.71961351 L39.5271383,0.790542766 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Susurluk"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.4686528" y="46.1450426">
                      SUS
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
