import React from "react";
import { useLocation } from "react-router-dom";
import { Paper, Grid, TextField, Typography, Button } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Service from "../service";

export const RequestForm = () => {
  const loc = useLocation();

  const context = React.useRef({
    nameSurname: "",
    comment: "",
    iban: "",
    page: loc.state.id.title,
    imgSrc: "",
  });

  const handleSend = () => {
    console.log(context.current);
    Service.postWithImg("adsRequest/add", context.current).then((data) => {
      window.location = "/";
    });
  };

  return (
    <Paper
      sx={{
        maxWidth: 936,
        margin: "auto",
        overflow: "hidden",
        mt: 3,
      }}
    >
      <Grid
        container
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "100%", pt: 2 }}
      >
        <Grid>
          <Typography variant="h4">O da mutlu olsun derneği</Typography>
        </Grid>
        <Grid>
          <Typography variant="h5">
            IBAN : TR250005901840130184019374
          </Typography>
        </Grid>
        <Grid>
          <List sx={{ width: 300, maxWidth: 360, bgcolor: "background.paper" }}>
            <ListItem alignItems="flex-start">
              <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>Ad Soyad</Typography> <br />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) => {
                    context.current.nameSurname = e.target.value;
                  }}
                  autoFocus
                />
              </Grid>
            </ListItem>

            <ListItem alignItems="flex-start">
              <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>İLETİŞİM BİLGİSİ</Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) => {
                    context.current.iban = e.target.value;
                  }}
                  autoFocus
                />
              </Grid>
            </ListItem>

            <ListItem alignItems="flex-start">
              <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>AÇIKLAMA</Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) => {
                    context.current.comment = e.target.value;
                  }}
                  autoFocus
                />
              </Grid>
            </ListItem>

            <ListItem alignItems="flex-start">
              <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>FATURA VE YÜKLENECEK RESİM</Typography> <br />{" "}
                <br />
                <br />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="file"
                  onChange={(e) => {
                    context.current.imgSrc = e.target.files;
                  }}
                  inputProps={{ multiple: true }}
                  autoFocus
                />
              </Grid>
            </ListItem>
            <br />
            <br />
            <Grid>
              <Button onClick={handleSend}>Gönder</Button>
            </Grid>
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};
