import React from "react";
import { Box, Divider, Grid, useTheme, useMediaQuery } from "@mui/material";
import { Announcements } from "../components/announcements";
import TurkeyMap from "../assets/svg/TurkeyMap";
import SelaImage from "../assets/imgs/sela.png";
import { RegionBar } from "../components/RegionBar";
import { Statistics } from "../components/Statistics";
import { useNavigate } from "react-router-dom";
import Service from "../service";
import { Helmet } from "react-helmet";

import { AdsVertical } from "../components/ads/ads-vertical-main";
import AdsHorizontal from "../components/ads/ads-horizontal-main";
import { SearchSide } from "../components/search";

function Home() {
  const navigation = useNavigate();
  const [ads, setAds] = React.useState([]);

  const clickHandler = (e) => {
    if (e.target) {
      navigation("/il/" + e.target.parentNode.id);
    } else {
      navigation("/" + e);
    }
  };

  React.useEffect(() => {
    Service.get("selaDepo/getMain").then((data) => setAds([...data]));
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ bgcolor: "white" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sela.TC Ana Sayfa</title>
        <meta
          name="description"
          content="Sela.TC Vefat Haberleri, Cenaze ilanları"
        ></meta>
      </Helmet>
      <Grid item lg={12} md={8} sm={12} xs={12}>
        <RegionBar />
      </Grid>
      <Divider />
      <Grid container sx={{ pt: 3 }}>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <SearchSide />
          <Announcements />
        </Grid>
        <Grid container lg={8} md={8} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {!isMobile && <TurkeyMap clickHandler={clickHandler} />}
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Divider />
            {ads.length > 0 && <AdsVertical ads={ads} />}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          lg={2}
          md={2}
          sm={2}
          xs={2}
        >
          {ads.length > 0 && !isMobile && !isTablet && (
            <AdsHorizontal ads={ads} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
