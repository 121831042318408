import React, { useEffect } from "react";
import "../turkeymap.css";

export const Corum = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(58.000000, 3.000000)">
                <g id="alaca" transform="translate(137.142857, 258.701299)">
                  <path
                    d="M110.649351,5.45454545 L110.649351,26.4935065 L106.753247,39.7402597 L114.545455,48.3116883 L105.194805,56.8831169 L108.311688,71.6883117 L96.6233766,81.8181818 L60,88.0519481 L60.7792208,81.038961 L54.5454545,74.8051948 L12.4675325,88.0519481 L10.9090909,40.5194805 L3.8961039,39.7402597 L7.79220779,24.9350649 L0.779220779,14.8051948 L0,7.01298701 L18.7012987,14.8051948 L57.6623377,0 L91.1688312,11.6883117 L110.649351,5.45454545 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alaca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.3376623" y="52.7532468">
                      ALC
                    </tspan>
                  </text>
                </g>
                <g id="bayat" transform="translate(4.675325, 122.337662)">
                  <path
                    d="M84.1558442,80.2597403 L72.4675325,26.4935065 L59.2207792,28.8311688 L47.5324675,0 L0,6.23376623 L31.1688312,45.1948052 L28.8311688,54.5454545 L27.2727273,73.2467532 L38.961039,74.8051948 L37.4025974,92.7272727 L49.0909091,98.1818182 L84.1558442,80.2597403 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bayat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.5324675" y="60.5454545">
                      BYT
                    </tspan>
                  </text>
                </g>
                <g id="bogazkale" transform="translate(101.298701, 297.662338)">
                  <path
                    d="M39.7402597,0 L46.7532468,0.779220779 L48.3116883,49.0909091 L26.4935065,56.8831169 L0,44.4155844 L2.33766234,25.7142857 L32.7272727,17.1428571 L39.7402597,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Boğazkale"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.2597403" y="37.1688312">
                      BĞZ
                    </tspan>
                  </text>
                </g>
                <g id="dodurga" transform="translate(131.688312, 105.974026)">
                  <path
                    d="M0,1.55844156 L21.8181818,0 L58.4415584,14.8051948 L35.0649351,39.7402597 L0,1.55844156 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dodurga"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.7142857" y="20.8051948">
                      DDR
                    </tspan>
                  </text>
                </g>
                <g id="iskilip" transform="translate(52.207792, 84.935065)">
                  <path
                    d="M36.6233766,117.662338 L60,102.077922 L68.5714286,102.857143 L95.0649351,89.6103896 L61.5584416,13.2467532 L52.987013,7.01298701 L10.9090909,0 L16.3636364,29.6103896 L1.38417416e-15,37.4025974 L11.6883117,66.2337662 L24.9350649,63.8961039 L36.6233766,117.662338 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İskilip"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.974026" y="58.2077922">
                      İSK
                    </tspan>
                  </text>
                </g>
                <g id="kargi" transform="translate(49.870130, 0.000000)">
                  <path
                    d="M55.3246753,91.9480519 L93.5064935,63.8961039 L71.6883117,63.1168831 L71.6883117,57.6623377 L95.0649351,57.6623377 L108.311688,53.7662338 L124.675325,33.5064935 L128.571429,24.9350649 L91.1688312,5.45454545 L54.5454545,6.9208708e-16 L24.9350649,11.6883117 L5.45454545,39.7402597 L0,61.5584416 L13.2467532,84.9350649 L55.3246753,91.9480519 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kargı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.5324675" y="44.1818182">
                      KRG
                    </tspan>
                  </text>
                </g>
                <g id="lacin" transform="translate(158.181818, 120.779221)">
                  <path
                    d="M52.2077922,0.779220779 L58.4415584,31.9480519 L38.961039,28.8311688 L24.1558442,42.0779221 L0,42.0779221 L8.57142857,24.9350649 L31.9480519,0 L52.2077922,0.779220779 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Laçin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.1688312" y="20.8051948">
                      LÇN
                    </tspan>
                  </text>
                </g>
                <g id="mecitozu" transform="translate(232.207792, 164.415584)">
                  <path
                    d="M42.0779221,0 L47.5324675,14.025974 L62.3376623,12.4675325 L74.025974,24.9350649 L87.2727273,33.5064935 L83.3766234,42.8571429 L56.1038961,81.038961 L15.5844156,99.7402597 L0,36.6233766 L14.025974,26.4935065 L24.9350649,5.45454545 L42.0779221,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mecitözü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.5194805" y="48.8571429">
                      MCT
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(114.545455, 149.610390)">
                  <path
                    d="M102.077922,3.11688312 L113.766234,13.2467532 L133.246753,6.23376623 L143.376623,8.57142857 L159.74026,14.8051948 L142.597403,20.2597403 L131.688312,41.2987013 L117.662338,51.4285714 L133.246753,114.545455 L113.766234,120.779221 L80.2597403,109.090909 L41.2987013,123.896104 L22.5974026,116.103896 L0,105.194805 L13.2467532,70.1298701 L14.025974,47.5324675 L6.23376623,38.1818182 L32.7272727,24.9350649 L43.6363636,13.2467532 L67.7922078,13.2467532 L82.5974026,0 L102.077922,3.11688312 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60" y="69.1168831">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="oguzlar" transform="translate(113.766234, 98.181818)">
                  <path
                    d="M0,0 L17.9220779,9.35064935 L52.987013,47.5324675 L44.4155844,64.6753247 L33.5064935,76.3636364 L0,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Oğuzlar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.4935065" y="43.4025974">
                      OĞZ
                    </tspan>
                  </text>
                </g>
                <g id="ortakoy" transform="translate(247.792208, 245.454545)">
                  <path
                    d="M0,18.7012987 L0,39.7402597 L11.6883117,35.8441558 L38.1818182,35.8441558 L45.974026,14.025974 L40.5194805,0 L0,18.7012987 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ortaköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="osmancik" transform="translate(105.194805, 27.272727)">
                  <path
                    d="M77.1428571,0 L72.4675325,10.1298701 L62.3376623,21.038961 L55.3246753,30.3896104 L51.4285714,34.2857143 L38.1818182,36.6233766 L0,64.6753247 L8.57142857,70.9090909 L26.4935065,80.2597403 L48.3116883,78.7012987 L84.9350649,93.5064935 L105.194805,94.2857143 L107.532468,87.2727273 L117.662338,77.9220779 L120,35.0649351 L91.9480519,23.3766234 L77.1428571,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Osmancık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="67.012987" y="58.987013">
                      OSM
                    </tspan>
                  </text>
                </g>
                <g id="sungurlu" transform="translate(0.000000, 215.064935)">
                  <path
                    d="M51.4285714,144.935065 L35.0649351,123.116883 L25.7142857,92.7272727 L26.4935065,81.8181818 L19.4805195,77.9220779 L0,60.7792208 L29.6103896,45.1948052 L45.1948052,21.8181818 L36.6233766,4.67532468 L42.0779221,0 L53.7662338,5.45454545 L49.8701299,21.038961 L62.3376623,29.6103896 L72.4675325,14.025974 L84.9350649,17.1428571 L77.1428571,33.5064935 L89.6103896,42.0779221 L114.545455,39.7402597 L137.142857,50.6493506 L138.701299,59.2207792 L144.935065,68.5714286 L141.038961,82.5974026 L134.025974,99.7402597 L103.636364,108.311688 L101.298701,127.012987 L84.1558442,133.246753 L51.4285714,144.935065 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sungurlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.2337662" y="81.5844156">
                      SNG
                    </tspan>
                  </text>
                </g>
                <g id="ugurludag" transform="translate(49.870130, 187.012987)">
                  <path
                    d="M3.8961039,33.5064935 L38.961039,15.5844156 L62.3376623,0 L70.9090909,0.779220779 L78.7012987,10.1298701 L77.9220779,32.7272727 L64.6753247,67.7922078 L39.7402597,70.1298701 L27.2727273,61.5584416 L35.0649351,45.1948052 L22.5974026,42.0779221 L12.4675325,57.6623377 L0,49.0909091 L3.8961039,33.5064935 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Uğurludağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.8571429" y="37.9480519">
                      UĞR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
