import React, { useEffect } from "react";
import "../turkeymap.css";

export const Kirsehir = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(54.000000, 20.000000)">
                <g id="akcakent" transform="translate(115.000000, 0.000000)">
                  <path
                    d="M87,21 L54,0 L23,11 L0,54 L28,66 L17,82 L58,91 L65,90 L87,21 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akçakent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37" y="45">
                      AKÇ
                    </tspan>
                  </text>
                </g>
                <g id="akpinar" transform="translate(79.000000, 54.000000)">
                  <path
                    d="M36,0 L0,43 L19,55 L25,69 L42,70 L65,99 L92,71 L94,37 L53,28 L64,12 L36,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akpınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38" y="48">
                      AKP
                    </tspan>
                  </text>
                </g>
                <g id="boztepe" transform="translate(190.000000, 110.000000)">
                  <path
                    d="M102,59 L108,32 L43,0 L5,56 L0,84 L15,94 L30,79 L56,64 L102,59 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Boztepe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50" y="43">
                      BZT
                    </tspan>
                  </text>
                </g>
                <g id="cicekdagi" transform="translate(180.000000, 21.000000)">
                  <path
                    d="M118,121 L130,103 L106,86 L90,78 L87,59 L98,58 L97,49 L65,9 L22,0 L0,69 L53,89 L118,121 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çiçekdağı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="41" y="50">
                      ÇÇD
                    </tspan>
                  </text>
                </g>
                <g id="kaman" transform="translate(0.000000, 97.000000)">
                  <path
                    d="M79,0 L51,3 L0,45 L3,62 L12,68 L10,71 L15,75 L19,72 L44,103 L52,97 L60,104 L60,115 L68,122 L144,56 L121,27 L104,26 L98,12 L79,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kaman"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57" y="52">
                      KMN
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(66.000000, 89.000000)">
                  <path
                    d="M118,236 L139,209 L137,114 L122,104 L127,76 L165,20 L112,0 L105,1 L103,35 L76,63 L0,129 L13,144 L38,148 L42,153 L48,147 L52,156 L63,150 L68,154 L65,159 L79,160 L99,168 L102,179 L87,174 L81,167 L66,166 L75,184 L86,187 L118,236 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="79" y="121">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="mucur" transform="translate(205.000000, 167.000000)">
                  <path
                    d="M2,132 L36,139 L49,122 L55,105 L74,99 L68,86 L70,78 L90,79 L87,51 L105,38 L123,10 L122,0 L87,2 L41,7 L15,22 L0,37 L2,132 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mucur"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35" y="56">
                      MCR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
