import React, { useEffect } from "react";
import "../turkeymap.css";

export const Istanbul = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 67.740125)">
                <g id="adalar" transform="translate(231.000000, 200.259875)">
                  <path
                    d="M32.4129638,21.8373633 L26.3225951,26.5783257 L26.0682284,32.3962876 L23.0897927,33.9892365 L20.6351687,32.1426796 L22.4762645,29.2287568 L22.0749458,25.6285208 L24.2163698,21.5762065 L28.2686841,19.2592386 L31.5537871,18.221309 L32.4129638,21.8373633 Z M23.4929277,16.7203289 L23.8641078,18.1548185 L22.6252544,20.3972331 L16.260731,19.9959144 L13.7481269,19.8906938 L14.319702,16.5611761 L19.5807312,14.0834693 L22.9911471,13.8873043 L23.4929277,16.7203289 Z M5.56682994,16.3157292 L10.256284,17.7904036 L11.9313534,13.7380892 L9.98556572,10.7538431 L6.3948472,10.343007 L5.56682994,16.3157292 Z M3.35247054,7.05183923 L6.53711162,5.98324224 L5.30777566,2.43270847 L3.1060746,0.858100942 L0.754209488,1.9309279 L0.618850345,4.27327557 L3.35247054,7.05183923 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Adalar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="arnavutkoy" transform="translate(127.000000, 66.000000)">
                  <path
                    d="M23.1871102,0.153846154 L59.8399168,18.033264 L55.3700624,34.1247401 L64.3097713,52.8981289 L65.2037422,70.7775468 L55.3700624,68.0956341 L46.4303534,72.5654886 L43.7484407,77.035343 L35.7027027,74.3534304 L25.8690229,82.3991684 L24.0810811,83.2931393 L13.3534304,72.5654886 L7.0956341,78.8232848 L2.62577963,78.8232848 L0.837837838,50.2162162 L12.4594595,39.4885655 L3.51975052,31.4428274 L20.5051975,12.6694387 L23.1871102,0.153846154 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arnavutköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.7629938" y="46.4885655">
                      ARN
                    </tspan>
                  </text>
                </g>
                <g id="atasehir" transform="translate(246.432432, 173.958420)">
                  <path
                    d="M30.6985447,11.0935551 L19.970894,10.1995842 L9.24324324,15.5634096 L4.77338877,7.51767152 L0.303534304,4.83575884 L0.303534304,2.15384615 L3.87941788,0.365904366 L10.1372141,4.83575884 L27.1226611,6.62370062 L30.6985447,11.0935551 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ataşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="avcilar" transform="translate(161.000000, 145.000000)">
                  <path
                    d="M3.49064449,39.1580042 L11.5363825,40.0519751 L16.006237,37.3700624 L16.006237,36.4760915 L11.5363825,36.4760915 L8.85446985,28.4303534 L8.85446985,25.7484407 L12.4303534,27.5363825 L10.6424116,18.5966736 L11.5363825,15.9147609 L13.3243243,16.8087318 L14.2182952,13.2328482 L11.5363825,13.2328482 L2.5966736,0.717255717 L1.7027027,5.18711019 L5.27858628,14.1268191 L2.5966736,14.1268191 L0.808731809,15.02079 L4.38461538,23.0665281 L3.49064449,31.1122661 L0.808731809,30.2182952 L3.49064449,39.1580042 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Avcılar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="bagcilar" transform="translate(188.000000, 155.000000)">
                  <path
                    d="M6.88565489,1.44490644 L2.41580042,0.550935551 L0.627858628,5.91476091 L0.627858628,13.960499 L12.2494802,15.7484407 L12.2494802,13.0665281 L14.9313929,13.960499 L14.9313929,11.2785863 L12.2494802,9.49064449 L11.3555094,5.91476091 L13.1434511,0.550935551 L11.3555094,0.550935551 L9.56756757,2.33887734 L6.88565489,2.33887734 L6.88565489,1.44490644 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bağcılar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g
                  id="bahcelievler"
                  transform="translate(188.000000, 168.000000)"
                >
                  <path
                    d="M12.2494802,2.74844075 L0.627858628,0.96049896 L0.627858628,4.53638254 L3.30977131,10.7941788 L10.4615385,11.6881497 L17.6133056,8.11226611 L16.7193347,4.53638254 L14.9313929,7.21829522 L12.2494802,2.74844075 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bahçelievler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="bakirkoy" transform="translate(181.000000, 175.000000)">
                  <path
                    d="M25.5072765,7.37006237 L21.9313929,10.9459459 L16.5675676,10.0519751 L13.8856549,14.5218295 L9.41580042,14.5218295 L0.476091476,8.26403326 L0.476091476,5.58212058 L5.83991684,4.68814969 L5.83991684,2.00623701 L7.62785863,2.00623701 L10.3097713,3.79417879 L17.4615385,4.68814969 L24.6133056,1.11226611 L27.2952183,0.218295218 L25.5072765,7.37006237 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bakırköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g
                  id="basaksehir"
                  transform="translate(152.000000, 134.000000)"
                >
                  <path
                    d="M0.869022869,14.3991684 L3.55093555,20.6569647 L11.5966736,25.1268191 L14.2785863,25.1268191 L10.7027027,16.1871102 L11.5966736,11.7172557 L20.5363825,24.2328482 L23.2182952,24.2328482 L37.5218295,25.1268191 L38.4158004,21.5509356 L42.8856549,22.4449064 L46.4615385,11.7172557 L45.5675676,9.03534304 L40.2037422,2.77754678 L30.3700624,0.0956340956 L21.4303534,4.56548857 L18.7484407,9.03534304 L10.7027027,6.35343035 L0.869022869,14.3991684 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Başakşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.1122661" y="16.9293139">
                      BŞH
                    </tspan>
                  </text>
                </g>
                <g
                  id="bayrampasa"
                  transform="translate(204.000000, 154.000000)"
                >
                  <path
                    d="M7.87110187,7.80873181 L4.2952183,2.44490644 L0.719334719,0.656964657 L0.719334719,2.44490644 L2.50727651,6.02079002 L1.61330561,9.5966736 L2.50727651,12.2785863 L5.18918919,14.960499 L7.87110187,14.0665281 L9.65904366,15.8544699 L10.5530146,14.960499 L7.87110187,7.80873181 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bayrampaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="besiktas" transform="translate(228.000000, 146.000000)">
                  <path
                    d="M16.0540541,7.76299376 L13.3721414,8.65696466 L10.6902287,16.7027027 L1.75051975,20.2785863 L0.856548857,19.3846154 L4.43243243,11.3388773 L6.22037422,12.2328482 L7.11434511,9.55093555 L4.43243243,5.08108108 L6.22037422,0.611226611 L12.4781705,5.97505198 L16.0540541,7.76299376 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beşiktaş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="beykoz" transform="translate(245.432432, 109.958420)">
                  <path
                    d="M58.5176715,5.36382536 L40.6382536,0 L17.3950104,5.36382536 L3.09147609,19.6673597 L2.1975052,25.9251559 L8.45530146,31.2889813 L1.3035343,38.4407484 L0.40956341,44.6985447 L3.98544699,49.1683992 L16.5010395,54.5322245 L31.6985447,51.8503119 L30.8045738,44.6985447 L47.7900208,30.3950104 L65.6694387,31.2889813 L56.7297297,17.8794179 L58.5176715,5.36382536 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beykoz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.5467775" y="26.6673597">
                      BEY
                    </tspan>
                  </text>
                </g>
                <g
                  id="beylikduzu"
                  transform="translate(142.000000, 170.000000)"
                >
                  <path
                    d="M19.8087318,5.21829522 L22.4906445,14.1580042 L15.3388773,16.8399168 L5.50519751,16.8399168 L0.141372141,14.1580042 L0.141372141,7.00623701 L3.71725572,7.9002079 L8.18711019,0.748440748 L15.3388773,4.32432432 L19.8087318,5.21829522 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beylikdüzü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.3991684" y="13.1122661">
                      BYL
                    </tspan>
                  </text>
                </g>
                <g id="beyoglu" transform="translate(217.000000, 159.000000)">
                  <path
                    d="M12.7505198,7.27858628 L9.17463617,12.6424116 L7.38669439,10.8544699 L3.81081081,8.17255717 L2.02286902,6.38461538 L0.234927235,2.80873181 L2.91683992,1.91476091 L3.81081081,0.126819127 L5.5987526,1.91476091 L12.7505198,7.27858628 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beyoğlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g
                  id="buyukcekmece"
                  transform="translate(97.000000, 138.000000)"
                >
                  <path
                    d="M0.442827443,22.9147609 L33.5197505,41.6881497 L37.989605,32.7484407 L45.1413721,34.5363825 L45.1413721,39.006237 L48.7172557,39.9002079 L53.1871102,32.7484407 L50.5051975,17.5509356 L54.0810811,11.2931393 L43.3534304,0.565488565 L37.0956341,6.82328482 L32.6257796,6.82328482 L20.1101871,4.14137214 L19.2162162,17.5509356 L1.33679834,13.0810811 L0.442827443,22.9147609 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Büyükçekmece"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.3679834" y="25.4449064">
                      BÜÇ
                    </tspan>
                  </text>
                </g>
                <g id="catalca" transform="translate(36.000000, 0.000000)">
                  <path
                    d="M20.3201663,1.13686838e-13 L114.18711,66.1538462 L111.505198,78.6694387 L94.5197505,97.4428274 L103.459459,105.488565 L91.8378378,116.216216 L93.6257796,144.823285 L81.1101871,142.141372 L80.2162162,155.550936 L62.3367983,151.081081 L52.5031185,105.488565 L41.7754678,94.7609148 L48.033264,84.033264 L31.047817,72.4116424 L0.652806653,53.6382536 L9.59251559,33.0769231 L11.3804574,24.1372141 L2.44074844,13.4095634 L20.3201663,1.13686838e-13 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çatalca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.3513514" y="61.5322245">
                      ÇAT
                    </tspan>
                  </text>
                </g>
                <g id="cekmekoy" transform="translate(261.432432, 139.958420)">
                  <path
                    d="M49.6694387,1.28898129 L31.7900208,0.395010395 L14.8045738,14.6985447 L15.6985447,21.8503119 L0.501039501,24.5322245 L0.501039501,27.2141372 L4.07692308,27.2141372 L12.1226611,34.3659044 L32.6839917,22.7442827 L46.0935551,23.6382536 L47.8814969,28.1081081 L52.3513514,27.2141372 L57.7151767,13.8045738 L54.1392931,3.07692308 L49.6694387,1.28898129 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çekmeköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.8960499" y="17.2286902">
                      ÇKM
                    </tspan>
                  </text>
                </g>
                <g id="esenler" transform="translate(194.000000, 145.000000)">
                  <path
                    d="M9.82536383,7.86902287 L4.46153846,0.717255717 L0.885654886,11.4449064 L0.885654886,12.3388773 L3.56756757,12.3388773 L5.35550936,10.5509356 L7.14345114,10.5509356 L5.35550936,15.9147609 L6.24948025,19.4906445 L8.93139293,21.2785863 L9.82536383,22.1725572 L11.6133056,22.1725572 L12.5072765,23.0665281 L11.6133056,25.7484407 L12.5072765,26.6424116 L15.1891892,26.6424116 L15.1891892,23.960499 L12.5072765,21.2785863 L11.6133056,18.5966736 L12.5072765,15.02079 L10.7193347,11.4449064 L10.7193347,9.65696466 L9.82536383,7.86902287 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Esenler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="esenyurt" transform="translate(147.000000, 148.000000)">
                  <path
                    d="M3.18711019,22.7484407 L10.3388773,26.3243243 L17.4906445,28.1122661 L18.3846154,20.0665281 L14.8087318,12.02079 L16.5966736,11.1268191 L8.55093555,6.65696466 L5.86902287,0.399168399 L4.08108108,1.29313929 L0.505197505,7.55093555 L3.18711019,22.7484407 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Esenyurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="4.08108108" y="22.5966736">
                      ESY
                    </tspan>
                  </text>
                </g>
                <g id="eyupsultan" transform="translate(182.000000, 84.000000)">
                  <path
                    d="M4.83991684,0.0332640333 L37.9168399,16.1247401 L36.1288981,26.8523909 L44.1746362,50.0956341 L40.5987526,57.2474012 L38.8108108,63.5051975 L42.3866944,67.0810811 L38.8108108,75.1268191 L37.9168399,76.9147609 L35.2349272,77.8087318 L37.022869,81.3846154 L33.4469854,85.8544699 L32.5530146,84.960499 L29.8711019,77.8087318 L35.2349272,76.02079 L34.3409563,73.3388773 L30.7650728,72.4449064 L31.6590437,65.2931393 L33.4469854,59.035343 L27.1891892,53.6715177 L19.1434511,46.5197505 L14.6735967,48.3076923 L12.8856549,53.6715177 L10.2037422,52.7775468 L9.30977131,34.8981289 L0.37006237,16.1247401 L4.83991684,0.0332640333 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eyüpsultan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.4615385" y="31.1704782">
                      EYP
                    </tspan>
                  </text>
                </g>
                <g id="fatih" transform="translate(213.000000, 165.000000)">
                  <path
                    d="M6.02286902,0.384615385 L6.91683992,3.06652807 L8.7047817,4.85446985 L12.2806653,7.53638254 L13.1746362,8.43035343 L14.962578,7.53638254 L14.962578,10.2182952 L13.1746362,12.006237 L4.23492723,12.006237 L2.44698545,15.5821206 L0.659043659,15.5821206 L0.659043659,11.1122661 L2.44698545,4.85446985 L6.02286902,0.384615385 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Fatih"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g
                  id="gaziosmanpasa"
                  transform="translate(203.000000, 149.000000)"
                >
                  <path
                    d="M10.6590437,0.293139293 L7.97713098,1.18711019 L4.4012474,1.18711019 L0.825363825,3.86902287 L1.71933472,5.65696466 L5.2952183,7.44490644 L8.87110187,12.8087318 L14.2349272,11.02079 L13.3409563,8.33887734 L9.76507277,7.44490644 L10.6590437,0.293139293 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gaziosmanpaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="gungoren" transform="translate(200.000000, 166.000000)">
                  <path
                    d="M8.2952183,9.21829522 L5.61330561,10.1122661 L4.71933472,6.53638254 L2.93139293,9.21829522 L0.249480249,5.64241164 L0.249480249,2.06652807 L2.93139293,2.96049896 L2.93139293,0.278586279 L3.82536383,1.17255717 L5.61330561,1.17255717 L6.50727651,2.06652807 L5.61330561,4.74844075 L6.50727651,5.64241164 L9.18918919,5.64241164 L8.2952183,9.21829522 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güngören"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="kadikoy" transform="translate(233.432432, 173.958420)">
                  <path
                    d="M0.787941788,3.94178794 L2.57588358,10.1995842 L15.985447,18.2453222 L22.2432432,15.5634096 L17.7733888,7.51767152 L13.3035343,4.83575884 L7.93970894,3.04781705 L7.93970894,0.365904366 L6.15176715,0.365904366 L0.787941788,3.94178794 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kadıköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7.93970894" y="12.7297297">
                      KA
                    </tspan>
                  </text>
                </g>
                <g id="kagithane" transform="translate(220.000000, 143.000000)">
                  <path
                    d="M11.5384615,5.3991684 L1.7047817,0.929313929 L0.810810811,4.50519751 L4.38669439,8.08108108 L0.810810811,16.1268191 L2.5987526,17.9147609 L6.17463617,13.4449064 L8.85654886,11.6569647 L11.5384615,11.6569647 L10.6444906,9.86902287 L10.6444906,8.08108108 L11.5384615,5.3991684 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kağıthane"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="kartal" transform="translate(259.432432, 191.958420)">
                  <path
                    d="M27.5322245,5.60914761 L25.7442827,0.245322245 L18.5925156,0.245322245 L15.9106029,8.29106029 L0.713097713,11.8669439 L15.9106029,22.5945946 L27.5322245,5.60914761 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kartal"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.5467775" y="16.1850312">
                      KA
                    </tspan>
                  </text>
                </g>
                <g
                  id="kucukcekmece"
                  transform="translate(174.000000, 158.000000)"
                >
                  <path
                    d="M15.5218295,1.12681913 L1.21829522,0.232848233 L0.324324324,3.80873181 L3.00623701,7.38461538 L3.00623701,15.4303534 L6.58212058,15.4303534 L7.47609148,17.2182952 L4.79417879,19.006237 L5.68814969,20.7941788 L6.58212058,21.6881497 L3.9002079,23.4760915 L3.9002079,24.3700624 L7.47609148,25.2640333 L7.47609148,22.5821206 L12.8399168,21.6881497 L12.8399168,19.006237 L14.6278586,19.006237 L17.3097713,20.7941788 L14.6278586,14.5363825 L14.6278586,10.960499 L14.6278586,2.91476091 L15.5218295,1.12681913 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Küçükçekmece"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="maltepe" transform="translate(248.432432, 183.958420)">
                  <path
                    d="M0.985446985,8.24532225 L11.7130977,19.8669439 L26.9106029,16.2910603 L29.5925156,8.24532225 L28.6985447,1.09355509 L17.970894,0.1995842 L7.24324324,5.56340956 L0.985446985,8.24532225 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Maltepe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.92515593" y="14.3513514">
                      MLT
                    </tspan>
                  </text>
                </g>
                <g id="pendik" transform="translate(274.432432, 165.958420)">
                  <path
                    d="M57.2307692,21.7754678 L55.4428274,19.0935551 L45.6091476,20.8814969 L31.3056133,32.5031185 L14.3201663,56.6403326 L0.910602911,48.5945946 L12.5322245,31.6091476 L24.1538462,28.033264 L20.5779626,10.1538462 L34.8814969,2.10810811 L39.3513514,1.21413721 L52.7609148,7.47193347 L53.6548857,11.9417879 L69.7463617,0.32016632 L81.3679834,11.9417879 L66.1704782,13.7297297 L57.2307692,21.7754678 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pendik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.3056133" y="19.8357588">
                      PEN
                    </tspan>
                  </text>
                </g>
                <g
                  id="sancaktepe"
                  transform="translate(273.432432, 161.958420)"
                >
                  <path
                    d="M35.8814969,6.10810811 L34.0935551,1.63825364 L20.6839917,0.744282744 L0.122661123,12.3659044 L0.122661123,18.6237006 L3.6985447,23.0935551 L4.59251559,30.2453222 L11.7442827,30.2453222 L15.3201663,15.047817 L21.5779626,14.1538462 L35.8814969,6.10810811 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sancaktepe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="9.95634096" y="13.1081081">
                      SCK
                    </tspan>
                  </text>
                </g>
                <g id="sariyer" transform="translate(218.000000, 100.000000)">
                  <path
                    d="M1.91683992,0.124740125 L18.9022869,6.38253638 L22.4781705,2.80665281 L38.5696466,9.06444906 L38.5696466,15.3222453 L29.6299376,25.1559252 L21.5841996,31.4137214 L28.7359667,40.3534304 L25.1600832,49.2931393 L26.0540541,53.7629938 L22.4781705,51.975052 L16.2203742,46.6112266 L18.9022869,44.8232848 L18.9022869,36.7775468 L17.1143451,34.0956341 L8.17463617,34.0956341 L0.128898129,10.8523909 L1.91683992,0.124740125 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıyer"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.6444906" y="21.4282744">
                      SRY
                    </tspan>
                  </text>
                </g>
                <g id="silivri" transform="translate(0.000000, 53.000000)">
                  <path
                    d="M8.04573805,96.2931393 L42.016632,90.9293139 L67.9417879,96.2931393 L97.4428274,107.914761 L98.3367983,98.0810811 L88.5031185,52.4885655 L77.7754678,41.7609148 L84.033264,31.033264 L67.047817,19.4116424 L36.6528067,0.638253638 L18.7733888,32.8212058 L0,75.7318087 L8.04573805,96.2931393 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Silivri"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.5467775" y="64.8523909">
                      SLV
                    </tspan>
                  </text>
                </g>
                <g
                  id="sultanbeyli"
                  transform="translate(284.432432, 175.958420)"
                >
                  <path
                    d="M10.5779626,0.153846154 L4.32016632,1.04781705 L0.744282744,16.2453222 L2.53222453,21.6091476 L14.1538462,18.033264 L10.5779626,0.153846154 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sultanbeyli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="2.53222453" y="16.987526">
                      SU
                    </tspan>
                  </text>
                </g>
                <g
                  id="sultangazi"
                  transform="translate(192.000000, 130.000000)"
                >
                  <path
                    d="M0.203742204,6.77754678 L5.56756757,13.035343 L6.46153846,15.7172557 L11.8253638,22.8690229 L15.4012474,20.1871102 L18.977131,20.1871102 L21.6590437,19.2931393 L23.4469854,13.035343 L9.14345114,0.51975052 L4.67359667,2.30769231 L2.88565489,7.67151767 L0.203742204,6.77754678 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sultangazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="sile" transform="translate(301.432432, 114.958420)">
                  <path
                    d="M128.567568,30.7588358 L87.4449064,20.031185 L49.004158,13.7733888 L2.51767152,0.363825364 L0.72972973,12.8794179 L9.66943867,26.2889813 L13.2453222,28.0769231 L17.7151767,38.8045738 L12.3513514,52.2141372 L24.8669439,58.4719335 L26.6548857,63.8357588 L35.5945946,58.4719335 L42.7463617,51.3201663 L54.3679834,62.9417879 L72.2474012,73.6694387 L86.5509356,68.3056133 L103.536383,58.4719335 L118.733888,54.002079 L128.567568,30.7588358 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şile"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48.1101871" y="35.970894">
                      ŞLE
                    </tspan>
                  </text>
                </g>
                <g id="sisli" transform="translate(221.000000, 134.000000)">
                  <path
                    d="M1.5987526,26.9147609 L7.85654886,31.3846154 L11.4324324,23.3388773 L13.2203742,24.2328482 L14.1143451,21.5509356 L11.4324324,17.0810811 L13.2203742,12.6112266 L15.9022869,10.8232848 L15.9022869,2.77754678 L14.1143451,0.0956340956 L5.17463617,0.0956340956 L1.5987526,7.24740125 L0.704781705,9.92931393 L10.5384615,14.3991684 L9.64449064,17.0810811 L9.64449064,18.8690229 L10.5384615,20.6569647 L7.85654886,20.6569647 L5.17463617,22.4449064 L1.5987526,26.9147609 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şişli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="3.38669439" y="9.77754678">
                      ŞŞL
                    </tspan>
                  </text>
                </g>
                <g id="tuzla" transform="translate(285.432432, 184.958420)">
                  <path
                    d="M46.2307692,2.77546778 L30.1392931,27.8066528 L19.4116424,48.3679834 L15.8357588,51.049896 L10.4719335,48.3679834 L0.638253638,50.1559252 L5.10810811,42.1101871 L3.32016632,37.6403326 L20.3056133,13.5031185 L34.6091476,1.88149688 L44.4428274,0.0935550936 L46.2307692,2.77546778 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tuzla"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.9417879" y="29.4428274">
                      TUZ
                    </tspan>
                  </text>
                </g>
                <g id="umraniye" transform="translate(248.432432, 158.958420)">
                  <path
                    d="M0.985446985,0.168399168 L1.87941788,15.3659044 L8.13721414,19.8357588 L25.1226611,21.6237006 L25.1226611,15.3659044 L17.0769231,8.21413721 L13.5010395,8.21413721 L13.5010395,5.53222453 L0.985446985,0.168399168 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ümraniye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="4.56133056" y="17.002079">
                      ÜMR
                    </tspan>
                  </text>
                </g>
                <g id="uskudar" transform="translate(232.432432, 153.958420)">
                  <path
                    d="M13.4095634,0.698544699 L11.6216216,2.48648649 L10.7276507,9.63825364 L0,15.8960499 L1.78794179,23.9417879 L7.15176715,20.3659044 L8.93970894,20.3659044 L8.93970894,23.047817 L15.1975052,24.8357588 L14.3035343,22.1538462 L17.8794179,20.3659044 L16.985447,5.16839917 L13.4095634,0.698544699 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Üsküdar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6.25779626" y="18.4261954">
                      ÜS
                    </tspan>
                  </text>
                </g>
                <g
                  id="zeytinburnu"
                  transform="translate(206.000000, 168.000000)"
                >
                  <path
                    d="M8.55301455,0.96049896 L7.65904366,1.85446985 L5.87110187,0.0665280665 L3.18918919,0.96049896 L3.18918919,3.64241164 L2.2952183,7.21829522 L0.507276507,14.3700624 L3.18918919,15.2640333 L7.65904366,12.5821206 L7.65904366,8.11226611 L9.44698545,1.85446985 L8.55301455,0.96049896 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Zeytinburnu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
