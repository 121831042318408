import React, { useEffect } from "react";
import "../turkeymap.css";

export const Adana = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            console.log(event.target.parentElement.id);
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(86.000000, 3.000000)">
                <g id="aladag" transform="translate(45.797101, 121.739130)">
                  <path
                    d="M16.8115942,8.11594203 L26.0869565,0 L78.8405797,5.2173913 L82.8985507,12.173913 L78.2608696,15.6521739 L78.8405797,23.1884058 L71.884058,25.5072464 L68.9855072,37.6811594 L70.1449275,41.1594203 L73.0434783,40 L73.6231884,30.7246377 L80,25.5072464 L80.5797101,30.7246377 L75.942029,38.2608696 L73.0434783,48.6956522 L70.1449275,48.6956522 L64.9275362,56.8115942 L60.8695652,60.8695652 L62.0289855,67.2463768 L64.3478261,71.884058 L67.2463768,79.4202899 L64.9275362,83.4782609 L59.7101449,87.5362319 L33.6231884,56.2318841 L18.5507246,57.9710145 L0,52.7536232 L16.8115942,8.11594203 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aladağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.2608696" y="38.4637681">
                      ALD
                    </tspan>
                  </text>
                </g>
                <g id="ceyhan" transform="translate(114.782609, 219.710145)">
                  <path
                    d="M60.2898551,4.63768116 L48.6956522,0 L33.6231884,4.05797101 L12.173913,3.47826087 L13.3333333,15.6521739 L3.47826087,16.8115942 L7.53623188,23.1884058 L19.7101449,27.2463768 L24.3478261,53.9130435 L26.6666667,78.2608696 L13.3333333,86.9565217 L4.05797101,85.7971014 L0,92.173913 L9.85507246,95.0724638 L32.4637681,80.5797101 L34.2028986,73.0434783 L48.115942,73.6231884 L74.7826087,59.7101449 L87.5362319,43.4782609 L89.2753623,15.0724638 L80,9.27536232 L60.8695652,15.6521739 L55.6521739,15.0724638 L60.2898551,4.63768116 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ceyhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.9565217" y="40.7826087">
                      CYH
                    </tspan>
                  </text>
                </g>
                <g id="cukurova" transform="translate(36.521739, 230.144928)">
                  <path
                    d="M40,0 L15.6521739,11.0144928 L7.53623188,6.95652174 L0,26.6666667 L18.5507246,31.884058 L30.1449275,28.4057971 L47.5362319,34.2028986 L46.3768116,28.4057971 L36.5217391,27.826087 L28.4057971,21.4492754 L31.3043478,15.0724638 L38.2608696,22.6086957 L43.4782609,18.5507246 L38.2608696,12.7536232 L36.5217391,4.63768116 L40,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çukurova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.7536232" y="23.9710145">
                      ÇKR
                    </tspan>
                  </text>
                </g>
                <g id="feke" transform="translate(124.637681, 69.565217)">
                  <path
                    d="M0,57.3913043 L1.15942029,19.7101449 L6.95652174,11.5942029 L30.7246377,0 L60.8695652,17.3913043 L82.8985507,48.6956522 L71.3043478,61.4492754 L50.4347826,63.7681159 L30.7246377,60.8695652 L13.9130435,66.6666667 L4.05797101,64.3478261 L0,57.3913043 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Feke"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.1449275" y="37.884058">
                      FEK
                    </tspan>
                  </text>
                </g>
                <g id="imamoglu" transform="translate(100.869565, 183.188406)">
                  <path
                    d="M10.4347826,0 L11.0144928,4.63768116 L6.95652174,5.79710145 L8.69565217,9.85507246 L14.4927536,11.0144928 L15.0724638,17.3913043 L13.9130435,24.9275362 L7.53623188,26.6666667 L4.05797101,32.4637681 L0,37.1014493 L3.47826087,40 L16.8115942,35.942029 L26.6666667,40 L47.5362319,41.1594203 L62.6086957,36.5217391 L49.8550725,19.7101449 L32.4637681,22.6086957 L23.7681159,3.47826087 L10.4347826,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İmamoğlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.5652174" y="33.826087">
                      İMM
                    </tspan>
                  </text>
                </g>
                <g id="karaisali" transform="translate(26.666667, 174.492754)">
                  <path
                    d="M9.85507246,82.3188406 L4.05797101,84.6376812 L0.579710145,62.0289855 L9.27536232,47.5362319 L0,37.1014493 L9.85507246,20.2898551 L8.69565217,2.89855072 L19.1304348,0 L38.2608696,5.2173913 L52.7536232,3.47826087 L78.8405797,34.7826087 L73.0434783,38.8405797 L73.6231884,42.3188406 L70.1449275,42.8985507 L68.4057971,37.6811594 L63.7681159,40.5797101 L60.8695652,43.4782609 L57.3913043,41.1594203 L56.2318841,47.5362319 L53.9130435,47.5362319 L53.3333333,49.8550725 L49.8550725,46.9565217 L50.4347826,37.6811594 L47.5362319,34.7826087 L45.7971014,39.4202899 L47.5362319,45.7971014 L49.8550725,52.173913 L53.3333333,53.9130435 L49.8550725,55.6521739 L25.5072464,66.6666667 L17.3913043,62.6086957 L9.85507246,82.3188406 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karaisalı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.5652174" y="32.6666667">
                      KRİ
                    </tspan>
                  </text>
                </g>
                <g id="karatas" transform="translate(21.449275, 309.565217)">
                  <path
                    d="M93.3333333,2.31884058 L55.6521739,8.69565217 L42.8985507,1.15942029 L39.4202899,0 L2.89855072,11.5942029 L0,17.9710145 L10.4347826,16.2318841 L33.0434783,27.2463768 L66.6666667,46.3768116 L68.4057971,41.7391304 L48.6956522,30.1449275 L48.115942,24.3478261 L71.884058,35.942029 L70.7246377,49.8550725 L75.3623188,49.2753623 L84.057971,42.3188406 L93.9130435,42.8985507 L93.3333333,2.31884058 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karataş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="70.1449275" y="26.2898551">
                      KRT
                    </tspan>
                  </text>
                </g>
                <g id="kozan" transform="translate(111.304348, 118.260870)">
                  <path
                    d="M126.376812,1.15942029 L96.2318841,0 L85.2173913,12.173913 L63.1884058,15.0724638 L44.057971,12.173913 L27.2463768,17.9710145 L17.3913043,15.6521739 L12.7536232,19.1304348 L16.2318841,25.5072464 L18.5507246,20.2898551 L22.6086957,19.1304348 L20.2898551,28.4057971 L14.4927536,28.9855072 L15.0724638,34.2028986 L10.4347826,41.7391304 L7.53623188,52.173913 L7.53623188,56.8115942 L5.79710145,61.4492754 L0,63.1884058 L0,64.9275362 L13.3333333,68.4057971 L21.4492754,87.5362319 L39.4202899,84.6376812 L51.0144928,100.869565 L63.7681159,106.086957 L66.0869565,91.0144928 L62.6086957,80.5797101 L70.7246377,73.6231884 L75.3623188,44.6376812 L96.8115942,12.173913 L125.797101,6.37681159 L126.376812,1.15942029 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kozan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="44.6376812" y="51.7971014">
                      KOZ
                    </tspan>
                  </text>
                </g>
                <g id="pozanti" transform="translate(0.000000, 129.855072)">
                  <path
                    d="M63.7681159,0 L42.3188406,10.4347826 L20.8695652,10.4347826 L20.8695652,41.1594203 L0,62.0289855 L8.11594203,81.1594203 L27.826087,81.7391304 L37.6811594,65.5072464 L36.5217391,47.5362319 L46.9565217,44.6376812 L63.7681159,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pozantı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.7246377" y="34.9855072">
                      POZ
                    </tspan>
                  </text>
                </g>
                <g id="saimbeyli" transform="translate(155.362319, 45.797101)">
                  <path
                    d="M0,23.7681159 L23.7681159,13.3333333 L31.884058,0 L46.3768116,17.9710145 L66.0869565,13.3333333 L89.8550725,17.3913043 L90.4347826,31.3043478 L82.8985507,45.7971014 L88.115942,53.3333333 L82.3188406,73.6231884 L52.173913,72.4637681 L30.1449275,41.1594203 L0,23.7681159 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Saimbeyli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54.4927536" y="40.7826087">
                      SBY
                    </tspan>
                  </text>
                </g>
                <g id="saricam" transform="translate(76.521739, 219.130435)">
                  <path
                    d="M24.3478261,1.15942029 L21.4492754,4.05797101 L16.2318841,1.15942029 L11.5942029,5.79710145 L3.47826087,9.27536232 L0,11.0144928 L2.89855072,22.0289855 L10.4347826,21.4492754 L9.85507246,26.6666667 L16.2318841,30.1449275 L16.8115942,33.6231884 L6.95652174,32.4637681 L6.95652174,36.5217391 L11.5942029,36.5217391 L12.173913,44.6376812 L7.53623188,45.2173913 L13.3333333,53.9130435 L62.6086957,54.4927536 L57.9710145,27.826087 L45.7971014,23.1884058 L41.7391304,17.3913043 L51.5942029,16.2318841 L51.0144928,4.05797101 L41.1594203,0 L27.826087,4.05797101 L24.3478261,1.15942029 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarıçam"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.6666667" y="38.4637681">
                      SRI
                    </tspan>
                  </text>
                </g>
                <g id="seyhan" transform="translate(51.014493, 258.550725)">
                  <path
                    d="M4.05797101,3.47826087 L15.6521739,0 L33.0434783,5.79710145 L38.8405797,14.4927536 L38.8405797,32.4637681 L13.3333333,52.173913 L9.85507246,51.0144928 L13.9130435,42.3188406 L0,23.7681159 L4.05797101,3.47826087 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Seyhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.1304348" y="27.4492754">
                      SYH
                    </tspan>
                  </text>
                </g>
                <g id="tufanbeyli" transform="translate(187.246377, 0.000000)">
                  <path
                    d="M0,45.7971014 L8.11594203,28.9855072 L36.5217391,0 L59.7101449,10.4347826 L61.4492754,25.5072464 L68.9855072,30.7246377 L58.5507246,49.2753623 L57.9710145,63.1884058 L34.2028986,59.1304348 L14.4927536,63.7681159 L0,45.7971014 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tufanbeyli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.884058" y="38.4637681">
                      TFN
                    </tspan>
                  </text>
                </g>
                <g
                  id="yumurtalik"
                  transform="translate(114.782609, 279.420290)"
                >
                  <path
                    d="M74.7826087,0 L74.2028986,9.27536232 L48.6956522,37.6811594 L26.6666667,38.8405797 L11.5942029,48.6956522 L12.173913,51.0144928 L24.3478261,45.7971014 L36.5217391,46.9565217 L37.1014493,51.5942029 L30.7246377,52.7536232 L23.7681159,71.3043478 L16.8115942,73.6231884 L26.0869565,51.0144928 L15.6521739,60.2898551 L13.3333333,73.6231884 L0.579710145,73.0434783 L0,32.4637681 L9.85507246,35.3623188 L32.4637681,20.8695652 L34.2028986,13.3333333 L48.115942,13.9130435 L74.7826087,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yumurtalık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.4202899" y="30.3478261">
                      YMR
                    </tspan>
                  </text>
                </g>
                <g id="yuregir" transform="translate(64.347826, 272.463768)">
                  <path
                    d="M50.4347826,39.4202899 L12.7536232,45.7971014 L0,38.2608696 L25.5072464,18.5507246 L25.5072464,0 L74.7826087,1.15942029 L77.1014493,25.5072464 L63.7681159,34.2028986 L54.4927536,33.0434783 L50.4347826,39.4202899 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yüreğir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.2173913" y="21.0724638">
                      YÜR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
