import React from "react";

import Card from "@mui/material/Card";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";

export const Announcement = ({ item }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card
        sx={{ m: 1, height: 110, boxShadow: 4, cursor: "pointer" }}
        onClick={() => {
          navigate("/duyurular", { state: item });
        }}
      >
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              item.title.length > 65
                ? item.title.slice(0, 65) + "..."
                : item.title
            }
            secondary={
              <>
                {item.provienceCity.il} - {moment(item.updatedAt).format("LL")}
              </>
            }
          />
        </ListItem>
      </Card>
      <Divider />
    </>
  );
};
