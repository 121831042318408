import React, { useEffect } from "react";
import "../turkeymap.css";

export const Edirne = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(112.000000, 3.000000)">
                <g id="enez" transform="translate(0.000000, 300.000000)">
                  <path
                    d="M56.1038961,10.1298701 L36.6233766,0.779220779 L21.038961,0 L14.025974,13.2467532 L0,15.5844156 L2.33766234,42.0779221 L14.8051948,52.987013 L30.3896104,51.4285714 L50.6493506,56.1038961 L57.6623377,52.2077922 L60.7792208,32.7272727 L45.974026,27.2727273 L56.1038961,10.1298701 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Enez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.5844156" y="31.9350649">
                      ENZ
                    </tspan>
                  </text>
                </g>
                <g id="havsa" transform="translate(142.000000, 69.000000)">
                  <path
                    d="M24.7532468,0.350649351 L46.5714286,7.36363636 L49.6883117,25.2857143 L65.2727273,33.8571429 L46.5714286,69.7012987 L38.7792208,83.7272727 L21.6363636,75.1558442 L8.38961039,76.7142857 L0.597402597,56.4545455 L18.5194805,6.58441558 L24.7532468,0.350649351 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Havsa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.3116883" y="47.8701299">
                      HAV
                    </tspan>
                  </text>
                </g>
                <g id="ipsala" transform="translate(21.000000, 229.000000)">
                  <path
                    d="M100.558442,15.6753247 L98.2207792,10.2207792 L82.6363636,1.64935065 L66.2727273,0.0909090909 L45.2337662,12.5584416 L42.1168831,25.025974 L43.6753247,35.1558442 L33.5454545,38.2727273 L33.5454545,46.0649351 L14.0649351,59.3116883 L0.038961039,71 L15.6233766,71.7792208 L35.1038961,81.1298701 L63.9350649,67.1038961 L100.558442,15.6753247 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İpsala"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.2467532" y="43.7142857">
                      İPS
                    </tspan>
                  </text>
                </g>
                <g id="kesan" transform="translate(45.000000, 244.000000)">
                  <path
                    d="M89.025974,1.45454545 L76.5584416,0.675324675 L39.9350649,52.1038961 L11.1038961,66.1298701 L0.974025974,83.2727273 L15.7792208,88.7272727 L12.6623377,108.207792 L36.038961,108.207792 L45.3896104,116 L64.8701299,105.87013 L92.9220779,103.532468 L103.831169,80.9350649 L92.1428571,55.2207792 L98.3766234,7.68831169 L89.025974,1.45454545 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Keşan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.4155844" y="68.4545455">
                      KEŞ
                    </tspan>
                  </text>
                </g>
                <g id="lalapasa" transform="translate(128.000000, 0.000000)">
                  <path
                    d="M82.3896104,0 L82.3896104,23.3766234 L30.961039,53.7662338 L0.571428571,36.6233766 L6.02597403,11.6883117 L22.3896104,7.01298701 L82.3896104,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Lalapasa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.961039" y="26.4805195">
                      LAL
                    </tspan>
                  </text>
                </g>
                <g id="meric" transform="translate(66.000000, 166.000000)">
                  <path
                    d="M43.8701299,0.753246753 L3.35064935,19.4545455 L0.233766234,75.5584416 L21.2727273,63.0909091 L47.7662338,27.2467532 L43.8701299,0.753246753 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Meriç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.2597403" y="37.3636364">
                      MRÇ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(81.000000, 36.000000)">
                  <path
                    d="M47.5714286,0.623376623 L77.961039,17.7662338 L86.5324675,24.7792208 L85.7532468,33.3506494 L79.5194805,39.5844156 L61.5974026,89.4545455 L69.3896104,109.714286 L49.1298701,105.038961 L49.9090909,71.5324675 L32.7662338,56.7272727 L26.5324675,41.9220779 L0.038961039,28.6753247 L9.38961039,6.07792208 L31.987013,8.41558442 L47.5714286,0.623376623 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.6753247" y="41.1298701">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="suloglu" transform="translate(158.000000, 23.000000)">
                  <path
                    d="M52.3896104,0.376623377 L54.7272727,37 L43.038961,62.7142857 L33.6883117,71.2857143 L30.5714286,53.3636364 L8.75324675,46.3506494 L9.53246753,37.7792208 L0.961038961,30.7662338 L52.3896104,0.376623377 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Süloğlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.8961039" y="36.2077922">
                      SÜL
                    </tspan>
                  </text>
                </g>
                <g id="uzunkopru" transform="translate(87.000000, 141.000000)">
                  <path
                    d="M93.7792208,11.7272727 L76.6363636,3.15584416 L63.3896104,4.71428571 L43.1298701,0.038961039 L44.6883117,21.8571429 L33.7792208,28.8701299 L22.8701299,25.7532468 L26.7662338,52.2467532 L0.272727273,88.0909091 L16.6363636,89.6493506 L32.2207792,98.2207792 L34.5584416,103.675325 L47.025974,104.454545 L53.2597403,99 L82.8701299,88.0909091 L99.2337662,71.7272727 L103.909091,55.3636364 L95.3376623,31.987013 L93.7792208,11.7272727 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Uzunköprü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.4805195" y="53.012987">
                      UZK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
