import React, { useEffect } from "react";
import "../turkeymap.css";

export const Gaziantep = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 44.000000)">
                <g id="araban" transform="translate(281.000000, 0.000000)">
                  <path
                    d="M0,56 L23,9 L51,0 L126,24 L98,60 L94,74 L30,52 L0,56 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Araban"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58" y="36">
                      ARB
                    </tspan>
                  </text>
                </g>
                <g id="islahiye" transform="translate(0.000000, 136.000000)">
                  <path
                    d="M39,0 L6,40 L0,73 L16,96 L56,112 L80,86 L119,65 L119,38 L77,48 L39,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İslahiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="63">
                      İSL
                    </tspan>
                  </text>
                </g>
                <g id="karkamis" transform="translate(339.000000, 199.000000)">
                  <path
                    d="M0,45 L2,66 L26,64 L87,26 L84,19 L78,19 L75,13 L76,4 L72,0 L39,5 L29,21 L0,45 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karkamış"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37" y="31">
                      KRK
                    </tspan>
                  </text>
                </g>
                <g id="nizip" transform="translate(298.000000, 93.000000)">
                  <path
                    d="M0,16 L18,51 L12,92 L41,151 L70,127 L80,112 L113,106 L103,85 L105,83 L82,69 L75,67 L69,59 L70,45 L63,33 L62,24 L66,22 L67,24 L71,15 L67,0 L0,16 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nizip"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="74">
                      NZP
                    </tspan>
                  </text>
                </g>
                <g id="nurdagi" transform="translate(39.000000, 74.000000)">
                  <path
                    d="M77,2.27373675e-13 L53,30 L21,43 L0,62 L38,110 L80,100 L101,95 L116,73 L109,46 L95,27 L90,4 L77,2.27373675e-13 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nurdağı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51" y="68">
                      NRD
                    </tspan>
                  </text>
                </g>
                <g id="oguzeli" transform="translate(249.000000, 174.000000)">
                  <path
                    d="M61,11 L33,0 L7,29 L0,78 L55,104 L79,91 L92,91 L90,70 L61,11 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Oğuzeli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33" y="61">
                      OĞZ
                    </tspan>
                  </text>
                </g>
                <g id="sahinbey" transform="translate(140.000000, 140.000000)">
                  <path
                    d="M109,112 L102,133 L80,100 L32,76 L15,63 L17,47 L0,38 L0,29 L15,7 L37,8 L56,0 L62,14 L92,23 L111,18 L142,34 L116,63 L109,112 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şahinbey"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58" y="50">
                      ŞHB
                    </tspan>
                  </text>
                </g>
                <g id="sehitkamil" transform="translate(148.000000, 59.000000)">
                  <path
                    d="M0,61 L20,63 L112,0 L150,50 L168,85 L162,126 L134,115 L103,99 L84,104 L54,95 L48,81 L29,89 L7,88 L0,61 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şehitkamil"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="72" y="61">
                      ŞHK
                    </tspan>
                  </text>
                </g>
                <g id="yavuzeli" transform="translate(260.000000, 52.000000)">
                  <path
                    d="M0,7 L21,4 L51,0 L115,22 L105,41 L38,57 L0,7 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yavuzeli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48" y="29">
                      YVZ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
