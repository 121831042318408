import React, { useEffect } from "react";
import "../turkeymap.css";

export const Canakkale = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(10.000000, 3.000000)">
                <g id="ayvacik" transform="translate(69.000000, 297.000000)">
                  <path
                    d="M146.825243,7.95145631 L89.1553398,5.33009709 L75.1747573,7.95145631 L63.815534,0.961165049 L61.1941748,5.33009709 L26.2427184,9.69902913 L0.902912621,52.5145631 L26.2427184,63 L72.5533981,59.5048544 L130.223301,47.2718447 L146.825243,7.95145631 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ayvacık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="70.8058252" y="38.5436893">
                      AYC
                    </tspan>
                  </text>
                </g>
                <g id="bayramic" transform="translate(151.000000, 200.000000)">
                  <path
                    d="M127.737864,62.1359223 L119.873786,52.5242718 L98.0291262,46.407767 C98.0291262,46.407767 97.4666146,31.1830074 97.1553398,23.6893204 C96.0103039,15.7461142 93.6601942,0.0970873786 93.6601942,0.0970873786 L76.184466,13.2038835 L59.5825243,4.46601942 L55.2135922,17.5728155 L38.6116505,14.0776699 L25.5048544,27.184466 L11.5242718,34.1747573 L12.3980583,61.2621359 L0.165048544,89.223301 L7.15533981,102.330097 L64.8252427,104.951456 L78.8058252,96.2135922 L106.76699,95.3398058 L125.116505,83.1067961 L127.737864,62.1359223 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bayramiç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.2135922" y="62.1456311">
                      BMÇ
                    </tspan>
                  </text>
                </g>
                <g id="biga" transform="translate(270.000000, 86.000000)">
                  <path
                    d="M37.5728155,18.8543689 L57.6699029,3.12621359 L76.0194175,9.24271845 L96.9902913,0.504854369 L106.601942,18.8543689 L98.7378641,19.7281553 L105.728155,31.961165 L146.796117,53.8058252 L138.058252,71.2815534 L111.84466,100.116505 L73.3980583,118.466019 L26.2135922,85.2621359 L0.873786408,84.3883495 L28.8349515,45.0679612 L37.5728155,18.8543689 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Biga"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69.0291262" y="59.0582524">
                      BGA
                    </tspan>
                  </text>
                </g>
                <g id="bozcaada" transform="translate(55.000000, 243.000000)">
                  <path
                    d="M21.8932039,0.786407767 L0.922330097,1.66019417 L1.7961165,6.02912621 L20.1456311,20.0097087 L28.0097087,17.3883495 L21.8932039,0.786407767 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozcaada"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8.78640777" y="10.407767">
                      BOZ
                    </tspan>
                  </text>
                </g>
                <g id="can" transform="translate(244.000000, 170.000000)">
                  <path
                    d="M26.8737864,0.388349515 L6.77669903,9.12621359 L0.660194175,30.0970874 L4.15533981,53.6893204 L5.02912621,76.407767 L26.8737864,82.5242718 L34.7378641,92.1359223 L67.9417476,77.2815534 L79.3009709,53.6893204 L99.3980583,34.4660194 L52.2135922,1.26213592 L26.8737864,0.388349515 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Can"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.7378641" y="45.8349515">
                      ÇAN
                    </tspan>
                  </text>
                </g>
                <g id="eceabat" transform="translate(105.000000, 84.000000)">
                  <path
                    d="M67.1359223,0.757281553 L19.9514563,26.0970874 L23.4466019,38.3300971 L32.184466,47.9417476 L0.72815534,100.368932 L15.5825243,103.864078 L51.407767,82.0194175 L47.9126214,64.5436893 L73.2524272,52.3106796 L61.0194175,16.4854369 L67.1359223,0.757281553 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eceabat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="41.7961165" y="46.2038835">
                      ECE
                    </tspan>
                  </text>
                </g>
                <g id="ezine" transform="translate(95.000000, 226.000000)">
                  <path
                    d="M67.5242718,8.17475728 L7.23300971,0.310679612 L0.242718447,80.6990291 L35.1941748,76.3300971 L37.815534,71.961165 L49.1747573,78.9514563 L63.1553398,76.3300971 L56.1650485,63.223301 L68.3980583,35.2621359 L67.5242718,8.17475728 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ezine"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.4563107" y="44.0097087">
                      EZN
                    </tspan>
                  </text>
                </g>
                <g id="gelibolu" transform="translate(166.000000, 0.000000)">
                  <path
                    d="M143.320388,3.49514563 L100.504854,0 L88.2718447,25.3398058 L103.126214,44.5631068 L91.7669903,54.1747573 L80.407767,50.6796117 L66.4271845,63.7864078 L6.13592233,84.7572816 L0.0194174757,100.485437 L12.2524272,136.31068 L50.6990291,109.223301 L53.3203883,99.6116505 L69.9223301,90.8737864 L69.0485437,82.1359223 L138.07767,55.0485437 L127.592233,41.0679612 L143.320388,19.223301 L143.320388,3.49514563 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gelibolu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.9805825" y="97">
                      GEL
                    </tspan>
                  </text>
                </g>
                <g id="gokceada" transform="translate(0.000000, 129.000000)">
                  <path
                    d="M69.9029126,9.93203883 L64.6601942,23.9126214 L77.7669903,25.6601942 L69.0291262,34.3980583 L16.6019417,38.7669903 L0,29.1553398 L0,19.5436893 L28.8349515,4.68932039 L62.9126214,0.32038835 L69.9029126,9.93203883 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gökçeada"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.7087379" y="23.9223301">
                      GÖK
                    </tspan>
                  </text>
                </g>
                <g id="lapseki" transform="translate(192.000000, 96.000000)">
                  <path
                    d="M115.572816,8.85436893 L106.834951,35.0679612 L78.8737864,74.3883495 L58.776699,83.1262136 L17.7087379,77.0097087 L8.09708738,59.5339806 L0.233009709,44.6796117 L11.592233,31.5728155 L50.9126214,0.116504854 L91.1067961,3.61165049 L115.572816,8.85436893 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Lapseki"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.6601942" y="38.5728155">
                      LAP
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(102.000000, 140.000000)">
                  <path
                    d="M148.776699,39.1262136 L107.708738,33.0097087 L90.2330097,0.67961165 L82.368932,9.41747573 L61.3980583,12.038835 L58.776699,33.0097087 L49.1650485,39.1262136 L38.6796117,58.3495146 L20.3300971,63.592233 L8.97087379,60.0970874 L0.233009709,86.3106796 L60.5242718,94.1747573 L74.5048544,87.184466 L87.6116505,74.0776699 L104.213592,77.5728155 L108.582524,64.4660194 L125.184466,73.2038835 L142.660194,60.0970874 L148.776699,39.1262136 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="65.7669903" y="54.8640777">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="yenice" transform="translate(276.000000, 186.000000)">
                  <path
                    d="M105.84466,0.116504854 L67.3980583,18.4660194 L47.3009709,37.6893204 L35.9417476,61.2815534 L2.73786408,76.1359223 L0.116504854,97.1067961 L46.4271845,112.834951 L69.1456311,127.68932 L106.718447,111.087379 L131.184466,67.3980583 L123.320388,26.3300971 L105.84466,0.116504854 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenice"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="70.8932039" y="76.1456311">
                      YNC
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
