import React from "react";
import { Box, Divider, Grid, useTheme, useMediaQuery } from "@mui/material";
import { CityContent } from "../containers/city";
import { RegionBar } from "../components/RegionBar";
import { AdsHorizontalCity } from "../components/ads/ads-horizontal-city";
import { AdsVerticalCity } from "../components/ads/ads-vertical-city";

import { useParams } from "react-router-dom";
import Service from "../service";
import { Announcements } from "../components/announcements";
//import { SearchWithDate } from "../components/search-with-date";
import { SearchSide } from "../components/search";

const City = () => {
  const [ads, setAds] = React.useState([]);
  const [city, setCity] = React.useState();

  const { id } = useParams();

  React.useEffect(() => {
    Service.get("adress/cities").then((data) => {
      const city = data.find((city) => city.il === id);
      Service.get("selaDepo/getCity?id=" + city.id).then((result) => {
        setCity(city);
        setAds([...result]);
      });
    });
  }, [id]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ bgcolor: "white" }}>
      <Grid item lg={12} md={8} sm={12} xs={12}>
        <RegionBar city={id} />
      </Grid>
      <Divider />

      <Grid container sx={{ pt: 3 }}>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <SearchSide />
          {!isMobile && <Announcements city={city} />}
        </Grid>
        <Grid container lg={8} md={8} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {city && <CityContent city={city} />}
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
          >
            {ads.length > 0 && <AdsVerticalCity ads={ads} />}
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          lg={2}
          md={2}
          sm={2}
          xs={2}
        >
          {ads.length > 0 && !isMobile && !isTablet && (
            <AdsHorizontalCity ads={ads} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default City;
