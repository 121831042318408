import { CircularProgress } from "@mui/material";
import React from "react";

import List from "@mui/material/List";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Service from "../service";
import { Announcement } from "./announcement";

export const Announcements = () => {
  const [announcement, setAnnouncement] = React.useState([]);
  React.useEffect(() => {
    Service.getWithPage("announcements", 1).then((data) => {
      setAnnouncement([...data.docs]);
    });
  }, []);

  return (
    <Grid
      container
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid>
        <Typography variant="h6">Duyurular</Typography>
      </Grid>

      <List
        sx={{
          width: "100%",
          maxHeight: 520,
          overflowY: "scroll",

          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
          },
        }}
      >
        {announcement.length === 0 && <CircularProgress />}

        {announcement.length > 0 && (
          <>
            {announcement.map((item, index) => {
              return <Announcement item={item} key={index} />;
            })}
          </>
        )}
      </List>
    </Grid>
  );
};
