import React, { useEffect } from "react";
import "../turkeymap.css";

export const Malatya = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(9.000000, 3.000000)">
                <g id="akcadag" transform="translate(87.476636, 181.682243)">
                  <path
                    d="M125.327103,7.57009346 L120.280374,8.41121495 L120.280374,2.52336449 L76.5420561,0 L24.3925234,10.0934579 L0,51.3084112 L27.7570093,90 L79.9065421,90 L101.775701,58.8785047 L105.140187,33.6448598 L125.327103,7.57009346 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akçadağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.5140187" y="48.8971963">
                      AKÇ
                    </tspan>
                  </text>
                </g>
                <g id="arapgir" transform="translate(202.710280, 0.000000)">
                  <path
                    d="M2.84217094e-14,15.1401869 L16.8224299,0 L30.2803738,2.52336449 L42.8971963,8.41121495 L76.5420561,4.20560748 L100.093458,26.9158879 L94.2056075,26.9158879 L102.616822,37.0093458 L90.8411215,47.9439252 L86.635514,42.8971963 L84.953271,44.5794393 L86.635514,49.6261682 L84.953271,57.1962617 L100.934579,83.271028 L104.299065,106.82243 L69.8130841,107.663551 L42.8971963,73.1775701 L43.7383178,58.0373832 L28.5981308,58.0373832 L26.0747664,25.2336449 L2.84217094e-14,15.1401869 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arapgir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.9906542" y="37.9626168">
                      ARP
                    </tspan>
                  </text>
                </g>
                <g id="arguvan" transform="translate(167.383178, 15.140187)">
                  <path
                    d="M0,5.04672897 L35.3271028,0 L61.4018692,10.0934579 L63.9252336,42.8971963 L79.0654206,42.8971963 L78.2242991,58.0373832 L105.140187,92.5233645 L101.775701,95.046729 L95.046729,95.8878505 L93.364486,108.504673 L72.3364486,138.785047 L26.0747664,121.121495 L40.3738318,95.8878505 L14.2990654,21.0280374 L0,5.04672897 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Arguvan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.1495327" y="74.1308411">
                      ARG
                    </tspan>
                  </text>
                </g>
                <g
                  id="battalgazi"
                  transform="translate(192.616822, 189.252336)"
                >
                  <path
                    d="M121.121495,16.8224299 L113.551402,12.6168224 L101.775701,16.8224299 L73.1775701,24.3925234 L47.1028037,23.5514019 L34.4859813,2.52336449 L20.1869159,0 L0,26.0747664 L60.5607477,74.8598131 L83.271028,86.635514 L142.990654,51.3084112 L121.121495,16.8224299 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Battalgazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="70.6542056" y="51.4205607">
                      BTL
                    </tspan>
                  </text>
                </g>
                <g
                  id="battalgazi"
                  transform="translate(227.102804, 190.093458)"
                >
                  <path
                    d="M0,1.62216288 L23.5514019,0 L36.1682243,8.11081442 L62.2429907,8.11081442 L67.2897196,15.4105474 L38.6915888,22.7102804 L12.6168224,21.8991989 L0,1.62216288 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Battalgazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.6635514" y="15.2523364">
                      BTL
                    </tspan>
                  </text>
                </g>
                <g id="darende" transform="translate(0.000000, 112.710280)">
                  <path
                    d="M87.4766355,120.280374 L0,85.7943925 L17.6635514,54.6728972 L67.2897196,0 L125.327103,15.9813084 L102.616822,50.4672897 L111.869159,79.0654206 L87.4766355,120.280374 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Darende"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.5140187" y="58.9906542">
                      DAR
                    </tspan>
                  </text>
                </g>
                <g id="dogansehir" transform="translate(92.523364, 271.682243)">
                  <path
                    d="M105.981308,21.0280374 L97.5700935,30.2803738 L111.869159,48.7850467 L111.028037,58.8785047 L103.457944,66.4485981 L35.3271028,88.317757 L0,80.7476636 L1.68224299,36.1682243 L22.7102804,0 L74.8598131,0 L105.981308,21.0280374 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Doğanşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.0560748" y="47.2149533">
                      DŞH
                    </tspan>
                  </text>
                </g>
                <g id="doganyol" transform="translate(359.158879, 222.056075)">
                  <path
                    d="M51.3084112,34.4859813 L58.0373832,27.7570093 L54.6728972,0.841121495 L42.8971963,0 L17.6635514,10.9345794 L0,24.3925234 L51.3084112,34.4859813 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Doğanyol"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.1214953" y="20.2990654">
                      DYL
                    </tspan>
                  </text>
                </g>
                <g id="hekimhan" transform="translate(102.616822, 20.186916)">
                  <path
                    d="M0.841121495,36.1682243 L14.2990654,4.20560748 L41.2149533,3.36448598 L64.7663551,0 L79.0654206,15.9813084 L105.140187,90.8411215 L90.8411215,116.074766 L62.2429907,142.990654 L61.4018692,161.495327 L9.25233645,171.588785 L0,142.990654 L22.7102804,108.504673 L0.841121495,36.1682243 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hekimhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54.6728972" y="99.364486">
                      HKM
                    </tspan>
                  </text>
                </g>
                <g id="kale" transform="translate(313.738318, 202.710280)">
                  <path
                    d="M42.0560748,10.0934579 L30.2803738,0 L14.2990654,6.72897196 L0,3.36448598 L21.8691589,37.8504673 L42.0560748,10.0934579 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kale"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.6635514" y="18.6168224">
                      KAL
                    </tspan>
                  </text>
                </g>
                <g id="kuluncak" transform="translate(51.308411, 41.214953)">
                  <path
                    d="M74.0186916,87.4766355 C74.0186904,87.4766341 52.1495327,15.1401869 52.1495327,15.1401869 L0,0 L0,15.9813084 L15.9813084,71.4953271 C15.9813084,71.4953271 74.0186928,87.4766369 74.0186916,87.4766355 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kuluncak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.7570093" y="51.4205607">
                      KUL
                    </tspan>
                  </text>
                </g>
                <g id="puturge" transform="translate(275.887850, 212.803738)">
                  <path
                    d="M140.46729,52.1495327 L134.579439,43.7383178 L83.271028,33.6448598 L79.0654206,31.1214953 L95.8878505,19.3457944 L79.9065421,0 L59.7196262,27.7570093 L0,63.0841121 L63.9252336,91.682243 L74.0186916,101.775701 L123.64486,79.0654206 L140.46729,52.1495327 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pütürge"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="63.0841121" y="67.4018692">
                      PTR
                    </tspan>
                  </text>
                </g>
                <g id="yazihan" transform="translate(164.018692, 136.261682)">
                  <path
                    d="M0,45.4205607 L0.841121495,26.9158879 L29.4392523,0 L75.7009346,17.6635514 L73.1775701,26.0747664 L62.2429907,28.5981308 L73.1775701,33.6448598 L84.1121495,45.4205607 L74.8598131,44.5794393 L74.8598131,48.7850467 L43.7383178,47.9439252 L0,45.4205607 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yazıhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.2803738" y="28.7102804">
                      YZH
                    </tspan>
                  </text>
                </g>
                <g id="yesilyurt" transform="translate(167.383178, 215.327103)">
                  <path
                    d="M25.2336449,0 L21.8691589,25.2336449 L0,56.3551402 L31.1214953,77.3831776 L85.7943925,48.7850467 L25.2336449,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yeşilyurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.9158879" y="51.4205607">
                      YŞL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
