import React, { useEffect } from "react";
import "../turkeymap.css";

export const Antalya = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 77.000000)">
                <g id="akseki" transform="translate(306.197802, 8.505495)">
                  <path
                    d="M4.72527473,4.72527473 L10.8681319,0 L17.010989,0.472527473 L32.6043956,20.3186813 L42.0549451,39.6923077 L54.8131868,52.9230769 L49.1428571,56.7032967 L52.4505495,66.6263736 L16.0659341,93.5604396 L0,75.1318681 L17.010989,53.3956044 L17.010989,31.1868132 L8.97802198,22.6813187 L4.72527473,4.72527473 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akseki"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.989011" y="62.7032967">
                      ASK
                    </tspan>
                  </text>
                </g>
                <g id="aksu" transform="translate(193.263736, 23.626374)">
                  <path
                    d="M1.41758242,0.945054945 L10.8681319,0 L12.7582418,16.0659341 L22.6813187,26.4615385 L23.6263736,43 L19.3736264,52.4505495 L22.6813187,57.1758242 L23.1538462,62.3736264 L16.5384615,62.8461538 L14.1758242,59.0659341 L8.97802198,57.1758242 L10.8681319,51.5054945 L7.08791209,49.1428571 L3.78021978,43.4725275 L10.8681319,40.1648352 L6.14285714,35.9120879 L5.67032967,27.4065934 L0,19.3736264 L4.25274725,14.6483516 L1.89010989,7.08791209 L1.41758242,0.945054945 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aksu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.2857143" y="39.5494505">
                      AKS
                    </tspan>
                  </text>
                </g>
                <g id="alanya" transform="translate(308.560440, 84.582418)">
                  <path
                    d="M83.6373626,0 L89.3076923,8.97802198 L103.483516,24.0989011 L109.153846,51.032967 L95.4505495,62.3736264 L76.0769231,92.1428571 L59.5384615,71.8241758 L60.4835165,67.5714286 L46.3076923,56.2307692 L43.4725275,58.1208791 L40.1648352,53.8681319 L15.1208791,45.8351648 L14.1758242,42.5274725 L6.14285714,38.7472527 L0,36.8571429 L12.7582418,28.8241758 L28.8241758,37.3296703 L49.6153846,34.021978 L81.7472527,40.1648352 L86,25.043956 L80.3296703,4.25274725 L83.6373626,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alanya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69.9340659" y="60.3406593">
                      ALN
                    </tspan>
                  </text>
                </g>
                <g id="demre" transform="translate(62.373626, 153.098901)">
                  <path
                    d="M0.945054945,35.9120879 L0,40.1648352 L8.50549451,37.3296703 L7.08791209,33.5494505 L10.3956044,32.6043956 L8.97802198,29.2967033 L15.1208791,30.7142857 L22.2087912,24.5714286 L27.8791209,27.8791209 L38.2747253,24.5714286 L38.2747253,20.3186813 L43.9450549,24.5714286 L46.7802198,23.1538462 L35.9120879,6.14285714 L22.2087912,0 L0.945054945,35.9120879 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Demre"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.2087912" y="17.3406593">
                      DMR
                    </tspan>
                  </text>
                </g>
                <g id="dosemealti" transform="translate(153.571429, 19.373626)">
                  <path
                    d="M1.89010989,0 L13.2307692,2.83516484 L23.6263736,0.472527473 L41.1098901,5.1978022 L41.5824176,11.3406593 L43.9450549,18.9010989 L32.1318681,18.9010989 L32.6043956,24.0989011 L28.8241758,22.6813187 L25.043956,27.4065934 L29.7692308,43.4725275 L24.5714286,51.5054945 L0.945054945,54.3406593 L12.2857143,34.967033 L9.45054945,25.043956 L0,20.7912088 L1.89010989,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Döşemealtı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13.7032967" y="20.1758242">
                      DÖŞ
                    </tspan>
                  </text>
                </g>
                <g id="elmali" transform="translate(43.945055, 68.516484)">
                  <path
                    d="M1.63424829e-13,49.1428571 L9.45054945,36.8571429 L16.0659341,14.1758242 L25.5164835,0 L60.4835165,0 L79.3846154,23.1538462 L76.0769231,40.6373626 L65.6813187,55.7582418 L32.6043956,60.4835165 L1.63424829e-13,49.1428571 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Elmalı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.2747253" y="34.3516484">
                      ELM
                    </tspan>
                  </text>
                </g>
                <g id="finike" transform="translate(76.549451, 124.274725)">
                  <path
                    d="M32.6043956,51.978022 L37.3296703,50.0879121 L37.8021978,44.4175824 L46.7802198,43 L46.3076923,38.7472527 L39.2197802,29.7692308 L33.0769231,0 L0,4.72527473 L0.472527473,22.2087912 L8.03296703,28.8241758 L21.7362637,34.967033 L32.6043956,51.978022 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Finike"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.010989" y="22.0659341">
                      FNK
                    </tspan>
                  </text>
                </g>
                <g id="gazipasa" transform="translate(384.637363, 135.615385)">
                  <path
                    d="M0.00830449866,41.1098901 C0.480745466,41.5824176 4.73271417,54.8131868 4.73271417,54.8131868 L7.09491901,54.8131868 L15.5988564,66.6263736 L40.1657867,75.6043956 L45.3626374,67.5714286 L44.4177554,46.3076923 L39.6933458,34.021978 L30.2445264,28.3516484 L34.4964951,8.03296703 L33.0791722,0 L19.3783842,11.3406593 C19.3783842,11.3406593 -0.464136469,40.6373626 0.00830449866,41.1098901 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gazipaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.1538462" y="51.3626374">
                      GZP
                    </tspan>
                  </text>
                </g>
                <g id="gundogmus" transform="translate(321.318681, 61.428571)">
                  <path
                    d="M39.6923077,0 L70.8791209,19.8461538 L70.8791209,23.1538462 L67.5714286,27.4065934 L73.2417582,48.1978022 L68.989011,63.3186813 L36.3846154,57.1758242 L16.0659341,60.4835165 L0,51.978022 L0.945054945,40.6373626 L37.3296703,13.7032967 L34.021978,3.78021978 L39.6923077,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gündoğmuş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="41.1098901" y="40.021978">
                      GND
                    </tspan>
                  </text>
                </g>
                <g id="ibradi" transform="translate(272.175824, 2.835165)">
                  <path
                    d="M0.472527473,0 L20.3186813,11.3406593 L38.7472527,10.3956044 L43,28.3516484 L51.032967,36.8571429 L51.032967,59.0659341 L34.021978,80.8021978 L28.3516484,79.8571429 L25.5164835,75.6043956 L27.8791209,72.7692308 L27.4065934,70.4065934 L24.5714286,71.3516484 L23.6263736,73.7142857 L21.2637363,73.7142857 L15.1208791,70.4065934 L9.92307692,50.0879121 L5.2461088e-17,30.2417582 L3.30769231,18.4285714 L0.945054945,6.14285714 L0.472527473,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İbradi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.5714286" y="43.8021978">
                      İBR
                    </tspan>
                  </text>
                </g>
                <g id="kas" transform="translate(0.000000, 117.659341)">
                  <path
                    d="M43.9450549,0 L10.3956044,20.3186813 L0,46.3076923 L9.45054945,57.6483516 L13.7032967,51.978022 L18.9010989,52.9230769 L16.0659341,59.0659341 L43,61.9010989 L40.1648352,66.1538462 L45.8351648,63.7912088 L49.6153846,66.6263736 L44.8901099,67.0989011 L51.032967,73.2417582 L63.3186813,71.3516484 L84.5824176,35.4395604 L77.021978,28.8241758 L76.5494505,11.3406593 L43.9450549,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kaş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.1648352" y="42.3846154">
                      KAŞ
                    </tspan>
                  </text>
                </g>
                <g id="kemer" transform="translate(144.593407, 108.208791)">
                  <path
                    d="M25.5164835,0 L3.30769231,6.14285714 L0,22.6813187 L3.30769231,36.3846154 L3.30769231,48.6703297 L16.0659341,59.5384615 L18.9010989,57.6483516 L14.6483516,50.0879121 L11.8131868,49.1428571 L13.7032967,42.0549451 L17.4835165,41.5824176 L17.4835165,35.4395604 L28.3516484,17.956044 L23.6263736,13.7032967 L26.4615385,4.25274725 L25.5164835,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kemer"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7.56043956" y="25.8461538">
                      KMR
                    </tspan>
                  </text>
                </g>
                <g id="kepez" transform="translate(176.252747, 38.274725)">
                  <path
                    d="M1.89010989,32.6043956 L0,39.6923077 L4.72527473,40.1648352 L20.3186813,38.7472527 L24.0989011,34.4945055 L20.7912088,28.8241758 L27.8791209,25.5164835 L23.1538462,21.2637363 L22.6813187,12.7582418 L17.010989,4.72527473 L21.2637363,0 L9.45054945,0 L9.92307692,5.1978022 L6.14285714,3.78021978 L2.36263736,8.50549451 L7.08791209,24.5714286 L1.89010989,32.6043956 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kepez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.8681319" y="25.8461538">
                      KPZ
                    </tspan>
                  </text>
                </g>
                <g id="konyaalti" transform="translate(141.758242, 70.879121)">
                  <path
                    d="M12.7582418,2.83516484 L36.3846154,0 L34.4945055,7.08791209 L39.2197802,7.56043956 L39.2197802,12.7582418 L32.1318681,18.9010989 L28.3516484,37.3296703 L6.14285714,43.4725275 L0,37.3296703 L0,19.8461538 L12.7582418,2.83516484 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Konyaaltı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.8681319" y="28.6813187">
                      KNY
                    </tspan>
                  </text>
                </g>
                <g id="korkuteli" transform="translate(64.736264, 8.978022)">
                  <path
                    d="M4.72527473,59.5384615 L0,52.9230769 L13.2307692,45.8351648 L14.6483516,25.5164835 L35.9120879,14.6483516 L66.1538462,0 L90.7252747,10.3956044 L88.8351648,31.1868132 L98.2857143,35.4395604 L101.120879,45.3626374 L89.7802198,64.7362637 L77.021978,81.7472527 L58.5934066,82.6923077 L39.6923077,59.5384615 L4.72527473,59.5384615 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Korkuteli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.4505495" y="44.2747253">
                      KOR
                    </tspan>
                  </text>
                </g>
                <g id="kumluca" transform="translate(109.626374, 90.725275)">
                  <path
                    d="M13.7032967,0.945054945 L32.1318681,0 L32.1318681,17.4835165 L38.2747253,23.6263736 L34.967033,40.1648352 L38.2747253,53.3956044 L38.2747253,66.1538462 L51.032967,77.021978 L45.8351648,79.3846154 L48.6703297,83.1648352 L36.8571429,94.5054945 L36.3846154,84.5824176 L31.1868132,84.5824176 L13.7032967,76.5494505 L13.2307692,72.2967033 L6.14285714,63.3186813 L0,33.5494505 L10.3956044,18.4285714 L13.7032967,0.945054945 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kumluca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.4835165" y="48.5274725">
                      KUM
                    </tspan>
                  </text>
                </g>
                <g id="manavgat" transform="translate(232.956044, 0.000000)">
                  <path
                    d="M0,7.56043956 L34.967033,0 L39.6923077,2.83516484 L40.1648352,9.45054945 L42.5274725,21.2637363 L39.2197802,33.0769231 L49.1428571,52.9230769 L54.3406593,73.2417582 L60.4835165,76.5494505 L58.5934066,81.7472527 L60.4835165,82.2197802 L62.3736264,80.8021978 L65.2087912,83.6373626 L67.0989011,82.6923077 L73.2417582,83.6373626 L89.3076923,102.065934 L88.3626374,113.406593 L75.6043956,121.43956 L68.043956,115.769231 L63.7912088,111.516484 L60.4835165,111.516484 L50.5604396,104.901099 L41.1098901,103.956044 L40.1648352,98.2857143 L24.0989011,90.7252747 L21.7362637,92.6153846 L13.2307692,90.7252747 L13.2307692,79.3846154 L22.6813187,60.956044 L19.8461538,45.8351648 L2.83516484,41.1098901 L5.1978022,28.3516484 L0,7.56043956 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Manavgat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.1868132" y="65.0659341">
                      MNV
                    </tspan>
                  </text>
                </g>
                <g id="muratpasa" transform="translate(180.978022, 72.769231)">
                  <path
                    d="M28.8241758,13.7032967 L26.4615385,9.92307692 L21.2637363,8.03296703 L23.1538462,2.36263736 L19.3736264,0 L15.5934066,4.25274725 L0,5.67032967 L0,10.8681319 L5.1978022,8.50549451 L9.45054945,10.8681319 L13.7032967,14.1758242 L28.8241758,13.7032967 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Muratpaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="12.7582418" y="13.5604396">
                      MRT
                    </tspan>
                  </text>
                </g>
                <g id="serik" transform="translate(204.131868, 6.615385)">
                  <path
                    d="M12.2857143,79.3846154 L26.9340659,79.3846154 L33.0769231,83.6373626 L42.0549451,84.1098901 L42.0549451,72.7692308 L51.5054945,54.3406593 L48.6703297,39.2197802 L31.6593407,34.4945055 L34.021978,21.7362637 L28.8241758,0.945054945 L18.4285714,5.67032967 L13.2307692,0 L0,17.010989 L1.89010989,33.0769231 L11.8131868,43.4725275 L12.7582418,60.010989 L8.50549451,69.4615385 L11.8131868,74.1868132 L12.2857143,79.3846154 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Serik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.5164835" y="50.4175824">
                      SRK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
