import React, { useEffect } from "react";
import "../turkeymap.css";

export const Kahramanmaras = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(62.000000, 3.000000)">
                <g id="afsin" transform="translate(81.019108, 7.643312)">
                  <path
                    d="M89.4267516,0 L26.7515924,9.93630573 L14.522293,16.8152866 L19.8726115,36.6878981 L0,42.0382166 L38.2165605,113.88535 L83.3121019,111.592357 L89.4267516,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Afşin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.0955414" y="65.6178344">
                      AFŞ
                    </tspan>
                  </text>
                </g>
                <g id="andirin" transform="translate(0.000000, 191.847134)">
                  <path
                    d="M28.2802548,0 L20.6369427,26.7515924 L19.8726115,33.6305732 L17.5796178,45.0955414 L0,54.2675159 L0.76433121,60.3821656 L14.522293,64.2038217 L13.7579618,129.936306 L17.5796178,127.643312 L17.5796178,122.292994 L21.4012739,121.528662 L22.1656051,125.350318 L38.9808917,130.700637 L44.3312102,126.11465 L50.4458599,126.11465 L53.5031847,124.585987 L53.5031847,120 L50.4458599,115.414013 L50.4458599,107.770701 L57.3248408,98.5987261 L64.2038217,95.5414013 L74.9044586,59.6178344 L77.1974522,26.7515924 L50.4458599,4.58598726 L28.2802548,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Andırın"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.6878981" y="66.3821656">
                      AND
                    </tspan>
                  </text>
                </g>
                <g
                  id="caglayancerit"
                  transform="translate(184.203822, 197.961783)"
                >
                  <path
                    d="M0,0 L77.9617834,8.40764331 L66.4968153,37.4522293 L20.6369427,32.1019108 L2.29299363,21.4012739 L0,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çağlayancerit"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.5732484" y="22.8152866">
                      ÇĞL
                    </tspan>
                  </text>
                </g>
                <g
                  id="dulkadiroglu"
                  transform="translate(121.528662, 156.687898)"
                >
                  <path
                    d="M39.7452229,0.76433121 L24.4585987,0 L0,14.522293 L18.343949,49.6815287 L22.9299363,43.566879 L25.2229299,46.6242038 L21.4012739,61.1464968 L29.8089172,89.4267516 L19.8726115,137.579618 L36.6878981,149.808917 L51.2101911,149.044586 L84.0764331,106.242038 L83.3121019,73.3757962 L64.9681529,62.6751592 L62.6751592,41.2738854 L38.2165605,35.1592357 L39.7452229,0.76433121 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dulkadiroğlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.8598726" y="86.2547771">
                      DUL
                    </tspan>
                  </text>
                </g>
                <g id="ekinozu" transform="translate(145.987261, 119.235669)">
                  <path
                    d="M18.343949,0 L0,37.4522293 L15.2866242,38.2165605 L13.7579618,72.611465 L53.5031847,50.4458599 L65.7324841,12.9936306 L83.3121019,6.11464968 L18.343949,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ekinözü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.3375796" y="28.9299363">
                      EKN
                    </tspan>
                  </text>
                </g>
                <g id="elbistan" transform="translate(164.331210, 0.000000)">
                  <path
                    d="M58.089172,0 L6.11464968,7.6433121 L0,119.235669 L63.4394904,125.350318 L127.643312,127.643312 L146.751592,94.7770701 L121.528662,59.6178344 L42.0382166,28.2802548 L58.089172,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Elbistan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="61.910828" y="86.2547771">
                      ELB
                    </tspan>
                  </text>
                </g>
                <g id="goksun" transform="translate(21.401274, 49.681529)">
                  <path
                    d="M142.929936,69.5541401 L98.5987261,71.8471338 L59.6178344,0 L23.6942675,51.9745223 L9.93630573,76.433121 L9.17197452,94.7770701 L9.93630573,113.121019 L0,132.229299 L6.87898089,142.165605 L29.044586,146.751592 L55.7961783,168.917197 L60.3821656,149.808917 L43.566879,123.821656 L63.4394904,108.535032 L100.127389,121.528662 L124.585987,107.006369 L142.929936,69.5541401 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Göksun"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.7388535" y="89.3121019">
                      GKS
                    </tspan>
                  </text>
                </g>
                <g id="nurhak" transform="translate(159.745223, 125.350318)">
                  <path
                    d="M132.229299,2.29299363 L130.700637,42.8025478 L102.420382,81.0191083 L24.4585987,72.611465 L0,66.4968153 L39.7452229,44.3312102 L51.9745223,6.87898089 L68.7898089,0 L132.229299,2.29299363 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nurhak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="82.5477707" y="38.866242">
                      NRH
                    </tspan>
                  </text>
                </g>
                <g id="onikisubat" transform="translate(53.503185, 158.216561)">
                  <path
                    d="M68.0254777,12.9936306 L86.3694268,48.1528662 L81.0191083,62.6751592 L75.6687898,67.2611465 L68.7898089,64.9681529 L62.6751592,61.1464968 L55.7961783,56.5605096 L55.0318471,60.3821656 L60.3821656,62.6751592 L71.8471338,74.9044586 L75.6687898,71.8471338 L81.0191083,71.0828025 L89.4267516,59.6178344 L97.8343949,87.8980892 L87.8980892,136.050955 L53.5031847,136.050955 L40.5095541,145.22293 L16.0509554,148.280255 L9.17197452,155.159236 L0,158.216561 L0,153.630573 L3.82165605,152.866242 L0.76433121,143.694268 L8.40764331,132.993631 L19.1082803,129.936306 L17.5796178,126.11465 L10.7006369,129.171975 L21.4012739,93.2484076 L23.6942675,60.3821656 L28.2802548,41.2738854 L11.4649682,15.2866242 L31.3375796,0 L68.0254777,12.9936306 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Onikişubat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53.5031847" y="101.541401">
                      OİŞ
                    </tspan>
                  </text>
                </g>
                <g id="pazarcik" transform="translate(146.751592, 230.063694)">
                  <path
                    d="M58.089172,0 L58.8535032,32.866242 L25.9872611,75.6687898 L11.4649682,76.433121 L0,93.2484076 L9.93630573,96.3057325 L13.7579618,113.121019 L24.4585987,129.171975 L39.7452229,129.936306 L110.063694,81.7834395 L126.11465,79.4904459 L143.694268,43.566879 L137.579618,35.9235669 L129.936306,24.4585987 L116.178344,25.9872611 L103.949045,5.35031847 L58.089172,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pazarcık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="76.433121" y="64.8535032">
                      PZR
                    </tspan>
                  </text>
                </g>
                <g id="turkoglu" transform="translate(62.675159, 294.267516)">
                  <path
                    d="M78.7261146,0 L44.3312102,0 L31.3375796,9.17197452 L6.87898089,12.2292994 L0,19.1082803 L15.2866242,21.4012739 L28.2802548,38.9808917 L41.2738854,61.910828 L65.7324841,51.9745223 L84.0764331,29.044586 L95.5414013,12.2292994 L78.7261146,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Türkoğlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.6815287" y="29.6942675">
                      TRK
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
