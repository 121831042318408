import React, { useEffect } from "react";
import "../turkeymap.css";
export const Kastamonu = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(9.000000, 3.000000)">
                <g id="abana" transform="translate(278.181818, 11.818182)">
                  <path
                    d="M24.5454545,-1.33226763e-14 L0,1.81818182 L22.7272727,14.5454545 L24.5454545,-1.33226763e-14 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Abana"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10" y="6.90909091">
                      ABN
                    </tspan>
                  </text>
                </g>
                <g id="agli" transform="translate(158.181818, 73.636364)">
                  <path
                    d="M12.7272727,0 L10,34.5454545 L0,47.2727273 L13.6363636,53.6363636 L23.6363636,50.9090909 L46.3636364,23.6363636 L12.7272727,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ağlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.2727273" y="33.2727273">
                      AĞL
                    </tspan>
                  </text>
                </g>
                <g id="arac" transform="translate(29.090909, 185.454545)">
                  <path
                    d="M154.545455,106.363636 L128.181818,109.090909 L112.727273,120 L100.909091,131.818182 L86.3636364,105.454545 L70.9090909,101.818182 L47.2727273,103.636364 L34.5454545,100.909091 L17.2727273,74.5454545 L0,69.0909091 L5.45454545,41.8181818 L12.7272727,13.6363636 L50.9090909,0 L67.2727273,10.9090909 L139.090909,20 L134.545455,66.3636364 L126.363636,75.4545455 L144.545455,90 L154.545455,106.363636 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Araç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71.536829" y="61.5424361">
                      ARÇ
                    </tspan>
                  </text>
                </g>
                <g id="azdavay" transform="translate(63.636364, 35.454545)">
                  <path
                    d="M94.5454545,85.4545455 L32.7272727,98.1818182 L32.7272727,70.9090909 L0,44.5454545 L24.5454545,45.4545455 L41.8181818,43.6363636 L59.0909091,29.0909091 L62.7272727,9.09090909 L72.7272727,0 L104.545455,1.81818182 L110.909091,14.5454545 L100,29.0909091 L107.272727,38.1818182 L104.545455,72.7272727 L94.5454545,85.4545455 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Azdavay"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="63.6363636" y="57.8181818">
                      AZD
                    </tspan>
                  </text>
                </g>
                <g id="bozkurt" transform="translate(255.454545, 13.636364)">
                  <path
                    d="M11.8181818,52.7272727 L18.1818182,43.6363636 L5.45454545,33.6363636 L0,1.81818182 L22.7272727,0 L45.4545455,12.7272727 L54.5454545,43.6363636 L40,57.2727273 L29.0909091,50.9090909 L11.8181818,52.7272727 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozkurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.8181818" y="28.7272727">
                      BZT
                    </tspan>
                  </text>
                </g>
                <g id="cide" transform="">
                  {" "}
                  <path
                    d="M134.545455,0 L124.545455,1.81818182 L59.0909091,30 L51.8181818,30.9090909 L49.0909091,37.2727273 L0,50.9090909 L10.9090909,70.9090909 L13.6363636,77.2727273 L19.0909091,100 L51.8181818,79.0909091 L63.6363636,80 L89.0909091,80.9090909 L80,60.9090909 L85.4545455,41.8181818 L131.818182,29.0909091 L130,12.7272727 L134.545455,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Cide"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.4545455" y="60.5454545">
                      CİD
                    </tspan>
                  </text>
                </g>
                <g
                  id="catalzeytin"
                  transform="translate(295.454545, 11.818182)"
                >
                  <path
                    d="M40,8.18181818 L7.27272727,0 L5.45454545,14.5454545 L14.5454545,45.4545455 L0,59.0909091 L11.8181818,76.3636364 L48.1818182,72.7272727 L52.7272727,45.4545455 L40,8.18181818 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çatalzeytin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.6363636" y="41.4545455">
                      ÇTZ
                    </tspan>
                  </text>
                </g>
                <g id="daday" transform="translate(63.636364, 120.909091)">
                  <path
                    d="M0,15.4545455 L8.18181818,52.7272727 L22.7272727,56.3636364 L16.3636364,64.5454545 L32.7272727,75.4545455 L104.545455,84.5454545 L109.090909,77.2727273 L121.818182,12.7272727 L118.181818,3.63636364 L108.181818,6.36363636 L94.5454545,0 L32.7272727,12.7272727 L0,15.4545455 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Daday"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="63.6363636" y="44.2697088">
                      DDY
                    </tspan>
                  </text>
                </g>
                <g id="devrekani" transform="translate(224.545455, 64.545455)">
                  <path
                    d="M82.7272727,23.6363636 L70.9090909,6.36363636 L60,0 L42.7272727,1.81818182 L32.7272727,7.27272727 L20.9090909,7.27272727 L17.2727273,21.8181818 L0,30.9090909 L5.45454545,50.9090909 L1.81818182,77.2727273 L16.3636364,85.4545455 L36.3636364,73.6363636 L66.3636364,52.7272727 L82.7272727,23.6363636 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Devrekani"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.7272727" y="39.6363636">
                      DVR
                    </tspan>
                  </text>
                </g>
                <g id="doganyurt" transform="translate(130.000000, 0.000000)">
                  <path
                    d="M63.6363636,10.9090909 L4.54545455,0 L0,12.7272727 L1.81818182,29.0909091 L6.36363636,35.4545455 L38.1818182,37.2727273 L54.5454545,26.3636364 L63.6363636,10.9090909 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Doğanyurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.0909091" y="22.3636364">
                      DNY
                    </tspan>
                  </text>
                </g>
                <g id="hanonu" transform="translate(348.181818, 90.909091)">
                  <path
                    d="M69.0909091,7.27272727 L63.6363636,35.4545455 L34.5454545,52.7272727 L19.0909091,40.9090909 L11.8181818,21.8181818 L0.909090909,10.9090909 L0,0 L7.27272727,0.909090909 L69.0909091,7.27272727 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hanönü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.9090909" y="26.9090909">
                      HNÜ
                    </tspan>
                  </text>
                </g>
                <g id="ihsangazi" transform="translate(155.454545, 198.181818)">
                  <path
                    d="M53.6363636,91.8181818 L28.1818182,93.6363636 L18.1818182,77.2727273 L0,62.7272727 L8.18181818,53.6363636 L12.7272727,7.27272727 L17.2727273,0 L30.9090909,10 L35.4545455,37.2727273 L47.2727273,51.8181818 L53.6363636,91.8181818 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İhsangazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20" y="56">
                      İHS
                    </tspan>
                  </text>
                </g>
                <g id="inebolu" transform="translate(184.545455, 10.909091)">
                  <path
                    d="M70.9090909,4.54545455 L42.7272727,1.81818182 L9.09090909,0 L0,15.4545455 L61.8181818,50.9090909 L77.2727273,36.3636364 L70.9090909,4.54545455 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İnebolu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.1818182" y="21.4545455">
                      İNB
                    </tspan>
                  </text>
                </g>
                <g id="kure" transform="translate(163.636364, 26.363636)">
                  <path
                    d="M20.9090909,0 L4.54545455,10.9090909 L10.9090909,23.6363636 L0,38.1818182 L7.27272727,47.2727273 L40.9090909,70.9090909 L50,64.5454545 L60.9090909,69.0909091 L78.1818182,60 L81.8181818,45.4545455 L93.6363636,45.4545455 L103.636364,40 L110.909091,30.9090909 L98.1818182,20.9090909 L82.7272727,35.4545455 L20.9090909,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kure"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.3636364" y="42.3636364">
                      KRE
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(172.727273, 133.636364)">
                  <path
                    d="M134.545455,116.363636 L130.909091,131.818182 L86.3636364,138.181818 L60,162.727273 L36.3636364,156.363636 L30,116.363636 L18.1818182,101.818182 L13.6363636,74.5454545 L0,64.5454545 L12.7272727,0 L53.6363636,8.18181818 L68.1818182,16.3636364 L88.1818182,4.54545455 L90,21.8181818 L100.909091,34.5454545 L90.9090909,46.3636364 L105.454545,71.8181818 L133.636364,80 L140,91.8181818 L134.545455,116.363636 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.7272727" y="83.3606179">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="pinarbasi" transform="translate(3.636364, 79.090909)">
                  <path
                    d="M15.4545455,20.9090909 L0,44.5454545 L5.45454545,53.6363636 L19.0909091,50.9090909 L23.6363636,53.6363636 L60,57.2727273 L92.7272727,54.5454545 L92.7272727,27.2727273 L60,0.909090909 L48.1818182,0 L15.4545455,20.9090909 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pınarbaşı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.6363636" y="36">
                      PNB
                    </tspan>
                  </text>
                </g>
                <g id="seydiler" transform="translate(181.818182, 90.000000)">
                  <path
                    d="M31.8181818,0.909090909 L22.7272727,7.27272727 L0,34.5454545 L3.63636364,43.6363636 L44.5454545,51.8181818 L48.1818182,25.4545455 L42.7272727,5.45454545 L31.8181818,0.909090909 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Seydiler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.8181818" y="31.4545455">
                      SYR
                    </tspan>
                  </text>
                </g>
                <g id="senpazar" transform="translate(80.000000, 29.090909)">
                  <path
                    d="M56.3636364,6.36363636 L46.3636364,15.4545455 L42.7272727,35.4545455 L25.4545455,50 L9.09090909,51.8181818 L0,31.8181818 L5.45454545,12.7272727 L51.8181818,0 L56.3636364,6.36363636 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şenpazar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.4545455" y="29.6363636">
                      ŞNP
                    </tspan>
                  </text>
                </g>
                <g id="taskopru" transform="translate(260.909091, 84.545455)">
                  <path
                    d="M82.7272727,0 L87.2727273,6.36363636 L88.1818182,17.2727273 L99.0909091,28.1818182 L106.363636,47.2727273 L121.818182,59.0909091 L118.181818,91.8181818 L123.636364,125.454545 L89.0909091,138.181818 L66.3636364,171.818182 L59.0909091,171.818182 L53.6363636,178.181818 L46.3636364,166.363636 L51.8181818,140.909091 L45.4545455,129.090909 L17.2727273,120.909091 L2.72727273,95.4545455 L12.7272727,83.6363636 L1.81818182,70.9090909 L0,53.6363636 L30,32.7272727 L46.3636364,3.63636364 L82.7272727,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Taşköprü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60.9090909" y="78.7272727">
                      TŞK
                    </tspan>
                  </text>
                </g>
                <g id="tosya" transform="translate(225.454545, 250.909091)">
                  <path
                    d="M101.818182,5.45454545 L95.4545455,30 L110.909091,57.2727273 L117.272727,91.8181818 L98.1818182,100.909091 L43.6363636,108.181818 L0.909090909,104.545455 L0,68.1818182 L7.27272727,45.4545455 L33.6363636,20.9090909 L78.1818182,14.5454545 L81.8181818,0 L89.0909091,11.8181818 L94.5454545,5.45454545 L101.818182,5.45454545 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tosya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.9090909" y="70.5454545">
                      TOS
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
