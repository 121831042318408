import React, { useEffect } from "react";
import "../turkeymap.css";
export const Konya = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(36.000000, 3.000000)">
                <g id="ahirli" transform="translate(67.688623, 266.011976)">
                  <path
                    d="M41.3892216,6.89820359 L32.3353293,0 L28.0239521,3.01796407 L31.9041916,9.48502994 L31.4730539,15.0898204 L25.4371257,16.8143713 L21.1257485,17.245509 L15.5209581,12.9341317 L16.3832335,17.6766467 L11.6407186,12.9341317 L0,21.1257485 L8.62275449,38.8023952 L28.4550898,30.1796407 L36.2155689,20.6946108 L41.3892216,6.89820359 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ahırlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.2095808" y="28.4191617">
                      AHR
                    </tspan>
                  </text>
                </g>
                <g id="akoren" transform="translate(99.592814, 243.161677)">
                  <path
                    d="M34.491018,28.8862275 L31.4730539,31.9041916 L9.48502994,29.748503 L0.431137725,22.8502994 L0,2.58682635 L6.03592814,0 L10.3473054,8.19161677 L17.245509,2.15568862 L42.251497,2.58682635 L48.7185629,5.60479042 L51.3053892,16.8143713 L34.0598802,18.5389222 L34.491018,28.8862275 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akören"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.8143713" y="22.8143713">
                      AKÖ
                    </tspan>
                  </text>
                </g>
                <g id="aksehir" transform="translate(0.000000, 106.922156)">
                  <path
                    d="M54.3233533,19.8323353 L28.8862275,5.17365269 L25.005988,6.03592814 L18.9700599,6.89820359 L14.6586826,5.17365269 L12.9341317,0 L0,8.62275449 L19.4011976,39.2335329 L28.8862275,47.4251497 L47.8562874,29.3173653 L54.3233533,19.8323353 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.4191617" y="26.6946108">
                      AKŞ
                    </tspan>
                  </text>
                </g>
                <g id="altinekin" transform="translate(150.035928, 112.095808)">
                  <path
                    d="M0.862275449,2.58682635 L0,28.0239521 L48.7185629,50.4431138 L60.7904192,33.1976048 L73.2934132,28.0239521 L76.742515,12.9341317 L54.3233533,12.0718563 L21.5568862,0 L0.862275449,2.58682635 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altınekin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.4730539" y="26.2634731">
                      ALT
                    </tspan>
                  </text>
                </g>
                <g id="beysehir" transform="translate(6.035928, 178.922156)">
                  <path
                    d="M14.6586826,37.0778443 L0,51.3053892 L1.29341317,65.1017964 L0,69.8443114 L17.245509,71.5688623 L31.0419162,76.742515 L37.9401198,70.2754491 L46.1317365,62.0838323 L69.8443114,51.7365269 L76.3113772,40.0958084 L77.1736527,12.0718563 L69.4131737,9.91616766 L62.9461078,1.7245509 L53.4610778,0 L49.1497006,17.245509 L33.1976048,25.8682635 L31.0419162,30.1796407 L34.0598802,32.3353293 L34.9221557,28.4550898 L39.2335329,29.3173653 L39.6646707,35.7844311 L42.6826347,35.7844311 L45.2694611,43.9760479 L38.8023952,47.8562874 L23.2814371,52.5988024 L22.4191617,56.9101796 L15.9520958,55.6167665 L15.9520958,41.3892216 L14.6586826,37.0778443 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Beyşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56.4790419" y="40.9221557">
                      BYŞ
                    </tspan>
                  </text>
                </g>
                <g id="bozkir" transform="translate(76.311377, 272.910180)">
                  <path
                    d="M40.0958084,62.0838323 L42.251497,59.497006 L36.6467066,48.2874251 L41.8203593,46.5628743 L56.0479042,34.491018 L68.9820359,37.508982 L84.0718563,33.1976048 L91.4011976,21.1257485 L77.1736527,20.2634731 L65.1017964,12.502994 L56.0479042,12.9341317 L57.7724551,3.01796407 L49.1497006,12.0718563 L43.5449102,11.6407186 L54.754491,2.15568862 L32.7664671,0 L27.5928144,13.7964072 L19.8323353,23.2814371 L0,31.9041916 L11.6407186,43.9760479 L40.0958084,62.0838323 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozkır"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.9221557" y="31.005988">
                      BOZ
                    </tspan>
                  </text>
                </g>
                <g id="cihanbeyli" transform="translate(131.497006, 28.886228)">
                  <path
                    d="M0,10.3473054 L29.748503,2.15568862 L39.6646707,6.89820359 L51.7365269,0 L60.7904192,6.03592814 L59.497006,15.5209581 L65.9640719,35.3532934 L88.8143713,28.8862275 L83.2095808,48.2874251 L84.502994,54.3233533 L93.1257485,56.0479042 L100.45509,62.5149701 L108.646707,72 L95.2814371,96.1437126 L72.4311377,95.2814371 L39.6646707,83.2095808 L19.4011976,85.7964072 L15.5209581,74.1556886 L19.8323353,57.3413174 L7.32934132,53.4610778 L2.58682635,46.5628743 L11.2095808,27.1616766 L0,10.3473054 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Cihanbeyli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.1137725" y="58.5988024">
                      CHN
                    </tspan>
                  </text>
                </g>
                <g id="celtik" transform="translate(45.700599, 14.227545)">
                  <path
                    d="M3.01796407,35.3532934 L1.7245509,29.748503 L8.62275449,29.3173653 L10.7784431,21.988024 L0,16.8143713 L1.29341317,3.01796407 L16.3832335,6.89820359 L23.2814371,0 L31.4730539,5.17365269 L40.9580838,21.988024 L54.3233533,22.4191617 L47.4251497,30.6107784 L25.4371257,26.7305389 L3.01796407,35.3532934 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çeltik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.9700599" y="21.5209581">
                      ÇEL
                    </tspan>
                  </text>
                </g>
                <g id="cumra" transform="translate(132.359281, 212.982036)">
                  <path
                    d="M77.1736527,9.91616766 L85.3652695,35.3532934 L60.3592814,61.2215569 L53.8922156,61.2215569 L45.7005988,50.8742515 L36.2155689,53.8922156 L25.8682635,62.5149701 L21.1257485,80.1916168 L9.05389222,72.4311377 L0,72.8622754 L1.7245509,62.9461078 L5.60479042,58.6347305 L7.76047904,62.0838323 L11.6407186,59.0658683 L11.2095808,55.6167665 L7.76047904,54.754491 L1.7245509,59.0658683 L1.29341317,48.7185629 L18.5389222,46.994012 L15.9520958,35.7844311 L9.48502994,32.7664671 L3.4491018,21.5568862 L12.0718563,12.9341317 L18.5389222,22.8502994 L23.2814371,16.3832335 L21.5568862,3.4491018 L36.2155689,0 L65.9640719,15.0898204 L77.1736527,9.91616766 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çumra"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.6826347" y="37.4730539">
                      ÇUM
                    </tspan>
                  </text>
                </g>
                <g id="derbent" transform="translate(68.982036, 161.676647)">
                  <path
                    d="M39.6646707,3.4491018 L34.0598802,21.1257485 L14.2275449,29.3173653 L6.46706587,27.1616766 L0,18.9700599 L8.62275449,13.3652695 L24.5748503,10.7784431 L31.4730539,0 L39.6646707,3.4491018 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Derbent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13.7964072" y="24.5389222">
                      DER
                    </tspan>
                  </text>
                </g>
                <g id="derebucak" transform="translate(3.017964, 248.766467)">
                  <path
                    d="M40.9580838,0.431137725 L34.0598802,6.89820359 L20.2634731,1.7245509 L3.01796407,0 L0,12.0718563 L4.31137725,14.6586826 L22.4191617,25.005988 L39.2335329,24.1437126 L44.8383234,19.8323353 L43.9760479,2.58682635 L40.9580838,0.431137725 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Derebucak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.3832335" y="17.2095808">
                      DRB
                    </tspan>
                  </text>
                </g>
                <g id="doganhisar" transform="translate(28.886228, 136.239521)">
                  <path
                    d="M7.32934132,37.0778443 L11.6407186,28.4550898 L0,18.1077844 L18.9700599,0 L24.5748503,15.9520958 L32.7664671,17.6766467 L27.5928144,32.7664671 L21.1257485,45.2694611 L7.32934132,37.0778443 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Doğanhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.6586826" y="27.1257485">
                      DĞN
                    </tspan>
                  </text>
                </g>
                <g id="emirgazi" transform="translate(263.856287, 179.353293)">
                  <path
                    d="M9.9475983e-14,6.46706587 L53.4610778,0 L62.5149701,2.58682635 L64.239521,6.46706587 L56.9101796,9.05389222 L51.7365269,16.3832335 L37.508982,18.5389222 L31.9041916,33.6287425 L9.9475983e-14,6.46706587 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Emirgazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21.5568862" y="16.3473054">
                      EMR
                    </tspan>
                  </text>
                </g>
                <g id="eregli" transform="translate(280.239521, 185.820359)">
                  <path
                    d="M15.5209581,27.1616766 L0,44.4071856 L14.2275449,79.3293413 L35.3532934,90.5389222 L49.1497006,72.8622754 L51.7365269,63.3772455 L61.6526946,72 L71.5688623,72 L70.7065868,51.7365269 L78.0359281,45.7005988 L79.3293413,31.4730539 L47.8562874,0 L40.5269461,2.58682635 L35.3532934,9.91616766 L21.1257485,12.0718563 L15.5209581,27.1616766 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ereğli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="38.8023952" y="45.6646707">
                      ERĞ
                    </tspan>
                  </text>
                </g>
                <g
                  id="guneysinir"
                  transform="translate(153.485030, 263.856287)"
                >
                  <path
                    d="M32.7664671,10.3473054 L24.5748503,0 L15.0898204,3.01796407 L4.74251497,11.6407186 L0,29.3173653 L14.2275449,30.1796407 L21.5568862,24.5748503 L21.988024,12.0718563 L32.7664671,10.3473054 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güneysınır"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8.19161677" y="22.8143713">
                      GNY
                    </tspan>
                  </text>
                </g>
                <g id="hadim" transform="translate(112.958084, 306.107784)">
                  <path
                    d="M8.62275449,40.0958084 L3.4491018,31.9041916 L3.4491018,28.8862275 L5.60479042,26.2994012 L9.9475983e-14,15.0898204 L5.17365269,13.3652695 L19.4011976,1.29341317 L32.3353293,4.31137725 L47.4251497,-1.70530257e-13 L51.7365269,12.0718563 L68.9820359,23.2814371 L61.2215569,34.0598802 L58.6347305,35.3532934 L53.8922156,21.5568862 L16.3832335,27.1616766 L8.62275449,40.0958084 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hadim"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.1437126" y="20.2275449">
                      HDM
                    </tspan>
                  </text>
                </g>
                <g
                  id="halkapinar"
                  transform="translate(313.437126, 249.197605)"
                >
                  <path
                    d="M38.3712575,8.62275449 L49.5808383,29.748503 L49.5808383,40.9580838 L39.6646707,45.7005988 L19.4011976,50.4431138 L0,61.2215569 L2.15568862,27.1616766 L15.9520958,9.48502994 L18.5389222,0 L28.4550898,8.62275449 L38.3712575,8.62275449 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Halkapınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.6946108" y="34.8862275">
                      HLK
                    </tspan>
                  </text>
                </g>
                <g id="huyuk" transform="translate(18.538922, 173.317365)">
                  <path
                    d="M0,7.32934132 L17.6766467,0 L31.4730539,8.19161677 L40.9580838,5.60479042 L36.6467066,22.8502994 L20.6946108,31.4730539 L17.6766467,28.0239521 L17.6766467,24.5748503 L7.76047904,15.5209581 L6.46706587,11.2095808 L0,7.32934132 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hüyük"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.1077844" y="19.7964072">
                      HYK
                    </tspan>
                  </text>
                </g>
                <g id="ilgin" transform="translate(47.856287, 98.730539)">
                  <path
                    d="M2.15568862,82.7784431 L11.6407186,80.1916168 L21.1257485,81.9161677 L29.748503,76.3113772 L45.7005988,73.7245509 L52.5988024,62.9461078 L46.5628743,51.3053892 L52.1676647,28.0239521 L44.8383234,9.05389222 L47.4251497,0 L36.2155689,3.4491018 L33.1976048,9.48502994 L19.8323353,7.76047904 L15.9520958,25.005988 L6.46706587,28.0239521 L0,37.508982 L5.60479042,53.4610778 L13.7964072,55.1856287 L8.62275449,70.2754491 L2.15568862,82.7784431 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ilgın"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.1616766" y="49.5449102">
                      ILG
                    </tspan>
                  </text>
                </g>
                <g id="kadinhani" transform="translate(92.694611, 88.814371)">
                  <path
                    d="M28.4550898,71.5688623 L30.1796407,35.3532934 L41.8203593,15.9520958 L31.9041916,9.91616766 L7.32934132,0 L2.15568862,9.91616766 L0,18.9700599 L7.32934132,37.9401198 L1.7245509,61.2215569 L7.76047904,72.8622754 L15.9520958,76.3113772 L28.4550898,71.5688623 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kadınhanı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13.3652695" y="27.988024">
                      KDN
                    </tspan>
                  </text>
                </g>
                <g id="karapinar" transform="translate(209.532934, 185.820359)">
                  <path
                    d="M9.9475983e-14,37.0778443 L8.19161677,62.5149701 L12.502994,70.2754491 L20.2634731,68.5508982 L28.8862275,73.2934132 L51.7365269,50.011976 L70.7065868,44.4071856 L86.2275449,27.1616766 L54.3233533,0 L36.2155689,0 L15.9520958,15.0898204 L9.9475983e-14,37.0778443 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karapınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.0598802" y="36.1796407">
                      KRP
                    </tspan>
                  </text>
                </g>
                <g id="karatay" transform="translate(142.706587, 140.119760)">
                  <path
                    d="M80.6227545,0 L68.1197605,5.17365269 L56.0479042,22.4191617 L0,58.6347305 L1.7245509,85.7964072 L8.19161677,95.7125749 L12.9341317,89.245509 L11.2095808,76.742515 L25.8682635,72.8622754 L55.6167665,87.9520958 L66.8263473,82.7784431 L82.7784431,60.7904192 L103.041916,45.7005988 L80.6227545,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karatay"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="56.4790419" y="52.1317365">
                      KRT
                    </tspan>
                  </text>
                </g>
                <g id="kulu" transform="translate(161.245509, 0.000000)">
                  <path
                    d="M0,31.0419162 L3.01796407,25.005988 L9.91616766,25.4371257 L18.9700599,15.5209581 L21.1257485,5.60479042 L29.748503,6.89820359 L33.6287425,3.01796407 L38.8023952,0 L46.5628743,4.74251497 L75.0179641,25.4371257 L77.6047904,42.251497 L74.1556886,46.1317365 L62.9461078,49.1497006 L59.0658683,57.7724551 L36.2155689,64.239521 L29.748503,44.4071856 L31.0419162,34.9221557 L21.988024,28.8862275 L9.91616766,35.7844311 L0,31.0419162 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kulu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.5269461" y="33.5928144">
                      KLU
                    </tspan>
                  </text>
                </g>
                <g id="meram" transform="translate(82.347305, 182.802395)">
                  <path
                    d="M20.6946108,0 L0.862275449,8.19161677 L0,36.2155689 L9.05389222,37.9401198 L17.245509,62.9461078 L23.2814371,60.3592814 L27.5928144,68.5508982 L34.491018,62.5149701 L42.6826347,62.9461078 L59.497006,62.9461078 L53.4610778,51.7365269 L62.0838323,43.1137725 L60.3592814,15.9520958 L34.0598802,15.0898204 L20.6946108,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Meram"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.748503" y="43.0778443">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sarayonu" transform="translate(120.718563, 74.155689)">
                  <path
                    d="M30.1796407,40.5269461 L26.2994012,28.4550898 L30.6107784,12.0718563 L18.1077844,8.19161677 L13.3652695,1.29341317 L6.03592814,0 L4.31137725,7.32934132 L9.91616766,12.9341317 L3.88023952,24.5748503 L13.7964072,30.6107784 L2.15568862,50.011976 L0,86.6586826 L28.0239521,73.7245509 L29.3173653,65.9640719 L30.1796407,40.5269461 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sarayönü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.6407186" y="59.4610778">
                      SRY
                    </tspan>
                  </text>
                </g>
                <g id="selcuklu" transform="translate(103.041916, 140.119760)">
                  <path
                    d="M46.994012,0 L45.7005988,7.76047904 L18.1077844,20.2634731 L5.60479042,25.005988 L0,42.6826347 L13.3652695,57.7724551 L39.6646707,58.6347305 L95.7125749,22.4191617 L46.994012,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Selçuklu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.9401198" y="35.748503">
                      SLÇ
                    </tspan>
                  </text>
                </g>
                <g id="seydisehir" transform="translate(43.976048, 219.017964)">
                  <path
                    d="M23.7125749,68.1197605 L9.48502994,50.011976 L3.88023952,49.5808383 L3.01796407,32.3353293 L0,30.1796407 L8.19161677,21.988024 L31.9041916,11.6407186 L37.9401198,0 L47.4251497,1.7245509 L55.6167665,26.7305389 L56.0479042,46.994012 L51.7365269,50.011976 L48.7185629,52.5988024 L46.994012,45.7005988 L43.5449102,45.7005988 L42.6826347,47.4251497 L35.3532934,46.994012 L33.6287425,44.4071856 L32.3353293,49.1497006 L28.8862275,46.5628743 L26.2994012,49.1497006 L32.3353293,53.8922156 L32.7664671,57.7724551 L35.3532934,59.9281437 L23.7125749,68.1197605 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Seydişehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.5928144" y="36.6107784">
                      SYD
                    </tspan>
                  </text>
                </g>
                <g id="taskent" transform="translate(121.580838, 327.664671)">
                  <path
                    d="M0,18.5389222 L7.76047904,5.60479042 L45.2694611,0 L50.011976,13.7964072 L41.3892216,19.8323353 L24.5748503,28.4550898 L12.9341317,32.3353293 L0,18.5389222 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Taşkent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.245509" y="22.8143713">
                      TAŞ
                    </tspan>
                  </text>
                </g>
                <g id="tuzlukcu" transform="translate(28.886228, 84.071856)">
                  <path
                    d="M0,28.0239521 L0.431137725,21.988024 L5.60479042,18.1077844 L6.89820359,8.19161677 L16.8143713,1.29341317 L22.8502994,0 L28.0239521,5.60479042 L34.0598802,6.89820359 L34.0598802,18.9700599 L38.8023952,22.4191617 L34.9221557,39.6646707 L25.4371257,42.6826347 L0,28.0239521 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tuzlukçu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.0898204" y="24.9700599">
                      TUZ
                    </tspan>
                  </text>
                </g>
                <g id="yalihuyuk" transform="translate(92.694611, 269.029940)">
                  <path
                    d="M3.01796407,0 L0,2.58682635 L3.4491018,6.03592814 L0.431137725,13.7964072 L6.46706587,12.0718563 L6.89820359,6.46706587 L3.01796407,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yalıhüyük"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="1.29341317" y="14.6227545">
                      YYK
                    </tspan>
                  </text>
                </g>
                <g id="yunak" transform="translate(34.922156, 32.766467)">
                  <path
                    d="M10.3473054,52.5988024 L0,45.2694611 L1.7245509,39.2335329 L11.2095808,28.4550898 L13.7964072,16.8143713 L35.7844311,8.19161677 L58.2035928,12.0718563 L65.1017964,3.88023952 L84.0718563,0 L96.5748503,6.46706587 L107.784431,23.2814371 L99.1616766,42.6826347 L91.8323353,41.3892216 L90.1077844,48.7185629 L95.7125749,54.3233533 L89.6766467,65.9640719 L64.6706587,56.0479042 L60.3592814,65.9640719 L49.1497006,69.4131737 L46.1317365,75.4491018 L32.7664671,73.7245509 L28.0239521,70.2754491 L28.0239521,58.2035928 L21.988024,56.9101796 L16.8143713,51.3053892 L10.3473054,52.5988024 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yunak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.1676647" y="37.9041916">
                      YUN
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
