import React, { useEffect } from "react";
import "../turkeymap.css";
export const Sanliurfa = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 32.000000)">
                <g id="akcakale" transform="translate(131.795666, 244.953560)">
                  <path
                    d="M7.9876161,8.65325077 L17.9721362,12.6470588 L58.5758514,0 L69.2260062,27.2910217 L97.1826625,34.6130031 L135.789474,33.2817337 L148.436533,19.3034056 L164.411765,27.2910217 L173.73065,43.2662539 L141.114551,51.25387 L119.148607,57.244582 L83.869969,47.9256966 L46.5944272,51.9195046 L17.3065015,36.6099071 L0,25.2941176 L7.9876161,8.65325077 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akçakale"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.9411765" y="34.622291">
                      AKÇ
                    </tspan>
                  </text>
                </g>
                <g id="birecik" transform="translate(3.993808, 180.386997)">
                  <path
                    d="M85.8668731,25.2941176 L69.2260062,37.9411765 L57.9102167,33.9473684 L58.5758514,46.5944272 L59.9071207,75.8823529 L36.6099071,87.8637771 L34.6130031,83.2043344 L35.2786378,81.2074303 L31.9504644,71.2229102 L21.3003096,55.247678 L5.99071207,45.9287926 L7.9876161,43.2662539 L3.32817337,36.6099071 L0,26.625387 L63.2352941,0 L85.8668731,25.2941176 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Birecik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.9504644" y="36.619195">
                      BİR
                    </tspan>
                  </text>
                </g>
                <g id="bozova" transform="translate(37.941176, 125.804954)">
                  <path
                    d="M0,11.9814241 L18.6377709,13.9783282 L45.9287926,0 L42.6006192,3.32817337 L43.9318885,7.9876161 L60.5727554,10.6501548 L89.8606811,24.628483 L101.842105,25.2941176 L100.510836,21.3003096 L109.829721,16.6408669 L111.826625,21.3003096 L103.839009,52.5851393 L80.5417957,67.8947368 L73.2198142,61.2383901 L67.8947368,65.8978328 L65.2321981,90.5263158 L51.9195046,79.876161 L29.2879257,54.5820433 L0,11.9814241 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.9195046" y="41.9442724">
                      BOZ
                    </tspan>
                  </text>
                </g>
                <g
                  id="ceylanpinar"
                  transform="translate(264.922601, 187.708978)"
                >
                  <path
                    d="M40.6037152,100.510836 L165.077399,50.5882353 L142.44582,25.9597523 L141.780186,0 L106.501548,5.99071207 L90.5263158,29.9535604 L63.9009288,36.6099071 L24.628483,19.9690402 L7.9876161,39.9380805 L0,50.5882353 L1.99690402,65.2321981 L15.3095975,76.5479876 L31.2848297,84.5356037 L40.6037152,100.510836 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ceylanpınar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="67.2291022" y="64.5758514">
                      CYL
                    </tspan>
                  </text>
                </g>
                <g id="eyyubiye" transform="translate(119.814241, 191.037152)">
                  <path
                    d="M147.105263,61.9040248 L145.108359,47.9256966 L153.095975,36.6099071 L138.452012,37.2755418 L143.111455,15.9752322 L53.250774,0 L0,36.6099071 L19.9690402,62.5696594 L29.9535604,66.5634675 L70.5572755,53.9164087 L81.2074303,48.5913313 L125.139319,65.8978328 L137.786378,59.9071207 L147.105263,61.9040248 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eyyübiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="68.5603715" y="34.622291">
                      EYB
                    </tspan>
                  </text>
                </g>
                <g id="halfeti" transform="translate(0.000000, 134.458204)">
                  <path
                    d="M27.9566563,0 L37.9411765,3.32817337 L67.2291022,45.9287926 L3.99380805,72.5541796 L0,61.9040248 L2.6625387,55.247678 L0,45.9287926 L6.65634675,33.2817337 L9.31888545,23.2972136 L27.9566563,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Halfeti"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.628483" y="38.6160991">
                      HAL
                    </tspan>
                  </text>
                </g>
                <g id="haliliye" transform="translate(173.065015, 133.126935)">
                  <path
                    d="M55.247678,0 L23.2972136,13.3126935 L0,57.9102167 L89.8606811,73.8854489 L91.8575851,47.2600619 L55.247678,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Haliliye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.6037152" y="43.2755418">
                      HLL
                    </tspan>
                  </text>
                </g>
                <g id="harran" transform="translate(190.371517, 239.628483)">
                  <path
                    d="M0,4.65944272 L10.6501548,0 L54.5820433,17.3065015 L67.2291022,11.3157895 L76.5479876,13.3126935 L89.8606811,24.628483 L77.2136223,38.6068111 L37.9411765,39.9380805 L10.6501548,32.6160991 L0,4.65944272 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Harran"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="35.2786378" y="29.2972136">
                      HAR
                    </tspan>
                  </text>
                </g>
                <g id="hilvan" transform="translate(136.455108, 81.873065)">
                  <path
                    d="M91.8575851,51.25387 L59.9071207,64.5665635 L56.5789474,51.9195046 L44.5975232,57.244582 L16.6408669,53.9164087 L0,35.2786378 L3.32817337,26.625387 L8.65325077,18.6377709 L26.625387,12.6470588 L42.6006192,9.98452012 L40.6037152,1.33126935 L49.9226006,0 L53.250774,11.3157895 L59.9071207,7.32198142 L67.8947368,5.3250774 L72.5541796,9.98452012 L79.2105263,5.99071207 L83.869969,9.31888545 L105.170279,13.3126935 L107.832817,27.2910217 L91.8575851,51.25387 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hilvan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.9195046" y="35.9535604">
                      HİL
                    </tspan>
                  </text>
                </g>
                <g id="karakopru" transform="translate(103.173375, 117.151703)">
                  <path
                    d="M16.6408669,110.495356 L69.8916409,73.8854489 L93.1888545,29.2879257 L89.8606811,16.6408669 L77.2136223,21.9659443 L49.9226006,18.6377709 L33.2817337,0 L31.2848297,1.99690402 L32.6160991,7.32198142 L39.9380805,13.3126935 L40.6037152,16.6408669 L23.9628483,15.3095975 L17.9721362,17.9721362 L20.6346749,24.628483 L35.2786378,29.9535604 L44.5975232,25.2941176 L46.5944272,29.9535604 L38.6068111,61.2383901 L15.3095975,76.5479876 L7.9876161,69.8916409 L2.6625387,74.5510836 L0,99.1795666 L16.6408669,110.495356 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karaköprü"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53.250774" y="54.5913313">
                      KRK
                    </tspan>
                  </text>
                </g>
                <g id="siverek" transform="translate(189.040248, 0.000000)">
                  <path
                    d="M170.402477,107.832817 L164.411765,99.1795666 L155.758514,82.5386997 L161.083591,43.2662539 L145.108359,27.2910217 L123.142415,41.2693498 L105.835913,29.9535604 L41.2693498,0 L31.2848297,0 L21.3003096,8.65325077 L20.6346749,22.6315789 L7.9876161,33.2817337 L3.32817337,50.5882353 L8.65325077,53.9164087 L15.9752322,59.2414861 L0.665634675,68.5603715 L0,77.2136223 L12.6470588,71.2229102 L11.3157895,80.5417957 L29.2879257,84.5356037 L26.625387,87.8637771 L31.2848297,91.1919505 L52.5851393,95.1857585 L55.247678,109.164087 L39.2724458,133.126935 L75.8823529,180.386997 L95.8513932,179.721362 L122.47678,121.811146 L141.114551,107.832817 L170.402477,107.832817 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Siverek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="89.8606811" y="93.8637771">
                      SİV
                    </tspan>
                  </text>
                </g>
                <g id="suruc" transform="translate(61.904025, 205.681115)">
                  <path
                    d="M1.99690402,50.5882353 L26.625387,47.9256966 L55.9133127,55.247678 L69.8916409,64.5665635 L77.879257,47.9256966 L57.9102167,21.9659443 L41.2693498,10.6501548 L27.9566563,0 L11.3157895,12.6470588 L0,8.65325077 L1.99690402,50.5882353 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Suruç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.9597523" y="37.2848297">
                      SRÇ
                    </tspan>
                  </text>
                </g>
                <g
                  id="viransehir"
                  transform="translate(258.266254, 107.832817)"
                >
                  <path
                    d="M148.401094,79.876161 L101.152315,0 L71.871382,0 L53.2380607,13.9783282 L26.6190304,71.8885449 L5.98928183,72.5541796 L4.65833031,99.1795666 L0,120.479876 L14.6404667,119.814241 L31.2773607,99.8452012 L70.5404305,116.486068 L97.1594608,109.829721 L113.130879,85.8668731 L148.401094,79.876161 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Viranşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="74.5510836" y="65.9071207">
                      VİR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
