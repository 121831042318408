import React, { useEffect } from "react";
import "../turkeymap.css";

export const Zonguldak = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="436px"
          height="366px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(91.000000, 88.000000)">
                <g id="alapli" transform="translate(0.000000, 122.000000)">
                  <path
                    d="M25,0 L14,15 L0,26 L12,38 L22,55 L33,59 L42,59 L49,32 L43,15 L25,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Alaplı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20" y="31">
                      ALP
                    </tspan>
                  </text>
                </g>
                <g id="caycuma" transform="translate(175.000000, 0.000000)">
                  <path
                    d="M19,1 L9,9 L11,20 L0,50 L17,79 L40,77 L69,61 L58,38 L59,19 L19,1 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çaycuma"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26" y="48">
                      ÇAY
                    </tspan>
                  </text>
                </g>
                <g id="devrek" transform="translate(105.000000, 86.000000)">
                  <path
                    d="M110,28 L107,63 L96,76 L103,98 L9,104 L0,71 L20,42 L37,26 L38,8 L50,0 L83,10 L110,28 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Devrek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="54" y="59">
                      DVR
                    </tspan>
                  </text>
                </g>
                <g id="eregli" transform="translate(25.000000, 57.000000)">
                  <path
                    d="M118,37 L105,45 L87,43 L70,23 L69,0 L56,7 L30,11 L20,23 L13,23 L0,29 L1,37 L7,41 L0,65 L18,80 L24,97 L17,124 L89,133 L80,100 L100,71 L117,55 L118,37 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ereğli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42" y="70">
                      ERĞ
                    </tspan>
                  </text>
                </g>
                <g id="gokcebey" transform="translate(188.000000, 61.000000)">
                  <path
                    d="M4,18 L0,35 L27,53 L65,32 L54,19 L56,0 L26,16 L4,18 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gökçebey"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25" y="31">
                      GKÇ
                    </tspan>
                  </text>
                </g>
                <g id="kilimli" transform="translate(130.000000, 9.000000)">
                  <path
                    d="M54,0 L17,13 L0,25 L11,38 L25,44 L45,41 L56,11 L54,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kilimli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25" y="28">
                      KİL
                    </tspan>
                  </text>
                </g>
                <g id="kozlu" transform="translate(94.000000, 46.000000)">
                  <path
                    d="M21,0 L0,11 L1,34 L18,54 L36,56 L44,35 L33,31 L36,13 L21,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kozlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11" y="30">
                      KZL
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(115.000000, 34.000000)">
                  <path
                    d="M60,16 L40,19 L26,13 L15,0 L0,12 L15,25 L12,43 L23,47 L15,68 L28,60 L40,52 L73,62 L77,45 L60,16 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34" y="38">
                      MER
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
