import React, { useEffect } from "react";
import "../turkeymap.css";

export const Tokat = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 60.000000)">
                <g id="almus" transform="translate(255.529623, 112.710952)">
                  <path
                    d="M142.818671,48.6355476 L138.958707,68.7073609 L115.02693,66.3913824 L91.8671454,64.8473968 L71.0233393,51.7235189 L44.0035907,62.5314183 L18.5278276,58.6714542 L0,62.5314183 L7.71992819,27.0197487 L14.6678636,6.94793537 L30.8797127,0 L73.3393178,17.7558348 L75.6552962,32.4236984 L60.2154399,27.7917415 L50.951526,22.3877917 L41.6876122,17.7558348 L30.1077199,8.49192101 L30.8797127,16.2118492 L31.6517056,26.2477558 L40.1436266,23.1597846 L50.951526,30.8797127 L61.7594255,30.8797127 L70.2513465,35.5116697 L77.9712747,37.0556553 L78.7432675,34.7396768 L75.6552962,32.4236984 L142.818671,48.6355476 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Almus"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.5637343" y="45.3716338">
                      ALM
                    </tspan>
                  </text>
                </g>
                <g id="artova" transform="translate(126.606822, 173.698384)">
                  <path
                    d="M60.9874327,37.0556553 L43.2315978,39.3716338 L9.26391382,37.8276481 L0,30.1077199 L8.49192101,4.63195691 L40.9156194,9.26391382 L57.8994614,0 L67.935368,19.2998205 L60.9874327,37.0556553 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Artova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.8797127" y="28.3877917">
                      ART
                    </tspan>
                  </text>
                </g>
                <g id="basciftlik" transform="translate(305.709156, 70.251346)">
                  <path
                    d="M32.4236984,47.8635548 L40.1436266,37.0556553 L32.4236984,29.3357271 L17.7558348,27.0197487 L18.5278276,19.2998205 L37.8276481,18.5278276 L40.9156194,10.8078995 L23.9317774,6.17594255 L20.8438061,0 L9.26391382,1.54398564 L0,33.967684 L32.4236984,47.8635548 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Başçiftlik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.5798923" y="15.2639138">
                      BŞÇ
                    </tspan>
                  </text>
                </g>
                <g id="erbaa" transform="translate(182.190305, 0.000000)">
                  <path
                    d="M0,77.1992819 L16.2118492,84.1472172 L19.2998205,78.7432675 L6.94793537,66.3913824 L5.40394973,44.7755835 L10.8078995,33.1956912 L23.1597846,27.7917415 L23.1597846,23.1597846 L11.5798923,20.0718133 L6.94793537,16.983842 L8.49192101,10.0359066 L16.983842,7.71992819 L34.7396768,3.08797127 L48.6355476,0 L55.5834829,4.63195691 L68.7073609,12.3518851 L96.4991023,38.5996409 L66.3913824,66.3913824 L61.7594255,89.551167 L7.71992819,100.359066 L0,77.1992819 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Erbaa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.8276481" y="50.7755835">
                      ERB
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(176.014363, 89.551167)">
                  <path
                    d="M3.08797127,63.3034111 L8.49192101,84.1472172 L18.5278276,103.447038 L11.5798923,121.202873 L12.3518851,148.222621 L47.8635548,152.082585 L61.7594255,136.642729 L73.3393178,89.551167 L79.5152603,85.6912029 L94.1831239,30.1077199 L110.394973,23.1597846 L91.8671454,4.63195691 L67.935368,0 L13.8958707,10.8078995 L0,50.951526 L3.08797127,63.3034111 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="42.459605" y="68.5314183">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="niksar" transform="translate(243.949731, 38.599641)">
                  <path
                    d="M34.7396768,0 L85.6912029,4.63195691 L98.8150808,6.94793537 L105.763016,15.4398564 L109.62298,26.2477558 L87.2351885,24.7037702 L82.6032316,31.6517056 L71.0233393,33.1956912 L61.7594255,65.6193896 L94.1831239,79.5152603 L84.9192101,91.8671454 L42.459605,74.1113106 L23.9317774,55.5834829 L0,50.951526 L4.63195691,27.7917415 L34.7396768,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Niksar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="41.6876122" y="35.3357271">
                      NİK
                    </tspan>
                  </text>
                </g>
                <g id="pazar" transform="translate(135.098743, 152.854578)">
                  <path
                    d="M49.4075404,20.8438061 L32.4236984,30.1077199 L0,25.475763 L0,13.1238779 L13.1238779,1.54398564 L32.4236984,4.63195691 L44.0035907,0 L49.4075404,20.8438061 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pazar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.983842" y="18.3518851">
                      PZR
                    </tspan>
                  </text>
                </g>
                <g id="resadiye" transform="translate(323.464991, 63.303411)">
                  <path
                    d="M30.1077199,1.54398564 L37.8276481,15.4398564 L67.935368,23.1597846 L84.1472172,21.6157989 L95.7271095,20.0718133 L106.535009,23.1597846 L97.2710952,51.7235189 L102.675045,72.567325 L96.4991023,81.8312388 L74.8833034,98.043088 L7.71992819,81.8312388 L5.40394973,67.1633752 L14.6678636,54.8114901 L22.3877917,44.0035907 L14.6678636,36.2836625 L0,33.967684 L0.771992819,26.2477558 L20.0718133,25.475763 L23.1597846,17.7558348 L6.17594255,13.1238779 L3.08797127,6.94793537 L7.71992819,0 L30.1077199,1.54398564 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Reşadiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.0915619" y="60.0394973">
                      REŞ
                    </tspan>
                  </text>
                </g>
                <g id="sulusaray" transform="translate(102.675045, 203.806104)">
                  <path
                    d="M49.4075404,37.0556553 L10.0359066,42.459605 L0,13.8958707 L23.9317774,0 L33.967684,8.49192101 L47.8635548,21.6157989 L49.4075404,37.0556553 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sulusaray"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.5278276" y="26.8438061">
                      SLU
                    </tspan>
                  </text>
                </g>
                <g id="turhal" transform="translate(67.935368, 74.883303)">
                  <path
                    d="M0,49.4075404 L6.94793537,28.5637343 L30.1077199,33.1956912 L39.3716338,28.5637343 L47.0915619,38.5996409 L67.935368,38.5996409 L96.4991023,20.0718133 L88.7791741,5.40394973 L91.8671454,0 L114.254937,2.31597846 L121.974865,25.475763 L108.078995,65.6193896 L111.166966,77.9712747 L99.5870736,82.6032316 L80.2872531,79.5152603 L67.1633752,91.0951526 L67.1633752,103.447038 L53.2675045,100.359066 L48.6355476,68.7073609 L0,49.4075404 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Turhal"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71.7953321" y="59.2675045">
                      TUR
                    </tspan>
                  </text>
                </g>
                <g id="yesilyurt" transform="translate(135.870736, 210.754039)">
                  <path
                    d="M52.4955117,27.0197487 L15.4398564,30.1077199 L14.6678636,14.6678636 L0,0.771992819 L33.967684,2.31597846 L51.7235189,0 L52.4955117,27.0197487 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yeşilyurt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.0197487" y="18.3518851">
                      YŞL
                    </tspan>
                  </text>
                </g>
                <g id="zile" transform="translate(0.000000, 124.290844)">
                  <path
                    d="M67.935368,0 L10.0359066,27.7917415 L0,50.951526 L4.63195691,64.0754039 L27.7917415,86.4631957 L50.951526,90.3231598 L80.2872531,80.2872531 L102.675045,93.4111311 L126.606822,79.5152603 L135.098743,54.0394973 L121.202873,50.951526 L116.570916,19.2998205 L67.935368,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Zile"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="74.1113106" y="50.7755835">
                      ZLE
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
