import React, { useEffect } from "react";
import "../turkeymap.css";

export const Ordu = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);

  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            console.log(event.target.parentElement.id);
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(27.000000, 47.000000)">
                <g id="akkus" transform="translate(0.000000, 71.000000)">
                  <path
                    d="M1,16 L0,28 L17,38 L53,72 L119,78 L129,12 L95,0 L51,2 L10,17 L1,16 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Akkuş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="77" y="40">
                      AKK
                    </tspan>
                  </text>
                </g>
                <g id="altinordu" transform="translate(249.000000, 41.000000)">
                  <path
                    d="M48,0 L56,10 L83,11 L81,29 L88,41 L102,41 L96,53 L48,52 L29,37 L11,37 L1,41 L0,32 L15,18 L37,15 L48,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Altınordu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51" y="35">
                      AOR
                    </tspan>
                  </text>
                </g>
                <g id="aybasti" transform="translate(145.000000, 135.000000)">
                  <path
                    d="M30,0 L0,28 L5,42 L15,60 L54,70 L74,68 L74,7 L30,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aybastı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33" y="36">
                      AYB
                    </tspan>
                  </text>
                </g>
                <g id="camas" transform="translate(198.000000, 72.000000)">
                  <path
                    d="M43,9 L27,32 L13,26 L0,3 L5,0 L21,0 L43,9 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çamaş"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15" y="16">
                      ÇMŞ
                    </tspan>
                  </text>
                </g>
                <g id="catalpinar" transform="translate(195.000000, 75.000000)">
                  <path
                    d="M16,23 L14,35 L6,41 L0,31 L3,0 L16,23 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çatalpınar"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="2" y="27">
                      ÇTL
                    </tspan>
                  </text>
                </g>
                <g id="caybasi" transform="translate(51.000000, 29.000000)">
                  <path
                    d="M0,44 L22,14 L66,0 L65,11 L44,42 L0,44 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çaybaşı"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28" y="27">
                      ÇYB
                    </tspan>
                  </text>
                </g>
                <g id="fatsa" transform="translate(175.000000, 28.000000)">
                  <path
                    d="M21,0 L32,12 L50,12 L62,1 L69,13 L74,45 L66,53 L44,44 L28,44 L12,53 L0,37 L10,9 L21,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Fatsa"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32" y="30">
                      FAT
                    </tspan>
                  </text>
                </g>
                <g id="golkoy" transform="translate(218.000000, 122.000000)">
                  <path
                    d="M30,0 L19,0 L0,7 L1,20 L1,81 L17,79 L73,17 L30,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölköy"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18" y="33">
                      GÖL
                    </tspan>
                  </text>
                </g>
                <g id="gulyali" transform="translate(330.000000, 52.000000)">
                  <path
                    d="M2,0 L32,7 L21,30 L7,30 L0,18 L2,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gülyalı"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7" y="17">
                      GÜL
                    </tspan>
                  </text>
                </g>
                <g id="gurgentepe" transform="translate(201.000000, 73.000000)">
                  <path
                    d="M0,43 L17,56 L36,49 L47,49 L43,30 L49,9 L48,0 L40,8 L24,31 L10,25 L8,37 L0,43 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gürgentepe"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22" y="43">
                      GÜR
                    </tspan>
                  </text>
                </g>
                <g id="ikizce" transform="translate(73.000000, 16.000000)">
                  <path
                    d="M0,27 L5,8 L35,0 L44,13 L0,27 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İkizce"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16" y="14">
                      İKZ
                    </tspan>
                  </text>
                </g>
                <g id="kabaduz" transform="translate(297.000000, 93.000000)">
                  <path
                    d="M48,1 L44,23 L36,37 L37,48 L51,57 L57,87 L44,96 L23,94 L15,84 L21,51 L5,41 L0,0 L48,1 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kabadüz"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29" y="74">
                      KBD
                    </tspan>
                  </text>
                </g>
                <g id="kabatas" transform="translate(175.000000, 116.000000)">
                  <path
                    d="M26,0 L0,19 L44,26 L43,13 L26,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kabataş"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18" y="15">
                      KBT
                    </tspan>
                  </text>
                </g>
                <g id="korgan" transform="translate(136.000000, 75.000000)">
                  <path
                    d="M9,88 L0,77 L32,22 L51,6 L62,0 L59,31 L65,41 L39,60 L9,88 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Korgan"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34" y="42">
                      KOR
                    </tspan>
                  </text>
                </g>
                <g id="kumru" transform="translate(119.000000, 59.000000)">
                  <path
                    d="M17,93 L0,90 L10,24 L29,5 L40,0 L56,6 L68,22 L49,38 L17,93 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kumru"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29" y="27">
                      KMR
                    </tspan>
                  </text>
                </g>
                <g id="mesudiye" transform="translate(235.000000, 134.000000)">
                  <path
                    d="M67,0 L56,5 L0,67 L13,71 L2,108 L23,130 L43,137 L46,115 L55,113 L68,125 L97,115 L102,82 L144,69 L146,64 L147,52 L139,44 L119,46 L106,55 L85,53 L77,42 L83,10 L67,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mesudiye"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59" y="83">
                      MES
                    </tspan>
                  </text>
                </g>
                <g id="persembe" transform="translate(237.000000, 5.000000)">
                  <path
                    d="M0,24 L12,0 L24,3 L38,1 L40,18 L51,36 L60,36 L49,51 L27,54 L12,68 L7,36 L0,24 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Perşembe"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17" y="26">
                      PRŞ
                    </tspan>
                  </text>
                </g>
                <g id="ulubey" transform="translate(244.000000, 78.000000)">
                  <path
                    d="M47,61 L4,44 L0,25 L6,4 L16,0 L34,0 L53,15 L58,56 L47,61 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ulubey"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23" y="33">
                      ULB
                    </tspan>
                  </text>
                </g>
                <g id="unye" transform="translate(95.000000, 0.000000)">
                  <path
                    d="M13,16 L25,4 L47,5 L53,0 L60,4 L60,10 L101,28 L90,37 L80,65 L64,59 L53,64 L34,83 L0,71 L21,40 L22,29 L13,16 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ünye"
                    data-sandik="%100,00"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43" y="36">
                      ÜNY
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
