import React, { useEffect } from "react";
import "../turkeymap.css";

export const Isparta = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(26.000000, 3.000000)">
                <g id="aksu" transform="translate(243.989770, 200.716113)">
                  <path
                    d="M45.1150895,2.76214834 L3.68286445,0 L0,11.9693095 L14.7314578,32.2250639 L14.7314578,49.7186701 L69.9744246,99.4373402 L67.2122762,69.9744246 L58.0051151,62.6086957 L62.6086957,27.6214834 L45.1150895,2.76214834 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aksu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.2250639" y="36.3836317">
                      AKS
                    </tspan>
                  </text>
                </g>
                <g id="atabey" transform="translate(121.534527, 148.235294)">
                  <path
                    d="M0,31.3043478 L1.84143223,0 L13.8107417,0.920716113 L38.6700767,2.76214834 L48.797954,26.7007673 L26.7007673,34.9872123 L0,31.3043478 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Atabey"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.6521739" y="19.8107417">
                      ATB
                    </tspan>
                  </text>
                </g>
                <g id="egirdir" transform="translate(160.204604, 115.089514)">
                  <path
                    d="M116.930946,54.3222506 L63.5294118,50.6393862 L51.5601023,66.2915601 L49.7186701,82.8644501 L40.511509,83.7851662 L34.0664962,67.2122762 L22.0971867,56.1636829 L23.0179028,40.511509 L32.2250639,34.9872123 L33.1457801,24.859335 L40.511509,17.4936061 L39.5907928,0 L17.4936061,29.4629156 L0,35.9079284 L10.1278772,59.8465473 L6.44501279,150.076726 L10.1278772,173.094629 L23.9386189,195.191816 L46.9565217,155.601023 L83.7851662,132.58312 L98.516624,135.345269 L98.516624,116.930946 L83.7851662,97.5959079 L87.4680307,85.6265985 L128.900256,88.3887468 L129.820972,79.1815857 L113.248082,63.5294118 L116.930946,54.3222506 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Eğirdir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="206.240409" y="232.496164">
                      EĞR
                    </tspan>
                  </text>
                </g>
                <g id="gelendost" transform="translate(211.764706, 87.468031)">
                  <path
                    d="M0,18.4143223 L12.8900256,35.9079284 L11.9693095,78.2608696 L65.370844,81.943734 L74.5780051,63.5294118 L60.7672634,46.0358056 L70.8951407,27.6214834 L39.5907928,0 L0,18.4143223 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gelendost"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.7800512" y="47.4322251">
                      GLD
                    </tspan>
                  </text>
                </g>
                <g id="gonen" transform="translate(83.785166, 139.028133)">
                  <path
                    d="M7.3657289,0 L39.5907928,9.20716113 L37.7493606,40.511509 L2.76214834,62.6086957 L0,58.0051151 L8.28644501,43.2736573 L7.3657289,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gönen"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.6521739" y="27.1764706">
                      GÖN
                    </tspan>
                  </text>
                </g>
                <g id="keciborlu" transform="translate(0.000000, 132.583120)">
                  <path
                    d="M0,83.7851662 L25.7800512,40.511509 L41.4322251,18.4143223 L76.4194373,0 L91.1508951,5.52429668 L92.0716113,49.7186701 L83.7851662,64.4501279 L75.4987212,60.7672634 L46.0358056,69.9744246 L16.57289,77.3401535 L11.0485934,92.0716113 L3.68286445,92.0716113 L0,83.7851662 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Keçiborlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.5601023" y="44.6700767">
                      KEÇ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(86.547315, 174.936061)">
                  <path
                    d="M34.9872123,4.60358056 L0,26.7007673 L21.1764706,63.5294118 L43.2736573,76.4194373 L80.1023018,90.230179 L83.7851662,0 L61.6879795,8.28644501 L34.9872123,4.60358056 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.8286445" y="38.2250639">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="senirkent" transform="translate(118.772379, 58.005115)">
                  <path
                    d="M14.7314578,24.859335 L55.2429668,0 L67.2122762,17.4936061 L56.1636829,40.511509 L62.6086957,51.5601023 L81.0230179,57.084399 L58.9258312,86.5473146 L41.4322251,92.9923274 L16.57289,91.1508951 L0,55.2429668 L14.7314578,24.859335 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Senirkent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.6214834" y="59.4015345">
                      SNR
                    </tspan>
                  </text>
                </g>
                <g id="sutculer" transform="translate(184.143223, 247.672634)">
                  <path
                    d="M0,62.6086957 L2.76214834,69.0537084 L7.3657289,65.370844 L10.1278772,74.5780051 L12.8900256,76.4194373 L18.4143223,72.7365729 L16.57289,89.3094629 L20.2557545,97.5959079 L22.0971867,101.278772 L32.2250639,112.327366 L52.4808184,103.120205 L120.613811,88.3887468 L129.820972,52.4808184 L74.5780051,2.76214834 L59.8465473,0 L23.0179028,23.0179028 L0,62.6086957 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sütçüler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.8465473" y="63.084399">
                      SÜT
                    </tspan>
                  </text>
                </g>
                <g
                  id="sarkikaraagac"
                  transform="translate(272.531969, 91.150895)"
                >
                  <path
                    d="M67.2122762,0 L112.327366,39.5907928 L103.120205,58.0051151 L65.370844,73.657289 L58.9258312,81.0230179 L44.1943734,79.1815857 L40.511509,84.7058824 L45.1150895,91.1508951 L17.4936061,103.120205 L0.920716113,87.4680307 L4.60358056,77.3401535 L13.8107417,59.8465473 L0,42.3529412 L10.1278772,23.9386189 L34.0664962,22.0971867 L67.2122762,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şarkikaraağaç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.8772379" y="50.1943734">
                      ŞRK
                    </tspan>
                  </text>
                </g>
                <g id="uluborlu" transform="translate(76.419437, 82.864450)">
                  <path
                    d="M0,49.7186701 L20.2557545,15.6521739 L57.084399,0 L42.3529412,30.3836317 L58.9258312,66.2915601 L47.8772379,65.370844 L14.7314578,56.1636829 L0,49.7186701 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Uluborlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.4936061" y="42.8286445">
                      ULB
                    </tspan>
                  </text>
                </g>
                <g id="yalvac" transform="translate(174.015345, 0.000000)">
                  <path
                    d="M0,58.0051151 L12.8900256,49.7186701 L45.1150895,28.5421995 L70.8951407,2.76214834 L100.358056,0 L124.296675,25.7800512 L165.7289,91.1508951 L132.58312,113.248082 L108.644501,115.089514 L77.3401535,87.4680307 L37.7493606,105.882353 L25.7800512,106.803069 L33.1457801,81.0230179 L42.3529412,69.0537084 L38.6700767,63.5294118 L11.9693095,75.4987212 L0,58.0051151 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yalvaç"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="74.5780051" y="53.8772379">
                      YLV
                    </tspan>
                  </text>
                </g>
                <g
                  id="yenisarbademli"
                  transform="translate(289.104859, 182.301790)"
                >
                  <path
                    d="M29.4629156,0 L0.920716113,11.9693095 L0,21.1764706 L17.4936061,46.0358056 L12.8900256,81.0230179 L22.0971867,88.3887468 L53.4015345,58.0051151 L42.3529412,23.0179028 L29.4629156,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenişarbademli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.7800512" y="41.9079284">
                      YNB
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
