import React, { useEffect } from "react";
import "../turkeymap.css";

export const Usak = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(55.000000, 65.000000)">
                <g id="banaz" transform="translate(193.000000, 0.000000)">
                  <path
                    d="M117,6 L80,1 L43,0 L0,21 L9,34 L38,43 L39,86 L71,100 L84,133 L106,112 L100,87 L132,61 L127,32 L105,23 L117,6 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Banaz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="72" y="48">
                      BNZ
                    </tspan>
                  </text>
                </g>
                <g id="esme" transform="translate(0.000000, 62.000000)">
                  <path
                    d="M62,0 L24,12 L1,104 L0,112 L19,133 L22,150 L101,153 L99,146 L96,94 L109,37 L62,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Esme"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45" y="73">
                      EŞM
                    </tspan>
                  </text>
                </g>
                <g id="karahalli" transform="translate(167.000000, 151.000000)">
                  <path
                    d="M24,0 L3,16 L7,25 L0,46 L31,68 L45,72 L60,21 L24,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karahallı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23" y="36">
                      KRH
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(62.000000, 21.000000)">
                  <path
                    d="M131,0 L72,20 L21,19 L0,41 L47,78 L73,84 L85,103 L129,117 L146,86 L170,65 L169,22 L140,13 L131,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="94" y="50">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sivasli" transform="translate(191.000000, 86.000000)">
                  <path
                    d="M0,52 L0,65 L36,86 L54,77 L75,58 L86,47 L73,14 L41,0 L17,21 L0,52 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sivaslı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36" y="48">
                      SVS
                    </tspan>
                  </text>
                </g>
                <g id="ulubey" transform="translate(96.000000, 99.000000)">
                  <path
                    d="M13,0 L0,57 L3,109 L8,109 L16,137 L21,132 L17,118 L19,99 L25,89 L29,92 L20,109 L22,114 L30,118 L60,98 L71,98 L78,77 L74,68 L95,52 L95,39 L51,25 L39,6 L13,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ulubey"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32" y="56">
                      ULU
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
