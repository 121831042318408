import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material/";
import Logo from "../assets/imgs/sela.png";
import { Helmet } from "react-helmet";

function About() {
  return (
    <Card sx={{ minWidth: 275 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sela.TC Hakkımızda</title>
        <meta
          name="description"
          content="Sela.TC Vefat Haberleri, Cenaze ilanları Hakkımızda"
        ></meta>
      </Helmet>
      <CardContent>
        <Grid
          container
          flexDirection={"center"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid lg={4} md={4}>
            <img src={Logo} style={{ width: "100%", height: "auto" }} />
          </Grid>
          <Grid lg={12} md={12} sx={{ height: "100%" }}>
            <br />
            <p>T&uuml;rkiye&rsquo;de bir ilk...</p>
            <p>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; G&uuml;n&uuml;m&uuml;zde
              İletişimden, bilgiye ulaşıma, bankacılıktan, alışverişe daha bir
              &ccedil;ok işlemi internette yapabiliyoruz. Teknolojinin bu denli
              ilerlediği d&ouml;nemde bizlerde &ldquo;O DA MUTLU OLSUN
              DERNEĞİ&rdquo; olarak sizlere bu teknoloji vasıtasıyla yeni bir
              hizmet alanı a&ccedil;ıyoruz. Cenaze ilanlarının &uuml;cretsiz
              yayınlanarak geliştirilen yazılımla bilgi sahibi olmanızı sağlayan
              SELA.TC İNTERNET sitemiz hizmetinizde&hellip;
            </p>
            <p>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; O da Mutlu Olsun Derneği,
              T&uuml;rkiye&rsquo;de Belediyeler ve Diyanet İşleri Başkanlığı
              işbirliği ile ilinizde ,il&ccedil;enizde, k&ouml;y&uuml;n&uuml;zde
              ve mahallenizde vefat eden komşu, akraba ve hemşerilerinizin
              vefatını iletişim cihazlarınıza (cep telefonu ,tablet, bilgisayar)
              gelecek bildirimlerle sizlere &uuml;cretsiz ulaştırıyoruz.
              Projenin geliri tamamen vatandaşların taziye ve başsağlığı ilan
              bağışlarıyla elde edilecek olup, sizlere en iyi şekilde hizmet
              vermeyi ama&ccedil;lamaktadır.
            </p>
            <p>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Ordu B&uuml;y&uuml;kşehir
              Belediyesiyle başlattığımız pilot il uygulamasını diğer
              Belediyelerle yapacağımız protokollerle T&uuml;rkiye&rsquo;de ki
              t&uuml;m, il, il&ccedil;e ve mahallelerdeki cenaze ilanlarını
              g&uuml;nl&uuml;k yayınlayarak, sizlere hizmet vermeyi
              hedefliyoruz.
            </p>
          </Grid>
          <Grid sx={{ height: "100px" }} />
        </Grid>
      </CardContent>
    </Card>
  );
}

export default About;
