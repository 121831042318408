import React, { useEffect } from "react";
import "../turkeymap.css";

export const Rize = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(81.000000, 43.000000)">
                <g id="ardesen" transform="translate(153.000000, 31.000000)">
                  <path
                    d="M0,19 L35,0 L74,43 L104,55 L66,102 L57,134 L52,136 L31,88 L29,66 L3,35 L0,19 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ardeşen"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45" y="62">
                      ARD
                    </tspan>
                  </text>
                </g>
                <g
                  id="camlihemsin"
                  transform="translate(126.000000, 66.000000)"
                >
                  <path
                    d="M30,0 L56,31 L58,53 L79,101 L71,120 L15,146 L13,133 L0,123 L13,108 L9,89 L24,29 L21,6 L30,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çamlıhemşin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33" y="94">
                      ÇHM
                    </tspan>
                  </text>
                </g>
                <g id="cayeli" transform="translate(76.000000, 73.000000)">
                  <path
                    d="M0,22 L18,16 L28,0 L45,8 L47,62 L59,82 L63,101 L50,116 L19,101 L22,89 L16,75 L1,51 L1,37 L0,22 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çayeli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22" y="61">
                      ÇAY
                    </tspan>
                  </text>
                </g>
                <g id="derepazari" transform="translate(11.000000, 112.000000)">
                  <path
                    d="M0,10 L14,0 L20,20 L10,20 L0,10 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Derepazarı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="3" y="13">
                      DRP
                    </tspan>
                  </text>
                </g>
                <g id="findikli" transform="translate(188.000000, 0.000000)">
                  <path
                    d="M0,31 L7,20 L13,11 L35,0 L47,13 L56,39 L83,56 L86,63 L69,86 L39,74 L0,31 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Fındıklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29" y="45">
                      FND
                    </tspan>
                  </text>
                </g>
                <g id="guneysu" transform="translate(58.000000, 110.000000)">
                  <path
                    d="M19,0 L19,14 L34,38 L15,43 L0,12 L19,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Güneysu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="13" y="33">
                      GNY
                    </tspan>
                  </text>
                </g>
                <g id="hemsin" transform="translate(121.000000, 72.000000)">
                  <path
                    d="M26,0 L29,23 L14,83 L2,63 L0,9 L26,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hemşin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6" y="21">
                      HMŞ
                    </tspan>
                  </text>
                </g>
                <g id="ikizdere" transform="translate(30.000000, 167.000000)">
                  <path
                    d="M0,3 L15,55 L4,81 L10,112 L46,109 L86,85 L111,45 L109,32 L96,22 L65,7 L27,0 L0,3 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İkizdere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47" y="56">
                      İKZ
                    </tspan>
                  </text>
                </g>
                <g id="iyidere" transform="translate(0.000000, 122.000000)">
                  <path
                    d="M0,11 L11,0 L21,10 L12,20 L0,11 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İyidere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="6" y="13">
                      İYD
                    </tspan>
                  </text>
                </g>
                <g id="kalkandere" transform="translate(12.000000, 132.000000)">
                  <path
                    d="M45,35 L18,38 L0,10 L9,0 L19,0 L45,35 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kalkandere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8" y="17">
                      KLD
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(25.000000, 95.000000)">
                  <path
                    d="M0,17 L12,14 L21,17 L51,0 L52,15 L33,27 L48,58 L67,53 L73,67 L71,79 L32,72 L6,37 L0,17 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17" y="43">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="pazar" transform="translate(104.000000, 50.000000)">
                  <path
                    d="M0,23 L20,6 L49,0 L52,16 L43,22 L17,31 L0,23 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pazar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="21" y="18">
                      PZR
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
