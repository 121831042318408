import React, { useEffect } from "react";
import "../turkeymap.css";

export const Kocaeli = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 6.000000)">
                <g id="basiskele" transform="translate(208.000000, 231.000000)">
                  <path
                    d="M11.8134328,17.6940299 L34.2761194,20.9029851 L40.6940299,8.06716418 L55.1343284,0.0447761194 L71.1791045,85.0820896 L29.4626866,117.171642 L0.582089552,101.126866 L11.8134328,17.6940299 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Başiskele"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.6492537" y="64.8059701">
                      BAŞ
                    </tspan>
                  </text>
                </g>
                <g id="cayirova" transform="translate(9.000000, 147.000000)">
                  <path
                    d="M0.626865672,37.5149254 L19.880597,0.611940299 L40.738806,24.6791045 L26.2985075,43.9328358 L10.2537313,47.141791 L0.626865672,37.5149254 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çayırova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8.64925373" y="26.8656716">
                      ÇAY
                    </tspan>
                  </text>
                </g>
                <g id="darica" transform="translate(0.000000, 184.000000)">
                  <path
                    d="M32.0895522,32.6044776 L19.2537313,37.4179104 L8.02238806,34.2089552 L0,19.7686567 L3.20895522,5.32835821 L9.62686567,0.514925373 L19.2537313,10.141791 L32.0895522,19.7686567 L32.0895522,32.6044776 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Darıca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="4.81343284" y="21.9552239">
                      DAR
                    </tspan>
                  </text>
                </g>
                <g id="derince" transform="translate(176.000000, 68.000000)">
                  <path
                    d="M40.6044776,0.992537313 L13.3283582,9.01492537 L24.5597015,94.0522388 L0.492537313,163.044776 L14.9328358,167.858209 L42.2089552,166.253731 L43.8134328,124.537313 L64.6716418,116.514925 L75.9029851,89.238806 L64.6716418,49.1268657 L40.6044776,0.992537313 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Derince"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.1865672" y="83.4029851">
                      DRN
                    </tspan>
                  </text>
                </g>
                <g id="dilovasi" transform="translate(65.000000, 158.000000)">
                  <path
                    d="M39.2910448,55.3955224 L0.78358209,53.7910448 L21.641791,0.843283582 L74.5895522,13.6791045 L39.2910448,55.3955224 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dilovası"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.0373134" y="33.5149254">
                      DİL
                    </tspan>
                  </text>
                </g>
                <g id="gebze" transform="translate(19.000000, 85.000000)">
                  <path
                    d="M139.843284,9.6641791 L114.171642,19.2910448 L82.0820896,0.0373134328 L54.8059701,3.24626866 L40.3656716,16.0820896 L9.88059701,62.6119403 L30.738806,86.6791045 L16.2985075,105.932836 L0.253731343,109.141791 L13.0895522,118.768657 L13.0895522,131.604478 L46.7835821,126.791045 L67.641791,73.8432836 L120.589552,86.6791045 L139.843284,9.6641791 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gebze"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.619403" y="42.3358209">
                      GBZ
                    </tspan>
                  </text>
                </g>
                <g id="golcuk" transform="translate(146.000000, 243.000000)">
                  <path
                    d="M0.00746268657,10.5074627 L30.4925373,0.880597015 L73.8134328,5.69402985 L62.5820896,89.1268657 L14.4477612,63.4552239 L1.6119403,69.8731343 L0.00746268657,10.5074627 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gölcük"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.4701493" y="36.761194">
                      GÖL
                    </tspan>
                  </text>
                </g>
                <g id="izmit" transform="translate(218.000000, 117.000000)">
                  <path
                    d="M30.6940299,122.067164 L27.4850746,112.440299 L0.208955224,117.253731 L1.81343284,75.5373134 L22.6716418,67.5149254 L33.9029851,40.238806 L22.6716418,0.126865672 L80.4328358,24.1940299 L98.0820896,48.261194 L139.798507,30.6119403 L175.097015,62.7014925 L155.843284,107.626866 L45.1343284,114.044776 L30.6940299,122.067164 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İzmit"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="80.4328358" y="74.5149254">
                      İZT
                    </tspan>
                  </text>
                </g>
                <g id="kandira" transform="translate(216.000000, 0.000000)">
                  <path
                    d="M177.097015,179.701493 L183.514925,134.776119 L214,11.2313433 L175.492537,-1.42506239e-15 L136.985075,30.4850746 L18.2537313,27.2761194 L0.604477612,68.9925373 L24.6716418,117.126866 L82.4328358,141.19403 L100.08209,165.261194 L141.798507,147.61194 L177.097015,179.701493 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kandıra"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="98.4776119" y="82.4104478">
                      KAN
                    </tspan>
                  </text>
                </g>
                <g id="karamursel" transform="translate(35.000000, 253.000000)">
                  <path
                    d="M53.2462687,6.92537313 L111.007463,0.507462687 L112.61194,59.8731343 L67.6865672,83.9402985 L21.1567164,82.3358209 L0.298507463,82.3358209 L25.9701493,19.761194 L53.2462687,6.92537313 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karamürsel"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.8283582" y="42.8059701">
                      KRM
                    </tspan>
                  </text>
                </g>
                <g id="kartepe" transform="translate(263.000000, 224.000000)">
                  <path
                    d="M0.134328358,7.04477612 L110.843284,0.626865672 L107.634328,27.9029851 L72.3358209,34.3208955 L72.3358209,43.9477612 L96.4029851,47.1567164 L72.3358209,79.2462687 L16.1791045,92.0820896 L0.134328358,7.04477612 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kartepe"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.619403" y="38.1119403">
                      KAR
                    </tspan>
                  </text>
                </g>
                <g id="korfez" transform="translate(104.000000, 77.000000)">
                  <path
                    d="M85.3283582,0.0149253731 L54.8432836,17.6641791 L35.5895522,94.6791045 L0.291044776,136.395522 L32.380597,139.604478 L59.6567164,142.813433 L72.4925373,154.044776 L96.5597015,85.0522388 L85.3283582,0.0149253731 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Körfez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.6343284" y="96.8656716">
                      KFZ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
