import React, { useEffect } from "react";
import "../turkeymap.css";

export const Bursa = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 36.000000)">
                <g
                  id="buyukorhan"
                  transform="translate(131.000000, 212.000000)"
                >
                  <path
                    d="M53.4123711,3.44329897 L9.96907216,0.783505155 L0.216494845,26.4948454 L6.42268041,53.0927835 L25.0412371,68.1649485 L48.0927835,63.7319588 L81.7835052,40.6804124 L53.4123711,3.44329897 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Büyükorhan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.7010309" y="37.0412371">
                      BOR
                    </tspan>
                  </text>
                </g>
                <g id="gemlik" transform="translate(203.000000, 12.000000)">
                  <path
                    d="M0.917525773,54.4948454 L59.4329897,66.9072165 L86.0309278,55.3814433 L60.3195876,43.8556701 L59.4329897,5.73195876 L29.2886598,0.412371134 L9.78350515,19.0309278 L39.0412371,34.9896907 L0.917525773,54.4948454 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gemlik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.8350515" y="23.371134">
                      GEM
                    </tspan>
                  </text>
                </g>
                <g id="gursu" transform="translate(229.000000, 74.000000)">
                  <path
                    d="M43.185567,0.474226804 L33.4329897,4.90721649 L0.628865979,28.8453608 L10.3814433,37.7113402 L29,34.1649485 L44.0721649,15.5463918 L43.185567,0.474226804 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gürsu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="16.5876289" y="26.0927835">
                      GÜR
                    </tspan>
                  </text>
                </g>
                <g id="harmancik" transform="translate(179.000000, 237.000000)">
                  <path
                    d="M54.1752577,0.608247423 L33.7835052,15.6804124 L0.0927835052,38.7319588 L13.3917526,50.257732 L54.1752577,57.3505155 L71.0206186,44.0515464 L84.3195876,16.5670103 L54.1752577,0.608247423 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Harmancık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.3298969" y="35.9793814">
                      HRM
                    </tspan>
                  </text>
                </g>
                <g id="inegol" transform="translate(268.000000, 119.000000)">
                  <path
                    d="M34.3298969,0.690721649 L0.639175258,44.1340206 L25.4639175,85.8041237 L103.484536,99.9896907 L96.3917526,23.742268 L34.3298969,0.690721649 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İnegöl"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="45.8556701" y="52.9072165">
                      İNG
                    </tspan>
                  </text>
                </g>
                <g id="iznik" transform="translate(320.000000, 0.000000)">
                  <path
                    d="M0.948453608,15.9587629 L26.6597938,16.8453608 L58.5773196,0 L85.1752577,14.185567 L101.134021,23.0515464 L110,28.371134 L70.9896907,68.2680412 L6.26804124,74.4742268 L7.15463918,62.9484536 L39.9587629,62.0618557 L51.4845361,47.8762887 L31.0927835,33.6907216 L6.26804124,33.6907216 L0.948453608,15.9587629 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İznik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.4639175" y="36.257732">
                      İZN
                    </tspan>
                  </text>
                </g>
                <g id="karacabey" transform="translate(0.000000, 41.000000)">
                  <path
                    d="M22.1649485,0.670103093 L21.2783505,11.3092784 L0,38.7938144 L7.09278351,55.6391753 L5.31958763,69.8247423 L7.97938144,81.3505155 L8.86597938,85.7835052 L17.7319588,93.7628866 L49.6494845,100.85567 L85.1134021,66.2783505 L81.5670103,58.2989691 L122.350515,69.8247423 L129.443299,44.1134021 L109.938144,29.0412371 L114.371134,15.742268 L22.1649485,0.670103093 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karacabey"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="49.6494845" y="44.9072165">
                      KRC
                    </tspan>
                  </text>
                </g>
                <g id="keles" transform="translate(216.000000, 163.000000)">
                  <path
                    d="M52.6391753,0.134020619 L0.329896907,8.11340206 L17.1752577,74.6082474 L47.3195876,90.5670103 L69.4845361,76.3814433 L77.4639175,41.8041237 L52.6391753,0.134020619 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Keles"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.0206186" y="43.4845361">
                      KEL
                    </tspan>
                  </text>
                </g>
                <g id="kestel" transform="translate(227.000000, 70.000000)">
                  <path
                    d="M53.1649485,0.927835052 L45.185567,4.4742268 L46.0721649,19.5463918 L31,38.1649485 L12.3814433,41.7113402 L0.855670103,67.4226804 L41.6391753,93.1340206 L75.3298969,49.6907216 L53.1649485,0.927835052 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kestel"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="29.2268041" y="58.4639175">
                      KES
                    </tspan>
                  </text>
                </g>
                <g id="mudanya" transform="translate(109.000000, 50.000000)">
                  <path
                    d="M5.37113402,6.74226804 L50.5876289,0.536082474 L94.9175258,16.4948454 L84.2783505,32.4536082 L20.443299,35.1134021 L0.93814433,20.0412371 L5.37113402,6.74226804 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mudanya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.2886598" y="23.4948454">
                      MDY
                    </tspan>
                  </text>
                </g>
                <g
                  id="mustafakemalpasa"
                  transform="translate(17.000000, 107.000000)"
                >
                  <path
                    d="M120.42268,15.3505155 L130.175258,35.742268 L138.154639,69.4329897 L123.969072,105.783505 L114.216495,131.494845 L102.690722,127.061856 L68.1134021,131.494845 L52.1546392,106.670103 L21.1237113,91.5979381 L2.50515464,48.1546392 L0.731958763,27.7628866 L32.6494845,34.8556701 L68.1134021,0.278350515 L84.9587629,24.2164948 L108.010309,21.556701 L120.42268,15.3505155 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mustafakemalpaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="71.6597938" y="79.0927835">
                      MKP
                    </tspan>
                  </text>
                </g>
                <g id="nilufer" transform="translate(122.000000, 82.000000)">
                  <path
                    d="M71.2783505,0.453608247 L7.44329897,3.11340206 L0.350515464,28.8247423 L18.9690722,29.7113402 L14.5360825,40.3505155 L24.2886598,59.8556701 L56.2061856,61.628866 L71.278349,29.7113406 L71.2783505,0.453608247 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nilüfer"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.2474227" y="31.3917526">
                      NFR
                    </tspan>
                  </text>
                </g>
                <g id="orhaneli" transform="translate(140.000000, 141.000000)">
                  <path
                    d="M38.2061856,2.62886598 L7.17525773,0.855670103 L15.1546392,35.4329897 L0.969072165,71.7835052 L44.4123711,74.443299 L72.7835052,111.680412 L93.1752577,96.6082474 L76.3298969,30.1134021 L38.2061856,2.62886598 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Orhaneli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.8865979" y="48.6391753">
                      ORH
                    </tspan>
                  </text>
                </g>
                <g id="orhangazi" transform="translate(262.000000, 15.000000)">
                  <path
                    d="M0.432989691,2.73195876 L40.3298969,0.958762887 L58.9484536,0.958762887 L64.2680412,18.6907216 L39.443299,13.371134 L23.4845361,19.5773196 L31.4639175,38.1958763 L65.1546392,47.9484536 L64.2680412,59.4742268 L27.0309278,52.3814433 L1.31958763,40.8556701 L0.432989691,2.73195876 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Orhangazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="8.41237113" y="31.0103093">
                      ORG
                    </tspan>
                  </text>
                </g>
                <g id="osmangazi" transform="translate(178.000000, 66.000000)">
                  <path
                    d="M15.5104258,45.6282271 L0.206185567,77.628866 L38.3298969,105.113402 L90.6391753,97.1340206 L49.8556701,71.4226804 L37.3581145,45.3930095 L51.1678448,36.7216457 L83.500438,13.1448704 L25.5382418,0.966438138 L15.5104258,16.3039912 L15.5104258,45.6282271 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Osmangazi"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17" y="71">
                      OSM
                    </tspan>
                  </text>
                </g>
                <g id="yenisehir" transform="translate(280.000000, 67.000000)">
                  <path
                    d="M110.989691,1.26804124 L46.2680412,7.4742268 L9.03092784,0.381443299 L0.164948454,3.92783505 L22.3298969,52.6907216 L84.3917526,75.742268 L114.536082,35.8453608 L115.42268,20.7731959 L110.989691,1.26804124 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenişehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="53.3608247" y="35.7525773">
                      YNŞ
                    </tspan>
                  </text>
                </g>
                <g id="yildirim" transform="translate(215.000000, 102.000000)">
                  <path
                    d="M14.628866,0.845360825 L0.443298969,9.71134021 L12.8556701,35.4226804 L24.3814433,9.71134021 L14.628866,0.845360825 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yıldırım"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="7.53608247" y="14.9381443">
                      YIL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
