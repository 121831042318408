import React, { useEffect } from "react";
import "../turkeymap.css";

export const Igdir = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 113.000000)">
                <g id="aralik" transform="translate(282.450980, 16.862745)">
                  <path
                    d="M16.8627451,0 L127.52451,89.5833333 L146.495098,122.254902 L94.8529412,80.0980392 L66.3970588,105.392157 L7.37745098,116.985294 L23.1862745,52.6960784 L0,43.2107843 L16.8627451,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aralık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.372549" y="67.127451">
                      ARA
                    </tspan>
                  </text>
                </g>
                <g id="karakoyunlu" transform="translate(238.186275, 8.431373)">
                  <path
                    d="M0,11.5931373 L29.5098039,0 L61.127451,8.43137255 L44.2647059,51.6421569 L0,11.5931373 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karakoyunlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="24.2401961" y="20.754902">
                      KRK
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(134.901961, 20.024510)">
                  <path
                    d="M20.0245098,6.32352941 L103.284314,0 L147.54902,40.0490196 L170.735294,49.5343137 L154.926471,113.823529 L0,104.338235 L20.0245098,6.32352941 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="69.5588235" y="63.9656863">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="tuzluca" transform="">
                  {" "}
                  <path
                    d="M0,55.8578431 L60.0735294,8.43137255 L99.0686275,0 L154.926471,26.3480392 L134.901961,124.362745 L85.3676471,106.446078 L69.5588235,126.470588 L48.4803922,125.416667 L49.5343137,77.9901961 L0,55.8578431 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tuzluca"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="84.3137255" y="56.5882353">
                      TUZ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
