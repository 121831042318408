import React, { useEffect } from "react";
import "../turkeymap.css";

export const Kirklareli = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(14.000000, 5.000000)">
                <g id="babaeski" transform="translate(12.000000, 190.000000)">
                  <path
                    d="M30.7972028,3.84615385 L49.6783217,0.0699300699 L122.685315,26.5034965 L92.4755245,66.7832168 L88.6993007,128.461538 L66.041958,129.72028 L33.3146853,113.356643 L30.7972028,96.993007 L10.6573427,79.3706294 L0.587412587,61.7482517 L30.7972028,3.84615385 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Babaeski"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="48.4195804" y="56.1608392">
                      BAB
                    </tspan>
                  </text>
                </g>
                <g id="demirkoy" transform="translate(211.000000, 52.000000)">
                  <path
                    d="M14.3146853,28.5594406 L0.468531469,38.6293706 L13.0559441,68.8391608 L45.7832168,92.7552448 L111.237762,99.048951 L170.398601,128 L149,65.0629371 L172.916084,52.4755245 L167.881119,13.4545455 L128.86014,13.4545455 L115.013986,0.867132867 L98.6503497,23.5244755 L68.4405594,9.67832168 L14.3146853,28.5594406 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Demirköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="77.2517483" y="58.2167832">
                      DMK
                    </tspan>
                  </text>
                </g>
                <g id="kofcaz" transform="translate(47.000000, 0.000000)">
                  <path
                    d="M116.636364,18.8811189 L86.4265734,5.68434189e-14 L70.0629371,17.6223776 L34.8181818,5.68434189e-14 L0.832167832,27.6923077 L0.832167832,65.4545455 L20.972028,65.4545455 L77.6153846,83.0769231 L116.636364,18.8811189 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kofcaz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.1468531" y="44.7622378">
                      KOF
                    </tspan>
                  </text>
                </g>
                <g
                  id="luleburgaz"
                  transform="translate(100.000000, 216.000000)"
                >
                  <path
                    d="M141.678322,69.7342657 L135.384615,54.6293706 L111.468531,39.5244755 L106.433566,13.0909091 L81.2587413,1.76223776 L34.6853147,0.503496503 L4.47552448,40.7832168 L0.699300699,102.461538 L19.5804196,104.979021 L51.048951,132.671329 L72.4475524,144 L113.986014,132.671329 L141.678322,69.7342657 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Lüleburgaz"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.8601399" y="67.9230769">
                      LÜL
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(17.000000, 18.000000)">
                  <path
                    d="M208.314685,62.5594406 L166.776224,29.8321678 L146.636364,0.881118881 L127.755245,31.0909091 L107.615385,65.0769231 L50.972028,47.4545455 L30.8321678,47.4545455 L34.6083916,106.615385 L15.7272727,148.153846 L0.622377622,162 L25.7972028,175.846154 L44.6783217,172.06993 L117.685315,198.503497 L140.342657,154.447552 L217.125874,110.391608 L207.055944,102.839161 L194.468531,72.6293706 L208.314685,62.5594406 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="98.8041958" y="113.615385">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="pehlivankoy" transform="translate(0.000000, 251.000000)">
                  <path
                    d="M12.5874126,0.748251748 L0,23.4055944 L2.51748252,56.1328671 L20.1398601,58.6503497 L45.3146853,52.3566434 L42.7972028,35.993007 L22.6573427,18.3706294 L12.5874126,0.748251748 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pehlivanköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.0699301" y="41.7342657">
                      PEH
                    </tspan>
                  </text>
                </g>
                <g
                  id="pinarhisar"
                  transform="translate(134.000000, 128.000000)"
                >
                  <path
                    d="M100.125874,0.391608392 L122.783217,16.7552448 L108.937063,70.8811189 L96.3496503,94.7972028 L100.125874,104.867133 L101.384615,142.629371 L77.4685315,127.524476 L72.4335664,101.090909 L47.2587413,89.7622378 L0.685314685,88.5034965 L23.3426573,44.4475524 L100.125874,0.391608392 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pınarhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="61.1048951" y="59">
                      PNR
                    </tspan>
                  </text>
                </g>
                <g id="vize" transform="translate(230.000000, 144.000000)">
                  <path
                    d="M151.398601,36 L92.2377622,7.04895105 L26.7832168,0.755244755 L12.9370629,54.8811189 L0.34965035,78.7972028 L4.12587413,88.8671329 L5.38461538,126.629371 L11.6783217,141.734266 L128.741259,98.9370629 L177.832168,92.6433566 L158.951049,67.4685315 L162.727273,53.6223776 L151.398601,36 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Vize"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="70.8391608" y="63.1398601">
                      VİZ
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
