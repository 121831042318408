import React, { useEffect } from "react";
import "../turkeymap.css";

export const Aydin = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 75.000000)">
                <g id="bozdogan" transform="translate(270.000000, 79.000000)">
                  <path
                    d="M31.177686,0.0702479339 L21.4049587,1.84710744 L16.9628099,28.5 L0.0826446281,42.714876 L9.8553719,58.7066116 L16.9628099,90.6900826 L41.838843,96.9090909 L68.4917355,118.231405 L95.1446281,117.342975 L98.6983471,113.789256 L92.4793388,104.016529 L86.2603306,110.235537 L82.7066116,108.458678 L87.1487603,99.5743802 L85.3719008,96.0206612 L75.5991736,89.8016529 L82.7066116,75.5867769 L69.3801653,8.95454545 L39.1735537,24.946281 L31.177686,0.0702479339 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bozdoğan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="33.8429752" y="57.7107438">
                      BDĞ
                    </tspan>
                  </text>
                </g>
                <g id="buharkent" transform="translate(389.000000, 23.000000)">
                  <path
                    d="M8.12809917,0.0991735537 L36.5578512,23.1983471 L35.6694215,31.1942149 L17.9008264,40.0785124 L0.132231405,9.87190083 L8.12809917,0.0991735537 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Buharkent"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="10.5702479" y="24.8677686">
                      BUH
                    </tspan>
                  </text>
                </g>
                <g id="cine" transform="translate(170.000000, 105.000000)">
                  <path
                    d="M116.96281,64.6900826 L103.636364,73.5743802 L37.0041322,81.5702479 L29.0082645,79.7933884 L35.2272727,54.0289256 L0.578512397,31.8181818 L22.7892562,5.16528926 L58.3264463,0.723140496 L84.0909091,7.83057851 L100.082645,16.714876 L109.855372,32.7066116 L116.96281,64.6900826 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çine"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.2190083" y="39.7066116">
                      ÇİN
                    </tspan>
                  </text>
                </g>
                <g id="didim" transform="translate(33.000000, 159.000000)">
                  <path
                    d="M45.1818182,6.24793388 L14.9752066,0.0289256198 L4.31404959,3.58264463 L4.31404959,13.3553719 L8.75619835,12.4669421 L9.6446281,33.7892562 L0.760330579,48.892562 L12.3099174,55.1115702 L21.1942149,51.5578512 L29.1900826,57.7768595 L29.1900826,45.338843 L37.1859504,46.2272727 L44.2933884,34.677686 L54.0661157,35.5661157 L54.0661157,44.4504132 L48.7355372,44.4504132 L49.6239669,48.0041322 L55.8429752,48.892562 L62.0619835,41.785124 L57.6198347,33.7892562 L49.6239669,25.7933884 L53.177686,14.2438017 L45.1818182,6.24793388 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Didim"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="22.0826446" y="28.3512397">
                      DDM
                    </tspan>
                  </text>
                </g>
                <g id="efeler" transform="translate(175.000000, 31.000000)">
                  <path
                    d="M63.0991736,0.983471074 L49.7727273,5.42561983 L12.4586777,6.31404959 L0.020661157,59.6198347 L16.9008264,68.5041322 L17.7892562,79.1652893 L53.3264463,74.7231405 L79.0909091,81.8305785 L78.2024793,46.2933884 L68.4297521,54.2892562 L55.9917355,51.6239669 L61.322314,35.6322314 L63.0991736,0.983471074 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Efeler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.5619835" y="44.4090909">
                      EFE
                    </tspan>
                  </text>
                </g>
                <g id="germencik" transform="translate(79.000000, 19.000000)">
                  <path
                    d="M80.9173554,12.0950413 L60.4834711,0.545454545 L34.7190083,14.7603306 L11.6198347,36.9710744 L0.958677686,52.9628099 L32.053719,52.0743802 L26.7231405,65.4008264 L39.161157,67.177686 L48.9338843,62.7355372 L69.3677686,65.4008264 L63.1487603,52.0743802 L80.9173554,12.0950413 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Germencik"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.0495868" y="37.7520661">
                      GER
                    </tspan>
                  </text>
                </g>
                <g id="incirliova" transform="translate(142.000000, 31.000000)">
                  <path
                    d="M45.4586777,6.31404959 L29.4669421,7.20247934 L17.9173554,0.0950413223 L0.148760331,40.0743802 L6.3677686,53.4008264 L23.2479339,52.5123967 L33.0206612,59.6198347 L45.4586777,6.31404959 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İncirliova"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.0289256" y="33.7479339">
                      İNC
                    </tspan>
                  </text>
                </g>
                <g id="karacasu" transform="translate(339.000000, 77.000000)">
                  <path
                    d="M15.4834711,0.29338843 L0.380165289,10.9545455 L13.7066116,77.5867769 L6.59917355,91.8016529 L16.3719008,98.0206612 L10.1528926,88.2479339 L12.8181818,86.4710744 L18.1487603,93.5785124 L25.2561983,92.6900826 L26.1446281,96.2438017 L39.4710744,92.6900826 L56.3512397,78.4752066 L80.338843,68.7024793 L91,46.4917355 L67.0123967,16.285124 L56.3512397,16.285124 L15.4834711,0.29338843 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karacasu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.694215" y="47.38429752">
                      KAR
                    </tspan>
                  </text>
                </g>
                <g id="karpuzlu" transform="translate(153.000000, 116.000000)">
                  <path
                    d="M4.25206612,56.3553719 L20.2438017,69.6818182 L46.0082645,68.7933884 L52.2272727,43.0289256 L17.5785124,20.8181818 L0.698347107,36.8099174 L4.25206612,56.3553719 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karpuzlu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.3553719" y="52.6942149">
                      KPZ
                    </tspan>
                  </text>
                </g>
                <g id="kocarli" transform="translate(118.000000, 81.000000)">
                  <path
                    d="M17.0413223,79.8057851 L39.2520661,91.3553719 L35.6983471,71.8099174 L52.5785124,55.8181818 L74.7892562,29.1652893 L73.9008264,18.5041322 L57.0206612,9.61983471 L47.2479339,2.51239669 L30.3677686,3.40082645 L9.9338843,0.73553719 L0.161157025,5.17768595 L25.0371901,56.7066116 L17.0413223,79.8057851 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Koçarlı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.1446281" y="34.3884298">
                      KOÇ
                    </tspan>
                  </text>
                </g>
                <g id="kosk" transform="translate(230.000000, 31.000000)">
                  <path
                    d="M23.2024793,46.2933884 L13.4297521,54.2892562 L0.991735537,51.6239669 L6.32231405,35.6322314 L8.09917355,0.983471074 L40.9710744,0.983471074 L21.4256198,27.6363636 L23.2024793,46.2933884 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Köşk"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.6528926" y="15.0909091">
                      KÖŞ
                    </tspan>
                  </text>
                </g>
                <g id="kusadasi" transform="translate(0.000000, 49.000000)">
                  <path
                    d="M90.6198347,6.97107438 L60.4132231,0.752066116 L50.6404959,9.63636364 L58.6363636,33.6239669 L42.6446281,52.2809917 L4.44214876,54.0578512 L0,63.8305785 L55.9710744,61.1652893 L72.8512397,38.0661157 L79.9586777,22.9628099 L90.6198347,6.97107438 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kuşadası"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.5247934" y="17.5247934">
                      KUŞ
                    </tspan>
                  </text>
                </g>
                <g id="kuyucak" transform="translate(332.000000, 8.000000)">
                  <path
                    d="M55.3553719,4.43801653 L42.0289256,0.884297521 L23.3719008,32.8677686 L0.272727273,41.7520661 L7.38016529,79.9545455 L22.4834711,69.2933884 L63.3512397,85.285124 L74.0123967,85.285124 L74.9008264,55.0785124 L57.1322314,24.8719008 L65.1280992,15.0991736 L55.3553719,4.43801653 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kuyucak"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.9214876" y="50.5289256">
                      KUY
                    </tspan>
                  </text>
                </g>
                <g id="nazilli" transform="translate(285.000000, 0.000000)">
                  <path
                    d="M89.0289256,8.88429752 L81.0330579,6.21900826 L62.3760331,4.44214876 L46.3842975,4.44214876 L25.0619835,0 L9.95867769,25.7644628 L0.185950413,31.0950413 L16.177686,79.0702479 L24.1735537,103.946281 L54.3801653,87.9545455 L47.2727273,49.7520661 L70.3719008,40.8677686 L89.0289256,8.88429752 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nazilli"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.838843" y="39.8719008">
                      NAZ
                    </tspan>
                  </text>
                </g>
                <g id="soke" transform="translate(0.000000, 71.000000)">
                  <path
                    d="M79.9586777,0.962809917 L111.053719,0.0743801653 L105.72314,13.4008264 L118.161157,15.177686 L143.03719,66.7066116 L135.041322,89.8057851 L95.9504132,88.0289256 L87.0661157,91.5826446 L93.285124,81.8099174 L86.177686,80.0330579 L83.5123967,87.1404959 L77.2933884,90.6942149 L78.1818182,94.2479339 L47.9752066,88.0289256 L37.3140496,91.5826446 L37.3140496,101.355372 L30.2066116,102.243802 L31.9834711,83.5867769 L48.8636364,81.8099174 L48.8636364,77.3677686 C48.8636364,77.3677686 30.2066116,79.1446281 30.2066116,78.2561983 C30.2066116,77.3677686 27.5413223,63.1528926 27.5413223,63.1528926 L30.2066116,63.1528926 L31.0950413,72.0371901 L38.2024793,77.3677686 L44.4214876,74.7024793 L40.8677686,65.8181818 L25.7644628,54.268595 L7.99586777,46.2727273 L0,41.8305785 L55.9710744,39.1652893 L79.9586777,0.962809917 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Söke"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="82.6239669" y="56.8264463">
                      SÖK
                    </tspan>
                  </text>
                </g>
                <g
                  id="sultanhisar"
                  transform="translate(251.000000, 31.000000)"
                >
                  <path
                    d="M34.1859504,0.0950413223 L19.9710744,0.983471074 L0.425619835,27.6363636 L2.20247934,46.2933884 L15.5289256,40.9628099 L29.7438017,50.7355372 L40.4049587,49.8471074 L50.177686,48.0702479 L34.1859504,0.0950413223 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sultanhisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="15.5289256" y="31.0826446">
                      SLT
                    </tspan>
                  </text>
                </g>
                <g id="yenipazar" transform="translate(253.000000, 71.000000)">
                  <path
                    d="M38.4049587,9.84710744 L27.7438017,10.7355372 L13.5289256,0.962809917 L0.202479339,6.29338843 L1.09090909,41.8305785 L17.0826446,50.714876 L33.9628099,36.5 L38.4049587,9.84710744 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yenipazar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.7520661" y="26.6198347">
                      YNP
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
