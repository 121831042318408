import React, { useEffect } from "react";
import "../turkeymap.css";

export const Tunceli = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(3.000000, 40.000000)">
                <g id="cemisgezek" transform="translate(0.000000, 139.351852)">
                  <path
                    d="M43.7962963,0 L87.5925926,54.7453704 L70.6712963,103.518519 L58.7268519,102.523148 L39.8148148,80.625 L36.8287037,110.486111 L26.875,122.430556 L11.9444444,112.476852 L22.8935185,105.509259 L15.9259259,92.5694444 L15.9259259,78.6342593 L0,66.6898148 L0,37.8240741 L43.7962963,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çemişgezek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.8287037" y="55.7685185">
                      ÇMŞ
                    </tspan>
                  </text>
                </g>
                <g id="hozat" transform="translate(43.796296, 120.439815)">
                  <path
                    d="M135.37037,14.9305556 L58.7268519,23.8888889 L60.7175926,0 L43.7962963,15.9259259 L0,18.912037 L43.7962963,73.6574074 L75.6481481,102.523148 L111.481481,76.6435185 L135.37037,14.9305556 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hozat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="60.7175926" y="48.8009259">
                      HOZ
                    </tspan>
                  </text>
                </g>
                <g id="mazgirt" transform="translate(202.060185, 172.199074)">
                  <path
                    d="M85.6018519,7.96296296 L66.6898148,0 L39.8148148,12.9398148 L6.96759259,12.9398148 L0,40.8101852 L5.97222222,46.7824074 L14.9305556,46.7824074 L24.8842593,88.587963 L79.6296296,76.6435185 L101.527778,38.8194444 L85.6018519,7.96296296 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Mazgirt"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.7638889" y="41.8333333">
                      MZG
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(155.277778, 82.615741)">
                  <path
                    d="M84.6064815,0 L74.6527778,4.97685185 L23.8888889,52.7546296 L0,114.467593 L46.7824074,130.393519 L53.75,102.523148 L86.5972222,102.523148 L113.472222,89.5833333 L102.523148,54.7453704 L108.49537,24.8842593 L84.6064815,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.7222222" y="65.7222222">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="nazimiye" transform="translate(239.884259, 82.615741)">
                  <path
                    d="M70.6712963,80.625 L82.6157407,12.9398148 L-1.13686838e-13,1.27897692e-13 L23.8888889,24.8842593 L17.9166667,54.7453704 L28.8657407,89.5833333 L47.7777778,97.5462963 L70.6712963,80.625 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Nazımiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="39.8148148" y="47.8055556">
                      NZM
                    </tspan>
                  </text>
                </g>
                <g id="ovacik" transform="translate(10.949074, 47.777778)">
                  <path
                    d="M32.8472222,91.5740741 L76.6435185,88.587963 L93.5648148,72.662037 L91.5740741,96.5509259 L168.217593,87.5925926 L218.981481,39.8148148 L209.027778,10.9490741 L152.291667,0 L15.9259259,54.7453704 L0,74.6527778 L32.8472222,91.5740741 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ovacık"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="129.398148" y="48.8009259">
                      OVA
                    </tspan>
                  </text>
                </g>
                <g id="pertek" transform="translate(70.671296, 194.097222)">
                  <path
                    d="M0,48.7731481 L38.8194444,63.7037037 L66.6898148,59.7222222 L119.444444,89.5833333 L147.314815,68.6805556 L137.361111,24.8842593 L131.388889,18.912037 L84.6064815,2.98611111 L48.7731481,28.8657407 L16.9212963,0 L0,48.7731481 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pertek"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="87.5925926" y="44.8194444">
                      PRT
                    </tspan>
                  </text>
                </g>
                <g id="pulumur" transform="translate(219.976852, 0.000000)">
                  <path
                    d="M0,58.7268519 L40.8101852,24.8842593 L47.7777778,3.98148148 L99.537037,16.9212963 L131.388889,2.98611111 L188.125,0 L210.023148,17.9166667 L159.259259,53.75 L167.222222,64.6990741 L133.37963,94.5601852 L102.523148,95.5555556 L19.9074074,82.6157407 L9.9537037,87.5925926 L0,58.7268519 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pülümür"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="96.5509259" y="50.7916667">
                      PÜL
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
