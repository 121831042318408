import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

import Logo from "./logo";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useRef } from "react";
import {
  Grid,
  Typography,
  TextField,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Button,
  Paper,
} from "@mui/material/";

import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import { Link, useNavigate } from "react-router-dom";

import { useContext } from "../store";

import Service from "../service";

const NavBar = () => {
  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    setAnchor(null);
  }, [isMobile]);

  const handleMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  return (
    <AppBar position="relative">
      <Toolbar
        sx={{
          backgroundImage: `linear-gradient(to bottom, #5baf33, #a5de61)`,
        }}
      >
        {isMobile ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Logo />
            <IconButton onClick={handleMenu}>
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchor}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={() => setAnchor(null)}
            >
              <MenuItem onClick={() => setAnchor(null)} component={Link} to="/">
                <Typography variant="h6"> Ana Sayfa</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => setAnchor(null)}
                component={Link}
                to="/hakkimizda"
              >
                <Typography variant="h6"> Hakkımızda</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => setAnchor(null)}
                component={Link}
                to="/iletisim"
              >
                <Typography variant="h6"> İletişim</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems={"center"}
          >
            <Logo />
            <Search />
            <Grid />
          </Grid>
        )}
      </Toolbar>

      <Grid
        sx={{
          shadowColor: "#000",
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.4,
          bgcolor: "white",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems={"center"}
        >
          <Button
            sx={{
              color: "#333",
              fontWeight: "bold",
              fontFamily: "Quicksand, sans-serif",
              fontSize: "h7.fontSize",
            }}
            component={Link}
            to="/"
          >
            Anasayfa
          </Button>
          <Button
            sx={{
              color: "#333",
              fontWeight: "bold",
              fontFamily: "Quicksand, sans-serif",
              fontSize: "h7.fontSize",
            }}
            component={Link}
            to="/hakkimizda"
          >
            Hakkımızda
          </Button>

          <Button
            sx={{
              color: "#333",
              fontWeight: "bold",
              fontFamily: "Quicksand, sans-serif",
              fontSize: "h7.fontSize",

              pr: 2,
            }}
            component={Link}
            to="/iletisim"
          >
            İLETİŞİM
          </Button>

          {isMobile && (
            <Grid>
              <a href="https://www.facebook.com/Selatc-110669198209234/">
                <FacebookIcon htmlColor="#2196f3" />
              </a>
              <a href="http://instagram.com/sela.tc?igshid=YmMyMTA2M2Y=">
                <InstagramIcon htmlColor="#ec407a" />
              </a>
              <a href="http://twitter.com">
                <TwitterIcon htmlColor="#2196f3" />
              </a>
            </Grid>
          )}
          {!isMobile && (
            <>
              <Typography
                sx={{
                  color: "#333",
                  fontWeight: "bold",
                  fontFamily: "Quicksand, sans-serif",
                  fontSize: 14,
                  pl: 1,
                  pr: 1,
                }}
              >
                TAKİP EDİN
              </Typography>
              <Grid>
                <a
                  style={{ marginLeft: 4, marginRight: 4 }}
                  target={"_blank"}
                  href="https://www.facebook.com/Selatc-110669198209234/"
                >
                  <FacebookIcon htmlColor="#2196f3" />
                </a>
                <a
                  style={{ marginLeft: 4, marginRight: 4 }}
                  target={"_blank"}
                  href="http://instagram.com/sela.tc?igshid=YmMyMTA2M2Y="
                >
                  <InstagramIcon htmlColor="#ec407a" />
                </a>
                <a
                  style={{ marginLeft: 4, marginRight: 4 }}
                  target={"_blank"}
                  href="http://twitter.com"
                >
                  <TwitterIcon htmlColor="#2196f3" />
                </a>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
};

const Search = () => {
  const navigate = useNavigate();
  const search = useRef("");

  const [, distpach] = useContext();

  const handleSearch = (searchText) => {
    searchText &&
      Service.getSearchWithPage("deceaseds/search", searchText, 1).then(
        (data) => {
          distpach({
            type: "SEARCH_DECEASE",
            payload: { response: data, name: searchText },
          });
          navigate("/vefatara");
        }
      );
  };

  return (
    <Paper
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Cenaze İlanı Ara"
        inputProps={{ "aria-label": "cenaze ilanı ara" }}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            handleSearch(search.current);
          }
        }}
        onChange={(e) => {
          search.current = e.target.value;
        }}
      />
      <IconButton
        sx={{ p: "10px" }}
        aria-label="ara"
        onClick={() => handleSearch(search.current)}
      >
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        color="primary"
        sx={{ p: "10px" }}
        aria-label="directions"
        onClick={() => handleSearch(search.current)}
      >
        ara
      </IconButton>
    </Paper>
  );
};

export default NavBar;
