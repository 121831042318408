import React, { useEffect } from "react";
import "../turkeymap.css";

export const Afyonkarahisar = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>",
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            console.log(event.target.parentElement.id);
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(16.000000, 3.000000)">
                <g id="basmakci" transform="translate(35.000000, 300.000000)">
                  <path
                    d="M28.1451613,0.483870968 L17.983871,1.93548387 L10.7258065,27.3387097 L17.983871,25.1612903 L28.8709677,30.2419355 L29.5967742,36.7741935 L0.564516129,52.7419355 L2.74193548,57.8225806 L21.6129032,53.4677419 L33.9516129,55.6451613 L37.5806452,60 L57.9032258,25.8870968 L28.1451613,0.483870968 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Başmakçı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.7741935" y="29.2580645">
                      BAŞ
                    </tspan>
                  </text>
                </g>
                <g id="bayat" transform="translate(220.000000, 17.000000)">
                  <path
                    d="M30.4032258,94.7741935 L26.7741935,86.0645161 L15.8870968,78.0806452 L15.1612903,43.9677419 L1.37096774,22.1935484 L0.64516129,15.6612903 L26.0483871,12.7580645 L34.7580645,0.419354839 L43.4677419,1.87096774 L48.5483871,12.7580645 L44.9193548,46.8709677 L49.2741935,56.3064516 L49.2741935,76.6290323 L30.4032258,94.7741935 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bayat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.8709677" y="48.7903226">
                      BAY
                    </tspan>
                  </text>
                </g>
                <g id="bolvadin" transform="translate(240.000000, 86.000000)">
                  <path
                    d="M138.870968,27.9516129 L53.2258065,0.370967742 L29.2741935,7.62903226 L10.4032258,25.7741935 L0.967741935,57.7096774 L43.0645161,65.6935484 L59.7580645,59.8870968 L63.3870968,52.6290323 L73.5483871,58.4354839 L74.2741935,63.516129 L138.870968,27.9516129 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Bolvadin"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.4193548" y="32.7741935">
                      BOL
                    </tspan>
                  </text>
                </g>
                <g id="cay" transform="translate(205.000000, 143.000000)">
                  <path
                    d="M83.8709677,46.4354839 L99.1129032,36.2741935 L96.9354839,15.9516129 L78.0645161,18.1290323 L75.1612903,13.0483871 L78.0645161,8.69354839 L35.9677419,0.709677419 L27.983871,30.4677419 L0.403225806,63.1290323 L14.9193548,85.6290323 L40.3225806,69.6612903 L60.6451613,48.6129032 L83.8709677,46.4354839 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çay"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.483871" y="36.7419355">
                      ÇAY
                    </tspan>
                  </text>
                </g>
                <g id="cobanlar" transform="translate(207.000000, 111.000000)">
                  <path
                    d="M0.580645161,50.1290323 L0.580645161,2.22580645 L43.4032258,0.774193548 L33.9677419,32.7096774 L25.983871,62.4677419 L0.580645161,50.1290323 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çobanlar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.4677419" y="28.8225806">
                      ÇOB
                    </tspan>
                  </text>
                </g>
                <g id="dazkiri" transform="translate(0.000000, 288.000000)">
                  <path
                    d="M52.983871,13.9354839 L28.3064516,11.7580645 L21.7741935,0.870967742 L4.35483871,15.3870968 L1.8189894e-12,35.7096774 L19.5967742,51.6774194 L32.6612903,45.8709677 L39.9193548,46.5967742 L45.7258065,39.3387097 L52.983871,13.9354839 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dazkırı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="20.3225806" y="31.8225806">
                      DAZ
                    </tspan>
                  </text>
                </g>
                <g id="dinar" transform="translate(55.000000, 206.000000)">
                  <path
                    d="M96.6935484,0.129032258 L76.3709677,26.983871 L47.3387097,45.1290323 L21.9354839,42.2258065 L0.887096774,64 L8.14516129,94.483871 L37.9032258,119.887097 L50.2419355,102.467742 L77.8225806,87.9516129 L93.7903226,61.0967742 L122.822581,48.7580645 L120.645161,26.2580645 L96.6935484,0.129032258 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Dinar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.6935484" y="68.8225806">
                      DNR
                    </tspan>
                  </text>
                </g>
                <g id="emirdag" transform="translate(263.000000, 0.000000)">
                  <path
                    d="M0.467741935,18.8709677 L11.3548387,18.1451613 L43.2903226,0 L63.6129032,1.4516129 L72.3225806,25.4032258 L123.854839,44.2741935 L121.677419,67.5 L139.822581,75.483871 L136.919355,88.5483871 L124.580645,89.2741935 L126.758065,98.7096774 L123.129032,118.306452 L115.870968,113.951613 L30.2258065,86.3709677 L6.27419355,93.6290323 L6.27419355,73.3064516 L1.91935484,63.8709677 L5.5483871,29.7580645 L0.467741935,18.8709677 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Emirdağ"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.5483871" y="62.8870968">
                      EMD
                    </tspan>
                  </text>
                </g>
                <g id="evciler" transform="translate(21.000000, 270.000000)">
                  <path
                    d="M34.8870968,0 L21.0967742,13.0645161 L0.774193548,18.8709677 L7.30645161,29.7580645 L31.983871,31.9354839 L42.1451613,30.483871 L34.8870968,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Evciler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="18.9193548" y="24.4193548">
                      EVC
                    </tspan>
                  </text>
                </g>
                <g id="hocalar" transform="translate(28.000000, 119.000000)">
                  <path
                    d="M43.1290323,0.0322580645 L19.9032258,18.9032258 L23.5322581,37.0483871 L8.29032258,52.2903226 L0.306451613,60.2741935 L24.2580645,71.8870968 L60.5483871,47.9354839 L69.2580645,29.7903226 L64.1774194,16 L43.1290323,0.0322580645 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hocalar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.4193548" y="41.8709677">
                      HOC
                    </tspan>
                  </text>
                </g>
                <g id="ihsaniye" transform="translate(119.000000, 7.000000)">
                  <path
                    d="M88.5806452,18.4032258 L68.983871,20.5806452 L58.0967742,4.61290323 L44.3064516,0.983870968 L10.1935484,27.8387097 L0.758064516,55.4193548 L43.5806452,75.016129 L87.8548387,61.9516129 L92.2096774,51.0645161 L87.8548387,39.4516129 L88.5806452,18.4032258 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İhsaniye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="37.7741935" y="44.2741935">
                      İHS
                    </tspan>
                  </text>
                </g>
                <g id="iscehisar" transform="translate(200.000000, 25.000000)">
                  <path
                    d="M51.1290323,86.7741935 L7.58064516,88.2258065 L0.322580645,83.8709677 L6.85483871,43.9516129 L11.2096774,33.0645161 L6.85483871,21.4516129 L7.58064516,0.403225806 L20.6451613,7.66129032 L21.3709677,14.1935484 L35.1612903,35.9677419 L35.8870968,70.0806452 L46.7741935,78.0645161 L51.1290323,86.7741935 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İscehisar"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="11.9354839" y="61.1129032">
                      İSÇ
                    </tspan>
                  </text>
                </g>
                <g id="kiziloren" transform="translate(73.000000, 219.000000)">
                  <path
                    d="M58.3709677,13.983871 L23.5322581,0.193548387 L0.306451613,16.8870968 L3.93548387,29.2258065 L29.3387097,32.1290323 L58.3709677,13.983871 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kızılören"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="19.1774194" y="21.7096774">
                      KZL
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(114.000000, 62.000000)">
                  <path
                    d="M5.75806452,0.419354839 L0.677419355,14.2096774 L32.6129032,35.2580645 L33.3387097,46.8709677 L44.2258065,61.3870968 L39.8709677,84.6129032 L93.5806452,99.1290323 L93.5806452,51.2258065 L86.3225806,46.8709677 L92.8548387,6.9516129 L48.5806452,20.016129 L5.75806452,0.419354839 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="57.2903226" y="51.6935484">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="sandikli" transform="translate(52.000000, 135.000000)">
                  <path
                    d="M40.1774194,0 L70.6612903,3.62903226 L85.9032258,34.8387097 L99.6935484,71.1290323 L79.3709677,97.983871 L44.5322581,84.1935484 L21.3064516,100.887097 L14.7741935,90 L13.3225806,71.1290323 L0.258064516,55.8870968 L36.5483871,31.9354839 L45.2580645,13.7903226 L40.1774194,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sandıklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="50.3387097" y="54.1774194">
                      SND
                    </tspan>
                  </text>
                </g>
                <g id="sinanpasa" transform="translate(71.000000, 76.000000)">
                  <path
                    d="M43.6774194,0.209677419 L0.129032258,43.0322581 L20.4516129,59 L51.6612903,62.6290323 L66.9032258,93.8387097 L82.8709677,70.6129032 L87.2258065,47.3870968 L76.3387097,32.8709677 L75.6129032,21.2580645 L43.6774194,0.209677419 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sinanpaşa"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.0483871" y="42.7741935">
                      SNN
                    </tspan>
                  </text>
                </g>
                <g
                  id="sultandagi"
                  transform="translate(288.000000, 113.000000)"
                >
                  <path
                    d="M98.1290323,5.30645161 L90.8709677,0.951612903 L26.2741935,36.516129 L21.9193548,48.1290323 L13.9354839,45.9516129 L16.1129032,66.2741935 L0.870967742,76.4354839 L19.7419355,96.7580645 L41.516129,82.2419355 L32.0806452,65.5483871 L34.2580645,59.016129 L50.9516129,60.4677419 L77.0806452,49.5806452 L79.983871,57.5645161 L96.6774194,45.9516129 L78.5322581,33.6129032 L81.4354839,23.4516129 L98.1290323,5.30645161 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Sultandağı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="44.4193548" y="47.1451613">
                      SLT
                    </tspan>
                  </text>
                </g>
                <g id="suhut" transform="translate(137.000000, 146.000000)">
                  <path
                    d="M16.8709677,0.612903226 L0.903225806,23.8387097 L14.6935484,60.1290323 L38.6451613,86.2580645 L40.8225806,108.758065 L82.9193548,82.6290323 L68.4032258,60.1290323 L95.983871,27.4677419 L70.5806452,15.1290323 L16.8709677,0.612903226 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şuhut"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="31.3870968" y="39.5483871">
                      ŞUH
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
