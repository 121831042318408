import React, { useEffect } from "react";
import "../turkeymap.css";

export const Erzurum = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(44.000000, 3.000000)">
                <g id="askale" transform="translate(0.000000, 182.368421)">
                  <path
                    d="M50.3289474,2.36842105 L58.0263158,9.47368421 L79.3421053,0 L101.25,39.0789474 L91.7763158,51.5131579 L102.434211,65.1315789 L58.0263158,82.3026316 L40.2631579,45.5921053 L10.0657895,39.0789474 L0,30.1973684 L11.25,19.5394737 L50.3289474,2.36842105 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aşkale"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="58.0263158" y="40.9342105">
                      AŞK
                    </tspan>
                  </text>
                </g>
                <g id="aziziye" transform="translate(50.328947, 133.223684)">
                  <path
                    d="M0,51.5131579 L17.7631579,24.2763158 L79.3421053,0 L91.7763158,23.0921053 L76.3815789,30.7894737 L82.3026316,81.7105263 L74.6052632,98.8815789 L63.9473684,100.065789 L50.9210526,88.2236842 L29.0131579,49.1447368 L7.69736842,58.6184211 L0,51.5131579 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Aziziye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="47.9605263" y="43.8947368">
                      AZZ
                    </tspan>
                  </text>
                </g>
                <g id="cat" transform="translate(58.026316, 235.657895)">
                  <path
                    d="M66.9078947,71.0526316 L27.8289474,79.9342105 L9.47368421,82.3026316 L0,75.7894737 L5.92105263,62.7631579 L25.4605263,57.4342105 L0,29.0131579 L44.4078947,11.8421053 L95.3289474,0 L100.065789,15.3947368 L75.7894737,37.8947368 L66.9078947,71.0526316 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Çat"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.8157895" y="46.2631579">
                      ÇAT
                    </tspan>
                  </text>
                </g>
                <g id="hinis" transform="translate(180.000000, 285.986842)">
                  <path
                    d="M62.1710526,0 L33.75,2.36842105 L0.592105263,18.3552632 L0,36.1184211 L20.7236842,42.0394737 L44.4078947,74.0131579 L65.7236842,72.8289474 L80.5263158,64.5394737 L78.1578947,5.92105263 L62.1710526,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hinis"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="46.7763158" y="37.9736842">
                      HIN
                    </tspan>
                  </text>
                </g>
                <g id="horasan" transform="translate(247.500000, 128.486842)">
                  <path
                    d="M29.6052632,0 L36.1184211,13.6184211 L65.1315789,19.5394737 L101.25,58.6184211 L62.1710526,85.2631579 L34.3421053,85.2631579 L0.592105263,37.3026316 L0,26.0526316 L29.6052632,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Horasan"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="40.2631579" y="49.2236842">
                      HOR
                    </tspan>
                  </text>
                </g>
                <g id="ispir" transform="translate(44.407895, 33.750000)">
                  <path
                    d="M0,120.789474 L23.6842105,123.75 L85.2631579,99.4736842 L82.3026316,68.6842105 L110.131579,48.5526316 L104.210526,14.2105263 L76.3815789,0 L73.4210526,1.18421053 L68.6842105,12.4342105 L35.5263158,27.8289474 L20.7236842,51.5131579 L42.0394737,95.9210526 L0,120.789474 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="İspir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="59.8026316" y="55.1447368">
                      İSP
                    </tspan>
                  </text>
                </g>
                <g id="karacoban" transform="translate(258.157895, 291.907895)">
                  <path
                    d="M0,0 L2.36842105,58.6184211 L32.5657895,50.3289474 L50.3289474,20.1315789 L0,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karaçoban"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="17.1710526" y="37.9736842">
                      KRÇ
                    </tspan>
                  </text>
                </g>
                <g id="karayazi" transform="translate(216.710526, 213.750000)">
                  <path
                    d="M0,13.6184211 L25.4605263,72.2368421 L41.4473684,78.1578947 L91.7763158,98.2894737 L114.868421,71.0526316 L129.078947,55.6578947 L124.342105,29.6052632 L92.9605263,0 L65.1315789,0 L17.7631579,5.92105263 L0,13.6184211 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Karayazı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.1710526" y="45.0789474">
                      KRY
                    </tspan>
                  </text>
                </g>
                <g id="koprukoy" transform="translate(233.881579, 165.789474)">
                  <path
                    d="M14.2105263,0 L47.9605263,47.9605263 L0.592105263,53.8815789 L14.2105263,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Köprüköy"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="14.8026316" y="37.3815789">
                      KPR
                    </tspan>
                  </text>
                </g>
                <g id="narman" transform="translate(203.092105, 108.947368)">
                  <path
                    d="M78.1578947,0 L74.0131579,14.8026316 L74.0131579,19.5394737 L44.4078947,45.5921053 L2.36842105,45 L11.25,31.3815789 L0,26.0526316 L4.73684211,2.96052632 L78.1578947,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Narman"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="32.5657895" y="27.9078947">
                      NRM
                    </tspan>
                  </text>
                </g>
                <g id="oltu" transform="translate(195.986842, 37.302632)">
                  <path
                    d="M14.8026316,23.6842105 L32.5657895,0 L77.5657895,5.92105263 L85.2631579,71.6447368 L11.8421053,74.6052632 L0,60.3947368 L14.8026316,23.6842105 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Oltu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="36.7105263" y="45.6710526">
                      OLT
                    </tspan>
                  </text>
                </g>
                <g id="olur" transform="translate(228.552632, 0.000000)">
                  <path
                    d="M0,37.3026316 L2.36842105,5.32894737 L8.88157895,0 L59.8026316,1.77631579 L76.9736842,17.1710526 L52.6973684,27.2368421 L45,43.2236842 L0,37.3026316 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Olur"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="23.0921053" y="21.9868421">
                      OLR
                    </tspan>
                  </text>
                </g>
                <g id="palandoken" transform="translate(91.776316, 203.684211)">
                  <path
                    d="M87.0394737,0 L40.8552632,11.25 L33.1578947,28.4210526 L22.5,29.6052632 L9.47368421,17.7631579 L0,30.1973684 L10.6578947,43.8157895 L61.5789474,31.9736842 L71.0526316,22.5 L87.0394737,22.5 L87.0394737,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Palandöken"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="" y=""></tspan>
                  </text>
                </g>
                <g id="pasinler" transform="translate(176.447368, 153.947368)">
                  <path
                    d="M71.0526316,0.592105263 L71.6447368,11.8421053 L58.0263158,65.7236842 L40.2631579,73.4210526 L2.36842105,72.2368421 L2.36842105,49.7368421 L0,26.0526316 L20.7236842,0 L71.0526316,0.592105263 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pasinler"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.7894737" y="39.75">
                      PSN
                    </tspan>
                  </text>
                </g>
                <g id="pazaryolu" transform="translate(28.421053, 85.263158)">
                  <path
                    d="M36.7105263,6.57368896e-17 L58.0263158,45 L15.3947368,69.2763158 L0,55.6578947 L14.2105263,46.7763158 L3.55263158,34.3421053 L13.0263158,14.2105263 L36.7105263,6.57368896e-17 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Pazaryolu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.4605263" y="37.9736842">
                      PZY
                    </tspan>
                  </text>
                </g>
                <g id="senkaya" transform="translate(273.552632, 17.171053)">
                  <path
                    d="M31.9736842,0 L62.1710526,29.0131579 L62.1710526,68.6842105 L38.4868421,97.6973684 L3.55263158,106.578947 L7.69736842,91.7763158 L0,26.0526316 L7.69736842,10.0657895 L31.9736842,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Şenkaya"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="27.2368421" y="52.1842105">
                      ŞNK
                    </tspan>
                  </text>
                </g>
                <g id="tekman" transform="translate(124.934211, 226.184211)">
                  <path
                    d="M55.0657895,95.9210526 L26.6447368,98.2894737 L0,80.5263158 L8.88157895,47.3684211 L33.1578947,24.8684211 L28.4210526,9.47368421 L37.8947368,0 L53.8815789,0 L91.7763158,1.18421053 L117.236842,59.8026316 L88.8157895,62.1710526 L55.6578947,78.1578947 L55.0657895,95.9210526 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tekman"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="55.0657895" y="48.0394737">
                      TKM
                    </tspan>
                  </text>
                </g>
                <g id="tortum" transform="translate(126.710526, 82.302632)">
                  <path
                    d="M27.8289474,0 L69.2763158,15.3947368 L81.1184211,29.6052632 L76.3815789,52.6973684 L87.6315789,58.0263158 L78.75,71.6447368 L69.8684211,71.6447368 L39.6710526,60.9868421 L25.4605263,77.5657895 L15.3947368,74.0131579 L2.96052632,50.9210526 L0,20.1315789 L27.8289474,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Tortum"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="34.3421053" y="38.5657895">
                      TOR
                    </tspan>
                  </text>
                </g>
                <g id="uzundere" transform="translate(154.539474, 57.434211)">
                  <path
                    d="M41.4473684,40.2631579 L56.25,3.55263158 L34.3421053,0 L0,24.8684211 L41.4473684,40.2631579 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Uzundere"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="26.0526316" y="24.9473684">
                      UZD
                    </tspan>
                  </text>
                </g>
                <g id="yakutiye" transform="translate(126.710526, 143.289474)">
                  <path
                    d="M70.4605263,10.6578947 L39.6710526,0 L25.4605263,16.5789474 L15.3947368,13.0263158 L0,20.7236842 L5.92105263,71.6447368 L52.1052632,60.3947368 L49.7368421,36.7105263 L70.4605263,10.6578947 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Yakutiye"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.4605263" y="40.9342105">
                      YKT
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
