import React, { useEffect } from "react";
import "../turkeymap.css";

export const Nevsehir = ({ handleClick }) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current.attributes.autofocus = true;
   
    function svgturkiyeharitasi() {
      const element = document.querySelector("#svg-turkiye-haritasi");
      const info = document.querySelector(".il-isimleri");

      element.addEventListener("mouseover", function (event) {
        const parent = event.target.parentNode;
        if (
          event.target.tagName === "path" &&
          event.target.parentNode.id !== "guney-kibris"
        ) {
          info.innerHTML = [
            "<div>",
            parent.getElementsByTagName("text")[0].dataset.city,
            "</div>"
          ].join("");
        }
      });

      element.addEventListener("mousemove", function (event) {
        info.style.top = event.pageY + 15 + "px";
        info.style.left = event.pageX + "px";
      });

      element.addEventListener("mouseout", function (event) {
        info.innerHTML = "";
      });

      element.addEventListener(
        "click",

        function (event) {
          if (event.target.tagName === "path") {
            const parent = event.target.parentNode;
            const id = parent.getAttribute("data-city");
            handleClick(parent.getElementsByTagName("text")[0].dataset.city);
          }
        },

        []
      );
    }
    svgturkiyeharitasi();
  }, []);
  return (
    <>
      <div className="il-isimleri"></div>
      <div className="svg-turkiye-haritasi">
        <svg
          onClick={(event) => {
            if (event.target.tagName === "path") {
              const parent = event.target.parentNode;
              const id = parent.getAttribute("data-city");
              handleClick(parent.getElementsByTagName("text")[0].dataset.city);
            }
          }}
          ref={ref}
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 366"
          version="1.1"
          class="election-map"
          width="536px"
          height="466px"
        >
          <g
            id="turkey"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="il">
              <g transform="translate(98.000000, 3.000000)">
                <g id="acigol" transform="translate(50.594595, 236.432432)">
                  <path
                    d="M27.2432432,106.054054 L3.89189189,88.5405405 L16.5405405,63.2432432 L13.6216216,46.7027027 L0,35.027027 L13.6216216,18.4864865 L2.91891892,7.78378378 L5.83783784,1.94594595 L25.2972973,2.16043399e-16 L62.2702703,36.972973 L58.3783784,60.3243243 L68.1081081,76.8648649 L27.2432432,106.054054 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Acıgöl"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="28.2162162" y="55.6216216">
                      ACG
                    </tspan>
                  </text>
                </g>
                <g id="avanos" transform="translate(136.216216, 99.243243)">
                  <path
                    d="M69.0810811,109.945946 L35.027027,152.756757 L5.83783784,131.351351 L0,81.7297297 L23.3513514,31.1351351 L14.5945946,17.5135135 L77.8378378,0 L83.6756757,7.78378378 L83.6756757,34.0540541 L69.0810811,109.945946 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Avanos"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="30.1621622" y="75.0810811">
                      AVN
                    </tspan>
                  </text>
                </g>
                <g id="derinkuyu" transform="translate(77.837838, 285.081081)">
                  <path
                    d="M105.081081,63.2432432 L73.9459459,74.9189189 L18.4864865,71.027027 L1.080217e-16,57.4054054 L40.8648649,28.2162162 L72,27.2432432 L88.5405405,0 L96.3243243,8.75675676 L88.5405405,35.027027 L105.081081,51.5675676 L105.081081,63.2432432 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Derinkuyu"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="51.5675676" y="55.6216216">
                      DRN
                    </tspan>
                  </text>
                </g>
                <g id="gulsehir" transform="translate(0.000000, 170.270270)">
                  <path
                    d="M136.216216,10.7027027 L122.594595,4.86486486 L108.972973,15.5675676 L70.0540541,11.6756757 L53.5135135,6.81081081 L20.4324324,0 L0,26.2702703 L20.4324324,36 L19.4594595,85.6216216 L50.5945946,101.189189 L64.2162162,84.6486486 L53.5135135,73.9459459 L56.4324324,68.1081081 L142.054054,60.3243243 L136.216216,10.7027027 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Gülşehir"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="62.2702703" y="43.9459459">
                      GLŞ
                    </tspan>
                  </text>
                </g>
                <g id="hacibektas" transform="translate(53.513514, 79.783784)">
                  <path
                    d="M66.1621622,0 L49.6216216,11.6756757 L52.5405405,38.9189189 L33.0810811,37.9459459 L31.1351351,46.7027027 L36.972973,58.3783784 L18.4864865,64.2162162 L12.6486486,80.7567568 L0,97.2972973 L16.5405405,102.162162 L55.4594595,106.054054 L69.0810811,95.3513514 L82.7027027,101.189189 L106.054054,50.5945946 L97.2972973,36.972973 L66.1621622,0 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Hacıbektaş"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="66.1621622" y="62.4324324">
                      HCB
                    </tspan>
                  </text>
                </g>
                <g id="kozakli" transform="translate(103.135135, 0.000000)">
                  <path
                    d="M110.918919,99.2432432 L82.7027027,72 L85.6216216,42.8108108 L61.2972973,31.1351351 L17.5135135,0 L5.83783784,17.5135135 L-1.42108547e-13,43.7837838 L34.0540541,41.8378378 L34.0540541,51.5675676 L16.5405405,79.7837838 L47.6756757,116.756757 L110.918919,99.2432432 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Kozaklı"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="43.7837838" y="79.9459459">
                      KOZ
                    </tspan>
                  </text>
                </g>
                <g id="merkez" transform="translate(75.891892, 230.594595)">
                  <path
                    d="M42.8108108,82.7027027 L33.0810811,66.1621622 L36.972973,42.8108108 L0,5.83783784 L66.1621622,0 L95.3513514,21.4054054 L90.4864865,54.4864865 L73.9459459,81.7297297 L42.8108108,82.7027027 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Merkez"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="52.5405405" y="42.972973">
                      MER
                    </tspan>
                  </text>
                </g>
                <g id="urgup" transform="translate(166.378378, 209.189189)">
                  <path
                    d="M16.5405405,127.459459 L17.5135135,109.945946 L60.3243243,74.9189189 L66.1621622,55.4594595 L53.5135135,40.8648649 L53.5135135,12.6486486 L38.9189189,0 L4.86486486,42.8108108 L0,75.8918919 L7.78378378,84.6486486 L0,110.918919 L16.5405405,127.459459 Z"
                    stroke="#FFFFFF"
                    fill="#9FB4B7"
                  ></path>
                  <text
                    data-city="Ürgüp"
                    font-family="Helvetica Neue"
                    font-size="7"
                    font-weight="bold"
                    fill="#000000"
                  >
                    <tspan x="25.2972973" y="60.4864865">
                      ÜRG
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
